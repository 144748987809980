import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { NAAC } from "../../../../utils/apiConstants";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Table,
  Upload,
} from "antd";

import { v4 as uuidv4 } from "uuid";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { getFileUrl } from "../../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../../utils/AssetsReferenceTypes";
import DocumentsModals from "../../../../modals/Students/DocumentsModal.jsx";

const FurnishTheDetails = ({ setUpdatedData, setLoading }) => {
  const [formData, setFormData] = useState({
    id: "",
    constituent_colleges: 0,
    affiliated_colleges: 0,
    colleges_under_2f: 0,
    colleges_under_12b: 0,
    naac_accredited_colleges: 0,
    autonomous_colleges: 0,
    colleges_with_postgraduate_departments: 0,
    colleges_with_research_departments: 0,
    university_recognized_research_institutes: 0,
    programmes_recognized_by_sra: "",

    status: "",
  });
  const [sra_data, setSra_data] = useState([]);
  const [badge, setBadge] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [editingRecord, setEditingRecord] = useState(null);
  let emp_id = sessionStorage.getItem("employee_id");

  const [link, setLink] = useState("");

  const [title, setTitle] = useState("");
  const handleOnchange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const addAttachment = async (file) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `Resume`,
        file.name.split(".")[1],
        setLoading,
        file
      );
      setFileUrl(d);
      console.log("file", d);
    } catch (error) {
      console.error("Error uploading file:", error);
      // message.error("File upload failed");
    }
  };
  const handleSaveAsDraft = async (e) => {
    e.preventDefault();
    // List all required fields
    // const requiredFields = [
    //   "constituent_colleges",
    //   "affiliated_colleges",
    //   "colleges_under_2f",
    //   "colleges_under_12b",
    //   "naac_accredited_colleges",
    //   "autonomous_colleges",
    //   "colleges_with_postgraduate_departments",
    //   "colleges_with_research_departments",
    //   "university_recognized_research_institutes",
    //   "programmes_recognized_by_sra",
    // ];

    // // Check if all required fields are filled
    // for (const field of requiredFields) {
    //   if (!formData[field]) {
    //     toast.error(`Please fill in the ${field.split("_").join(" ")} field`);
    //     return;
    //   }
    // }
    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/colleges-under-university`,
      data: {
        ...formData,
        status: "DRAFT",
        university_profile_id: 1,
        created_by: emp_id,
        updated_by: emp_id,
        application_id: 1,
        sra_data: JSON.stringify(sra_data),
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(" Successfully saved as draft");
        getFormData();
        setUpdatedData(uuidv4());
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const handleSave = () => {
    form.validateFields().then((values) => {
      if (editingRecord) {
        // Editing an existing record
        setSra_data(
          sra_data.map((item) =>
            (item.id && item.id === editingRecord.id) ||
            (item.tempId && item.tempId === editingRecord.tempId)
              ? { ...item, ...values, document: fileUrl }
              : item
          )
        );
      } else {
        // Adding a new record without `id`
        setSra_data([
          ...sra_data,
          {
            ...values,
            document: fileUrl,
            tempId: uuidv4(),
          },
        ]);
      }
      setIsModalVisible(false);
    });
  };

  const beforeUpload = (file) => {
    setUploadedFile(file);
    addAttachment(file); // Call the API to upload the file and get the URL
    return false; // Prevent automatic upload by the Upload component
  };
  const handleSubmitForReview = async (e) => {
    setLoading(1);

    e.preventDefault();
    // List all required fields
    // const requiredFields = [
    //   "constituent_colleges",
    //   "affiliated_colleges",
    //   "colleges_under_2f",
    //   "colleges_under_12b",
    //   "naac_accredited_colleges",
    //   "autonomous_colleges",
    //   "colleges_with_postgraduate_departments",
    //   "colleges_with_research_departments",
    //   "university_recognized_research_institutes",
    //   "programmes_recognized_by_sra",
    // ];

    // // Check if all required fields are filled
    // for (const field of requiredFields) {
    //   if (!formData[field]) {
    //     toast.error(`Please fill in the ${field.split("_").join(" ")} field`);
    //     return;
    //   }
    // }

    const config = {
      method: "patch",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/colleges-under-university`,
      data: {
        ...formData,
        status: "REVIEW",
        university_profile_id: 1,
        created_by: emp_id,
        updated_by: emp_id,
        application_id: 1,
        sra_data: JSON.stringify(sra_data),
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(" Successfully submitted for review");
        getFormData();
        setLoading(0);
        setUpdatedData(uuidv4());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const getFormData = async () => {
    setLoading(1);

    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      //  Make this url dynamic after adding the university or college id to the university-profile table
      url: `${NAAC}/colleges-under-university/1`,
    };
    await axios(config)
      .then((res) => {
        let data = res.data[0];
        if (data) {
          setFormData(data);
          setSra_data(() => JSON.parse(data.sra_data));
        }
        setLoading(0);

        // console.log("data fetched is", res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };
  const handleAdd = () => {
    form.resetFields();
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const handleDelete = (identifier) => {
    const updatedData = sra_data.filter((item) => {
      if (item.id === identifier) {
        // For existing items with `id`, mark as INACTIVE
        item.status = "INACTIVE";
        return true; // Keep in the array to send in `toDelete`
      }
      // For new items with `tempId` and no `id`, remove from array
      return item.tempId !== identifier;
    });

    setSra_data(updatedData);
  };

  useEffect(() => {
    getFormData();
  }, []);
  const columns = [
    {
      title: "S. No",

      key: "s_no",
      render: (_, record, index) => <p>{index + 1}</p>,
    },
    {
      title: "SRA program ",
      dataIndex: "sra_program",
      key: "sra_program",
    },

    {
      title: "Document",
      key: "view",
      render: (text, record) => (
        <a
          className="mt-3 ml-1 text-primary"
          data-toggle="modal"
          data-target="#DocumentsModal"
          onClick={(e) => {
            e.preventDefault();
            setTitle(record?.sra_program);
            setLink(record?.document);
          }}
        >
          View Document
          {console.log("Modal image link", record?.document)}
        </a>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record?.id || record?.tempId)}
            okText="Yes"
            cancelText="No"
            placement="topRight"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex flex-column gap-2">
        <span className="fw-semibold h5">
          Furnish the Details of Colleges under University
        </span>
        <DocumentsModals img={link} title={title} setLink={setLink} />
        <span
          className={`text-white text-center px-4 py-1 shadow-sm text-xs rounded-pill ${
            formData.status === "DRAFT"
              ? "bg-primary"
              : formData.status === "REVIEW"
              ? "bg-warning"
              : formData.status === "REJECTED"
              ? "bg-danger"
              : formData.status === "APPROVED"
              ? "bg-success"
              : "bg-secondary"
          }`}
          style={{ width: "fit-content" }}
        >
          {formData.status === "DRAFT"
            ? "In Draft"
            : formData.status === "REVIEW"
            ? "Submitted for review"
            : formData.status === "REJECTED"
            ? "Rejected"
            : formData.status === "APPROVED"
            ? "Approved"
            : "Not Started"}
        </span>
      </div>

      <form className="mt-4">
        <table className="table table-bordered">
          <tr>
            <th>Type Of Colleges</th>
            <th>Numbers</th>
          </tr>
          <tbody>
            <tr>
              <td className="w-50 align-middle font-weight-bold">
                Constituent Colleges
              </td>
              <td>
                <input
                  type="number"
                  name="constituent_colleges"
                  value={formData.constituent_colleges}
                  onChange={handleOnchange}
                  className="form-control"
                  required
                  min={0}
                />
              </td>
            </tr>
            <tr>
              <td className="w-50 align-middle font-weight-bold">
                Affiliated Colleges
              </td>
              <td>
                <input
                  type="number"
                  name="affiliated_colleges"
                  value={formData.affiliated_colleges}
                  onChange={handleOnchange}
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <td className="w-50 align-middle font-weight-bold">
                Colleges Under 2(f)
              </td>
              <td>
                <input
                  type="number"
                  name="colleges_under_2f"
                  value={formData.colleges_under_2f}
                  onChange={handleOnchange}
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <td className="w-50 align-middle font-weight-bold">
                Colleges Under 12B
              </td>
              <td>
                <input
                  type="number"
                  name="colleges_under_12b"
                  value={formData.colleges_under_12b}
                  onChange={handleOnchange}
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <td className="w-50 align-middle font-weight-bold">
                NAAC Accredited Colleges
              </td>
              <td>
                <input
                  type="number"
                  name="naac_accredited_colleges"
                  value={formData.naac_accredited_colleges}
                  onChange={handleOnchange}
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <td className="w-50 align-middle font-weight-bold">
                Autonomous Colleges
              </td>
              <td>
                <input
                  type="number"
                  name="autonomous_colleges"
                  value={formData.autonomous_colleges}
                  onChange={handleOnchange}
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <td className="w-50 align-middle font-weight-bold">
                Colleges with Post Graduate Departments
              </td>
              <td>
                <input
                  type="number"
                  name="colleges_with_postgraduate_departments"
                  value={formData.colleges_with_postgraduate_departments}
                  onChange={handleOnchange}
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <td className="w-50 align-middle font-weight-bold">
                Colleges with Research Departments
              </td>
              <td>
                <input
                  type="number"
                  name="colleges_with_research_departments"
                  value={formData.colleges_with_research_departments}
                  onChange={handleOnchange}
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <td className="w-50 align-middle font-weight-bold">
                University Recognized Research Institutes/Centers
              </td>
              <td>
                <input
                  type="number"
                  name="university_recognized_research_institutes"
                  value={formData.university_recognized_research_institutes}
                  onChange={handleOnchange}
                  className="form-control"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <div className="mt-5">
          <form>
            <label
              className="fw-semibold h5"
              htmlFor="programmes_recognized_by_sra"
            >
              Is the University Offering any Programmes Recognised by any
              Statutory Regulatory Authority (SRA)
            </label>
            <select
              name="programmes_recognized_by_sra"
              value={formData.programmes_recognized_by_sra}
              onChange={handleOnchange}
              className="form-control"
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
          </form>

          <div>
            {formData.programmes_recognized_by_sra === "yes" ? (
              <div className="d-flex flex-column gap-2 mt-4">
                <Modal
                  title={editingRecord ? "Edit Record" : "Add New Record"}
                  visible={isModalVisible}
                  onCancel={() => setIsModalVisible(false)}
                  onOk={handleSave}
                  okText="Save"
                  cancelText="Cancel"
                >
                  <Form form={form} layout="vertical">
                    <Form.Item
                      label="SRA Program"
                      name="sra_program"
                      rules={[
                        {
                          required: true,
                          message: "Please input the SRA Program ",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Upload Document"
                      name="documentUpload"
                      rules={[
                        { required: true, message: "Please upload a document" },
                      ]}
                    >
                      <Upload
                        accept=".pdf, .jpg, .jpeg, .png"
                        beforeUpload={beforeUpload} // Handle the upload manually
                      >
                        <Button icon={<UploadOutlined />}>Choose File</Button>
                      </Upload>
                    </Form.Item>
                  </Form>
                </Modal>

                <div className="d-flex justify-content-end">
                  <Button
                    type="primary"
                    onClick={handleAdd}
                    icon={<PlusOutlined />}
                  >
                    Add New Row
                  </Button>
                </div>

                <div className="mt-0 d-flex flex-column">
                  <div className="overflow-auto">
                    <div className="table-responsive shadow-sm">
                      <Table
                        dataSource={sra_data?.filter(
                          (item) => item.status !== "INACTIVE"
                        )}
                        columns={columns}
                        rowKey="id"
                        pagination={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="d-flex justify-content-end my-3 mt-3">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSaveAsDraft}
          >
            Save as Draft
          </button>
          <button
            type="button"
            className="btn btn-warning"
            onClick={handleSubmitForReview}
          >
            Submit for Review
          </button>
        </div>
      </form>
    </div>
  );
};

export default FurnishTheDetails;

import { Button, Steps, message } from "antd";
import React, { useEffect, useState } from "react";
import PersonalInformation from "./PersonalInformation";
import EducationDetails from "./EducationDetails";
import Documents from "./Documents";
import Submit from "./Submit";
import ApplicationFee from "./ApplicationFee.jsx";
import "./JobApplications.css"; // Import the CSS file for styling
import {
  JOB_APPLICATIONSS,
  JOB_OPENINGS,
} from "../../../../utils/apiConstants";
import axios from "axios";
import { toast } from "react-toastify";

const steps = [
  {
    title: "Personal Information",
    component: PersonalInformation,
    formLabel: "personalinformation",
  },
  {
    title: "Education Details",
    component: EducationDetails,
    formLabel: "educationdetails",
  },
  {
    title: "Documents",
    component: Documents,
    formLabel: "documents",
  },
  {
    title: "ApplicationFee",
    component: ApplicationFee,
    formLabel: "applicationfee",
  },
  {
    title: "Submit",
    component: Submit,
    formLabel: "submit",
  },
];

const JobApplications = ({ setLoading }) => {
  const [current, setCurrent] = useState(0); // Track the current step
  const [formData, setFormData] = useState({
    personalinformation: null,
    educationdetails: [],
    documents: [],
    applicationfee: null,
  });
  const [jobOpts, setJobOpts] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [educationsRequired, setEducationsRequired] = useState([]);
  const [documentsRequired, setDocumentsRequired] = useState([]);
  const [genderRequired, setGenderRequired] = useState([]);
  const [jobId, setJobId] = useState(null);
  const [educationOptions, setEducationOptions] = useState([]);
  const [docOptions, setDocOptions] = useState([]);

  useEffect(() => {
    if (jobId) {
      const education = educationsRequired.filter((item) => item.id === jobId);
      const doc = documentsRequired.filter((item) => item.id === jobId);

      if (education.length > 0 && education[0].label) {
        setEducationOptions(JSON.parse(education[0].label)); // Store the options in state
      } else {
        setEducationOptions(null);
      }

      if (doc.length > 0 && doc[0].label) {
        setDocOptions(JSON.parse(doc[0].label)); // Store the options in state
      } else {
        setDocOptions(null);
      }
    }
  }, [jobId]);

  const getAllData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: JOB_OPENINGS,
    };

    axios(config)
      .then((res) => {
        console.log("job openings data - ", res.data.data);
        let tempData = res.data.data?.filter((s) => s.status == "ACTIVE");
        setJobs(tempData);
        let options = [];
        let edtRequired = [];
        let docRequired = [];

        for (let i of tempData) {
          let obj = {
            value: i?.id,
            label: i?.title,
            from_age: i?.from_age,
            to_age: i?.to_age,
            gender_required: i?.gender_required,
          };
          let obj2 = {
            id: i?.id,
            label: i?.education_required,
          };
          let obj3 = {
            id: i?.id,
            label: i?.documents_required,
          };

          options.push(obj);
          edtRequired.push(obj2);
          docRequired.push(obj3);
        }

        setJobOpts(options);
        setEducationsRequired(edtRequired);
        setDocumentsRequired(docRequired);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };
  useEffect(() => {
    getAllData();
  }, []);
  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  // const handleFinish = (data, stepKey) => {
  //   // Only add non-empty data
  //   // if ((stepKey == "educationdetails" || stepKey == "documents") && data) {
  //   //   // Prevent adding empty data
  //   //   if (Object.keys(data).length > 0) {
  //   //     setFormData((prev) => ({
  //   //       ...prev,
  //   //       [stepKey]: [...prev[stepKey], data],
  //   //     }));
  //   //   }
  //   // }

  //   if (stepKey === "educationdetails" || stepKey === "documents") {
  //     // Replace the entire array with updated data (add or delete)
  //     if (data) {
  //       setFormData((prev) => ({
  //         ...prev,
  //         // [stepKey]: Array.isArray(prev[stepKey])
  //         //   ? [...prev[stepKey], data]
  //         //   : [data], // Append the new entry to the existing array
  //         [stepKey]: [...prev[stepKey], data],
  //       }));
  //     }
  //   } else if (data) {
  //     // For non-array type data, ensure it's not empty
  //     setFormData((prev) => ({
  //       ...prev,
  //       [stepKey]: data,
  //     }));
  //   }
  // };
  const handleFinish = (data, stepKey) => {
    if (stepKey === "educationdetails" || stepKey === "documents") {
      if (data) {
        setFormData((prev) => ({
          ...prev,
          [stepKey]: data, // Replace with the updated array, not appending individual items
        }));
      }
    } else if (data) {
      setFormData((prev) => ({
        ...prev,
        [stepKey]: data, // For non-array data like personalinformation
      }));
    }
  };

  const handleSubmit = () => {
    const { personalinformation, educationdetails, documents, applicationfee } =
      formData;
    if (
      !personalinformation ||
      !educationdetails.length > 0 ||
      !documents.length > 0 ||
      !applicationfee
    ) {
      toast.error("Please complete  all the steps");
      return;
    }
    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: JOB_APPLICATIONSS,
      data: formData,
    };
    axios(config)
      .then((res) => {
        console.log("Job openings data - ", res.data.data);
        toast.success("Successfully applied for the job");
      })
      .catch((err) => {
        setLoading(0);
        console.error("Error applying for the job:", err);
        toast.error("Something went wrong. Please try again.");
      })
      .finally(() => {
        next();
        setLoading(0);
      });
  };
  // Step items with status (finish, process, wait)
  const items = steps.map((item, index) => ({
    key: item.title,
    title: item.title,
    status: index < current ? "finish" : index === current ? "process" : "wait",
    label: item.title.toLowerCase().replace(/ /g, ""),
  }));

  const CurrentComponent = steps[current].component;
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="job-application-container">
            <div className="steps-container">
              <Steps
                current={current}
                labelPlacement="vertical"
                className="custom-steps"
                // onChange={(step) => setCurrent(step)}
              >
                {items.map((item, index) => {
                  // console.log(item);
                  // console.log(formData[item.label]);

                  let dataPresent =
                    item.label === "educationdetails" ||
                    item.label === "documents"
                      ? formData[item.label]?.length > 0
                      : formData[item.label];
                  // console.log("dataPresent for", item.title, dataPresent);

                  return (
                    <Steps.Step
                      key={item.key}
                      title={item.title}
                      status={item.status}
                      className={`step-item ${
                        dataPresent || current == steps.length - 1
                          ? "completed-step"
                          : index === current
                          ? "current-step"
                          : "not-started-step"
                      }`}
                    />
                  );
                })}
              </Steps>
            </div>

            <div className="form-container">
              {/* Render the current step's form */}
              <CurrentComponent
                onFinish={(data) =>
                  handleFinish(
                    data,
                    steps[current].title.toLowerCase().replace(/ /g, "")
                  )
                }
                initialValues={
                  formData[steps[current].title.toLowerCase().replace(/ /g, "")]
                }
                next={next}
                prev={prev}
                handleSubmit={handleSubmit}
                formData={formData}
                jobOpts={jobOpts}
                jobs={jobs}
                educationOptions={educationOptions}
                docOptions={docOptions}
                setJobId={setJobId}
                setLoading={setLoading}
                setFormData={setFormData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobApplications;

import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../../utils/AssetsReferenceTypes";
import { getFileUrl } from "../../../../Helpers/Helpers";
import { message } from "antd";
import axios from "axios";
import { NAAC } from "../../../../utils/apiConstants";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
const toolbar = [
  "undo",
  "redo",
  "|",
  "heading",
  "|",
  "bold",
  "italic",
  "|",
  "link",
  "blockQuote",
  "insertTable",
  "|",
  "bulletedList",
  "numberedList",
  "outdent",
  "alignment",
  "indent",
  "|",
  "imageUpload",
];
const maxWords = 500;
const title = "nep_parameters";
const sub_title = "skill_development";

export default function SkillDevelopment({ setLoading, setUpdatedData }) {
  const [data, setData] = useState({
    id: "",
    status: "",
    efforts_strengthen_vocational_education: "",
    promote_vocational_education: "",
    value_based_education: "",
    list_of_institution_efforts: "",
    skill_development: "",
  });
  let emp_id = sessionStorage.getItem("employee_id");
  const [wordCount, setWordCount] = useState({
    efforts_strengthen_vocational_education: 0,
    promote_vocational_education: 0,
    value_based_education: 0,
    list_of_institution_efforts: 0,
    skill_development: 0,
  });
  const countWords = (text) => {
    const words = text.trim().split(/\s+/); // Split by spaces and trim extra spaces
    return words.filter((word) => word.length > 0).length; // Filter out empty words
  };
  const handleEditorChange = (editor, key) => {
    const editorData = editor.getData();
    const currentWordCount = countWords(editorData);

    if (currentWordCount <= maxWords) {
      setData((prevValue) => ({
        ...prevValue,
        [key]: editorData,
      }));
    }
    setWordCount((prev) => ({ ...prev, [key]: currentWordCount }));
  };
  const addAttachment = async (file) => {
    try {
      const response = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `Resume`,
        file.name.split(".")[1],
        setLoading,
        file
      );
      return response;
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("File upload failed");
      throw error;
    }
  };

  const createUploadAdapter = (loader) => {
    return {
      upload: async () => {
        try {
          const file = await loader.file;
          // Directly get the uploaded file URL
          const uploadedUrl = await addAttachment(file);
          console.log("Uploaded image URL:", uploadedUrl);
          // Return the URL in the format CKEditor expects
          return { default: uploadedUrl };
        } catch (error) {
          console.error("Upload failed:", error);
          throw error;
        }
      },
    };
  };
  function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return createUploadAdapter(loader);
    };
  }
  const validateData = () => {
    const requiredFields = [
      "efforts_strengthen_vocational_education",
      "promote_vocational_education",
      "value_based_education",
      "list_of_institution_efforts",
      "skill_development",
    ];

    for (const field of requiredFields) {
      if (wordCount[field] === 0) {
        toast.error(`Please fill in the ${field.split("_").join(" ")} field`);
        return false;
      }
      if (wordCount[field] > maxWords) {
        toast.error(
          `Please reduce the number of words in the ${field
            .split("_")
            .join(" ")} field`
        );
        return false;
      }
    }
    return true;
  };
  const handleSaveOrSubmit = async (e, status) => {
    let {
      efforts_strengthen_vocational_education,
      promote_vocational_education,
      value_based_education,
      list_of_institution_efforts,
      skill_development,
    } = data;
    let jsonResponse = JSON.stringify({
      efforts_strengthen_vocational_education,
      promote_vocational_education,
      value_based_education,
      list_of_institution_efforts,
      skill_development,
    });
    e.preventDefault();
    // List all required fields
    if (!validateData()) return;
    const info = {
      ...data,
      title: title,
      response: jsonResponse,
      sub_title: sub_title,
      created_by: emp_id,
      updated_by: emp_id,
      status,
      application_id: 1,
    };
    console.log("info", info);
    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/nep-parameters`,
      data: info,
    };
    await axios(config)
      .then((res) => {
        setUpdatedData(uuidv4());
        toast.success(
          `${
            status == "DRAFT"
              ? "Successfully saved as draft"
              : "Successfully submitted for review"
          } `
        );
        getFormData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const handleSaveAsDraft = (e) => {
    handleSaveOrSubmit(e, "DRAFT");
  };
  const handleSubmitForReview = (e) => {
    handleSaveOrSubmit(e, "REVIEW");
  };
  const getFormData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/nep-parameters/${sub_title}`,
    };

    await axios(config)
      .then((res) => {
        let backendData = res.data[0];
        if (data) {
          const responseObj = JSON.parse(backendData?.response || "");
          setData((prevState) => ({
            ...prevState,
            id: backendData.id,
            status: backendData.status,
            ...responseObj,
          }));
        }
        // console.log("data fetched is", res.data);
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  useEffect(() => {
    getFormData();
  }, []);
  return (
    <>
      <div>
        <div className="d-flex flex-column mb-3">
          <p className="h5">3. Skill Development</p>
          <span className="text-danger">
            *Note - Maximum word allowed is {maxWords} words
          </span>
        </div>
        <span
          className={`text-white d-inline-block text-center px-4 py-1 shadow-sm small rounded-pill ${
            data?.status === "DRAFT"
              ? "bg-primary"
              : data?.status === "REVIEW"
              ? "bg-warning"
              : data?.status === "REJECTED"
              ? "bg-danger"
              : data?.status === "APPROVED"
              ? "bg-success"
              : "bg-secondary"
          }`}
          style={{ width: "fit-content" }}
        >
          {data?.status === "DRAFT"
            ? "In Draft"
            : data?.status === "REVIEW"
            ? "Submitted for review"
            : data?.status === "REJECTED"
            ? "Rejected"
            : data?.status === "APPROVED"
            ? "Approved"
            : "Not Started"}
        </span>
      </div>

      <div>
        <p>
          a) Describe the efforts made by the institution to strengthen the
          vocational education and soft skills of students in alignment with
          National Skills Qualifications Framework.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.efforts_strengthen_vocational_education}
          onChange={(event, editor) =>
            handleEditorChange(
              editor,
              "efforts_strengthen_vocational_education"
            )
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.efforts_strengthen_vocational_education >= maxWords
                ? "text-danger"
                : ""
            }`}
          >
            {wordCount.efforts_strengthen_vocational_education}/{maxWords}{" "}
            {wordCount.efforts_strengthen_vocational_education > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div>
        <p>
          b) Provide the details of the programmes offered to promote vocational
          education and its integration into mainstream education.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.promote_vocational_education}
          onChange={(event, editor) =>
            handleEditorChange(editor, "promote_vocational_education")
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.promote_vocational_education >= maxWords
                ? "text-danger"
                : ""
            }`}
          >
            {wordCount.promote_vocational_education}/{maxWords}{" "}
            {wordCount.promote_vocational_education > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div>
        <p>
          c) How the institution is providing Value-based education to inculcate
          positivity amongst the learner that includes the development of
          humanistic, ethical, Constitutional, and universal human values of
          truth (satya), righteous conduct (dharma), peace (shanti), love
          (prem), nonviolence (ahimsa), scientific temper, citizenship values,
          and life-skills.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.value_based_education}
          onChange={(event, editor) =>
            handleEditorChange(editor, "value_based_education")
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.value_based_education >= maxWords ? "text-danger" : ""
            }`}
          >
            {wordCount.value_based_education}/{maxWords}{" "}
            {wordCount.value_based_education > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div>
        <p>
          d) Enlist the institution's efforts to: <br />
          i) Design a credit structure to ensure that all students take at least
          one vocational course before graduating. <br />
          ii) Engaging the services of Industry veterans and Master Crafts
          persons to provide vocational skills and address faculty gaps. <br />
          iii) Offer vocational education in ODL/blended/on-campus modular
          modes. <br />
          iv) NSDC association to manage learner enrolment, skill mapping, and
          certification. <br />
          v) Offer skilling courses through online and/or distance mode.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.list_of_institution_efforts}
          onChange={(event, editor) =>
            handleEditorChange(editor, "list_of_institution_efforts")
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.list_of_institution_efforts >= maxWords
                ? "text-danger"
                : ""
            }`}
          >
            {wordCount.list_of_institution_efforts}/{maxWords}{" "}
            {wordCount.list_of_institution_efforts > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div>
        <p>
          e) Describe any good practice/s of the institution pertaining to Skill
          Development in view of NEP 2020.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.skill_development}
          onChange={(event, editor) =>
            handleEditorChange(editor, "skill_development")
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.skill_development >= maxWords ? "text-danger" : ""
            }`}
          >
            {wordCount.skill_development}/{maxWords}{" "}
            {wordCount.skill_development > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div className="text-right mt-5">
        <button className="btn btn-primary mr-2" onClick={handleSaveAsDraft}>
          Save as Draft
        </button>
        <button className="btn btn-warning" onClick={handleSubmitForReview}>
          <span>Submit for Review</span>
        </button>
      </div>
    </>
  );
}

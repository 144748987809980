import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Loader from "../../../../Components/Loader/Loader";
import Nodata from "../../../../Components/NoData/Nodata";
import { sessionOpt } from "../../../../Data/jsonData/Academics/Academics";
import useEmployee from "../../../../Hooks/Employee/useEmployee";
import ModalPrintPaySlip from "./ModalPrintPaySlip";
import { PAYROLL_MONTHWISE_PAYSLIPS } from "../../../../utils/apiConstants";
import { LOCAL_EMPLOYEE } from "../../../../utils/LocalStorageConstants";
import { LOCAL_JOBROLES } from "../../../../utils/LocalStorageConstants";
import {
  LOCAL_DEPARTMENT,
  LOCAL_COLLEGE,
} from "../../../../utils/LocalStorageConstants";
import getUniversityDetails from "../../../../utils/universityDetails.api";
import ReactToPrint from "react-to-print";
import Check from "./check";

function PaySlipPrint({ setLoading, collegeId }) {
  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  const [count, setCount] = useState(0);

  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const componentRef = useRef();

  const handleBeforeGetContent = (data) => {
    // Set data and return a resolved Promise to ReactToPrint once the data is updated
    return new Promise((resolve) => {
      setData(data);
      resolve();
    }).then(() => {
      console.log("Data ready for print:", data); // Confirm data is set before printing
    });
  };

  useEffect(() => {
    setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT), collegeId]);

  const [universityData, setUniversityData] = useState([]);

  const [month, setMonth] = useState("");
  const [toMonth, setToMonth] = useState("");
  const [year, setYear] = useState("2024");
  const [designationSearch, setDesignationSearch] = useState("");
  const [searchName, setSearchName] = useState("");

  const jobPositionOpt = JSON.parse(localStorage.getItem(LOCAL_JOBROLES));
  const [empData, setEmpData] = useState([]);
  const [displayData, setDisplayData] = useState([]);

  const [data, setData] = useState([]);

  const getData = async () => {
    if (!year) return toast.error("Please Select Year");
    if (!month) return toast.error("Please Select From Month");
    if (!toMonth) return toast.error("Please Select To Month");
    const config = {
      method: "get",
      url: `${PAYROLL_MONTHWISE_PAYSLIPS}?college_id=${collegeId}&year=${year}&month=${month}&to_month=${toMonth}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        let tempData = res.data.data;
        let finalData = [];

        for (let i of tempData) {
          i.earning = JSON.parse(i.earning);
          i.deduction = JSON.parse(i.deduction);
          i.bonus = JSON.parse(i.bonus);
          i.salary_details = JSON.parse(i.salary_details);
          i.leave_details = JSON.parse(i.leave_details);
          let existingEmployee = finalData.find(
            (s) => s.employee_id == i.employee_id
          );
          if (existingEmployee) {
            existingEmployee.data.push(i);
          } else {
            finalData.push({
              employee_id: i.employee_id,
              role: i.role,
              first_name: i.first_name,
              last_name: i.last_name,
              department_id: i.department_id,
              data: [i],
            });
          }
        }

        for (let entry of finalData) {
          if (Array.isArray(entry.data)) {
            entry.data.sort((a, b) => {
              return a.month < b.month ? -1 : a.month > b.month ? 1 : 0;
            });
          }
        }

        const sortedNames = res.data.data.sort((a, b) => {
          // Regular expression to remove designations
          const removePrefix = (str) =>
            str
              .replace(/^(Mr\.?|Mr\. ?|Mrs\.?|Ms\.?|Dr\.?|Dr\. ?)\s+/i, "")
              .replace(/^(\.?|\. ?)\s+/i, "");

          const nameA = removePrefix(a.first_name + a.last_name)
            .split(" ")
            .join("");

          const nameB = removePrefix(b.first_name + b.last_name)
            .split(" ")
            .join("");

          // Compare the names after removing prefixes
          return nameA.localeCompare(nameB);
        });

        setEmpData(sortedNames);
        setDisplayData(sortedNames);
        console.log("employee slip data - ", finalData);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
    setLoading(0);
  };

  useEffect(() => {
    let filteredData = [];

    setDesignationSearch("");
    filteredData = empData?.filter(
      (item) =>
        item.first_name.toLowerCase().includes(searchName.toLowerCase()) ||
        item.last_name.toLowerCase().includes(searchName.toLowerCase()) ||
        `${item.first_name} ${item.last_name}`
          .toLowerCase()
          .includes(searchName.toLowerCase())
    );
    setDisplayData(filteredData);
  }, [searchName]);

  useEffect(() => {
    setDisplayData(
      empData?.filter(
        (item) => !designationSearch || item.role == designationSearch
      )
    );
  }, [designationSearch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUniversityDetails();
        setUniversityData(data);
      } catch (error) {
        console.error(`Error fetching university details: ${error.message}`);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("hello");
  }, [data]);

  return (
    <div className="PayRollProcess">
      <div className="main-content">
        {data?.length > 0 && <Check data={data} />}
        {/* <ModalPrintPaySlip
          department={department}
          jobPositionOpt={jobPositionOpt}
          universityData={universityData}
          month={month}
          toMonth={toMonth}
          year={year}
          data={data}
          setLoading={setLoading}
        /> */}
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Print Pay Slip</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/">PayRoll</a>
                      </li>
                      <li className="breadcrumb-item active">Process</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Year<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="role"
                            name="role"
                            className="form-control"
                            value={year}
                            onChange={(e) => {
                              setYear(e.target.value);
                            }}
                          >
                            <option value="">Select year</option>
                            {sessionOpt?.map((data, key) => {
                              return (
                                <option key={key} value={data.id}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            From Month<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="role"
                            name="role"
                            className="form-control"
                            value={month}
                            onChange={(e) => {
                              setMonth(e.target.value);
                            }}
                          >
                            <option value="">From Month</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            To Month<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="role"
                            name="role"
                            className="form-control"
                            value={toMonth}
                            onChange={(e) => {
                              setToMonth(e.target.value);
                            }}
                          >
                            <option value="">To Month</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <button
                          className="btn btn-nex mt-4"
                          type="submit"
                          name="submit"
                          onClick={getData}
                        >
                          Search
                        </button>
                      </div>
                    </div>

                    <div className="row "></div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="college_id">
                            Filter by Designation
                          </label>
                          <select
                            name="designation"
                            id="designation"
                            required=""
                            className="form-control"
                            value={designationSearch}
                            onChange={(e) =>
                              setDesignationSearch(e.target.value)
                            }
                          >
                            <option value="">All</option>
                            {jobPositionOpt?.map((data, key) => {
                              return (
                                <option value={data.id}>{data.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Filter By Employee Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Employee Name"
                          value={searchName}
                          onChange={(e) => {
                            setSearchName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-xl-12">
                        <div className="table-responsive mt-3 fixTableHead">
                          <table className="table table-bordered" style={{}}>
                            <thead className="">
                              <tr>
                                <th>Sl No.</th>
                                <th>Name</th>
                                <th>Department</th>
                                <th>Designation</th>
                                {/* <th>Basic Salary</th> */}
                                {/* <th>Status</th> */}
                                <th>Payslip</th>
                              </tr>
                            </thead>
                            <tbody>
                              {displayData?.map((i, k) => {
                                return (
                                  <tr>
                                    <td>{k + 1}</td>
                                    <td>
                                      {i.first_name} {i.last_name}
                                    </td>
                                    <td>
                                      {
                                        department.find(
                                          (s) => s.id == i.department_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {
                                        jobPositionOpt?.find(
                                          (s) => s.id == i.role
                                        )?.name
                                      }
                                    </td>
                                    {/* <td>
                                      <button
                                        className="btn btn-info"
                                        data-toggle="modal"
                                        data-target="#ModalPrintPaySlip"
                                        onClick={() => {
                                          setData(i.data);
                                        }}
                                      >
                                        Print
                                      </button>
                                    </td> */}
                                    {/* <ReactToPrint
                                      documentTitle={" "}
                                      onBeforeGetContent={() => {
                                        handleBeforeGetContent(i?.data);
                                      }}
                                      trigger={() => {
                                        return ( */}
                                    <button
                                      className="btn btn-success  btn-outline px-4  mr-3"
                                      // data-dismiss="modal"
                                      // aria-label="Close"
                                      onClick={() => {
                                        setData(i?.data);
                                        console.log(i?.data);
                                        setCount((count) => count + 1);
                                      }}
                                    >
                                      Print
                                    </button>
                                    {/* );
                                      }}
                                      content={() => componentRef.current}
                                    /> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaySlipPrint;

import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Table,
  Popconfirm,
  InputNumber,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { toast } from "react-toastify";

const { Option } = Select;
const defaultOptions = [
  { value: "sslc", label: "SSLC/10th" },
  { value: "puc", label: "PUC/12th" },
  { value: "bachelors", label: "BACHELORS" },
  { value: "masters", label: "MASTERS" },
  { value: "other", label: "OTHER" },
];

const EducationDetails = ({
  onFinish,
  initialValues,
  next,
  prev,
  educationOptions,
  formData,
  setFormData,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [educationData, setEducationData] = useState(
    initialValues.length > 0 ? initialValues : []
  );
  console.log(educationOptions);
  const [editingKey, setEditingKey] = useState(null);
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditingKey(null);
    setIsOtherSelected(false);
  };

  // const handleFinish = (values) => {
  //   const newEntry = {
  //     key: editingKey !== null ? editingKey : uuidv4(),
  //     educationType: isOtherSelected
  //       ? values.otherEducationType
  //       : values.educationType,
  //     universityName: values.universityName,
  //     collegeName: values.collegeName,
  //     grade: values.grade,
  //     fromYear: values.fromYear ? values.fromYear.format("MMM YYYY") : null,
  //     toYear: values.toYear ? values.toYear.format("MMM YYYY") : null,
  //   };

  //   if (editingKey !== null) {
  //     const updatedData = educationData.map((item) =>
  //       item.key === editingKey ? newEntry : item
  //     );
  //     setEducationData(updatedData);
  //     setEditingKey(null);
  //   } else {
  //     setEducationData([...educationData, newEntry]);
  //   }

  //   setIsModalVisible(false);
  //   form.resetFields();
  //   setIsOtherSelected(false);
  //   onFinish(...educationData, newEntry); // Send the new entry back to parent
  // };
  const handleFinish = (values) => {
    const newEntry = {
      key: editingKey !== null ? editingKey : uuidv4(),
      educationType: isOtherSelected
        ? values.otherEducationType
        : values.educationType,
      universityName: values.universityName,
      collegeName: values.collegeName,
      grade: values.grade,
      fromYear: values.fromYear ? values.fromYear.format("MMM YYYY") : null,
      toYear: values.toYear ? values.toYear.format("MMM YYYY") : null,
    };

    let updatedData;

    if (editingKey !== null) {
      updatedData = educationData.map((item) =>
        item.key === editingKey ? newEntry : item
      );
      setEditingKey(null);
    } else {
      updatedData = [...educationData, newEntry];
    }

    setEducationData(updatedData);

    setIsModalVisible(false);
    form.resetFields();
    setIsOtherSelected(false);

    // Pass the updated array to the parent component
    onFinish(updatedData); // This is the corrected line
  };

  const handleEdit = (record) => {
    setEditingKey(record.key);
    form.setFieldsValue({
      educationType: record.educationType,
      universityName: record.universityName,
      collegeName: record.collegeName,
      grade: record.grade,
      fromYear: record.fromYear ? moment(record.fromYear, "MMM YYYY") : null,
      toYear: record.toYear ? moment(record.toYear, "MMM YYYY") : null,
    });
    setIsModalVisible(true);
  };

  const handleDelete = (key) => {
    const updatedData = educationData.filter((item) => item.key !== key);
    setEducationData(updatedData);

    // Pass the updated data to the parent, or an empty array if all items are deleted
    // onFinish(updatedData.length > 0 ? updatedData : []);
    setFormData((prev) => ({ ...prev, educationdetails: updatedData }));
  };

  const onEducationTypeChange = (value) => {
    setIsOtherSelected(value === "other");
  };

  const handleNext = () => {
    if (educationOptions) {
      const presentTypes = educationData.map((doc) => doc.educationType);
      const missingTypes = educationOptions.filter(
        (type) => !presentTypes.includes(type)
      );
      const missingEducations = missingTypes.join(",");

      if (!(educationData.length >= educationOptions.length)) {
        toast.error(`Please add ${missingEducations} education details`);
        return;
      }
    }

    next();
  };

  const columns = [
    {
      title: "Education Type",
      dataIndex: "educationType",
      key: "educationType",
      responsive: ["sm"],
    },
    {
      title: "University Name",
      dataIndex: "universityName",
      key: "universityName",
      responsive: ["sm"],
    },
    {
      title: "School / College Name",
      dataIndex: "collegeName",
      key: "collegeName",
      responsive: ["sm"],
    },
    {
      title: "Grade / GPA / Percentage",
      dataIndex: "grade",
      key: "grade",
      responsive: ["sm"],
    },
    {
      title: "From (Month/Year)",
      dataIndex: "fromYear",
      key: "fromYear",
      responsive: ["sm"],
    },
    {
      title: "To (Month/Year)",
      dataIndex: "toYear",
      key: "toYear",
      responsive: ["sm"],
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          {/* <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button> */}
          <Popconfirm
            title="Are you sure you want to delete this entry?"
            onConfirm={() => handleDelete(record.key)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
      responsive: ["sm"],
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-end">
        <button type="button" className="btn btn-primary" onClick={showModal}>
          Add Educational Details
        </button>
      </div>

      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={educationData}
          pagination={false}
          style={{ marginTop: 20 }}
          scroll={{ x: "max-content" }} // Enable horizontal scroll if needed
        />
      </div>
      <div className="d-flex align-items-baseline justify-content-between gap-2 mt-4">
        <button className="btn btn-secondary" onClick={prev}>
          Previous
        </button>
        <button
          disabled={educationData.length > 0 ? false : true}
          className="btn btn-success"
          onClick={handleNext}
          style={{
            cursor: educationData.length > 0 ? "pointer" : "not-allowed",
          }}
        >
          Next
        </button>
      </div>

      <Modal
        title={editingKey !== null ? "Edit Education" : "Add New Education"}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        centered
        bodyStyle={{ padding: "20px" }}
      >
        <Form
          scrollToFirstError={true}
          form={form}
          layout="vertical"
          onFinish={handleFinish}
        >
          <Form.Item
            label="Select Education Type"
            name="educationType"
            rules={[
              {
                required: !isOtherSelected,
                message: "Please select an education type",
              },
            ]}
          >
            <Select
              placeholder="Choose Option"
              onChange={onEducationTypeChange}
            >
              {educationOptions.length > 0
                ? educationOptions.map((item) => (
                    <Option key={item} value={item}>
                      {item?.toUpperCase()}
                    </Option>
                  ))
                : defaultOptions.map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
            </Select>
          </Form.Item>

          {isOtherSelected && (
            <Form.Item
              label="Enter Education Type"
              name="otherEducationType"
              rules={[
                {
                  required: true,
                  message: "Please input the education type",
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Enter education type" />
            </Form.Item>
          )}

          <Form.Item
            label="School / College Name"
            name="collegeName"
            rules={[
              {
                required: true,
                message: "Please input the school/college name",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Enter school or college name" />
          </Form.Item>

          <Form.Item label="University Name" name="universityName">
            <Input placeholder="Enter university name" />
          </Form.Item>

          <Form.Item
            label="Grade / CGPA / GPA / Percentile / Percentage"
            name="grade"
            rules={[
              {
                type: "number",
                required: true,
                message: "Please input the grade",
              },
            ]}
          >
            <InputNumber
              placeholder="Enter grade, GPA, or percentage"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            label="From (Month/Year)"
            name="fromYear"
            rules={[
              {
                required: true,
                message: "Please select the starting month and year",
              },
            ]}
          >
            <DatePicker picker="month" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="To (Month/Year)"
            name="toYear"
            rules={[
              {
                required: true,
                message: "Please select the ending month and year",
              },
            ]}
          >
            <DatePicker picker="month" style={{ width: "100%" }} />
          </Form.Item>

          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default EducationDetails;

import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Table, Modal, Form, Input, Upload } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FileExcelOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../../utils/AssetsReferenceTypes";
import { getFileUrl } from "../../../../Helpers/Helpers";
import { NAAC } from "../../../../utils/apiConstants";
const title = "students";
const sub_title = "number_of_students_appeared_in_exam";
export default function NumberOfStudentsAppearedInExam({
  setUpdatedData,
  setLoading,
}) {
  const [recordId, setRecordId] = useState(null);
  const [data, setData] = useState([]);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [badge, setBadge] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [form] = Form.useForm();
  console.log("uploadedFile", uploadedFile);
  const emp_id = sessionStorage.getItem("employee_id");
  console.log("data", data);
  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleAdd = () => {
    form.resetFields();
    setEditingRecord(null);
    setIsModalVisible(true);
  };
  const extractFileName = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf(".");
    return fileName.substring(0, lastDotIndex); // Removes the extension
  };

  const handleDelete = (id) => {
    let updatedData = data.filter((item) => {
      if (recordId && item.id === id) {
        item.status = "INACTIVE";
        return true;
      }
      return item.id !== id;
    });
    setData(updatedData);
  };

  const handleSave = () => {
    form.validateFields().then((values) => {
      if (editingRecord) {
        setData(
          data.map((item) =>
            (item.id && item.id === editingRecord.id) ||
            (item.tempId && item.tempId === editingRecord.tempId)
              ? { ...item, ...values, updated_by: emp_id }
              : item
          )
        );
      } else {
        setData([
          ...data,
          {
            ...values,
            id: uuidv4(),
            status: "ACTIVE",
            created_by: emp_id,
            updated_by: emp_id,
          },
        ]);
      }
      setIsModalVisible(false);
    });
  };

  const handleDraftOrSubmit = async (e, status) => {
    e.preventDefault();

    if (data.length === 0) {
      return toast.error("Please add at least one contact detail");
    }
    // Set up the common information fields
    let info = {
      data,
      document_url: fileUrl,
      status: status,
      title: title,
      sub_title: sub_title,
      application_id: 1,
      document_name: uploadedFile,
      ...(recordId
        ? { id: recordId, updated_by: emp_id }
        : { updated_by: emp_id, created_by: emp_id }),
    };

    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/extended-profile`,
      data: info,
    };

    await axios(config)
      .then((res) => {
        console.log("response", res);
        toast.success("Successfully saved the data");
        getFormData(); // Refetch data to update view
        setUpdatedData(uuidv4());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };
  const handleSaveAsDraft = (e) => {
    handleDraftOrSubmit(e, "DRAFT");
  };
  const handleSaveAndSubmitForReview = async (e) => {
    handleDraftOrSubmit(e, "REVIEW");
  };
  useEffect(() => {
    getFormData();
  }, []);
  const columns = [
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Number of Students",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  const addAttachment = async (file) => {
    try {
      const fileLink = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        "Resume",
        extractFileName(file?.name),
        setLoading,
        file
      );
      setFileUrl(fileLink);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("File upload failed");
    }
  };
  const beforeUpload = (file) => {
    setUploadedFile(file?.name);
    addAttachment(file);
    return false;
  };

  const getFormData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      url: `${NAAC}/extended-profile/${sub_title}`,
    };
    try {
      const res = await axios(config);
      let result = res?.data[0];
      if (result?.data) {
        let jsonData;
        try {
          // Attempt to parse the data only if it's a string
          jsonData =
            typeof result?.data === "string"
              ? JSON.parse(result?.data)
              : result?.data;
          setData(jsonData);
        } catch (parseError) {
          console.error("Error parsing JSON:", parseError);
          toast.error("Failed to parse data");
        }
      } else {
        // toast.error("No data found");
        console.log("No data found");
      }

      // let jsonData = JSON.parse(result?.data);
      // setData(jsonData);
      setBadge(result?.status);
      setFileUrl(result?.document_url);
      setRecordId(result?.id);
      setUploadedFile(result?.document_name);
    } catch (err) {
      // toast.error("Failed to fetch data");
      console.log(err);
    } finally {
      setLoading(0);
    }
  };

  return (
    <>
      <Modal
        title={editingRecord ? "Edit Record" : "Add New Record"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSave}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Year" name="year" rules={[{ required: true }]}>
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="Number of Students"
            name="number"
            rules={[{ required: true }]}
          >
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex flex-column">
          <span className="fw-semibold h5">
            Number of students appeared in the University examination year-wise
            during the last five years
          </span>
          <span
            className={`text-white d-inline-block text-center px-4 py-1 shadow-sm text-xs rounded-pill ${
              badge === "DRAFT"
                ? "bg-primary"
                : badge === "REVIEW"
                ? "bg-warning"
                : badge === "REJECTED"
                ? "bg-danger"
                : badge === "APPROVED"
                ? "bg-success"
                : "bg-secondary"
            }`}
            style={{ width: "fit-content" }}
          >
            {badge === "DRAFT"
              ? "In Draft"
              : badge === "REVIEW"
              ? "Submitted for review"
              : badge === "REJECTED"
              ? "Rejected"
              : badge === "APPROVED"
              ? "Approved"
              : "Not Started"}
          </span>
        </div>
        <Button type="primary" onClick={handleAdd} icon={<PlusOutlined />}>
          Add New Record
        </Button>
      </div>

      <Table
        dataSource={data.filter((item) => item.status !== "INACTIVE")}
        columns={columns}
        rowKey="id"
        pagination={false}
      />

      <div className="my-4">
        <span className="h5">Upload Data Template</span>
        <div className="d-flex flex-column my-2 gap-2 mt-2">
          <a
            className="text-primary mb-3"
            href="/templates/Extended Profile 2.3 Template.xlsx"
            download
          >
            <FileExcelOutlined /> Download 2.3 Template
          </a>
          {fileUrl ? (
            <div className="d-flex">
              <div className="mr-2">
                <a
                  className=" btn btn-primary mr-4"
                  style={{ width: "fit-content" }}
                  href={fileUrl}
                  download
                  target="_blank"
                >
                  <DownloadOutlined /> Download
                </a>
              </div>
              <button onClick={() => setFileUrl("")} className="btn btn-danger">
                <DeleteOutlined /> Delete
              </button>
            </div>
          ) : (
            <Upload accept=".pdf,.xlsx,.docx,.zip" beforeUpload={beforeUpload}>
              <button className="btn btn-primary">
                <UploadOutlined /> Browse
              </button>
            </Upload>
          )}

          {fileUrl ? (
            <div style={{ marginTop: "10px" }}>{uploadedFile}</div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="d-flex justify-content-end gap-2 mb-3">
        <button
          type="submit"
          onClick={handleSaveAsDraft}
          className="btn btn-primary"
        >
          Save as Draft
        </button>
        <button
          type="button"
          onClick={handleSaveAndSubmitForReview}
          className="btn btn-warning text-dark"
        >
          Submit for Review
        </button>
      </div>
    </>
  );
}

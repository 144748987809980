import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  LOCAL_COLLEGE,
  LOCAL_JOBROLES,
} from "../../utils/LocalStorageConstants";
import {
  ADD_SHIFT_TO_EMPLOYEE,
  DEPARTMENTS,
  EMPLOYEE,
  GET_ALL_USERS,
  GET_EMPLOYEE_DETAILS_WITH_SHIFT,
  LEAVE_ENTITLEMENT,
  LEAVE_ENTITLEMENT1,
  LEAVE_ENTITLEMENTS,
} from "../../utils/apiConstants.js";
import "../HR/Leave/TableScroll.css";

function ModalAssignEmpShift({
  empData,
  allData,
  edit,
  editType,
  reloadData,
  setLoading,
  CollegeId,
  displayData,
  setDisplayData,
  getEmpData,
}) {
  const [collegeOpt, setCollegeOpt] = useState(
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null
  );

  const [departmentOpt, setDepartmentOpt] = useState([]);

  const [promotes, setPromotes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [collegeSearch, setCollegeSearch] = useState("");
  const [departmentSearch, setDepartmentSearch] = useState("");
  const [designationSearch, setDesignationSearch] = useState("");
  const [groupSearch, setGroupSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [users, setUsers] = useState([]);
  const jobPositionOpt = JSON.parse(localStorage.getItem(LOCAL_JOBROLES));
  const [promotes2, setPromotes2] = useState([]);

  let shift = edit?.id;
  console.log("shift -", shift);
  // const [loading,setLoading] = useState(0)

  //   console.log("displayData -", displayData);

  //   console.log("empData -", empData);

  const [shiftData, setShiftData] = useState([]);

  function getLastDayOfYear(year) {
    return new Date(year, 11, 31);
  }

  const [tDate, setTDate] = useState(
    getLastDayOfYear(new Date().getFullYear()).toISOString()?.split("T")[0]
  );

  const toggleSelectAll = () => {
    // Toggle the select all checkbox
    setSelectAllChecked((prev) => {
      const newSelectAllChecked = !prev;

      const updatedPromotes = displayData
        // .filter((d) => {
        //   // Check if the item should be included based on shift_id
        //   return d?.shift_id === edit?.id;
        // })
        .filter((d) => !d?.shift_id)
        ?.map((d) => ({
          id: d.id,
          data: d,
        }));

      const promotesData = displayData?.map((d) => ({
        id: d.id,
        data: d,
      }));

      // If selecting all, set the promotes to the filtered data; otherwise, set an empty array
      setPromotes(newSelectAllChecked ? updatedPromotes : []);
      setPromotes2(promotesData);

      return newSelectAllChecked;
    });
  };

  const togglePromotion = (id, data) => {
    const isPromoted = promotes?.some((student) => student.id === id);

    if (isPromoted) {
      setPromotes((prevPromotes) =>
        prevPromotes?.filter((student) => student.id !== id)
      );
    } else {
      setPromotes((prevPromotes) => [...prevPromotes, { id, data }]);
    }
  };

  const getAllData = async () => {
    const config22 = {
      method: "get",
      url: GET_EMPLOYEE_DETAILS_WITH_SHIFT,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config22)
      .then((res) => {
        const dataaaa = res.data.data.filter((s) => s.shift_id === shift);
        setShiftData(dataaaa);
        // console.log("dataaaa -", dataaaa);
      })
      .catch((err) => {
        console.log(err);
      });
    const config2 = {
      method: "get",
      url: `${GET_ALL_USERS}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    setLoading(1);
    await axios(config2)
      .then((res) => {
        setLoading(0);
        setUsers(res.data.data);
        // console.log("users - ", res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    const config6 = {
      method: "get",
      // url: `${DEPARTMENTS}?college_id=${college_id}`,
      url: DEPARTMENTS,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config6)
      .then((res) => {
        setDepartmentOpt(res.data.data);
        // console.log("departments data - ", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    // console.log("Effect triggered, shift:", shift); // Debugging line
    if (shift) {
      getAllData();
    } else {
      //   console.log("Skipping fetching data for shift:", shift); // Debugging line
    }
  }, [shift]);

  //   console.log("dataaaa -", shiftData);

  const updateDisplayData = (data) => {
    // console.log("data -", data);
    const updatedData = data;
    setDisplayData(updatedData);
  };

  const assign = async (id, emp) => {
    if (!id) {
      return toast.error("Unable to get Employee id");
    }

    if (!shift) {
      return toast.error("Please Enter all the details");
    }

    const data = {
      employee_id: id,
      shift_id: shift,
      updatedBy: sessionStorage.getItem("employee_id"),
    };

    let config = {
      method: "put",
      //   url: `${ADD_SHIFT_TO_EMPLOYEE}` + `/${id}`,
      url: ADD_SHIFT_TO_EMPLOYEE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        ...data,
        // college_id: emp.college_id,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        toast.success("Assigned Shift Successfully");
        setLoading(0);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(0);
      });
    setLoading(0);
    setPromotes([]);
    getEmpData();
    "#ModalEntitlementAssign".modal("hide");
  };

  const assignAll = async () => {
    if (promotes.length === 0) {
      return toast.error("Please Select Employees to assign");
    }
    if (!shift) {
      return toast.error("Please Enter all the details");
    }

    setLoading(1);
    let cnt = 0;
    for (let i in promotes) {
      const data = {
        employee_id: promotes[i].id,
        shift_id: shift,
      };

      let config = {
        method: "put",
        // url: `${ADD_SHIFT_TO_EMPLOYEE}`+ `/${id}`,
        url: ADD_SHIFT_TO_EMPLOYEE,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        },
        data: {
          ...data,
          //   college_id: promotes[i]?.data?.college_id,
        },
      };

      await axios(config)
        .then((res) => {
          cnt++;
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setLoading(0);
        });
    }
    setLoading(0);
    toast.success(`Assigned Entitlement to ${cnt} employees`);
    setPromotes([]);
    getEmpData();
    "#ModalEntitlementAssign".modal("hide");
  };

  const removeAssigned = async (id, emp) => {
    if (!id) {
      return toast.error("Unable to get Employee id");
    }
    const config = {
      method: "put",
      //   url: `${ADD_SHIFT_TO_EMPLOYEE}/${id}`,
      url: ADD_SHIFT_TO_EMPLOYEE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        employee_id: id,
        shift_id: "",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        toast.success("successfully removed");
        setLoading(0);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(0);
      });
    setLoading(0);
    setPromotes([]);
    getEmpData();
    "#ModalEntitlementAssign".modal("hide");
  };

  useEffect(() => {
    // console.log("promotes - ", promotes);
  }, [promotes]);

  //   console.log("empData -", empData);

  useEffect(() => {
    const filteredData = empData.filter((item) => {
      const nameMatches =
        item.first_name.toLowerCase().includes(searchName.toLowerCase()) ||
        item.last_name.toLowerCase().includes(searchName.toLowerCase()) ||
        `${item.first_name} ${item.last_name}`
          .toLowerCase()
          .includes(searchName.toLowerCase());
      const collegeMatches =
        collegeSearch === "" || item.college_id === parseInt(collegeSearch);
      const departmentMatches =
        departmentSearch === "" ||
        item.department_id === parseInt(departmentSearch);
      const designationMatches =
        designationSearch === "" || item.role === designationSearch;
      const categoryMatches =
        categorySearch === "" || item.employee_category === categorySearch;
      const groupMatches = groupSearch === "" || item.group === groupSearch;
      return (
        nameMatches &&
        collegeMatches &&
        departmentMatches &&
        designationMatches &&
        groupMatches &&
        categoryMatches
      );
    });

    updateDisplayData(filteredData);
  }, [
    searchName,
    collegeSearch,
    departmentSearch,
    designationSearch,
    groupSearch,
    categorySearch,
    empData,
  ]);

  useEffect(() => {
    const filteredData = empData.filter((item) => {
      const nameMatches =
        item.first_name.toLowerCase().includes(searchName.toLowerCase()) ||
        item.last_name.toLowerCase().includes(searchName.toLowerCase()) ||
        `${item.first_name} ${item.last_name}`
          .toLowerCase()
          .includes(searchName.toLowerCase());
      const collegeMatches =
        collegeSearch === "" || item.college_id === parseInt(collegeSearch);
      const departmentMatches =
        departmentSearch === "" ||
        item.department_id === parseInt(departmentSearch);
      const designationMatches =
        designationSearch === "" || item.role === designationSearch;
      const categoryMatches =
        categorySearch === "" || item.employee_category === categorySearch;
      const groupMatches = groupSearch === "" || item.group === groupSearch;
      return (
        nameMatches &&
        collegeMatches &&
        departmentMatches &&
        designationMatches &&
        groupMatches &&
        categoryMatches
      );
    });

    updateDisplayData(filteredData);
  }, [
    searchName,
    collegeSearch,
    departmentSearch,
    designationSearch,
    groupSearch,
    categorySearch,
    empData,
  ]);

  useEffect(() => {
    updateDisplayData(empData);
  }, [empData]);

  //   console.log("promotes -", promotes);

  return (
    <div className="ModalAssignEmpShift">
      <div className="ModalAssignEmpShift">
        <div
          className="modal fade"
          id="ModalAssignEmpShift"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered mw-100 w-75"
            role="document"
          >
            <div className="modal-content ">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Assign Shift to Employees
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <h5 className="">
                  Shift : {edit?.title}{" "}
                  <span className="ml-2">
                    {edit?.from_time} to {edit?.to_time}
                  </span>
                </h5>
                <br />
                <div className="row"></div>
                <hr />
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <div className="form-group">
                      <label htmlFor="college_id">College</label>
                      <select
                        name="college_id"
                        id="college_id"
                        required=""
                        className="form-control"
                        value={collegeSearch}
                        onChange={(e) => setCollegeSearch(e.target.value)}
                      >
                        <option value="">All</option>
                        {collegeOpt?.map((data, key) => {
                          return <option value={data.id}>{data.name}</option>;
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 mt-3">
                    <div className="form-group">
                      <label htmlFor="department_id">Department</label>
                      <select
                        name="department_id"
                        id="department_id"
                        required=""
                        className="form-control"
                        value={departmentSearch}
                        onChange={(e) => setDepartmentSearch(e.target.value)}
                      >
                        <option value="">All</option>
                        {departmentOpt
                          ?.filter(
                            (data) => data.college_id == parseInt(collegeSearch)
                          )
                          ?.map((data, key) => {
                            return <option value={data.id}>{data.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4 mt-3">
                    <label>Designation</label>
                    <select
                      name="role"
                      id="role"
                      required=""
                      className="form-control"
                      value={designationSearch}
                      onChange={(e) => setDesignationSearch(e.target.value)}
                    >
                      <option value="">All</option>
                      {jobPositionOpt?.map((data, key) => {
                        return <option value={data.id}>{data.name}</option>;
                      })}
                    </select>
                  </div>

                  <div className="col-md-4 mt-3">
                    <label>Employee Category</label>
                    <select
                      name="employee_category"
                      id="employee_category"
                      required=""
                      className="form-control"
                      value={categorySearch}
                      onChange={(e) => setCategorySearch(e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="TEACHING STAFF">Teaching Staff</option>
                      <option value="NON-TEACHING STAFF">
                        Non Teaching Staff
                      </option>
                    </select>
                  </div>
                  <div className="col-md-4 mt-3">
                    <div className="form-group">
                      <label htmlFor="">Group</label>
                      <select
                        name="group"
                        id="group"
                        required=""
                        className="form-control"
                        value={groupSearch}
                        onChange={(e) => setGroupSearch(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-10 mt-3">
                    <label>Search By Employee Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search By Employee Name"
                      value={searchName}
                      onChange={(e) => {
                        setSearchName(e.target.value);
                      }}
                    />
                  </div>

                  <br />
                  <div className="col-md-12 mt-5">
                    <label>Assign Selected ({promotes.length}) : </label>
                    <button
                      className="btn btn-success float-right mr-4"
                      // data-dismiss="modal" aria-label="Close"
                      onClick={assignAll}
                    >
                      Assign
                    </button>
                  </div>
                </div>
                <div className="table-responsive mt-2 fixTableHead">
                  <table className="table table-bordered" style={{}}>
                    <thead className="">
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            id="select-all"
                            checked={selectAllChecked}
                            onChange={toggleSelectAll}
                          />
                        </th>
                        <th>Sl No.</th>
                        <th>Name</th>
                        <th>Gender</th>
                        <th>College</th>
                        <th>Department</th>
                        <th>Designation</th>
                        <th>Group</th>
                        <td>Category</td>
                        <th>Shift Assigned</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayData ? (
                        displayData?.map((i, k) => {
                          return (
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  name="checkbox[]"
                                  value={i.id}
                                  checked={promotes?.some(
                                    (staff) => staff.id === i.id
                                  )}
                                  onChange={() => togglePromotion(i.id, i)}
                                  disabled={
                                    i.shift_id === edit?.id || i?.shift_id
                                  }
                                />
                              </td>
                              <td>{k + 1}</td>
                              <td>{`${i.first_name} ${i.last_name}`}</td>
                              <td>{i?.gender}</td>
                              <td>
                                {
                                  collegeOpt.find((s) => s.id == i.college_id)
                                    ?.name
                                }
                              </td>
                              <td>{i?.department_name}</td>
                              <td>
                                {i?.designation ? i?.designation : i?.role}
                              </td>
                              <td>{i?.group}</td>
                              <td>{i?.employee_category}</td>
                              <td>
                                {i.shift_id ? (
                                  i.shift_name
                                ) : (
                                  <span className="badge badge-soft-danger">
                                    Not Assigned
                                  </span>
                                )}
                              </td>
                              <td>
                                {i?.shift_id && i.shift_id != edit?.id ? (
                                  <>
                                    <span
                                      className="badge badge-soft-success ml-2"
                                      style={{ fontSize: "13px" }}
                                    >
                                      Assigned
                                    </span>
                                  </>
                                ) : i?.shift_id && i?.shift_id === edit?.id ? (
                                  <>
                                    <span>
                                      {i.shift_id === edit?.id && (
                                        <button
                                          className="btn btn-danger btn-sm ml-2 mt-2"
                                          onClick={() =>
                                            removeAssigned(i.id, i)
                                          }
                                          disabled={promotes.length > 0}
                                        >
                                          Remove
                                        </button>
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="btn btn-success ml-2"
                                      // data-dismiss="modal" aria-label="Close" s
                                      onClick={() => assign(i.id, i)}
                                      disabled={
                                        promotes.length > 0 ||
                                        i.shift_id === edit?.id
                                      }
                                    >
                                      Assign
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <>Loading Employees...</>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row d-flex justify-content-between px-2">
                  <button
                    className="btn btn-danger btn-rounded btn-outline ml-3"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-success btn-outline mr-3"
                    // data-dismiss="modal"
                    // aria-label="Close"
                    onClick={() => {
                      assignAll();
                    }}
                  >
                    Assign
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalAssignEmpShift;

import React from "react";
import SelectTag from "../../../../Components/SelectTag";

export default function SourceOfFunding() {
  return (
    <>
      <span className="font-weight-bold">6. Source of Funding</span>
      <span className="text-white whitespace-nowrap w-28 text-center bg-gray-400 px-4 py-1 shadow-sm text-xs rounded-2xl">
        Not Started
      </span>

      <form className="d-flex flex-column gap-5">
        <SelectTag
          name="source_of_funding"
          label="Source Of Funding"
          data={[
            { id: 0, value: "Government" },
            { id: 1, value: "Grant-In-Aid" },
            { id: 2, value: "Self Financing" },
            { id: 3, value: "Other" },
          ]}
        />

        <div className="flex justify-start my-3  mb-3">
          <button className="btn btn-primary">Save as Draft</button>
          <button className="btn btn-warning">
            <span>Submit for Review</span>
          </button>
        </div>
      </form>
    </>
  );
}

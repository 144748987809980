import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { REPORT_STAFF_ATTENDANCE } from "../../../utils/Reports.apiConst";
import { toast } from "react-toastify";
import NoData from "./../../../Components/NoData/Nodata";
import { useDownloadExcel } from "react-export-table-to-excel";
import { EMPLOYEE_BIO_IDS } from "../../../utils/apiConstants";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
import "../../../modals/HR/Leave/TableScroll.css";

const ReportStaffTodayAttendance = ({ setLoading, collegeId }) => {
  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const collegeid = sessionStorage.getItem("college_id");

  const [user, setUser] = useState({
    from_date: getCurrentDate(), // Set default value for "From Date"
    to_date: getCurrentDate(), // Set default value for "To Date"
    department_id: "",
    college_id: "",
  });

  const [filter, setFilter] = useState("");
  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());
  const [departmentOpt, setDepartmentOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  const [programOpt, setProgramOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
  );

  useEffect(
    () => {
      setDepartmentOpt(
        JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)).filter(
          // (item) => item.college_id == user.college_id
          (item) => item.college_id == collegeid
        )
      );
      setProgramOpt(JSON.parse(localStorage.getItem(LOCAL_PROGRAM)));
      user.department_id = "";
    },
    // [localStorage.getItem(LOCAL_DEPARTMENT), user.college_id]);
    [localStorage.getItem(LOCAL_DEPARTMENT), collegeid]
  );

  useEffect(() => {
    setDepartmentOpt(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  // const getEmpData = async () => {
  //   const config = {
  //     method: "get",
  //     url:
  //     EMPLOYEEALL,
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   setLoading(1);
  //   await axios(config)
  //     .then((res) => {
  //       console.log('emp data - ', res.data.data);
  //       setEmpData(res.data.data);
  //       setLoading(0);
  //     })
  //     .catch((err) => {
  //       setLoading(0);
  //       console.log(err);
  //       toast.error("Something went wrong");
  //     });
  // }

  const tableRef = useRef();

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const [mainData, setMainData] = useState([]);
  const [data, setData] = useState([]);
  const [searchName, setSearchName] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePrint = () => {
    PrintRecipt();
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${data[0]?.DateString} Employee Attendance`,
    sheet: "Users",
  });

  const handleSubmit = async () => {
    if (!user?.from_date) {
      return toast.error("Enter Date");
    }
    setLoading(1);
    let dateParts = user.from_date.split("-");
    let year = dateParts[0];
    let month = dateParts[1];
    let day = dateParts[2];
    let from_date = `${year}-${month}-${day}`;

    // dateParts = user.to_date.split('-');
    // year = dateParts[0];
    // month = dateParts[1];
    // day = dateParts[2];
    // let to_date = `${day}/${month}/${year}`;

    let to_date = from_date;

    const config1 = {
      method: "get",
      // url: `${EMPLOYEE_BIO_IDS}?department_id=${user.department_id}&college_id=${user.college_id}`,
      url: `${EMPLOYEE_BIO_IDS}?department_id=${user.department_id}&college_id=${collegeid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    let bioIds;
    setLoading(1);
    await axios(config1)
      .then(async (res) => {
        bioIds = res.data.data;

        const config = {
          method: "get",
          url: `${REPORT_STAFF_ATTENDANCE}?from_date=${from_date}&to_date=${to_date}`,
          headers: {
            "Content-Type": "application/json",
          },
        };

        await axios(config)
          .then((res) => {
            let countsByEmpcode = res.data.data;
            countsByEmpcode = countsByEmpcode.filter(
              (s) => s?.EmpCode && bioIds.includes(s.EmpCode)
            );

            console.log(countsByEmpcode?.length);

            if (filter == "EO") {
              countsByEmpcode = countsByEmpcode.filter(
                (s) =>
                  s.Erl_Out[0] != "0" ||
                  s.Erl_Out[1] != "0" ||
                  s.Erl_Out[3] != "0" ||
                  s.Erl_Out[4] != "0"
              );
            } else if (filter == "LI") {
              countsByEmpcode = countsByEmpcode.filter(
                (s) =>
                  s.Late_In[0] != "0" ||
                  s.Late_In[1] != "0" ||
                  s.Late_In[3] != "0" ||
                  s.Late_In[4] != "0"
              );
            } else if (filter == "AB") {
              countsByEmpcode = countsByEmpcode.filter((s) => s.Status == "A");
            } else if (filter == "PR") {
              countsByEmpcode = countsByEmpcode.filter((s) => s.Status == "P");
            }
            setData(countsByEmpcode);
            setMainData(countsByEmpcode);
            setLoading(0);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
          });
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
    setLoading(0);
  };

  useEffect(() => {
    const filteredData = mainData.filter((item) =>
      item.Name.toLowerCase().includes(searchName.toLowerCase())
    );
    setData(filteredData);
  }, [searchName]);

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">STAFF ATTENDANCE DATE WISE</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="card-title">Select Criteria</div>

                <div className="row d-flex align-items-center">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">
                        Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        name="from_date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.from_date}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="college_id">
                        College <span className="text-danger">*</span>
                      </label>
                      <select
                        name="college_id"
                        id="college_id"
                        required=""
                        className="form-control"
                        value={user.college_id}
                        onChange={handleChange}
                      >
                        <option value="">All</option>
                        {collegeOpt?.map((data, key) => {
                          return <option value={data.id}>{data.name}</option>;
                        })}
                      </select>
                    </div>
                  </div> */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="validationCustom02">
                        Department <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-control"
                        name="department_id"
                        id="class"
                        value={user.department_id}
                        onChange={handleChange}
                      >
                        <option value="">All</option>
                        {departmentOpt
                          ?.filter((s) => s.college_id == collegeid)
                          ?.map((i, key) => (
                            <option value={i.id} key={key}>
                              {i.name},{" "}
                              {
                                programOpt.find(
                                  (item) => item.id == i.program_id
                                )?.name
                              }
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="validationCustom02">Filter</label>
                      <select
                        className="form-control"
                        name="filter"
                        id="filter"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="Present">Present</option>
                        <option value="AB">Absent</option>
                        <option value="LI">Late In</option>
                        <option value="EO">Early Out</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="d-flex mt-2 ml-4">
                      <button className="btn btn-nex" onClick={handleSubmit}>
                        Search
                      </button>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">To Date</label>
                      <input
                        type="date"
                        name="to_date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.to_date}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search By Name"
                        value={searchName}
                        onChange={(e) => {
                          setSearchName(e.target.value);
                        }}
                      />
                    </div>
                    <div></div>
                    <div>
                      <button
                        className="btn btn-primary rounded btn-sm mr-2"
                        onClick={onDownload}
                      >
                        <i class="ri-file-excel-2-line"></i> Excel
                      </button>
                      <button
                        className="btn btn-primary btn-sm rounded"
                        onClick={handlePrint}
                      >
                        <i class="bi bi-file-earmark-pdf"></i> PDF
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex flex-end float-right align-items-center">
                      <p className="mr-3">
                        <span className="badge badge-danger ">MIS</span> -
                        Missed Out Punch
                      </p>
                      <p className="mr-2">
                        <span className="badge badge-secondary ">WO</span> -
                        Week off
                      </p>
                      <p className="mr-2">
                        <span className="badge badge-primary ">H</span> -
                        Holiday
                      </p>
                    </div>
                    <div className="table-responsive  fixTableHead">
                      <table className="table table-bordered" ref={tableRef}>
                        <thead>
                          <tr>
                            <th style={{ display: "none" }} colSpan={13}>
                              Khaja Bandanawaz University
                            </th>
                          </tr>
                          <th className="text-uppercase" colSpan={14}>
                            {data?.length
                              ? `Date : ${data[0]?.DateString}`
                              : "Date : "}
                          </th>
                          <tr>
                            <th className="text-uppercase" colSpan={14}>
                              Total Employees - {data?.length}
                            </th>
                          </tr>
                          <tr>
                            <th>Sl.No</th>
                            {/* <th>Date</th> */}
                            <th>Staff Name</th>
                            <th className="text-center">Biometric ID</th>
                            <th className="text-center">Start Time</th>
                            <th className="text-center">In Time</th>
                            <th className="text-center">Lunch Out</th>
                            <th className="text-center">Lunch In</th>
                            <th className="text-center">Out Time</th>
                            <th className="text-center">Over Time</th>
                            <th className="text-center">Early Out</th>
                            <th className="text-center">Late In</th>
                            <th className="text-center">Work Time</th>
                            <th className="text-center">Status</th>
                            <th>Remark</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data && data.length ? (
                            data?.map((i, key) => (
                              <tr>
                                <td>{key + 1}</td>
                                {/* <td>{i?.DateString}</td> */}
                                <td>{i?.Name}</td>
                                <td className="text-center">{i?.EmpCode}</td>
                                <td className="text-center">{i?.Start}</td>

                                <td className="text-center">
                                  {i?.INTime?.split(" ")[1]}
                                </td>
                                {/* <td>{i?.Lunch_out}</td> */}
                                <td className="text-center">
                                  {i?.Lunch_out
                                    ? i?.Lunch_out.split(" ")[1]
                                    : ""}
                                </td>
                                {/* <td>{i?.Lunch_in}</td> */}
                                <td className="text-center">
                                  {i?.Lunch_in ? i?.Lunch_in.split(" ")[1] : ""}
                                </td>
                                <td className="text-center">
                                  {i?.OUTTime?.split(" ")[1]}
                                </td>
                                <td className="text-center">{i?.OverTime}</td>
                                <td className="text-center">{i?.Erl_Out}</td>
                                <td className="text-center">{i?.Late_In}</td>
                                <td className="text-center">{i?.WorkHours}</td>
                                <td key={key} className="text-center">
                                  <span
                                    className={`badge badge-soft-${
                                      i?.STATUS == "P"
                                        ? "success"
                                        : i.STATUS == "A"
                                        ? "danger"
                                        : i.STATUS == "HLD"
                                        ? "primary"
                                        : "secondary"
                                    }`}
                                  >
                                    {i.STATUS == "P"
                                      ? "Present"
                                      : i.STATUS == "A"
                                      ? "Absent"
                                      : i.STATUS == "HLD"
                                      ? "H"
                                      : i.STATUS}
                                  </span>
                                </td>
                                {/* <td>{i?.Remarks}</td> */}
                                <td>
                                  {i.STATUS == "MIS" &&
                                  i.Erl_Out != "00:00" &&
                                  i.Late_In != "00:00"
                                    ? "Halfday,  EO, LI"
                                    : i.STATUS == "MIS" && i.Erl_Out != "00:00"
                                    ? "Halfday, EO"
                                    : i.STATUS == "MIS" && i.Late_In != "00:00"
                                    ? "Halfday, LI"
                                    : i.STATUS == "MIS"
                                    ? "Halfday"
                                    : i.Late_In != "00:00"
                                    ? "LI"
                                    : i.Erl_Out != "00:00"
                                    ? "EO"
                                    : i.Erl_Out != "00:00" &&
                                      i.Late_In != "00:00"
                                    ? " EO, LI"
                                    : null}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={12}>
                                <NoData />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportStaffTodayAttendance;

import { BASE_URL } from "./apiConstants";
// export const BASE_URL = "http://localhost:4000"

export const FEE_DETAILS = `${BASE_URL}/api/fee/details`;

export const FEE_STUDENT_DETAILS = `${BASE_URL}/api/fee/getStudentSemData`;

export const FEE_STUDENT_DETAILS_BY_NAME = `${BASE_URL}/api/fee/getStudentSemData-by-name`;

export const FEE_DETAILS_SQL = `${BASE_URL}/api/fee/details-sql`;

export const FEE_TYPE_AMOUNT = `${BASE_URL}/api/fee/amount`;

export const FEE_STUDENT = `${BASE_URL}/api/fee/student`;

//Previous Year Due Fees

export const PREVIOUS_YEAR_DUE_FEES = `${BASE_URL}/api/fee/getpreviousdueFees`;

//Add Income

export const FEE_INCOME = `${BASE_URL}/api/fee/income`;

export const FEE_INCOME_SOURCE = `${BASE_URL}/api/fee/income-source`;

export const FEE_EXPENSE = `${BASE_URL}/api/fee/expense`;

export const FEE_EXPENSE_SOURCE = `${BASE_URL}/api/fee/expense-source`;

///////////////STUDENT REPORTS

export const FEE_DETAILS_BY_STUDENT_ID = `${BASE_URL}/api/fee/student-fee`;

export const FEE_PENDING_COLLEGE_WISE = `${BASE_URL}/api/fee/college-reports`;

export const ADDHOSTELFEE = `${BASE_URL}/api/fee/hostelfees`;

export const GETHOSTELFEE = `${BASE_URL}/api/fee/findAllHostelFeesData`;

export const ADDHOSTELFEE1 = `${BASE_URL}/api/fee/sqlhostelfees`;

export const GETSTUDENT_HOSTELFEES = `${BASE_URL}/api/fee/findStudentHostelFees`;

export const ADDTRANSPORTFEE = `${BASE_URL}/api/fee/transportfees`;

export const HOSTELPAYMENTDATA = `${BASE_URL}/api/fee/hostelpaymentdata`;

//Student Complaint Api

export const STUDENT_COMPLAINT_GET = `${BASE_URL}/api/student/complaint/all`;

export const COLLECT_FEE = `${BASE_URL}/api/fee/collect-fee`;

export const RETURN_FEE = `${BASE_URL}/api/fee/return-fee`;

export const ADD_OTHER_FEES = `${BASE_URL}/api/fee/addOtherFees`;

export const FEE_DETAILS_BY_STUDENT_ID_VIEW = `${BASE_URL}/api/fee/student-fee-view`;

export const UPDATE_HOSTEL_FEE_STATUS = `${BASE_URL}/api/payment/update-hostel-fee-status`;

export const SEM_WISE_PENDING_REPORT = `${BASE_URL}/api/payment/sem-wise-pending-report`;

export const FEE_COLLECT_DETAILS = `${BASE_URL}/api/fee/fee-collection`;

export const FEE_DRAWER_STUDENTS = `${BASE_URL}/api/fee/fee-drawer-students`;

export const ASSIGN_FEE_MASTER = `${BASE_URL}/api/fee/assign-fee-master`;

export const SEM_WISE_SCHOLARSHIP_REPORT = `${BASE_URL}/api/payment/sem-wise-scholarship-report`;

export const FEE_REPORT_DEPT = `${BASE_URL}/api/fee/department-wise-report`; /////////////

import React, { useEffect, useState } from "react";
import {
  Button,
  Popconfirm,
  Table,
  Modal,
  Form,
  Input,
  Select,
  Upload,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import { NAAC } from "../../../../utils/apiConstants";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../../utils/AssetsReferenceTypes";
import { getFileUrl } from "../../../../Helpers/Helpers";
import DocumentsModal from "../../../../modals/Students/DocumentsModal.jsx";

export default function EvaluativeReports({ setUpdatedData, setLoading }) {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [badge, setBadge] = useState("");
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const [fileUrl, setFileUrl] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const emp_id = sessionStorage.getItem("employee_id");

  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleAdd = () => {
    form.resetFields();
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const handleDelete = (identifier) => {
    const updatedData = data.filter((item) => {
      if (item.id === identifier) {
        item.status = "INACTIVE";
        return true;
      }
      return item.tempId !== identifier;
    });

    setData(updatedData);
  };

  const getFormData = async () => {
    setLoading(1);

    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/evaluative-reports/1`,
    };
    await axios(config)
      .then((res) => {
        setData(res.data);
        let fetchedData = res.data;
        setBadge(() => {
          if (
            fetchedData?.length > 0 &&
            fetchedData.every((item) => item.status === "REVIEW")
          ) {
            return "REVIEW";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "DRAFT")
          ) {
            return "DRAFT";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "APPROVED")
          ) {
            return "APPROVED";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "REJECTED")
          ) {
            return "REJECTED";
          }
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  useEffect(() => {
    getFormData();
  }, []);

  const handleSave = () => {
    form.validateFields().then((values) => {
      console.log(values);
      if (editingRecord) {
        setData(
          data.map((item) =>
            (item.id && item.id === editingRecord.id) ||
            (item.tempId && item.tempId === editingRecord.tempId)
              ? {
                  ...item,
                  ...values,
                  document_report: fileUrl,
                  updated_by: emp_id,
                }
              : item
          )
        );
      } else {
        setData([
          ...data,
          {
            ...values,
            tempId: uuidv4(),
            created_by: emp_id,
            updated_by: emp_id,
            university_profile_id: 1,
            status: "DRAFT",
            document_report: fileUrl,
          },
        ]);
      }
      setIsModalVisible(false);
    });
  };
  const handleFormSubmission = async (status) => {
    if (data.length === 0) {
      return toast.error("Please add at least one teaching faculty detail");
    }

    // Separate data for backend operations
    const toDelete = data.filter(
      (item) => item.status === "INACTIVE" && item.id
    );
    const toUpdate = data.filter(
      (item) => item.status !== "INACTIVE" && item.id
    );
    const toCreate = data.filter((item) => !item.id);

    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/evaluative-reports`,
      data: { toDelete, toUpdate, toCreate, status },
    };

    try {
      const res = await axios(config);
      toast.success(
        status === "REVIEW"
          ? "Successfully submitted for review"
          : "Successfully saved as draft"
      );
      setUpdatedData(uuidv4());
      getFormData();
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    } finally {
      setLoading(0);
    }
  };

  const handleSaveAsDraft = (e) => {
    e.preventDefault();
    handleFormSubmission("DRAFT");
  };

  const handleSaveAndSubmitForReview = (e) => {
    e.preventDefault();
    handleFormSubmission("REVIEW");
  };
  const columns = [
    {
      title: "Department Name",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Reports",
      key: "view",
      render: (text, record) => (
        <a
          className="mt-3 ml-1 text-primary"
          data-toggle="modal"
          data-target="#DocumentsModal"
          onClick={(e) => {
            e.preventDefault();
            setTitle("Report");
            setLink(record?.document_report);
          }}
        >
          View Document
          {console.log("Modal image link", record?.document)}
        </a>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record.id || record.tempId)}
            okText="Yes"
            cancelText="No"
            placement="topRight"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  const addAttachment = async (file) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `Resume`,
        file.name.split(".")[1],
        setLoading,
        file
      );
      setFileUrl(d);
      console.log("file", d);
    } catch (error) {
      console.error("Error uploading file:", error);
      // message.error("File upload failed");
    }
  };
  const beforeUpload = (file) => {
    setUploadedFile(file);
    addAttachment(file); // Call the API to upload the file and get the URL
    return false; // Prevent automatic upload by the Upload component
  };
  useEffect(() => {
    getFormData();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column gap-2">
          <span className="fw-semibold h5">Evaluative Reports</span>
          <DocumentsModal img={link} title={title} setLink={setLink} />
          <span
            className={`text-white d-inline-block text-center px-4 py-1 shadow-sm text-xs rounded-pill ${
              badge === "DRAFT"
                ? "bg-primary"
                : badge === "REVIEW"
                ? "bg-warning"
                : badge === "REJECTED"
                ? "bg-danger"
                : badge === "APPROVED"
                ? "bg-success"
                : "bg-secondary"
            }`}
            style={{ width: "fit-content" }}
          >
            {badge === "DRAFT"
              ? "In Draft"
              : badge === "REVIEW"
              ? "Submitted for review"
              : badge === "REJECTED"
              ? "Rejected"
              : badge === "APPROVED"
              ? "Approved"
              : "Not Started"}
          </span>
        </div>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
          Add New Report
        </Button>
      </div>

      <div className="table-responsive">
        <Table
          className="table table-bordered"
          dataSource={data.filter((item) => item.status !== "INACTIVE")}
          columns={columns}
          rowKey="id"
          pagination={false}
        />
      </div>

      <Modal
        title={editingRecord ? "Edit Report" : "Add New Report"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSave}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Department Name"
            name="department"
            rules={[{ required: true, message: "Please enter the department" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Upload Report"
            name="document_report"
            rules={[{ required: true, message: "Please upload the report" }]}
          >
            <Upload
              accept=".pdf, .jpg, .jpeg, .png"
              beforeUpload={beforeUpload} // Handle the upload manually
            >
              <Button icon={<UploadOutlined />}>Choose File</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>

      <div className="d-flex justify-content-end gap-2 mb-3">
        <button
          type="submit"
          onClick={handleSaveAsDraft}
          className="btn btn-primary"
        >
          Save as Draft
        </button>
        <button
          type="button"
          onClick={handleSaveAndSubmitForReview}
          className="btn btn-warning text-dark"
        >
          Submit for Review
        </button>
      </div>
    </>
  );
}

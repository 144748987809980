import { Routes, Route, Navigate } from "react-router-dom";
import NepParameters from "../Pages/NAAC/nep-parameters/NepParameters.jsx";

// Sub Pages of NEP Parameters Page
import Multidisciplinary from "../Pages/NAAC/nep-parameters/sub-pages/Multidisciplinary.jsx";
import AcademicBankOfCredits from "../Pages/NAAC/nep-parameters/sub-pages/AcademicBankOfCredits.jsx";
import SkillDevelopment from "../Pages/NAAC/nep-parameters/sub-pages/SkillDevelopment.jsx";
import AppropriateIntegrationOfIndianKnowledgeSystem from "../Pages/NAAC/nep-parameters/sub-pages/AppropriateIntegrationOfIndianKnowledgeSystem.jsx";
import FocusOnOutcomeBasedEducation from "../Pages/NAAC/nep-parameters/sub-pages/FocusOnOutcomeBasedEducation.jsx";
import DistanceEducation from "../Pages/NAAC/nep-parameters/sub-pages/DistanceEducation.jsx";
import { useEffect, useState } from "react";
import axios from "axios";
import { NAAC } from "../utils/apiConstants.js";
import Loader from "../Components/Loader/Loader.jsx";
import { toast } from "react-toastify";

export default function NepParametersRoutes() {
  const [updatedData, setUpdatedData] = useState("");
  const [loading, setLoading] = useState(0);
  const [data, setData] = useState(null);
  let roleName = sessionStorage.getItem("role");
  let role =
    roleName === "SUPERADMIN"
      ? "registarar"
      : roleName === "Pincipal" ||
        roleName === "Principal" ||
        roleName === "PRINC"
      ? "principal"
      : "employee";
  const getFormData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/get-nep-parameters/status`,
    };
    await axios(config)
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        // toast.error("Something went wrong");
        console.log(err);
      });
  };
  useEffect(() => {
    getFormData();
  }, [updatedData]);
  return (
    <>
      <Loader loading={loading} />
      <Routes>
        <Route
          path="/"
          element={
            <Navigate to={`/${role}/naac/nep-parameters/multidisciplinary`} />
          }
        />
        <Route
          path="multidisciplinary"
          element={
            <NepParameters data={data}>
              <Multidisciplinary
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </NepParameters>
          }
        />
        <Route
          path="academic-bank-of-credits"
          element={
            <NepParameters data={data}>
              <AcademicBankOfCredits
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </NepParameters>
          }
        />
        <Route
          path="skill-development"
          element={
            <NepParameters data={data}>
              <SkillDevelopment
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </NepParameters>
          }
        />
        <Route
          path="appropriate-integration-of-indian-knowledge-system"
          element={
            <NepParameters data={data}>
              <AppropriateIntegrationOfIndianKnowledgeSystem
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </NepParameters>
          }
        />
        <Route
          path="focus-on-outcome-based-education"
          element={
            <NepParameters data={data}>
              <FocusOnOutcomeBasedEducation
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </NepParameters>
          }
        />
        <Route
          path="distance-education"
          element={
            <NepParameters data={data}>
              <DistanceEducation
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </NepParameters>
          }
        />
      </Routes>
    </>
  );
}

import React from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
const Submit = ({ prev }) => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6">
          <div>
            <div className=" d-flex justify-content-center flex-column align-items-center text-center p-5">
              <IoMdCheckmarkCircleOutline size={90} className="text-success" />
              <h2 className="mt-4">Application Submitted Successfully!</h2>
              <p className="text-muted mt-1">
                Thank you for applying. We have received your application and
                will review it shortly.
              </p>
              <div className="mt-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Submit;

import axios from "axios";
import React from "react";
import { useState } from "react";
import Nodata from "../../../Components/NoData/Nodata";
import {
  REPORT_FEE_DETAILS,
  REPORT_PROGRAM_WISE_FEE_DETAILS,
} from "../../../utils/Reports.apiConst";
import * as XLSX from "xlsx/xlsx.mjs";
import { useNavigate } from "react-router-dom";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import { toast } from "react-toastify";
import { college_title } from "../../../Data/main";
import Select from "react-select";
import "../../../modals/HR/Leave/TableScroll.css";

const ProgramWiseCollectionReport = ({ setLoading, collegeId }) => {
  // const [user, setUser] = useState({
  //   date: "",
  //   to_transaction_date: "",
  // });

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  const [user, setUser] = useState({
    date: new Date().toISOString().split("T")[0], // Set to current date
    to_transaction_date: new Date().toISOString().split("T")[0], // Set to current date
  });

  let count = 0;

  const tableRef = useRef();

  let amount = 0;

  const [data, setData] = useState([]);

  const [dates, setDates] = useState([]);

  const [program, setProgram] = useState("");
  const [selectedprogram, setSelectedProgram] = useState("");

  const programData = JSON.parse(localStorage.getItem(LOCAL_PROGRAM));
  const facultyData = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  const departmentData = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));

  const [studentSet, setStudentSet] = useState([]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getData = async () => {
    if (!program) return toast.error("Please Select Program");
    setLoading(1);
    const config = {
      method: "get",
      url: `${REPORT_PROGRAM_WISE_FEE_DETAILS}?transaction_date=${user?.date}&to_transaction_date=${user?.to_transaction_date}&college_id=${collegeId}&program_id=${program}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log("payment data - ", res.data.data);

        let groupedData = [];

        let tempData = res.data.data;
        for (let i in tempData) {
          let deptId = tempData[i].department_id;
          let existingGroup = groupedData.find(
            (group) => group.department_id === deptId
          );
          if (!existingGroup) {
            existingGroup = {
              department_id: deptId,
              department_name: departmentData?.find((s) => s.id == deptId)
                ?.name,
              transactions: [],
              amount: parseInt(tempData[i].amount),
              discount: parseInt(tempData[i].discount),
              fine: parseInt(tempData[i].fine),
            };
            groupedData.push(existingGroup);
          } else {
            existingGroup.amount += parseInt(tempData[i].amount);
            existingGroup.discount += parseInt(tempData[i].discount);
            existingGroup.fine += parseInt(tempData[i].fine);
          }
          existingGroup.transactions.push(tempData[i]);
        }
        console.log("final data - ", groupedData);
        setData(groupedData);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const collegeList1 = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  const downloadExcel = () => {
    let json_data = [];
    for (const iterator of data) {
      const obj = {
        "Student Registeration Number": iterator?.usn,
        "Student Name": iterator?.name,
        Amount: iterator?.amount,
        "Transaction Id": iterator?.transaction_id,
        "Transaction Type": iterator?.type,
      };
      json_data.push(obj);
    }
    var worksheet = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `SUKALOL-Fee Details.xlsx`);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const options =
    programData?.map((i) => ({ value: i.id, label: i.name })) || [];

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-nex d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">PROGRAM AND DATE WISE FEE REPORT</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="card-title">Select Criteria</div>

                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Date</label>
                      <input
                        type="date"
                        name="date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.date}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Date</label>
                      <input
                        type="date"
                        name="to_transaction_date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.to_transaction_date}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Program</label>

                      <Select
                        name="program"
                        className="basic-single"
                        classNamePrefix="select"
                        value={
                          options.find((option) => option.value == program) ||
                          ""
                        }
                        onChange={(selectedOption) =>
                          setProgram(selectedOption.value)
                        }
                        options={options}
                        placeholder="Select Program"
                        style={{ zIndex: "1000" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex">
                      <button className="btn btn-nex mt-4" onClick={getData}>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div className="card-title">Fee Reports</div>
                    <button
                      className="btn btn-nex rounded-pill"
                      onClick={onDownload}
                    >
                      Excel
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive fixTableHeadPage">
                      <table className="table table-bordered" ref={tableRef}>
                        <thead>
                          <tr>
                            <th colSpan={14}>{college_title}</th>
                          </tr>
                          <tr>
                            <th colSpan={14}>
                              {
                                facultyData?.find((s) => s.id == collegeId)
                                  ?.name
                              }
                            </th>
                          </tr>
                          <tr>
                            <th>Sl.No</th>
                            <th>Student Name</th>
                            <th>Admission No.</th>
                            <th>University No.</th>
                            <th>Class (Sem)</th>
                            <th>Date</th>
                            <th>Transaction Id</th>
                            <th>Payment Type</th>
                            <th>Note</th>
                            <th>Collected By</th>
                            <th className="text-right">Paid</th>
                            <th className="text-right">Discount</th>
                            <th className="text-right">Fine</th>
                            <th className="text-right">Total</th>
                          </tr>
                        </thead>

                        {data?.map((i, k) => {
                          return (
                            <>
                              <tr className="bg-dark text-light">
                                <th colSpan={10}>{i?.department_name}</th>
                                <th>
                                  {i?.amount?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </th>
                                <th>
                                  {i?.discount?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </th>
                                <th>
                                  {i?.fine?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </th>
                                <th>
                                  {(i?.amount + i?.fine)?.toLocaleString(
                                    "en-IN",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    }
                                  )}
                                </th>
                              </tr>
                              {i.transactions?.map((j, key) => {
                                return (
                                  <tr>
                                    <td>{key + 1}</td>
                                    <td>{j?.name}</td>
                                    <td>{j?.user_id}</td>
                                    <td>{j?.university_number}</td>
                                    <td>
                                      {j?.class_name} ({j?.sem_name})
                                    </td>
                                    <td>{formatDate(j?.transaction_date)}</td>
                                    <td>{j?.transaction_id}</td>
                                    <td>{j?.type}</td>
                                    <td>
                                      {j?.first_name} {j?.last_name}
                                    </td>
                                    <td>{j?.note}</td>
                                    <td>
                                      {parseInt(j?.amount)?.toLocaleString(
                                        "en-IN",
                                        {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        }
                                      )}
                                    </td>
                                    <td>
                                      {parseInt(j?.discount)?.toLocaleString(
                                        "en-IN",
                                        {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        }
                                      )}
                                    </td>
                                    <td>
                                      {parseInt(j?.fine)?.toLocaleString(
                                        "en-IN",
                                        {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        }
                                      )}
                                    </td>
                                    <td>
                                      {(
                                        parseInt(j?.amount || 0) +
                                        parseInt(j?.fine || 0)
                                      )?.toLocaleString("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 0,
                                      })}
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramWiseCollectionReport;

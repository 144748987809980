import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { REPORT_STAFF_ATTENDANCE } from "../../../../utils/Reports.apiConst";
import { toast } from "react-toastify";
import NoData from "./../../../../Components/NoData/Nodata";
import { useDownloadExcel } from "react-export-table-to-excel";
import {
  EMPLOYEEALL,
  EMPLOYEE_BIO_IDS,
  EMP_ATTENDANCE,
} from "./../../../../utils/apiConstants";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../../utils/LocalStorageConstants";
import useEmployee from "../../../../Hooks/Employee/useEmployee";
import Select from "react-select";
import { sessionOpt } from "../../../../Data/jsonData/Academics/Academics";
import "../../../../modals/HR/Leave/TableScroll.css";

const EditStaffAttendance = ({ setLoading, collegeId }) => {
  const [user, setUser] = useState({
    year1: 2024,
    month1: "",
    employee_id: "",
  });

  var emp_id = sessionStorage.getItem("employee_id");

  const [employee] = useEmployee(collegeId);
  const [employeeOpt, setEmployeeOpt] = useState([]);

  const [changes, setChanges] = useState([]);
  const [note, setNote] = useState("");

  const [empData, setEmpData] = useState([]);
  const [biocodes, setBiocodes] = useState([]);
  const [filter, setFilter] = useState("");
  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());
  const [departmentOpt, setDepartmentOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  const [programOpt, setProgramOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
  );

  useEffect(() => {
    setDepartmentOpt(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)).filter(
        (item) => item.college_id == user.college_id
      )
    );
    setProgramOpt(JSON.parse(localStorage.getItem(LOCAL_PROGRAM)));
    user.department_id = "";
  }, [localStorage.getItem(LOCAL_DEPARTMENT), user.college_id]);

  useEffect(() => {
    setDepartmentOpt(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  const tableRef = useRef();

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const [mainData, setMainData] = useState([]);
  const [data, setData] = useState([]);
  const [searchName, setSearchName] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePrint = () => {
    PrintRecipt();
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${data[0]?.DateString} Employee Attendance`,
    sheet: "Users",
  });

  const handleSubmit = async () => {
    if (!user?.year1) {
      return toast.error("Please Select Year");
    }
    if (!user?.month1) {
      return toast.error("Please Select Month");
    }
    if (!user?.employee_id) {
      return toast.error("Please Select Employee");
    }
    setLoading(1);
    const config1 = {
      method: "get",
      url: `${EMP_ATTENDANCE}?year=${user.year1}&month=${user.month1}&employee_id=${user?.employee_id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config1)
      .then(async (res) => {
        console.log("emp attendance - ", res.data.data);
        setData(res.data.data);
        setChanges([]);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        setChanges([]);
      });
    setLoading(0);
  };

  const handleEdit = async () => {
    if (changes.length == 0) {
      return toast.error("No changes");
    }
    setLoading(1);
    const config1 = {
      method: "put",
      url: `${EMP_ATTENDANCE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        changes,
        employee_id: emp_id,
      },
    };

    setLoading(1);
    await axios(config1)
      .then(async (res) => {
        toast.success("Updated Successfully");
        handleSubmit();
        setChanges([]);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        setChanges([]);
      });
    setLoading(0);
  };

  const attendanceChange = async (i, status) => {
    console.log("hi");

    let temp = [...changes];

    let existingIndex = temp.findIndex((obj) => obj.id == i.id);
    if (existingIndex !== -1) {
      temp[existingIndex].status = status;
      if (status === i.status) {
        temp = temp.filter((obj) => obj.id !== i.id);
      }
    } else {
      temp.push({ id: i.id, status, note: i.update_reason });
      if (status === i.status) {
        temp = temp.filter((obj) => obj.id !== i.id);
      }
    }
    setChanges(temp);
    console.log(temp);
  };

  const handleNoteChange = (id, note) => {
    let temp = [...changes];

    let existingIndex = temp.findIndex((obj) => obj.id == id);
    if (existingIndex != -1) temp[existingIndex].note = note;
    setChanges(temp);
  };

  useEffect(() => {
    console.log("changes - ", changes);
  }, [changes]);

  useEffect(() => {
    let temp = [];
    // temp.push({'value':'All','label': 'All'});
    employee?.map((i, key) => {
      let obj = {};
      obj["value"] = i?.id;
      obj["label"] = i?.first_name + " " + i?.last_name;
      temp.push(obj);
    });
    setEmployeeOpt(temp);
  }, [employee]);

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-nex d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">EDIT STAFF ATTENDANCE</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="card-title">Select Criteria</div>

                <div className="row d-flex align-items-center">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="year">
                        Year <span className="text-danger">*</span>
                      </label>
                      <select
                        name="year1"
                        id="year1"
                        required=""
                        className="form-control"
                        value={user?.year1}
                        onChange={handleChange}
                      >
                        <option value="">Select Year</option>
                        {sessionOpt &&
                          sessionOpt?.map((item, key) => {
                            return (
                              <option value={item?.id}>
                                {item?.name.substring(0, 4)}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="month">
                        Month <span className="text-danger">*</span>
                      </label>
                      <select
                        name="month1"
                        id="month1"
                        required=""
                        className="form-control"
                        value={user?.month1}
                        onChange={handleChange}
                      >
                        <option value="">Select Month</option>
                        <option value="01">JANUARY</option>
                        <option value="02">FEBRUARY</option>
                        <option value="03">MARCH</option>
                        <option value="04">APRIL</option>
                        <option value="05">MAY</option>
                        <option value="06">JUNE</option>
                        <option value="07">JULY</option>
                        <option value="08">AUGUST</option>
                        <option value="09">SEPTEMBER</option>
                        <option value="10">OCTOBER</option>
                        <option value="11">NOVEMBER</option>
                        <option value="12">DECEMBER</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="validationCustom01">
                        Employee <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        id="employee"
                        options={employeeOpt}
                        onChange={(e) => {
                          setUser({ ...user, employee_id: e.value });
                        }}
                        style={{ zIndex: "101" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="d-flex mt-2">
                      <button className="btn btn-nex" onClick={handleSubmit}>
                        Search
                      </button>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">To Date</label>
                      <input
                        type="date"
                        name="to_date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.to_date}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="float-right">
                      {/* <button
                        className="btn btn-nex rounded-pill mr-2"
                        onClick={onDownload}
                      >
                        Excel
                      </button>
                      <button
                        className="btn btn-nex rounded-pill"
                        onClick={handlePrint}
                      >
                        PDF
                      </button> */}
                      <button className="btn btn-success" onClick={handleEdit}>
                        Update
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered" ref={tableRef}>
                        <thead>
                          <th className="text-uppercase" colSpan={12}>
                            Changes : {changes.length}
                          </th>
                          <tr>
                            <th>Sl.No</th>
                            <th>Date</th>
                            <th>In Time</th>
                            <th>Out Time</th>
                            <th>Work Time</th>
                            <th>Remark</th>
                            <th>Biometric</th>
                            <th>Attendance</th>
                            <th>Lunch In</th>
                            <th>Lunch Out</th>
                            <th>Action</th>
                            <th>Note</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data && data.length ? (
                            data?.map((i, key) => (
                              <tr>
                                <td>{key + 1}</td>
                                <td>{i?.date}</td>
                                <td>{i?.in_time ? i?.in_time : "00:00"}</td>
                                <td>{i?.out_time ? i?.out_time : "00:00"}</td>
                                <td>{i?.work_time}</td>
                                <td>{i?.remark}</td>
                                <td>
                                  <span
                                    className={`badge badge-soft-${
                                      i?.old_status == "P"
                                        ? "success"
                                        : i.old_status == "A"
                                        ? "danger"
                                        : "secondary"
                                    }`}
                                  >
                                    {i.old_status == "P"
                                      ? "Present"
                                      : i.old_status == "A"
                                      ? "Absent"
                                      : i.old_status}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`badge badge-soft-${
                                      i?.status == "P"
                                        ? "success"
                                        : i.status == "A"
                                        ? "danger"
                                        : "secondary"
                                    }`}
                                  >
                                    {i.status == "P"
                                      ? "Present"
                                      : i.status == "A"
                                      ? "Absent"
                                      : i.status}
                                  </span>
                                </td>
                                <td>{i?.Lunch_in ? i?.Lunch_in : "00:00"}</td>
                                <td>{i?.Lunch_out ? i?.Lunch_out : "00:00"}</td>
                                <td key={key}>
                                  <select
                                    value={
                                      changes.find((s) => s.id == i.id)?.status
                                        ? changes.find((s) => s.id == i.id)
                                            ?.status
                                        : i?.status
                                    }
                                    onChange={(e) =>
                                      attendanceChange(i, e.target.value)
                                    }
                                  >
                                    <option value="P">
                                      <span>Present</span>
                                    </option>
                                    <option value="A">
                                      <span>Absent</span>
                                    </option>
                                    <option value="WO">
                                      <span>Weekly Off</span>
                                    </option>
                                  </select>
                                </td>
                                <td>
                                  <textarea
                                    cols="70"
                                    rows="1"
                                    onChange={(e) => {
                                      handleNoteChange(i.id, e.target.value);
                                    }}
                                    disabled={
                                      !changes.find((s) => s.id == i.id)
                                    }
                                    value={
                                      changes.find((s) => s.id == i.id)?.note ||
                                      i?.update_reason
                                    }
                                  ></textarea>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={12}>
                                <NoData />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStaffAttendance;

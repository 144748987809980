import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Nodata from "../../../Components/NoData/Nodata";
import { Input } from "antd";
import { JOB_OPENINGS } from "../../../utils/apiConstants";
import CreatableSelect from "react-select/creatable";
const customOptions = [
  { value: "resume", label: "Resume", isDisabled: true },
  { value: "aadhar", label: "Aadhar", isDisabled: true },
  { value: "pan", label: "PAN" },
  { value: "photo", label: "Profile Picture", isDisabled: true },
];
const customEducationOption = [
  { value: "sslc", label: "SSLC/10th", isDisabled: true },
  { value: "puc", label: "PUC/12th" },
  { value: "bachelors", label: "Bachelors" },
];
function JobOpenings({ collegeId, setLoading }) {
  const [data, setData] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const [edit, setEdit] = useState(false);

  const { TextArea } = Input;

  const [user, setUser] = useState({
    title: "",
    description: "",
    specifications: "",
    from_age: "",
    to_age: "",
    attachment: "",
    gender_required: "all",
    documents_required: [
      { value: "resume", label: "Resume", isDisabled: true },
      { value: "photo", label: "Profile Picture", isDisabled: true },
      { value: "aadhar", label: "Aadhar", isDisabled: true },
    ],
    education_required: [
      { value: "sslc", label: "SSLC/10th", isDisabled: true },
    ],
    experience_required: "",
    status: "ACTIVE",
  });

  const clearData = () => {
    setUser({
      title: "",
      description: "",
      specifications: "",
      from_age: "",
      to_age: "",
      attachment: "",
      gender_required: "all",
      documents_required: [
        { value: "resume", label: "Resume", isDisabled: true },
        { value: "photo", label: "Profile Picture", isDisabled: true },
        { value: "aadhar", label: "Aadhar", isDisabled: true },
      ],
      education_required: [
        { value: "sslc", label: "SSLC/10th", isDisabled: true },
      ],
      experience_required: "",
      status: "ACTIVE",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  console.log(user);

  const handleMultiSelect = (newValue) => {
    // Always keep Resume and Profile Picture in the selected options
    const fixedOptions = [
      { value: "resume", label: "Resume", isDisabled: true },
      { value: "photo", label: "Profile Picture", isDisabled: true },
      { value: "aadhar", label: "Aadhar", isDisabled: true },
    ];

    // Filter out any existing fixed options from the selection
    const filteredNewValue = newValue.filter((option) => !option.isDisabled);

    // Merge fixed options with user-selected options
    const mergedValues = [...fixedOptions, ...filteredNewValue];

    setUser((prev) => ({ ...prev, documents_required: mergedValues }));
  };
  console.log("user", user);

  const handleEducationMultiSelect = (newValue) => {
    const fixedOptions = [
      { value: "sslc", label: "SSLC/10th", isDisabled: true },
    ];
    const filteredNewValue = newValue.filter((option) => !option.isDisabled);
    const mergedValues = [...fixedOptions, ...filteredNewValue];

    setUser((prev) => ({ ...prev, education_required: mergedValues }));
  };

  const getAllData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: JOB_OPENINGS,
    };

    axios(config)
      .then((res) => {
        console.log("job openings data - ", res.data.data);
        setData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleSubmit = async () => {
    if (!user.title) {
      return toast.error("Please Enter title of Job Opening");
    }
    const newUser = {
      ...user,
      education_required: user.education_required.map((item) => item.value),
      documents_required: user.documents_required.map((item) => item.value),
    };
    console.log("data to backend - ", newUser);
    setLoading(1);
    const config = {
      method: "post",
      url: JOB_OPENINGS,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: newUser,
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Job Opening Added");
        clearData();
        getAllData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleEdit = async () => {
    if (!user.title) return toast.error("Please Enter Title of Job Opening");
    setLoading(1);
    const config = {
      method: "put",
      url: JOB_OPENINGS + `/${user.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: user,
    };
    console.log("data to backend", user);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Job Opening Updated");
        setEdit(false);
        setAddNew(true);
        clearData();
        getAllData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleDelete = async (i, status) => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${JOB_OPENINGS}/${i?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: status,
      },
    };
    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success(`Job Opening made ${status}`);
        getAllData();
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Some Error Occured");
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Job Openings</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Interview</a>
                      </li>
                      <li className="breadcrumb-item active"> Job Openings</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    {edit || addNew ? (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="title">
                              Title <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              className="form-control"
                              placeholder="Enter Title"
                              value={user.title}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="from_age">From Age</label>
                            <input
                              type="number"
                              name="from_age"
                              id="from_age"
                              placeholder="Enter From Age"
                              className="form-control"
                              value={user.from_age}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="to_age">To Age</label>
                            <input
                              type="number"
                              name="to_age"
                              id="to_age"
                              placeholder="Enter To Age"
                              className="form-control"
                              value={user.to_age}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="experience_required">
                              Experience Required
                            </label>
                            <input
                              type="text"
                              name="experience_required"
                              id="experience_required"
                              placeholder="Experience Required"
                              className="form-control"
                              value={user.experience_required}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="documents_required">
                              Documents Required
                            </label>
                            <CreatableSelect
                              name="documents_required"
                              id="documents_required"
                              placeholder="Select Documents required"
                              isMulti
                              value={user.documents_required}
                              options={customOptions}
                              onChange={handleMultiSelect}
                              isOptionDisabled={(option) => option.isDisabled}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="education_required">
                              Educations Required
                            </label>
                            <CreatableSelect
                              name="education_required"
                              id="education_required"
                              placeholder="Select Education required"
                              isMulti
                              options={customEducationOption}
                              onChange={handleEducationMultiSelect}
                              value={user.education_required}
                              isOptionDisabled={(option) => option.isDisabled}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="gender_required">
                              Gender Required
                            </label>
                            <select
                              value={user.gender_required}
                              className="form-control"
                              name="gender_required"
                              id="gender_required"
                              onChange={handleChange}
                            >
                              <option value={"male"}>Male</option>
                              <option value={"female"}>Female </option>
                              <option value={"all"}>All</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Job Description
                            </label>
                            <TextArea
                              style={{ width: "100%", height: "70px" }}
                              showCount
                              maxLength={5000}
                              name="description"
                              onChange={handleChange}
                              value={user.description}
                              placeholder="Enter the Description"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Specifications
                            </label>
                            <TextArea
                              style={{ width: "100%", height: "70px" }}
                              showCount
                              maxLength={5000}
                              name="specifications"
                              onChange={handleChange}
                              value={user.specifications}
                              placeholder="Enter the Specifications"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <br />
                    {edit == false ? (
                      <>
                        {addNew == false ? (
                          <button
                            className="btn btn-nex btn-rounded float-lg-right mx-1 "
                            type="submit"
                            name="submit"
                            onClick={() => setAddNew(true)}
                          >
                            <i className="fa fa-add" aria-hidden="true" /> + Add
                            New
                          </button>
                        ) : (
                          <>
                            <button
                              className="btn btn-nex btn-rounded float-lg-right mx-1"
                              type="submit"
                              name="submit"
                              onClick={handleSubmit}
                            >
                              <i className="fa fa-save" aria-hidden="true" />{" "}
                              Save
                            </button>
                            <button
                              className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                              type="submit"
                              name="submit"
                              // style={{aspectRatio:'1/1'}}
                              onClick={() => {
                                setEdit(false);
                                setAddNew(false);
                                clearData();
                              }}
                            >
                              {"<   "}{" "}
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-nex btn-rounded float-lg-right mx-1"
                          type="submit"
                          name="submit"
                          onClick={handleEdit}
                        >
                          <i className="fa fa-save" aria-hidden="true" /> Edit
                        </button>
                        <button
                          className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                          type="submit"
                          name="submit"
                          // style={{aspectRatio:'1/1'}}
                          onClick={() => {
                            setEdit(false);
                            setAddNew(false);
                            clearData();
                          }}
                        >
                          {"<   "}{" "}
                        </button>
                      </>
                    )}
                    <br />
                    <br />
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h3 className="card-title">Job Openings</h3>
                      </div>
                    </div>
                    <table
                      id="datatable"
                      className="table table-bordered dt-responsive nowrap table-hover "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Sl. No.</th>
                          <th>Title</th>
                          <th>From Age</th>
                          <th>To Age</th>
                          <th>Description</th>
                          <th>Specifications</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((d, key) => {
                          return (
                            <tr>
                              <td>{key + 1}</td>
                              <td>{d?.title}</td>
                              <td>{d?.from_age}</td>
                              <td>{d?.to_age}</td>
                              <td>{d?.description}</td>
                              <td>{d?.specifications}</td>
                              <td>
                                <span
                                  className={`badge badge-soft-${
                                    d?.status == "ACTIVE" ? "success" : "danger"
                                  }`}
                                >
                                  {d?.status}
                                </span>
                              </td>
                              <td>
                                <acronym title="Edit">
                                  <a
                                    href="javascript:void(0)"
                                    className="badge badge-light"
                                    onClick={(e) => {
                                      setEdit(true);
                                      setUser({ ...d });
                                    }}
                                  >
                                    <i
                                      className="fa fa-edit "
                                      aria-hidden="true"
                                    />
                                  </a>
                                </acronym>
                                {d?.status == "ACTIVE" ? (
                                  <acronym title="In Active">
                                    <a
                                      href="javascript:void(0)"
                                      className="badge badge-light ml-2"
                                      onClick={() => {
                                        handleDelete(d, "INACTIVE");
                                      }}
                                    >
                                      <i
                                        className="fa fa-thumbs-down"
                                        aria-hidden="true"
                                        style={{ color: "red" }}
                                      />
                                    </a>
                                  </acronym>
                                ) : (
                                  <acronym title="Active">
                                    <a
                                      href="javascript:void(0)"
                                      className="badge badge-light ml-2"
                                      onClick={() => {
                                        handleDelete(d, "ACTIVE");
                                      }}
                                    >
                                      <i
                                        className="fa fa-thumbs-up"
                                        aria-hidden="true"
                                        style={{ color: "green" }}
                                      />
                                    </a>
                                  </acronym>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {data?.length == 0 ? <Nodata /> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobOpenings;

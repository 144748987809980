import React, { useState, useEffect } from "react";
import axios from "axios";
import NoData from "../../../../Components/NoData/Nodata";
import { useNavigate } from "react-router-dom";
import { LOCAL_JOBROLES } from "../../../../utils/LocalStorageConstants";
import { sessionOpt } from "../../../../Data/jsonData/Academics/Academics";
import { REPORT_STAFF_ATTENDANCE_MONTHWISE } from "../../../../utils/Reports.apiConst";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../../utils/LocalStorageConstants";
import "../../../../modals/HR/Leave/TableScroll.css";
import { EMPLOYEE_BIO_IDS } from "../../../../utils/apiConstants";

function AttendanceTimeClockReport({ setLoading, collegeId }) {
  const navigate = useNavigate();

  const monthNums = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "22",
  ];

  const collegeid = sessionStorage.getItem("college_id");
  console.log("collegeid -", collegeid);

  const [user, setUser] = useState({
    month1: monthNums[new Date().getMonth()],
    year1: new Date().getFullYear(),
    department_id: "",
    college_id: "",
  });

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());
  const [departmentOpt, setDepartmentOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  const [programOpt, setProgramOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
  );

  useEffect(
    () => {
      setDepartmentOpt(
        JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)).filter(
          // (item) => item.college_id == user.college_id
          (item) => item.college_id == collegeid
        )
      );
      setProgramOpt(JSON.parse(localStorage.getItem(LOCAL_PROGRAM)));
      user.department_id = "";
    },
    // [localStorage.getItem(LOCAL_DEPARTMENT), user.college_id]);
    [localStorage.getItem(LOCAL_DEPARTMENT), collegeid]
  );

  useEffect(() => {
    setDepartmentOpt(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  const [mainData, setMainData] = useState([]);
  const [data, setData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const tableRef = useRef();

  const months = [
    { key: "01", name: "JANUARY" },
    { key: "02", name: "FEBRUARY" },
    { key: "03", name: "MARCH" },
    { key: "04", name: "APRIL" },
    { key: "05", name: "MAY" },
    { key: "06", name: "JUNE" },
    { key: "07", name: "JULY" },
    { key: "08", name: "AUGUST" },
    { key: "09", name: "SEPTEMBER" },
    { key: "10", name: "OCTOBER" },
    { key: "11", name: "NOVEMBER" },
    { key: "12", name: "DECEMBER" },
  ];

  const getlocalStorage = () => {
    return localStorage.getItem(LOCAL_JOBROLES)
      ? JSON.parse(localStorage.getItem(LOCAL_JOBROLES))
      : [];
  };

  // const [attendanceData, setAttendanceData] = useState([]);
  const [jobRoleOpt, setJobRoleOpt] = useState(getlocalStorage);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  function groupBy(array, key) {
    return array.reduce((result, currentItem) => {
      (result[currentItem[key]] = result[currentItem[key]] || []).push(
        currentItem
      );
      return result;
    }, {});
  }

  // function countStatus(data) {
  //   const count = { present: 0, absent: 0, wo: 0 };
  //   data.forEach((item) => {
  //     if (item.Status === "A") {
  //       count.absent++;
  //     } else if (item.Status === "P") {
  //       count.present++;
  //     } else {
  //       count.wo++;
  //     }
  //   });
  //   return count;
  // }

  function countStatus(data) {
    const count = { present: 0, absent: 0, wo: 0 };
    data.forEach((item) => {
      const status = item.Status.toUpperCase(); // Normalize case
      if (status === "A") {
        count.absent++;
      } else if (status === "P") {
        count.present++;
      } else if (status === "WO") {
        count.wo++;
      }
    });
    return count;
  }

  const getData = async () => {
    if (!user?.month1) {
      return toast.error("Please select Month");
    }
    if (!user?.year1) {
      return toast.error("Please select Year");
    }

    // console.log("college_id - ", user.college_id);
    console.log("dept - ", user.department_id);
    const config1 = {
      method: "get",
      // url: `${EMPLOYEE_BIO_IDS}?department_id=${user.department_id}&college_id=${user.college_id}`,
      url: `${EMPLOYEE_BIO_IDS}?department_id=${user.department_id}&college_id=${collegeid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config1)
      .then(async (res) => {
        let bioIds = res.data.data;
        console.log("emp ids - ", res.data.data);
        let url = "";
        console.log("month - ", monthNums[new Date().getMonth()]);
        console.log("month1 - ", user.month1);
        console.log("year1 - ", new Date().getFullYear());
        console.log("year2 - ", user.year1);
        if (
          monthNums[new Date().getMonth()] == user.month1 &&
          new Date().getFullYear() == user.year1
        ) {
          url = `${REPORT_STAFF_ATTENDANCE_MONTHWISE}?month=${
            user.month1
          }&year=${user.year1}&last_date=${
            new Date().getDate() < 10
              ? `0${new Date().getDate()}`
              : new Date().getDate()
          }`;
        } else {
          url = `${REPORT_STAFF_ATTENDANCE_MONTHWISE}?month=${user.month1}&year=${user.year1}`;
        }
        const config = {
          method: "get",
          url: url,
          headers: {
            "Content-Type": "application/json",
          },
        };

        await axios(config)
          .then((res) => {
            console.log("month attendance data - ", res.data.data);

            let attendanceData = res.data.data;
            const parsedData = attendanceData.map((item) => ({
              Empcode: item.EmpCode,
              Status: item.STATUS,
              Name: item.Name,
            }));

            console.log("parsedData -", parsedData);

            const groupedData = groupBy(parsedData, "Empcode");

            console.log("groupedData -", groupedData);

            let countsByEmpcode = [];

            // Iterate over groupedData using Object.entries for better readability
            Object.entries(groupedData).forEach(([empcode, records]) => {
              countsByEmpcode.push({
                Name: records[0].Name, // Assuming Name is consistent across records
                Empcode: empcode,
                Counts: countStatus(records),
              });
            });

            // Filter employees by Empcode if bioIds contains the Empcode
            countsByEmpcode = countsByEmpcode.filter(
              (employee) =>
                employee?.Empcode && bioIds.includes(employee.Empcode)
            );

            console.log("number of employees - ", countsByEmpcode.length);
            console.log("Counts by Empcode:", countsByEmpcode);

            setMainData(countsByEmpcode);

            // Filter the data by searchName
            let filteredData = countsByEmpcode.filter((employee) =>
              employee.Name.toLowerCase().includes(searchName.toLowerCase())
            );

            // Set filtered data to state
            setData(filteredData);
            setLoading(0);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
          });
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
    setLoading(0);
  };

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${months.find((s) => s.key == user?.month1)?.name}-${
      user?.year1
    } Employee Attendance`,
    sheet: "Users",
  });

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    const formattedTime = `${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
    return `${formattedDate} ${formattedTime}`;
  };

  useEffect(() => {
    const filteredData = mainData.filter((item) =>
      item.Name.toLowerCase().includes(searchName.toLowerCase())
    );
    setData(filteredData);
  }, [searchName]);

  useEffect(() => {
    setData([]);
    setMainData([]);
  }, [user?.month1, user?.year1, user?.department_id]);

  console.log("data -", data);

  return (
    <div className="AttendanceTimeClockReport">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">STAFF ATTENDANCE MONTH WISE</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="card-title">Select Criteria</div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Department <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="department_id"
                            id="class"
                            value={user.department_id}
                            onChange={handleChange}
                          >
                            <option value="">All</option>
                            {departmentOpt
                              ?.filter((s) => s.college_id == collegeid)
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name},{" "}
                                  {
                                    programOpt.find(
                                      (item) => item.id == i.program_id
                                    )?.name
                                  }
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="month">
                            Month <span className="text-danger">*</span>
                          </label>
                          <select
                            name="month1"
                            id="month1"
                            required=""
                            className="form-control"
                            value={user?.month1}
                            onChange={handleChange}
                          >
                            <option value="">Select Month</option>
                            <option value="01">JANUARY</option>
                            <option value="02">FEBRUARY</option>
                            <option value="03">MARCH</option>
                            <option value="04">APRIL</option>
                            <option value="05">MAY</option>
                            <option value="06">JUNE</option>
                            <option value="07">JULY</option>
                            <option value="08">AUGUST</option>
                            <option value="09">SEPTEMBER</option>
                            <option value="10">OCTOBER</option>
                            <option value="11">NOVEMBER</option>
                            <option value="12">DECEMBER</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="year">
                            Year <span className="text-danger">*</span>
                          </label>
                          <select
                            name="year1"
                            id="year1"
                            required=""
                            className="form-control"
                            value={user?.year1}
                            onChange={handleChange}
                          >
                            <option value="">Select Year</option>
                            {sessionOpt &&
                              sessionOpt?.map((item, key) => {
                                return (
                                  <option value={item?.id}>
                                    {item?.name.substring(0, 4)}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-1 my-4 ml-4">
                        <button
                          className="btn btn-nex"
                          name="submit"
                          type="submit"
                          value="staff"
                          onClick={getData}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <br />
                    <div className="col-md-12 d-flex justify-content-between align-items-center">
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search By Name"
                          value={searchName}
                          onChange={(e) => {
                            setSearchName(e.target.value);
                          }}
                        />
                      </div>
                      <div></div>
                      <div>
                        <button
                          className="btn btn-primary rounded-pill mr-2"
                          onClick={onDownload}
                        >
                          Excel
                        </button>
                        <button
                          className="btn btn-primary rounded-pill"
                          onClick={handlePrint}
                        >
                          PDF
                        </button>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table-responsive fixTableHead">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th className="text-uppercase" colSpan={2}>
                                  Employees - {data ? data.length : 0}
                                </th>
                                <th className="text-uppercase" colSpan={3}>
                                  Year - {user?.year1}
                                </th>
                                <th className="text-uppercase" colSpan={3}>
                                  Month -{" "}
                                  {
                                    months.find((s) => s.key == user?.month1)
                                      ?.name
                                  }
                                </th>
                              </tr>
                              <tr>
                                <th>Sl.No</th>
                                <th>Employee Name</th>
                                <th>Biometric ID</th>
                                <th>Present</th>
                                <th>Absent</th>
                                <th>Weekly Off</th>
                                <th>Total</th>
                                <th>Percentage</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data && data.length ? (
                                data?.map((i, key) => {
                                  let percentage = 0;
                                  if (
                                    i?.Counts.present + i?.Counts.absent !==
                                    0
                                  ) {
                                    percentage = (
                                      (i?.Counts.present * 100) /
                                      (i?.Counts.present + i?.Counts.absent)
                                    ).toFixed(2);
                                  }
                                  let color = "#CCFFCD";
                                  if (percentage < 60) color = "#FFCCCB";
                                  else if (percentage < 80 && percentage >= 60)
                                    color = "#fffdd0";
                                  return (
                                    <tr>
                                      <td>{key + 1}</td>
                                      <td>{i?.Name}</td>
                                      <td>{i?.Empcode}</td>
                                      <td className="text-success">
                                        <b>{i?.Counts.present}</b>
                                      </td>
                                      <td className="text-danger">
                                        <b>{i?.Counts.absent}</b>
                                      </td>
                                      <td className="text-muted">
                                        <b>{i?.Counts.wo}</b>
                                      </td>
                                      <td className="text-primary">
                                        <b>
                                          {i?.Counts.present +
                                            i?.Counts.absent +
                                            i?.Counts.wo}
                                        </b>
                                      </td>
                                      <td style={{ backgroundColor: color }}>
                                        <b>{percentage}%</b>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={12}>
                                    <NoData />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "none" }}>
                <table className="table table-bordered" ref={tableRef}>
                  <thead>
                    <tr>
                      <th colSpan={8}>
                        <div className="row">
                          <div className="col-12">
                            <p className="text-dark float-right">
                              Printed On - {getCurrentDate()}
                            </p>
                          </div>
                          <div
                            className="col-12"
                            // style={{ marginLeft: "180px" }}
                          >
                            <h3 className="text-primary text-center">
                              <img
                                className=""
                                src="../../../assets/images/Nexenstial Logo.jpg"
                                width="80px"
                                height="60px"
                              />
                              <span className="ml-3">
                                {
                                  collegeOpt?.find((s) => s.id == collegeid)
                                    ?.name
                                }
                              </span>
                            </h3>
                          </div>
                          <div className="col-12 text-center mt-4">
                            <h5>
                              <strong>MONTHLY STAFF ATTENDANCE REPORT</strong>
                            </h5>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th className="text-uppercase" colSpan={2}>
                        Employees - {data ? data.length : 0}
                      </th>
                      <th className="text-uppercase" colSpan={3}>
                        Year - {user?.year1}
                      </th>
                      <th className="text-uppercase" colSpan={3}>
                        Month -{" "}
                        {months.find((s) => s.key == user?.month1)?.name}
                      </th>
                    </tr>
                    <tr>
                      <th>Sl.No</th>
                      <th>Employee Name</th>
                      <th>Biometric ID</th>
                      <th>Present</th>
                      <th>Absent</th>
                      <th>Weekly Off</th>
                      <th>Total</th>
                      <th>Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.length ? (
                      data?.map((i, key) => {
                        let percentage = 0;
                        if (i?.Counts.present + i?.Counts.absent !== 0) {
                          percentage = (
                            (i?.Counts.present * 100) /
                            (i?.Counts.present + i?.Counts.absent)
                          ).toFixed(2);
                        }
                        let color = "#CCFFCD";
                        if (percentage < 60) color = "#FFCCCB";
                        else if (percentage < 80 && percentage >= 60)
                          color = "#fffdd0";
                        return (
                          <tr>
                            <td>{key + 1}</td>
                            <td>{i?.Name}</td>
                            <td>{i?.Empcode}</td>
                            <td className="text-success">
                              <b>{i?.Counts.present}</b>
                            </td>
                            <td className="text-danger">
                              <b>{i?.Counts.absent}</b>
                            </td>
                            <td className="text-muted">
                              <b>{i?.Counts.wo}</b>
                            </td>
                            <td className="text-primary">
                              <b>
                                {i?.Counts.present +
                                  i?.Counts.absent +
                                  i?.Counts.wo}
                              </b>
                            </td>
                            <td style={{ backgroundColor: color }}>
                              <b>{percentage}%</b>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <NoData />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttendanceTimeClockReport;

import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import StatusBadges from "./../../../Components/StatusBadge.jsx";
import { getFileUrl } from "../../../Helpers/Helpers.js";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../utils/AssetsReferenceTypes.js";
import { message } from "antd";
import { NAAC } from "../../../utils/apiConstants.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { useSearchParams } from "react-router-dom";
const toolbar = [
  "undo",
  "redo",
  "|",
  "heading",
  "|",
  "bold",
  "italic",
  "|",
  "link",
  "blockQuote",
  "insertTable",
  "|",
  "bulletedList",
  "numberedList",
  "outdent",
  "alignment",
  "indent",
  "|",
  "imageUpload",
];

export default function ExecutiveSummary() {
  const [data, setData] = useState({
    id: "",
    status: "",
    introductory_note: "",
    criterion_wise_summary: "",
    strength_weaknesses_opportunities_and_challenges: "",
    additional_information: "",
    over_all_conclusive_explication: "",
  });
  const [searchParams] = useSearchParams();
  const userRole = searchParams.get("userRole");
  let emp_id = sessionStorage.getItem("employee_id");
  const [loading, setLoading] = useState(0);
  const [wordCount, setWordCount] = useState({
    introductory_note: 0,
    criterion_wise_summary: 0,
    strength_weaknesses_opportunities_and_challenges: 0,
    additional_information: 0,
    over_all_conclusive_explication: 0,
  });
  const pdfRef = useRef();
  const maxWords = 5000;
  const validateData = () => {
    const requiredFields = [
      "introductory_note",
      "criterion_wise_summary",
      "strength_weaknesses_opportunities_and_challenges",
      "additional_information",
      "over_all_conclusive_explication",
    ];

    for (const field of requiredFields) {
      if (wordCount[field] === 0) {
        toast.error(`Please fill in the ${field.split("_").join(" ")} field`);
        return false;
      }
      if (wordCount[field] > maxWords) {
        toast.error(
          `Please reduce the number of words in the ${field
            .split("_")
            .join(" ")} field`
        );
        return false;
      }
    }
    return true;
  };
  const handleSubmitForReview = (e) => {
    handleSaveOrSubmit(e, "REVIEW");
  };

  const handleSaveOrSubmit = async (e, status) => {
    e.preventDefault();
    // List all required fields
    if (!validateData()) return;
    const info = {
      ...data,
      created_by: emp_id,
      updated_by: emp_id,
      status,
      application_id: 1,
    };
    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/executive-summary`,
      data: info,
    };
    await axios(config)
      .then((res) => {
        toast.success(
          `${
            status == "DRAFT"
              ? "Successfully saved as draft"
              : "Successfully submitted for review"
          } `
        );
        getFormData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const handleSaveAsDraft = (e) => {
    handleSaveOrSubmit(e, "DRAFT");
  };

  const countWords = (text) => {
    const words = text.trim().split(/\s+/); // Split by spaces and trim extra spaces
    return words.filter((word) => word.length > 0).length; // Filter out empty words
  };

  const handleEditorChange = (editor, key) => {
    const editorData = editor.getData();
    const currentWordCount = countWords(editorData);

    if (currentWordCount <= maxWords) {
      // Update the state if the word count is within the limit
      setData((prevValue) => ({
        ...prevValue,
        [key]: editorData,
      }));
    }
    setWordCount((prev) => ({ ...prev, [key]: currentWordCount }));
    //  else {
    //   // If the word count exceeds, do nothing (i.e., prevent further input)
    //   alert(`You cannot exceed ${maxWords} words.`);
    //   editor.setData(data[key]); // Revert editor content
    // }
  };
  console.log("data", data);
  const addAttachment = async (file) => {
    try {
      const response = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `Resume`,
        file.name.split(".")[1],
        setLoading,
        file
      );
      return response;
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("File upload failed");
      throw error;
    }
  };

  const createUploadAdapter = (loader) => {
    return {
      upload: async () => {
        try {
          const file = await loader.file;
          // Directly get the uploaded file URL
          const uploadedUrl = await addAttachment(file);
          console.log("Uploaded image URL:", uploadedUrl);
          // Return the URL in the format CKEditor expects
          return { default: uploadedUrl };
        } catch (error) {
          console.error("Upload failed:", error);
          throw error;
        }
      },
    };
  };

  function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return createUploadAdapter(loader);
    };
  }

  const getFormData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/executive-summary/1`,
    };

    await axios(config)
      .then((res) => {
        let data = res.data[0];
        if (data) {
          setData(data);
        }
        // console.log("data fetched is", res.data);
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  useEffect(() => {
    getFormData();
  }, []);
  const printPdf = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "Executive Summary",
  });
  const handlePrint = () => {
    pdfRef.current.style.display = "block";
    setTimeout(() => {
      printPdf();
      pdfRef.current.style.display = "none";
    }, 1);
  };

  return (
    <>
      <div className="shadow p-3 border my-2 mb-4 bg-white">
        <div className="d-flex justify-content-end">
          {data.status !== "" ? (
            <button type="button" class="btn btn-primary" onClick={handlePrint}>
              Export PDF
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex flex-column gap-4">
          <div className="d-flex justify-content-between align-items-center">
            <h3>Executive Summary</h3>
            <StatusBadges />
          </div>

          <span className="fw-medium">
            Every HEI applying for the A&A process shall prepare an Executive
            Summary highlighting the main features of the Institution including
          </span>

          <span className="text-danger">
            *Note - Maximum word allowed is {maxWords} words
          </span>
          <span
            className={`text-white d-inline-block text-center px-4 py-1 shadow-sm small rounded-pill ${
              data?.status === "DRAFT"
                ? "bg-primary"
                : data?.status === "REVIEW"
                ? "bg-warning"
                : data?.status === "REJECTED"
                ? "bg-danger"
                : data?.status === "APPROVED"
                ? "bg-success"
                : "bg-secondary"
            }`}
            style={{ width: "fit-content" }}
          >
            {data?.status === "DRAFT"
              ? "In Draft"
              : data?.status === "REVIEW"
              ? "Submitted for review"
              : data?.status === "REJECTED"
              ? "Rejected"
              : data?.status === "APPROVED"
              ? "Approved"
              : "Not Started"}
          </span>
          <div className="d-flex flex-column gap-3">
            <div>
              <p>
                <strong>Introductory Note:</strong>
              </p>
              <div
                className={` border pdf-border ${
                  wordCount?.introductory_note > maxWords ? "border-danger" : ""
                }`}
              >
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: toolbar,
                    extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
                  }}
                  data={data.introductory_note}
                  onChange={(event, editor) =>
                    handleEditorChange(editor, "introductory_note")
                  }
                />
              </div>
              <div className="text-left mt-2 word-count-section">
                <span className="font-weight-bold">Words:</span>
                <span
                  className={`${
                    wordCount.introductory_note > maxWords ? "text-danger" : ""
                  }`}
                >
                  {wordCount.introductory_note}/{maxWords}{" "}
                  {wordCount.introductory_note > maxWords
                    ? "Exceeded the word limit"
                    : ""}
                </span>
              </div>
            </div>
            <div>
              <p>
                <strong>Criterion-wise Summary:</strong>
              </p>
              <div
                className={`pdf-border border ${
                  wordCount?.criterion_wise_summary > maxWords
                    ? "border-danger"
                    : ""
                }`}
              >
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: toolbar,
                    extraPlugins: [CustomUploadAdapterPlugin],
                  }}
                  data={data.criterion_wise_summary}
                  onChange={(event, editor) =>
                    handleEditorChange(editor, "criterion_wise_summary")
                  }
                />
              </div>
              <div className="text-left mt-2 word-count-section">
                <span className="font-weight-bold">Words:</span>
                <span
                  className={`${
                    wordCount.criterion_wise_summary >= maxWords
                      ? "text-danger"
                      : ""
                  }`}
                >
                  {wordCount.criterion_wise_summary}/{maxWords}{" "}
                  {wordCount.criterion_wise_summary > maxWords
                    ? "Exceeded the word limit"
                    : ""}
                </span>
              </div>
            </div>
            <div>
              <p>
                <strong>
                  Strength Weaknesses Opportunities and Challenges (SWOC):
                </strong>
              </p>
              <div
                className={`pdf-border border ${
                  wordCount?.strength_weaknesses_opportunities_and_challenges >
                  maxWords
                    ? "border-danger"
                    : ""
                }`}
              >
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: toolbar,
                    extraPlugins: [CustomUploadAdapterPlugin],
                  }}
                  data={data.strength_weaknesses_opportunities_and_challenges}
                  onChange={(event, editor) =>
                    handleEditorChange(
                      editor,
                      "strength_weaknesses_opportunities_and_challenges"
                    )
                  }
                />
              </div>
              <div className="text-left mt-2 word-count-section">
                <span className="font-weight-bold">Words:</span>
                <span
                  className={`${
                    wordCount.strength_weaknesses_opportunities_and_challenges >=
                    maxWords
                      ? "text-danger"
                      : ""
                  }`}
                >
                  {wordCount.strength_weaknesses_opportunities_and_challenges}/
                  {maxWords}{" "}
                  {wordCount.strength_weaknesses_opportunities_and_challenges >
                  maxWords
                    ? "Exceeded the word limit"
                    : ""}
                </span>
              </div>
            </div>
            <div>
              <p>
                <strong>Any additional information:</strong>
              </p>
              <div
                className={`pdf-border border ${
                  wordCount?.additional_information > maxWords
                    ? "border-danger"
                    : ""
                }`}
              >
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: toolbar,
                    extraPlugins: [CustomUploadAdapterPlugin],
                  }}
                  data={data.additional_information}
                  onChange={(event, editor) =>
                    handleEditorChange(editor, "additional_information")
                  }
                />
              </div>
              <div className="text-left mt-2 word-count-section">
                <span className="font-weight-bold">Words:</span>
                <span
                  className={`${
                    wordCount.additional_information >= maxWords
                      ? "text-danger"
                      : ""
                  }`}
                >
                  {wordCount.additional_information}/{maxWords}{" "}
                  {wordCount.additional_information > maxWords
                    ? "Exceeded the word limit"
                    : ""}
                </span>
              </div>
            </div>
            <div>
              <p>
                <strong>Over all conclusive explication:</strong>
              </p>
              <div
                className={`pdf-border border ${
                  wordCount?.over_all_conclusive_explication > maxWords
                    ? "border-danger"
                    : ""
                }`}
              >
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: toolbar,
                    extraPlugins: [CustomUploadAdapterPlugin],
                  }}
                  data={data.over_all_conclusive_explication}
                  onChange={(event, editor) =>
                    handleEditorChange(
                      editor,
                      "over_all_conclusive_explication"
                    )
                  }
                />
              </div>
              <div className="text-left mt-2 word-count-section">
                <span className="font-weight-bold">Words:</span>
                <span
                  className={`${
                    wordCount.over_all_conclusive_explication >= maxWords
                      ? "text-danger"
                      : ""
                  }`}
                >
                  {wordCount.over_all_conclusive_explication}/{maxWords}{" "}
                  {wordCount.over_all_conclusive_explication > maxWords
                    ? "Exceeded the word limit"
                    : ""}
                </span>
              </div>
            </div>
          </div>
          {data.status != "" ? (
            <div ref={pdfRef} style={{ display: "none" }}>
              <div className=" m-5 d-flex flex-column gap-4 text-justify">
                <h2>Executive Summary</h2>
                <div>
                  <h3>1.1 Introductory Note:</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.introductory_note,
                    }}
                  />
                </div>
                <div>
                  <h3>1.2 Criterion-wise Summary:</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.criterion_wise_summary,
                    }}
                  />
                </div>
                <div>
                  <h3>1.3 Any additional information:</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.additional_information,
                    }}
                  />
                </div>
                <div>
                  <h3>1.4 Over all conclusive explication:</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.over_all_conclusive_explication,
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {userRole === "author" ? (
            <div className="text-right mt-5">
              <button
                className="btn btn-primary mr-2"
                onClick={handleSaveAsDraft}
              >
                Save as Draft
              </button>
              <button
                className="btn btn-warning"
                onClick={handleSubmitForReview}
              >
                <span>Submit for Review</span>
              </button>
            </div>
          ) : (
            <div className="text-right mt-5">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSaveAsDraft}
              >
                Reject
              </button>
              <button
                className="btn btn-success"
                onClick={handleSubmitForReview}
              >
                <span>Accept</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

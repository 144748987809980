import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import { EMPLOYEE_SHIFT, SHIFT_DATA_UPDATE } from "../../utils/apiConstants";
import TextArea from "antd/lib/input/TextArea";

function ModalCreateShift(props) {
  const employee_id = sessionStorage.getItem("employee_id");
  const college_id = sessionStorage.getItem("college_id");

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [workingHours, setWorkingHours] = useState("");
  const [minimumWorkingHr, setMinimumWorkingHr] = useState("");
  const [weekOff, setWeekOff] = useState("");
  const [bufferTime, setBufferTime] = useState("");
  const [duplicatePunch, setDuplicatePunch] = useState("");
  const [active, setActive] = useState(1);
  const [day, setDay] = useState("");

  //Function to clear the data
  const clearData = () => {
    setTitle("");
    setDesc("");
    setActive(1);
    setFromTime("");
    setToTime("");
    setMinimumWorkingHr("");
    setWeekOff("");
    setBufferTime("");
    setDuplicatePunch("");
  };

  const weekDays = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  // console.log("props.data.id -", props.data.id);

  //Config for axios to send requests
  const config = {
    method: props.type === "edit" ? "put" : "post",
    // url: `${EMPLOYEE_SHIFT}${props.type == "edit" ? "/" + props.data.id : ""}`,
    url:
      props.type === "edit"
        ? `${SHIFT_DATA_UPDATE}/${props.data.id}`
        : EMPLOYEE_SHIFT,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
    },
    data: {
      title: title,
      description: desc,
      from_time: fromTime,
      to_time: toTime,
      working_hours: workingHours,
      minimum_working_hours: minimumWorkingHr,
      week_off: weekOff,
      date: new Date(),
      created_by: employee_id,
      status: active ? "ACTIVE" : "INACTIVE",
      college_id: college_id,
      buffer_time: bufferTime,
      duplicate_punch: duplicatePunch,
      holiday: day,
    },
  };

  //Function which triggers when user save changes
  //function hit the end points with put or post requests
  const SubmitModal = async () => {
    props.setLoading(1);
    await axios(config)
      .then((res) => {
        props.setLoading(0);
        console.log(res);
        clearData();
        props.reloadData();
        // toast.success("Successfully added details");
        if (props.type === "edit") {
          toast.success("Successfully updated details");
        } else {
          toast.success("Successfully added details");
        }
      })
      .catch((err) => {
        props.setLoading(0);
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  // Helper function to convert 12-hour time (e.g., "09:35 AM") to 24-hour format (e.g., "09:35")
  const convertTo24HourFormat = (time12h) => {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");

    if (modifier === "PM" && hours !== "12") {
      hours = String(parseInt(hours, 10) + 12);
    }
    if (modifier === "AM" && hours === "12") {
      hours = "00";
    }

    return `${hours.padStart(2, "0")}:${minutes}`;
  };

  useEffect(() => {
    if (props.type === "edit") {
      if (props.data) {
        setTitle(props.data.title);
        setDesc(props.data.description);
        setActive(props.data.status == "ACTIVE" ? true : false);
        setFromTime(convertTo24HourFormat(props.data.from_time));
        setToTime(convertTo24HourFormat(props.data.to_time));
        setMinimumWorkingHr(props.data.minimum_working_hours);
        setWeekOff(props.data.week_off);
        setBufferTime(props.data.buffer_time);
        setDuplicatePunch(props.data.duplicate_punch);
      }
    }

    if (props.type == "add") {
      clearData();
    }
  }, [props.data, props.type]);

  //////////////////////////////////////////////

  // Calculate working hours whenever fromTime or toTime changes
  useEffect(() => {
    if (fromTime && toTime) {
      const from = new Date(`1970-01-01T${fromTime}:00`);
      const to = new Date(`1970-01-01T${toTime}:00`);

      // Calculate the difference in milliseconds
      const difference = to - from;

      if (difference > 0) {
        const hours = String(
          Math.floor(difference / (1000 * 60 * 60))
        ).padStart(2, "0");
        const minutes = String(
          Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
        ).padStart(2, "0");
        setWorkingHours(`${hours}:${minutes}`);
      } else {
        setWorkingHours("Invalid time range");
      }
    } else {
      setWorkingHours("");
    }
  }, [fromTime, toTime]);

  const handleWeekOffChange = (day) => {
    setWeekOff((prev) => {
      const days = prev ? prev.split(",") : [];
      if (days.includes(day)) {
        // If day is already in weekOff, remove it
        return days.filter((d) => d !== day).join(",");
      } else {
        // Otherwise, add it
        return [...days, day].join(",");
      }
    });
  };

  console.log("weekOff -", weekOff);
  console.log("workingHours -", workingHours);

  return (
    <div className="ModalCreateShift">
      <div
        className="modal fade"
        id="ModalCreateShift"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Create Shift
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <lable>
                      {" "}
                      Title <span style={{ color: "red" }}>*</span>
                    </lable>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      // disabled={props.type == "edit" ? true : false}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <lable>
                      {" "}
                      Description
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </lable>
                    <TextArea
                      type="text"
                      className="form-control"
                      name="description"
                      value={desc}
                      onChange={(e) => {
                        setDesc(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <lable>
                      {" "}
                      From Time <span style={{ color: "red" }}>*</span>
                    </lable>
                    <input
                      type="time"
                      className="form-control"
                      name="from_time"
                      value={fromTime}
                      onChange={(e) => {
                        setFromTime(e.target.value);
                      }}
                      // disabled={props.type == "edit" ? true : false}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <lable>
                      {" "}
                      To Time <span style={{ color: "red" }}>*</span>
                    </lable>
                    <input
                      type="time"
                      className="form-control"
                      name="to_time"
                      value={toTime}
                      onChange={(e) => {
                        setToTime(e.target.value);
                      }}
                      // disabled={props.type == "edit" ? true : false}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <lable>
                      {" "}
                      Working Hours
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </lable>
                    <input
                      type="text"
                      className="form-control"
                      name="working_hours"
                      value={workingHours}
                      onChange={(e) => {
                        setWorkingHours(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <lable>
                      {" "}
                      Minimum Working Hours
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </lable>
                    <input
                      type="number"
                      className="form-control"
                      name="minimum_working_hours"
                      value={minimumWorkingHr}
                      onChange={(e) => {
                        setMinimumWorkingHr(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Week Off</label>
                    <div className="d-flex">
                      <div className="form-check me-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="secondSaturday"
                          onChange={() => handleWeekOffChange("2")}
                          checked={weekOff.includes("2")}
                        />
                        <label
                          className="form-check-label mt-1"
                          htmlFor="secondSaturday"
                        >
                          2nd Saturday
                        </label>
                      </div>
                      <div className="form-check ml-5">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="fourthSaturday"
                          onChange={() => handleWeekOffChange("4")}
                          checked={weekOff.includes("4")}
                        />
                        <label
                          className="form-check-label mt-1"
                          htmlFor="fourthSaturday"
                        >
                          4th Saturday
                        </label>
                      </div>
                      <div className="form-check ml-2">
                        <select
                          className="form-control"
                          name="day"
                          onChange={(e) => {
                            setDay(e.target.value);
                          }}
                        >
                          <option value="" selected>
                            {" "}
                            Day
                          </option>
                          {weekDays?.map((item, key) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <lable>
                      {" "}
                      Buffer Time <span style={{ color: "red" }}>*</span>
                    </lable>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        name="buffer_time"
                        value={bufferTime}
                        onChange={(e) => {
                          setBufferTime(e.target.value);
                        }}
                        // disabled={props.type == "edit" ? true : false}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          In Min
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4">
                  {" "}
                  <div className="form-group">
                    <lable>
                      {" "}
                      Duplicate Punch <span style={{ color: "red" }}>*</span>
                    </lable>
                    <div className="input-group mb-3">
                      <input
                        type="number"
                        className="form-control"
                        name="duplicate_punch"
                        value={duplicatePunch}
                        onChange={(e) => {
                          setDuplicatePunch(e.target.value);
                        }}
                        disabled={props.type == "edit" ? true : false}
                        placeholder="Duplicate Punch Time"
                        aria-label="Duplicate Punch Time"
                        aria-describedby="basic-addon2"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          In Min
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <br />
              </div>
              <div className="row d-flex justify-content-between px-2 mt-3">
                <button
                  className="btn btn-danger btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={SubmitModal}
                >
                  {props?.type == "edit" ? "Save Changes" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalCreateShift;

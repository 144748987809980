import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ADD_EVENT, ANNOUNCEMENTS, GET_EVENT } from "../../utils/apiConstants";
import { LOCAL_EMPLOYEE } from "../../utils/LocalStorageConstants";
import ModalAnnouncementAttachment from "./ModalAnnouncementAttachment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function ViewAnnouncementModal({ application_id }) {
  const [alldata, setAllData] = useState([]);
  const [link, setLink] = useState("");
  const [isRejected, setIsRejected] = useState(false);
  const [formData, setFormData] = useState({
    application_id,
    isApproved: "approved",
    rejectionMessage: "",
  });

  const employee_id = sessionStorage.getItem("role");
  console.log("application_id", application_id);
  const getAlldata = async () => {
    console.log("Inside the get all data");
    try {
      const config = {
        method: "get",
        url: `${GET_EVENT}/${application_id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        },
      };

      const res = await axios(config);
      console.log("The data in modal", res.data.data);
      setAllData(res.data.data);
    } catch (err) {
      console.error(err);
      toast.error("Something Went Wrong");
    }
  };

  useEffect(() => {
    if (application_id) {
      getAlldata();
    }
  }, [application_id]);

  const [modalTitle, setModalTitle] = useState("");
  const [modalLink, setModalLink] = useState("");
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "isApproved" && value === "reject") {
      setIsRejected(true);
    } else {
      setIsRejected(false);
    }
  };
  const handleSave = async () => {
    const { application_id, isApproved, rejectionMessage } = formData;
    if (!isApproved) {
      toast.error("Please approve or reject before saving ");
      return;
    }
    try {
      const config = {
        method: "put",
        url: `${ADD_EVENT}/${application_id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        },
        data: { ...alldata, formData },
      };

      const res = await axios(config);
      if (res.success) {
        toast.success("Successfully saved the data");
      }
    } catch (err) {
      console.error(err);
      toast.error("Something Went Wrong");
    }
  };
  return (
    <div className="ViewModalAnnouncement">
      <div
        className="modal fade"
        id="ViewModalAnnouncement"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ViewModalAnnouncement"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title fw-2" id="exampleModalLongTitle">
                Event View
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  onClick={() => {
                    setLink("");
                  }}
                >
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <table
                id="datatable"
                className="table table-bordered  table-hover"
                style={{
                  borderCollapse: "collapse",
                  borderSpacing: 0,
                  width: "100%",
                }}
              >
                {alldata?.map((item, index) => (
                  <>
                    <tr>
                      <th>Title</th>
                      <td colSpan={5}>{item?.title}</td>
                    </tr>

                    <tr>
                      <th>From Date</th>
                      <th>To Date</th>
                      <th>Created on</th>
                      <th>Created by</th>
                      <th>Attachment</th>
                    </tr>

                    <tr>
                      {/* {console.log(item?.attachment.length)} */}

                      <td>{item?.from_date?.split("T")[0]}</td>
                      <td>{item?.to_date?.split("T")[0]}</td>
                      <td>{item?.createdAt?.split("T")[0]}</td>
                      <td>{employee_id}</td>
                      <td>
                        {item?.attachment === "" ? (
                          "No attachments are there "
                        ) : (
                          <button
                            onClick={() => {
                              console.log(item?.attachment);
                              setModalLink(item?.attachment);
                              setModalTitle(item?.title);
                            }}
                            data-toggle="modal"
                            data-target="#ModalAnnouncementAttachment"
                            className="btn btn-nex btn-sm my-1 ml-3"
                            title="View"
                          >
                            View
                          </button>
                        )}

                        <ModalAnnouncementAttachment
                          title={modalTitle}
                          img={modalLink}
                          setLink={alldata?.attachment}
                        />
                      </td>
                    </tr>

                    <th colSpan={6}>Description</th>
                    <tr>
                      <td colSpan={6}>
                        <ReactQuill
                          theme="snow"
                          inputReadOnly
                          readOnly
                          value={item.description}
                          placeholder="Write Detailed Description of Announcement Here..."
                        />
                      </td>
                    </tr>
                    <th colSpan={6}>Approve or Reject</th>
                    <tr>
                      <td colSpan={6}>
                        <div class="form-group">
                          <select
                            defaultValue={"approve"}
                            name="isApproved"
                            onChange={handleChange}
                            className="form-control"
                          >
                            <option value={"approve"}>Approve</option>
                            <option value={"reject"}>Reject</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    {isRejected ? (
                      <>
                        <th colSpan={6}>Reason For Rejection</th>
                        <tr>
                          <td colSpan={6}>
                            <div class="form-group">
                              <textarea
                                name="rejectionMessage"
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="Please write a reason for the rejection..."
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </table>
              <div className="d-flex justify-content-end w-100 gap-2">
                {/* <button className="btn btn-danger">Reject</button> */}
                <button onClick={handleSave} className="btn btn-success">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAnnouncementModal;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Components/Loader/Loader";
import {
  EMPLOYEE_EXPERIENCE,
  EMPLOYEE_PUBLICATION,
} from "../../../utils/apiConstants";
import { getFileUrl } from "../../../Helpers/Helpers";
import {
  ASSET_EMPLOYEE_DOCUMENT,
  ASSET_EMPLOYEE_EXPERIENCE,
} from "../../../utils/AssetsReferenceTypes";
import DocumentsModal from "../../Students/DocumentsModal";

function ModalStaffPublication({ type, id, data, reloadData, setLoading }) {
  const [profilePhoto, setProfilePhoto] = useState();

  let tempPhoto = "";

  //object for all input values
  const [user, setUser] = useState({
    name: "",
    attachment: "",
    date: "",
    index: "",
    impact_factor: "",
    author: "",
    co_author: "",
    attachment_link: "",
  });

  const [attachment, setAttachment] = useState();

  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  //Function upload attachment to the s3
  const addAttachment = async (e) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `${id}_Experience`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      setUser((prevValue) => ({
        ...prevValue,
        attachment: d ? d : "",
      }));
      tempPhoto = d ? d : "";
    } catch (error) {
      console.log(error);
    }
  };

  //handleChange for all input fields
  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevValue) => ({
      ...prevValue,
      [`${name}`]: value,
    }));
  };

  //fuction to clear the input fields after completion of any operation
  const clearData = () => {
    setUser({
      name: "",
      attachment: "",
      date: "",
      index: "",
      impact_factor: "",
      author: "",
      co_author: "",
      attachment_link: "",
    });
  };

  console.log("temPhoto -", tempPhoto);

  //fuction to call after post or put
  const handleSubmit = async (d) => {
    // Validation for required fields
    if (!user.name) {
      toast.error("Name is required");
      return;
    }
    if (!user.date) {
      toast.error("Date is required");
      return;
    }
    if (!user.index) {
      toast.error("Index (Journal) is required");
      return;
    }
    if (!user.impact_factor) {
      toast.error("Impact Factor is required");
      return;
    }
    if (!user.author) {
      toast.error("Author is required");
      return;
    }
    if (!user.co_author) {
      toast.error("Co-Author is required");
      return;
    }

    // if (!user.attachment) {
    //   toast.error("Attachment is required");
    //   return;
    // }

    if (!user.attachment_link) {
      toast.error("Attachment Link is required");
      return;
    }

    let attachmentPromises = [];
    if (profilePhoto) attachmentPromises.push(addAttachment(profilePhoto));

    await Promise.all(attachmentPromises);
    setLoading(1);

    let data1 = user;
    if (tempPhoto) data1 = { ...data1, attachment: tempPhoto };
    console.log("data - ", data1);
    //config for axios
    const config = {
      method: type === "edit" ? "put" : "post",
      url: `${EMPLOYEE_PUBLICATION}${type === "edit" ? "/" + data.id : ""}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        employee_id: id,
        ...data1,
        status: d ? "INACTIVE" : "ACTIVE",
      },
    };
    console.log(config);

    setLoading(1);
    await axios(config)
      .then((res) => {
        toast.success(res.data.message);
        console.log(res);
        setLoading(0);
        reloadData();
        tempPhoto = "";
        // Close the modal after successful submission
        "#ModalStaffPublication".modal("hide");
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went wrong");
        tempPhoto = "";
        setLoading(0);
      });
  };

  useEffect(() => {
    if (type === "edit") {
      if (data) {
        setUser({
          name: data.name,
          attachment: data.attachment,
          date: data.date?.split("T")[0],
          index: data.index,
          impact_factor: data.impact_factor,
          author: data.author,
          co_author: data.co_author,
          attachment_link: data.attachment_link,
        });
      }
    }

    if (type === "add") {
      clearData();
    }
  }, [data, type]);

  return (
    <>
      <DocumentsModal title={title} img={link} setLink={setLink} />
      <div className="ModalStaffPublication">
        <div
          className="modal fade"
          id="ModalStaffPublication"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered mw-100 w-75"
            role="document"
          >
            <div className="modal-content ">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Add New Publication
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={user?.name}
                        onChange={handleChange}
                        placeholder="Enter Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        Date <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="date"
                        value={user.date}
                        onChange={handleChange}
                        placeholder="Select Date"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        Index (Journal) <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        type="text"
                        className="form-control"
                        name="index"
                        value={user.index}
                        onChange={handleChange}
                      >
                        <option value="">Select Index</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        Impact Factor <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="impact_factor"
                        value={user.impact_factor}
                        onChange={handleChange}
                        placeholder="Enter Impact Factor"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        Author <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="author"
                        value={user.author}
                        onChange={handleChange}
                        placeholder="Enter Author Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        Co-Author <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="co_author"
                        value={user.co_author}
                        onChange={handleChange}
                        placeholder="Enter Co-Author Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <lable htmlFor="">
                        {" "}
                        Attachment <span style={{ color: "red" }}>*</span>
                      </lable>
                      <input
                        className="form-control"
                        type="file"
                        name="attachment"
                        accept="application/pdf"
                        // value={user.attachment}
                        onChange={(e) => {
                          // addAttachment(e);
                          setProfilePhoto(e);
                        }}
                      />
                      {user?.attachment?.length > 0 ? (
                        <button
                          onClick={() => {
                            setLink(user?.attachment);
                            setTitle("Publication");
                          }}
                          data-toggle="modal"
                          data-target="#DocumentsModal"
                          className="btn btn-primary btn-sm my-1"
                        >
                          View
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        Attachment Link <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="attachment_link"
                        value={user.attachment_link}
                        onChange={handleChange}
                        placeholder="Enter Attachment Link"
                      />
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-between px-2">
                  <button
                    className="btn btn-danger btn-rounded btn-outline"
                    // data-dismiss="modal"
                    // aria-label="Close"
                    onClick={() => {
                      handleSubmit(1);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="btn btn-success btn-rounded btn-outline"
                    // data-dismiss="modal"
                    // aria-label="Close"
                    onClick={() => {
                      handleSubmit(0);
                    }}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalStaffPublication;

import React from "react";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Nodata from "./../../../Components/NoData/Nodata";
import { FEE_DETAILS, FEE_DETAILS_SQL } from "../../../utils/fees.apiConst";
import { useEffect } from "react";
import { ROUTES } from "../../../Router/routerConfig";
import * as XLSX from "xlsx/xlsx.mjs";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SECTION,
  ACADEMICS_ADD_SEMESTER,
} from "./../../../utils/Academics.apiConst";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";
import {
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
import { SESSION_ROLE } from "../../../utils/sessionStorageContants";

function FeeCollectionSearchDue({ setLoading, collegeId }) {
  const navigate = useNavigate();

  //Query data for easy filtering
  const location = useLocation();
  const query_department = new URLSearchParams(location.search).get(
    "department_id"
  );
  const query_session = new URLSearchParams(location.search).get("session_id");
  const query_class = new URLSearchParams(location.search).get("class_id");

  const [role, setRole] = useState("");

  const tableRef = useRef();

  useEffect(() => {
    setRole(sessionStorage.getItem(SESSION_ROLE));
  }, [sessionStorage.getItem(SESSION_ROLE)]);

  const [nextRoute, setNextRoute] = useState(
    ROUTES.Registar.Accounts.FeeCollection.AddFee
  );

  useEffect(() => {
    if (role == "SUPERADMIN") {
      setNextRoute(ROUTES.Registar.Accounts.FeeCollection.AddFee);
    } else if (role == "SUACC" || role == "ACC") {
      setNextRoute(ROUTES.Accountant.AddFee);
    } else if (role == "CASHIER") {
      setNextRoute(ROUTES.Cashier.AddFee);
    }
  }, [role]);

  const [data, setData] = useState([]);

  const [classOpt, setClassOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [sectionOpt, setSectionOpt] = useState([]);

  const [feeStatus, setFeeStatus] = useState("PENDING");

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("all");

  const getDepartmentOpt = () => {
    return localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;
  };
  const getProgramOpt = () => {
    return localStorage.getItem(LOCAL_PROGRAM)
      ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
      : null;
  };

  console.log(collegeId);
  const departmentOpt = getDepartmentOpt().filter(
    (s) => s.college_id == collegeId
  );
  const programOpt = getProgramOpt();

  const [user, setUser] = useState({
    // session_id: sessionOpt?.find((s) => s.status == "ACTIVE").id,
    session_id:
      Array.isArray(sessionOpt) && sessionOpt.find((s) => s.status === "ACTIVE")
        ? sessionOpt.find((s) => s.status === "ACTIVE").id
        : null,
    class_id: query_class ? query_class : "",
    section_id: "",
    department_id: query_department ? query_department : "",
    status: "ACTIVE",
  });

  useEffect(() => {
    if (location.search)
      setUser({
        // session_id: sessionOpt?.find((s) => s.status == "ACTIVE").id,
        session_id: sessionOpt?.find((s) => s.status == "ACTIVE").id || null,
        class_id: query_class ? query_class : "",
        section_id: "",
        department_id: query_department ? query_department : "",
        status: "ACTIVE",
      });
  }, [location.search]);

  const changeDir = (id) => {
    navigate(`${nextRoute}/${id}`);
  };

  const clearData = () => {
    setUser({
      session_id: sessionOpt?.find((s) => s.status == "ACTIVE").id,
      class_id: "",
      section_id: "",
      department_id: "",
      status: "ACTIVE",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getData = async () => {
    if (!user.session_id) return toast.error("Session is required");
    if (!user.department_id) return toast.error("Department is required");
    if (!user.class_id) return toast.error("Class is required");
    if (!user.semester_id) return toast.error("Semester is required");

    const config = {
      method: "get",
      url: `${FEE_DETAILS_SQL}?college_id=${collegeId}&department_id=${user.department_id}&session_id=${user.session_id}&class_id=${user.class_id}&status=${user?.status}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("search due fee data - ", res.data.data);
        const uniqueCategories = [
          ...new Set(
            res.data.data
              ?.filter((s) => s.category)
              ?.map((item) => item.category)
          ),
        ];
        setCategories(uniqueCategories);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  console.log("data -", data);

  const getAll = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    const [data1, data2] = await Promise.all([
      axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${collegeId}`,
      })
        .then((res) => {
          console.log(res);
          setClassOpt(res.data.data);
        })
        .catch((err) => {
          {
            console.log(err);
          }
        }),

      axios({
        ...config,
        url: `${ACADEMICS_ADD_SECTION}?college_id=${collegeId}`,
      })
        .then((res) => {
          setLoading(0);
          console.log(res);
          setSectionOpt(res.data.data);
        })
        .catch((err) => {
          {
            setLoading(0);
            console.log(err);
            toast.error("Some Error Occured");
          }
        }),
    ]);
  };

  const downloadExcel = () => {
    let json_data = [];
    for (const iterator of data) {
      const obj = {
        "Student Registeration Number": iterator?.usn,
        "Student Name": iterator?.name,
        Amount: iterator?.amount,
        "Transaction Id": iterator?.transaction_id,
        "Transaction Type": iterator?.type,
      };
      json_data.push(obj);
    }
    var worksheet = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `SUKALOL-Fee Details.xlsx`);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Student Fee Collection Fee Details",
    sheet: "Users",
  });

  const getAllData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    const [data1, data2] = await Promise.all([
      await axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${collegeId}`,
      })
        .then((res) => {
          console.log("class data - ", res.data.data);
          setClassOpt(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        }),

      await axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${collegeId}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        }),
    ]);
    setLoading(0);
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <div className="FeeCollectionSearchDue">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Search Due Fee</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Fee Collection</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Search Due Fee
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select criteria</h2>
                    <br />
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Session <span style={{ color: "red" }}>*</span>
                          </label>

                          <Select
                            name="session_id"
                            id="fee_grp"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            options={sessionOpt.map((data) => ({
                              value: data.id,
                              label: data.name,
                            }))}
                            value={
                              user.session_id
                                ? {
                                    value: user.session_id,
                                    label: sessionOpt.find(
                                      (data) => data.id == user.session_id
                                    )?.name,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              setUser((prev) => ({
                                ...prev,
                                session_id: selectedOption
                                  ? selectedOption.value
                                  : "",
                              }));
                            }}
                            placeholder="Select Session"
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="">
                            Department<span style={{ color: "red" }}>*</span>
                          </label>
                         

                          <Select
                            value={
                              user?.department_id
                                ? {
                                    value: user?.department_id,
                                    label: departmentOpt.find(
                                      (data) => data.id == user?.department_id
                                    )?.name,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              setUser((prev) => ({
                                ...prev,
                                department_id: selectedOption
                                  ? selectedOption.value
                                  : "",
                              }));
                            }}
                            options={departmentOpt.map((i) => ({
                              value: i.id,
                              label: `${i.name}, ${
                                programOpt?.find((s) => s.id === i.program_id)
                                  ?.name
                              }`,
                            }))}
                            placeholder="Select Department"
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Class<span style={{ color: "red" }}>*</span>
                          </label>
                          

                          <Select
                            type="text"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={
                              user.class_id
                                ? {
                                    value: user.class_id,
                                    label: classOpt.find(
                                      (i) => i.id == user.class_id
                                    )?.name,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              setUser((prev) => ({
                                ...prev,
                                class_id: selectedOption
                                  ? selectedOption.value
                                  : "",
                              }));
                            }}
                            options={classOpt
                              ?.filter(
                                (s) => s.department_id == user?.department_id
                              )
                              ?.map((i) => ({
                                value: i.id,
                                label: i.name,
                              }))}
                            placeholder="Select Class"
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="">
                            Semester<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="currentSemester"
                            id="semester"
                            value={semesterOpt.find(
                              (s) => s.value == user.semester_id
                            )}
                            onChange={(selectedOption) => {
                              setUser((prev) => ({
                                ...prev,
                                semester_id: selectedOption
                                  ? selectedOption.value
                                  : "",
                              }));
                            }}
                            options={semesterOpt
                              ?.filter((s) => s.class_id == user.class_id)
                              ?.map((i) => ({ label: i.name, value: i.id }))}
                            placeholder="Select Semester"
                          />
                        </div>
                      </div> */}
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="">
                            Department<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              user?.department_id
                                ? {
                                    value: user?.department_id,
                                    label: departmentOpt.find(
                                      (data) => data.id == user?.department_id
                                    )?.name,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              setUser((prev) => ({
                                ...prev,
                                department_id: selectedOption
                                  ? selectedOption.value
                                  : "",
                                class_id: "", // Clear the class
                                semester_id: "", // Clear the semester
                              }));
                            }}
                            options={departmentOpt.map((i) => ({
                              value: i.id,
                              label: `${i.name}, ${
                                programOpt?.find((s) => s.id === i.program_id)
                                  ?.name
                              }`,
                            }))}
                            placeholder="Select Department"
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Class<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            type="text"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={
                              user.class_id
                                ? {
                                    value: user.class_id,
                                    label: classOpt.find(
                                      (i) => i.id == user.class_id
                                    )?.name,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              setUser((prev) => ({
                                ...prev,
                                class_id: selectedOption
                                  ? selectedOption.value
                                  : "",
                                semester_id: "", // Clear the semester
                              }));
                            }}
                            options={classOpt
                              ?.filter(
                                (s) => s.department_id == user?.department_id
                              )
                              ?.map((i) => ({
                                value: i.id,
                                label: i.name,
                              }))}
                            placeholder="Select Class"
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="">
                            Semester<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="currentSemester"
                            id="semester"
                            value={
                              user.semester_id
                                ? {
                                    value: user.semester_id,
                                    label: semesterOpt.find(
                                      (s) => s.id == user.semester_id
                                    )?.name,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              setUser((prev) => ({
                                ...prev,
                                semester_id: selectedOption
                                  ? selectedOption.value
                                  : "",
                              }));
                            }}
                            options={semesterOpt
                              ?.filter((s) => s.class_id == user.class_id)
                              ?.map((i) => ({ label: i.name, value: i.id }))}
                            placeholder="Select Semester"
                          />
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Status<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id=""
                            className="form-control"
                            name="status"
                            value={user?.status}
                            onChange={handleChange}
                          >
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">Inactive</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Fee Status<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id=""
                            className="form-control"
                            name="feeStatus"
                            value={feeStatus}
                            onChange={(e) => setFeeStatus(e.target.value)}
                          >
                            <option value="PENDING">PENDING</option>
                            <option value="PAID">PAID</option>
                            <option value="ALL">ALL</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={getData}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>
            {/* container-fluid */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">Category</label>
                          <select
                            id=""
                            className="form-control"
                            name="category"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option value="all">ALL</option>
                            {categories?.map((i, k) => {
                              return <option value={i}>{i}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-8 ">
                        <span className="float-right">
                          <button
                            className="btn btn-primary rounded-pill"
                            onClick={onDownload}
                          >
                            Export
                          </button>
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div className="table-responsive">
                      <div
                        id="datatable_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <table
                              id="datatable"
                              className="table table-bordered dt-responsive table-hover no-wrap no-footer dtr-inline"
                              style={{ width: "100%" }}
                              role="grid"
                              ref={tableRef}
                              aria-describedby="datatable_info"
                            >
                              <thead>
                                <tr role="row">
                                  <th>Sl No</th>
                                  <th>Enrollment No.</th>

                                  <th>Student Name</th>
                                  <th>Category</th>
                                  <th>Caste</th>
                                  <th>Scholarship</th>

                                  <th>Amount</th>
                                  <th>Fee Paid</th>
                                  <th>Discount</th>
                                  <th>Fine</th>
                                  <th>Fee Pending</th>
                                  <th>Status</th>
                                  {role != "ADMIN" ? <th>Action</th> : <></>}
                                </tr>
                              </thead>
                              <tbody>
                                {data && data?.length != 0 ? (
                                  data
                                    ?.filter((s) =>
                                      feeStatus == "PENDING"
                                        ? parseInt(
                                            s.amount -
                                              s.paid_amount -
                                              s.discount
                                          ) != 0
                                        : feeStatus == "PAID"
                                        ? s.amount -
                                            s.paid_amount -
                                            s.discount ==
                                          0
                                        : 1
                                    )
                                    ?.filter(
                                      (s) =>
                                        s.status == user.status &&
                                        (category == "all" ||
                                          s.category == category)
                                    )
                                    ?.map((i, key) => {
                                      return (
                                        <tr>
                                          <td>{key + 1}</td>
                                          <td>{i.user_id}</td>

                                          <td>{i.name}</td>
                                          <td>{i?.category}</td>
                                          <td>{i?.caste}</td>

                                          <td>
                                            <span
                                              className={`badge badge-soft-${
                                                i?.scholarship
                                                  ? "success"
                                                  : "danger"
                                              }`}
                                            >
                                              {i?.scholarship ? "YES" : "NO"}
                                            </span>
                                          </td>

                                          <td>
                                            {parseInt(i.amount).toLocaleString(
                                              "en-IN",
                                              {
                                                style: "currency",
                                                currency: "INR",
                                                minimumFractionDigits: 0,
                                              }
                                            )}
                                          </td>
                                          <td>
                                            {parseInt(
                                              i.paid_amount
                                            ).toLocaleString("en-IN", {
                                              style: "currency",
                                              currency: "INR",
                                              minimumFractionDigits: 0,
                                            })}
                                          </td>
                                          <td>
                                            {parseInt(
                                              i.discount
                                            ).toLocaleString("en-IN", {
                                              style: "currency",
                                              currency: "INR",
                                              minimumFractionDigits: 0,
                                            })}
                                          </td>
                                          <td>
                                            {i.fine
                                              ? parseInt(i.fine).toLocaleString(
                                                  "en-IN",
                                                  {
                                                    style: "currency",
                                                    currency: "INR",
                                                    minimumFractionDigits: 0,
                                                  }
                                                )
                                              : "₹0"}
                                          </td>
                                          <td>
                                            {parseInt(
                                              i.amount -
                                                i.paid_amount -
                                                i.discount
                                            ).toLocaleString("en-IN", {
                                              style: "currency",
                                              currency: "INR",
                                              minimumFractionDigits: 0,
                                            })}
                                          </td>
                                          <td>
                                            <span
                                              className={`badge badge-soft-${
                                                parseInt(
                                                  i.amount -
                                                    i.paid_amount -
                                                    i.discount
                                                ) == 0
                                                  ? "success"
                                                  : parseInt(
                                                      i.amount -
                                                        i.paid_amount -
                                                        i.discount
                                                    ) == parseInt(i.amount)
                                                  ? "danger"
                                                  : "warning"
                                              }`}
                                            >
                                              {parseInt(
                                                i.amount -
                                                  i.paid_amount -
                                                  i.discount
                                              ) == 0
                                                ? "Paid"
                                                : parseInt(
                                                    i.amount -
                                                      i.paid_amount -
                                                      i.discount
                                                  ) == i.amount
                                                ? "Not Paid"
                                                : "Partial"}
                                            </span>
                                          </td>
                                          {role != "ADMIN" ? (
                                            <>
                                              <td>
                                                {i?.student_semester_id ? (
                                                  <button
                                                    onClick={() => {
                                                      changeDir(
                                                        i?.student_semester_id
                                                      );
                                                    }}
                                                    className="btn btn-nex btn-sm btn-rounded"
                                                    type="button"
                                                  >
                                                    Collect
                                                  </button>
                                                ) : (
                                                  "Not Assigned"
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })
                                ) : (
                                  <tr className="odd">
                                    <td
                                      valign="top"
                                      colSpan={14}
                                      className="dataTables_empty"
                                    >
                                      <Nodata />
                                    </td>
                                  </tr>
                                )}
                                {data && data?.length != 0 ? (
                                  <tr className="bg-dark text-light">
                                    <td colSpan={6}>Grand Total</td>
                                    <td>
                                      {data
                                        ?.filter((s) =>
                                          feeStatus == "PENDING"
                                            ? parseInt(
                                                s.amount -
                                                  s.paid_amount -
                                                  s.discount
                                              ) != 0
                                            : feeStatus == "PAID"
                                            ? s.amount -
                                                s.paid_amount -
                                                s.discount ==
                                              0
                                            : 1
                                        )
                                        ?.filter((s) => s.status == user.status)
                                        .reduce(
                                          (acc, curr) =>
                                            acc + parseInt(curr?.amount),
                                          0
                                        )
                                        .toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                    </td>
                                    <td>
                                      {data
                                        ?.filter((s) =>
                                          feeStatus == "PENDING"
                                            ? parseInt(
                                                s.amount -
                                                  s.paid_amount -
                                                  s.discount
                                              ) != 0
                                            : feeStatus == "PAID"
                                            ? s.amount -
                                                s.paid_amount -
                                                s.discount ==
                                              0
                                            : 1
                                        )
                                        ?.filter((s) => s.status == user.status)
                                        .reduce(
                                          (acc, curr) =>
                                            acc + parseInt(curr?.paid_amount),
                                          0
                                        )
                                        .toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                    </td>
                                    <td>
                                      {data
                                        ?.filter((s) =>
                                          feeStatus == "PENDING"
                                            ? parseInt(
                                                s.amount -
                                                  s.paid_amount -
                                                  s.discount
                                              ) != 0
                                            : feeStatus == "PAID"
                                            ? s.amount -
                                                s.paid_amount -
                                                s.discount ==
                                              0
                                            : 1
                                        )
                                        ?.filter((s) => s.status == user.status)
                                        .reduce(
                                          (acc, curr) =>
                                            acc + parseInt(curr?.discount),
                                          0
                                        )
                                        .toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                    </td>
                                    <td>₹ 0</td>
                                    <td>
                                      {data
                                        ?.filter((s) =>
                                          feeStatus == "PENDING"
                                            ? parseInt(
                                                s.amount -
                                                  s.paid_amount -
                                                  s.discount
                                              ) != 0
                                            : feeStatus == "PAID"
                                            ? s.amount -
                                                s.paid_amount -
                                                s.discount ==
                                              0
                                            : 1
                                        )
                                        ?.filter((s) => s.status == user.status)
                                        .reduce(
                                          (acc, curr) =>
                                            acc +
                                            parseInt(curr?.amount) -
                                            parseInt(curr?.paid_amount) -
                                            parseInt(curr?.discount),
                                          0
                                        )
                                        .toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                    </td>
                                    {role == "ADMIN" ? null : (
                                      <>
                                        <td></td>
                                        <td></td>
                                      </>
                                    )}
                                  </tr>
                                ) : null}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/* end col */}
            </div>{" "}
            {/* end row */}
          </div>
          {/* End Page-content */}
        </div>
        {/* end main content*/}
      </div>
    </div>
  );
}

export default FeeCollectionSearchDue;

import React, { useState } from "react";
import DraggableTable from "./DraggableTable";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { INVENTORY_PURCHASE_ORDER } from "../../../utils/Inventory.apiConst";
const paymentTermsOptions = [
  { value: "Net 15", label: "Net 15" },
  { value: "Net 30", label: "Net 30" },
  { value: "Net 45", label: "Net 45" },
  { value: "Net 60", label: "Net 60" },
  { value: "Due On Receipt", label: "Due On Receipt" },
  { value: "Due end of the month", label: "Due end of the month" },
  { value: "Due end of the next month", label: "Due end of the next month" },
];
const CreatePurchaseOrder = ({ setLoading }) => {
  const [data, setData] = useState({
    id: "",
    status: "",
  });
  const [formData, setFormData] = useState({
    subTotal: 0,
    discount: 0,
    percentageOrRs: "percentage",
    total: 0,
    selectedTax: null,
    selectedTaxAmount: 0,
    adjustmentAmount: 0,
  });
  const [vendorName, setVendorName] = useState("");
  const [organizationAddress, setOrganizationAddress] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [selectedAddressType, setSelectedAddressType] =
    useState("Organization");
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [reference, setReference] = useState("");
  const [date, setDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [shipmentPreference, setShipmentPreference] = useState("");
  let emp_id = sessionStorage.getItem("employee_id");
  const [dataSource, setDataSource] = useState([
    {
      key: "1",
      itemDetails: "",
      account: "",
      quantity: 1.0,
      rate: 0.0,
      amount: 0.0,
    },
  ]);
  const [tcsOrTds, setTcsOrTds] = useState("TDS");
  const navigate = useNavigate();
  // console.log(vendorName);

  const handleSaveOrSubmit = async (e, status) => {
    e.preventDefault();
    let info = {
      vendor_name: vendorName,
      delivery_address:
        selectedAddressType === "Organization"
          ? organizationAddress
          : customerAddress,
      purchase_order: purchaseOrder,
      reference,
      date,
      delivery_date: deliveryDate,
      payment_terms: paymentTerms,
      shipment_preference: shipmentPreference,
      items_data: dataSource,
      customer_notes: "customerNotes",
      terms_and_conditions: "termsAndConditions",
      documents: "documents",
      status,
      created_by: emp_id,
      updated_by: emp_id,
      total_amount_data: formData,
      ...(data.id
        ? { id: data.id, updated_by: emp_id }
        : { created_by: emp_id, updated_by: emp_id }),
    };
    console.log("info", info);
    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${INVENTORY_PURCHASE_ORDER}`,
      data: info,
    };
    await axios(config)
      .then((res) => {
        toast.success(
          `${
            status == "DRAFT"
              ? "Successfully saved as draft"
              : "Successfully submitted for review"
          } `
        );
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  return (
    <div className="PurchaseOrder">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">New Purchase Order</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card py-4">
                  <div className="card-body">
                    <div className="container">
                      <form>
                        <div className="d-flex flex-column gap-3">
                          <div className="row align-items-center mb-3">
                            <div className="col-md-2 text-danger">
                              <label
                                htmlFor="vendorName"
                                className="form-label"
                              >
                                Vendor Name *
                              </label>
                            </div>
                            <div className="col-md-9">
                              <select
                                className="form-control w-50"
                                id="vendorName"
                                name="vendorName"
                                value={vendorName}
                                onChange={(e) => setVendorName(e.target.value)}
                              >
                                <option value="" disabled>
                                  Select a Vendor
                                </option>
                                <option value="vendor1"> Vendor 1</option>
                                <option value="vendor2">Vendor 2</option>
                              </select>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-2 text-danger">
                              <label
                                htmlFor="vendorName"
                                className="form-label"
                              >
                                Delivery Address *
                              </label>
                            </div>
                            <div className="col-md-4">
                              <div className="d-flex gap-5 mb-3">
                                <div class=" d-flex align-items-center gap-1">
                                  <input
                                    type="radio"
                                    name="deliveryAddress"
                                    id="Organization"
                                    value="Organization"
                                    onChange={(e) =>
                                      setSelectedAddressType(e.target.value)
                                    }
                                    checked={
                                      selectedAddressType === "Organization"
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    for="Organization"
                                  >
                                    Organization
                                  </label>
                                </div>
                                <div class=" d-flex align-items-center gap-1">
                                  <input
                                    type="radio"
                                    name="deliveryAddress"
                                    id="Customer"
                                    value="Customer"
                                    onChange={(e) =>
                                      setSelectedAddressType(e.target.value)
                                    }
                                    checked={selectedAddressType === "Customer"}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="Customer"
                                  >
                                    Customer
                                  </label>
                                </div>
                              </div>
                              {selectedAddressType === "Organization" ? (
                                <div>
                                  <select
                                    className="form-control"
                                    id="organization"
                                    name="organization"
                                    placeholder="Select Organization"
                                    value={organizationAddress}
                                    onChange={(e) =>
                                      setOrganizationAddress(e.target.value)
                                    }
                                  >
                                    <option value="example1">
                                      Example Organization
                                    </option>
                                    <option value="example1.1">
                                      Example1.1 Organization
                                    </option>
                                  </select>
                                </div>
                              ) : (
                                <div>
                                  <select
                                    className="form-control"
                                    id="customer"
                                    name="customer"
                                    placeholder="Select Customer"
                                    value={customerAddress}
                                    onChange={(e) =>
                                      setCustomerAddress(e.target.value)
                                    }
                                  >
                                    <option value="example2">
                                      Example Customer
                                    </option>
                                    <option value="example2.2">
                                      Example2.2 Customer
                                    </option>
                                  </select>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row align-items-center mb-3">
                            <div className="col-md-2 text-danger">
                              <label
                                htmlFor="purchaseOrder"
                                className="form-label"
                              >
                                Purchase Order# *
                              </label>
                            </div>
                            <div className="col-md-4">
                              <input
                                className="form-control"
                                type="text"
                                name="purchaseOrder"
                                id="purchaseOrder"
                                value={purchaseOrder}
                                onChange={(e) =>
                                  setPurchaseOrder(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-md-2">
                              <label htmlFor="reference" className="form-label">
                                Reference#
                              </label>
                            </div>
                            <div className="col-md-4">
                              <input
                                className="form-control"
                                type="text"
                                name="reference"
                                id="reference"
                                value={reference}
                                onChange={(e) => setReference(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-md-2">
                              <label htmlFor="date" className="form-label">
                                Date
                              </label>
                            </div>
                            <div className="col-md-4">
                              <input
                                className="form-control"
                                type="date"
                                name="date"
                                id="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-md-2">
                              <label
                                htmlFor="deliveryDate"
                                className="form-label"
                              >
                                Delivery Date
                              </label>
                            </div>
                            <div className="col-md-4">
                              <input
                                className="form-control"
                                type="date"
                                name="deliveryDate"
                                id="deliveryDate"
                                value={deliveryDate}
                                onChange={(e) =>
                                  setDeliveryDate(e.target.value)
                                }
                              />
                            </div>
                            <div className="col-md-2">
                              <label
                                htmlFor="paymentTerms"
                                className="form-label"
                              >
                                Payment Terms
                              </label>
                            </div>
                            <div className="col-md-4">
                              <select
                                className="form-control"
                                id="paymentTerms"
                                name="paymentTerms"
                                defaultValue=""
                                placeholder="Select paymentTerms"
                                value={paymentTerms}
                                onChange={(e) =>
                                  setPaymentTerms(e.target.value)
                                }
                              >
                                {paymentTermsOptions.map((item) => (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-md-2">
                              <label
                                htmlFor="shipmentPreference"
                                className="form-label"
                              >
                                Shipment Preference
                              </label>
                            </div>
                            <div className="col-md-4">
                              <select
                                className="form-control"
                                id="shipmentPreference"
                                name="shipmentPreference"
                                placeholder="Select Shipment Preference"
                                value={shipmentPreference}
                                onChange={(e) =>
                                  setShipmentPreference(e.target.value)
                                }
                              >
                                <option disabled>Select Shipment</option>
                                <option value={"Cash"}>Cash</option>
                                <option value={"Online"}>Online</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <DraggableTable
                          dataSource={dataSource}
                          setDataSource={setDataSource}
                          tcsOrTds={tcsOrTds}
                          setTcsOrTds={setTcsOrTds}
                          formData={formData}
                          setFormData={setFormData}
                        />

                        {/* Submit Button */}
                        <div className="row mt-4">
                          <div className="col-md-12 text-right">
                            <button
                              onClick={(e) => handleSaveOrSubmit(e, "DRAFT")}
                              type="submit"
                              className="btn btn-light"
                            >
                              Save as Draft
                            </button>
                            <button
                              type="submit"
                              className="btn mx-2 btn-primary"
                            >
                              Save and Send
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(-1);
                              }}
                              className="btn btn-light"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePurchaseOrder;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Nodata from "../../../Components/NoData/Nodata";
import { JOB_OPENINGS, JOB_APPLICATIONS } from "../../../utils/apiConstants";
import { getFileUrl } from "../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../utils/AssetsReferenceTypes";
import DocumentsModal from "../../../modals/Students/DocumentsModal";
import { international } from "../../../Data/Countries/international";
import ModalViewJobOpening from "./ModalViewJobOpening";

import { Select, Input, DatePicker, Form, Button, FormInstance } from "antd";
const { TextArea } = Input;

function JobApplication({ collegeId, setLoading }) {
  const addAttachment = async (e, name) => {
    if (name == "resume" && e.target.value.split(".")[1] != "pdf") {
      setUser({ ...user, photo: "" });
      return toast.error("Upload resume in PDF format");
    }
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `Resume`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );

      if (name == "resume") setUser({ ...user, resume: d ? d : "" });
      else setUser({ ...user, photo: d ? d : "" });
    } catch (error) {
      console.log(error);
    }
  };

  const [submitClicked, setSubmitClicked] = useState(false);
  const [jobOpts, setJobOpts] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [dobMsg, setDobMsg] = useState("");

  const [opening, setOpening] = useState([]);

  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  const currDate = new Date().toDateString();

  const [countries, setCountries] = useState([]);

  const setCountryAndState = async () => {
    let arr = [];
    await international?.map((item, key) => {
      arr.push({ label: item?.country, value: item?.country });
    });
    setCountries(arr);
  };

  const [user, setUser] = useState({
    job_openings_id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    dob: "",
    gender: "",
    nationality: "",
    country: "",
    state: "",
    city: "",
    current_address: "",
    permanent_address: "",
    father_name: "",
    mother_name: "",
    marital_status: "",
    qualification: "",
    work_experience: "",
    photo: "",
    resume: "",
    date: "",
  });

  const clearData = () => {
    setUser({
      job_openings_id: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      dob: "",
      gender: "",
      nationality: "",
      country: "",
      state: "",
      city: "",
      current_address: "",
      permanent_address: "",
      father_name: "",
      mother_name: "",
      marital_status: "",
      qualification: "",
      work_experience: "",
      photo: "",
      resume: "",
      date: "",
    });
    setLink("");
    setTitle("");
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getAllData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: JOB_OPENINGS,
    };

    axios(config)
      .then((res) => {
        console.log("job openings data - ", res.data.data);
        let tempData = res.data.data?.filter((s) => s.status == "ACTIVE");
        setJobs(tempData);
        let options = [];

        for (let i of tempData) {
          let obj = {
            value: i?.id,
            label: i?.title,
            from_age: i?.from_age,
            to_age: i?.to_age,
          };
          options.push(obj);
        }
        setJobOpts(options);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleSubmit = async () => {
    if (
      !user.first_name ||
      !user.last_name ||
      !user.dob ||
      !user.state ||
      !user.city ||
      !user.gender ||
      !user.phone ||
      !user.email ||
      !user.resume ||
      !user.job_openings_id ||
      !user.nationality ||
      !user.country ||
      !user.state ||
      !user.city ||
      !user.current_address ||
      !user.permanent_address ||
      !user.qualification ||
      !user.photo
    ) {
      setSubmitClicked(true);
      // return toast.error("Please fill in all the required fields");
      return;
    }
    setSubmitClicked(false);
    console.log("data to backend - ", user);
    const config = {
      method: "post",
      url: JOB_APPLICATIONS,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        job_name: jobs?.find((s) => s.id == user?.job_openings_id)?.title,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Submitted. Please check mail");
        clearData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
    clearData();
  };

  useEffect(() => {
    getAllData();
    setCountryAndState();
  }, []);

  const onJobOptChange = (value) => {
    setUser({ ...user, job_openings_id: value });
  };

  const onJobOptSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const validateAndSetDob = (date, d) => {
    const age = calculateAge(new Date(d));

    console.log(age);

    if (
      age >= jobOpts?.find((s) => s.value == user?.job_openings_id)?.from_age &&
      age <= jobOpts?.find((s) => s.value == user?.job_openings_id)?.to_age
    ) {
      setUser({ ...user, dob: d, date: date });
      return true;
    } else {
      setUser({ ...user, dob: "", date: "" });
      return false;
    }
  };

  useEffect(() => {
    console.log(user);
  }, [user]);

  useEffect(() => {
    setOpening(jobs?.find((s) => s.id == user?.job_openings_id));
  }, [user?.job_openings_id]);
  return (
    <div>
      <DocumentsModal title={title} img={link} setLink={setLink} />
      <ModalViewJobOpening opening={opening} />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Job Application Form</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Job Openings</label>
                        <br />
                        <Select
                          showSearch
                          placeholder="Select Job Opening"
                          optionFilterProp="children"
                          onChange={onJobOptChange}
                          onSearch={onJobOptSearch}
                          filterOption={filterOption}
                          value={user?.job_openings_id}
                          options={jobOpts}
                          style={{ width: "80%" }}
                        />
                        {user?.job_openings_id ? (
                          <button
                            data-toggle="modal"
                            data-target="#ModalViewJobOpening"
                            className="btn btn-nex btn-sm my-1 ml-3"
                            title="View"
                          >
                            View
                          </button>
                        ) : (
                          <></>
                        )}
                        {submitClicked && !user?.job_openings_id ? (
                          <p className="text-danger">
                            Please select job opening
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label>Application Date</label>
                        <br />
                        <Input
                          type="text"
                          value={new Date().toDateString()}
                          disabled="true"
                          style={{ width: "80%" }}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>First Name</label>
                        <br />
                        <Input
                          type="text"
                          name="first_name"
                          value={user?.first_name}
                          onChange={handleChange}
                          disabled={!user?.job_openings_id}
                          style={{ width: "80%" }}
                        />
                        {submitClicked && !user?.first_name ? (
                          <p className="text-danger">Please enter first name</p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Last Name</label>
                        <br />
                        <Input
                          type="text"
                          name="last_name"
                          value={user?.last_name}
                          onChange={handleChange}
                          style={{ width: "80%" }}
                          disabled={!user?.job_openings_id}
                        />
                        {submitClicked && !user?.last_name ? (
                          <p className="text-danger">Please enter last name</p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Personal email</label>
                        <br />
                        <Input
                          type="text"
                          name="email"
                          value={user?.email}
                          disabled={!user?.job_openings_id}
                          onChange={handleChange}
                          style={{ width: "80%" }}
                        />
                        {submitClicked && !user?.email ? (
                          <p className="text-danger">
                            Please enter valid email
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Phone</label>
                        <br />
                        <Input
                          type="number"
                          name="phone"
                          value={user?.phone}
                          onChange={handleChange}
                          disabled={!user?.job_openings_id}
                          style={{ width: "80%" }}
                        />
                        {submitClicked && !user?.phone ? (
                          <p className="text-danger">
                            Please enter phone number
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Date of Birth : </label>
                        <br />
                        <DatePicker
                          onChange={(date, dateString) => {
                            if (!validateAndSetDob(date, dateString)) {
                              setDobMsg(
                                `Applicants with age between ${
                                  jobOpts?.find(
                                    (s) => s.value == user?.job_openings_id
                                  )?.from_age
                                } and ${
                                  jobOpts?.find(
                                    (s) => s.value == user?.job_openings_id
                                  )?.to_age
                                } can apply.`
                              );
                            } else {
                              setDobMsg("");
                            }
                            // setUser({ ...user, dob: dateString, date: date });
                          }}
                          value={user?.date}
                          style={{ width: "80%" }}
                          disabled={!user?.job_openings_id}
                        />
                        {dobMsg ? (
                          <p className="text-danger">{dobMsg}</p>
                        ) : submitClicked && !user?.dob ? (
                          <p className="text-danger">
                            Please select Date of Birth
                          </p>
                        ) : (
                          <></>
                        )}
                        {/* {submitClicked && !user?.dob ? (
                          <p className="text-danger">
                            Please select Date of Birth
                          </p>
                        ) : (
                          <></>
                        )} */}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Gender</label>
                        <br />
                        <Select
                          showSearch
                          placeholder="Select Gender"
                          optionFilterProp="children"
                          onChange={(value) => {
                            setUser({ ...user, gender: value });
                          }}
                          filterOption={filterOption}
                          value={user?.gender}
                          disabled={!user?.job_openings_id}
                          options={[
                            {
                              value: "MALE",
                              label: "Male",
                            },
                            {
                              value: "FEMALE",
                              label: "Female",
                            },
                            {
                              value: "OTHERS",
                              label: "Others",
                            },
                          ]}
                          style={{ width: "80%" }}
                        />
                        {submitClicked && !user?.gender ? (
                          <p className="text-danger">Please select gender</p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Nationality</label>
                        <br />
                        <Select
                          showSearch
                          placeholder="Select Nationality"
                          optionFilterProp="children"
                          onChange={(value) => {
                            setUser({
                              ...user,
                              nationality: value,
                              country: value == "INDIAN" ? "India" : "",
                            });
                          }}
                          filterOption={filterOption}
                          value={user?.nationality}
                          disabled={!user?.job_openings_id}
                          options={[
                            {
                              value: "INDIAN",
                              label: "Indian",
                            },
                            {
                              value: "INTERNATIONAL",
                              label: "International",
                            },
                          ]}
                          style={{ width: "80%" }}
                        />
                        {submitClicked && !user?.nationality ? (
                          <p className="text-danger">
                            Please select nationality
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Country</label>
                        <br />
                        <Select
                          showSearch
                          placeholder="Select country"
                          optionFilterProp="children"
                          onChange={(value) => {
                            setUser({ ...user, country: value, state: "" });
                          }}
                          filterOption={filterOption}
                          value={user?.country}
                          disabled={
                            !user?.job_openings_id ||
                            user?.nationality == "INDIAN"
                          }
                          options={countries}
                          style={{ width: "80%" }}
                        />
                        {submitClicked && !user?.country ? (
                          <p className="text-danger">Please select country</p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>State</label>
                        <br />
                        <Select
                          showSearch
                          placeholder="Select state"
                          optionFilterProp="children"
                          onChange={(value) => {
                            setUser({ ...user, state: value });
                          }}
                          filterOption={filterOption}
                          value={user?.state}
                          disabled={!user?.job_openings_id}
                          options={international
                            ?.find((s) => s.country == user?.country)
                            ?.states?.map((i, k) => {
                              return { value: i, label: i };
                            })}
                          style={{ width: "80%" }}
                        />
                        {submitClicked && !user?.state ? (
                          <p className="text-danger">Please select state</p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>City</label>
                        <br />
                        <Input
                          type="text"
                          name="city"
                          value={user?.city}
                          onChange={handleChange}
                          disabled={!user?.job_openings_id}
                          style={{ width: "80%" }}
                        />
                        {submitClicked && !user?.city ? (
                          <p className="text-danger">Please enter city</p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Current Address</label>
                        <br />
                        <TextArea
                          type="text"
                          name="current_address"
                          value={user?.current_address}
                          onChange={handleChange}
                          disabled={!user?.job_openings_id}
                          style={{ width: "80%" }}
                        />
                        {submitClicked && !user?.current_address ? (
                          <p className="text-danger">
                            Please enter current address
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Permanent Address</label>
                        <br />
                        <TextArea
                          type="text"
                          name="permanent_address"
                          value={user?.permanent_address}
                          onChange={handleChange}
                          disabled={!user?.job_openings_id}
                          style={{ width: "80%" }}
                        />
                        {submitClicked && !user?.permanent_address ? (
                          <p className="text-danger">
                            Please enter permanent address
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Father name</label>
                        <br />
                        <Input
                          type="text"
                          name="father_name"
                          value={user?.father_name}
                          disabled={!user?.job_openings_id}
                          onChange={handleChange}
                          style={{ width: "80%" }}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Mother name</label>
                        <br />
                        <Input
                          type="text"
                          name="mother_name"
                          value={user?.mother_name}
                          disabled={!user?.job_openings_id}
                          onChange={handleChange}
                          style={{ width: "80%" }}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Martial Status</label>
                        <br />
                        <Select
                          showSearch
                          placeholder="Select marital status"
                          optionFilterProp="children"
                          onChange={(value) => {
                            setUser({ ...user, marital_status: value });
                          }}
                          filterOption={filterOption}
                          value={user?.marital_status}
                          disabled={!user?.job_openings_id}
                          options={[
                            {
                              value: "married",
                              label: "Married",
                            },
                            {
                              value: "single",
                              label: "Single",
                            },
                          ]}
                          style={{ width: "80%" }}
                        />
                        {submitClicked && !user?.marital_status ? (
                          <p className="text-danger">
                            Please select marital status
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Qualification</label>
                        <br />
                        <Input
                          type="text"
                          name="qualification"
                          value={user?.qualification}
                          onChange={handleChange}
                          disabled={!user?.job_openings_id}
                          style={{ width: "80%" }}
                        />
                        {submitClicked && !user?.qualification ? (
                          <p className="text-danger">
                            Please enter qualification
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Work Experience</label>
                        <br />
                        <Input
                          type="text"
                          name="work_experience"
                          value={user?.work_experience}
                          disabled={!user?.job_openings_id}
                          onChange={handleChange}
                          style={{ width: "80%" }}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Photo</label>
                        <br />
                        <div className="row">
                          <input
                            type="file"
                            name=""
                            className="form-control ml-2"
                            onChange={(e) => {
                              addAttachment(e, "photo");
                            }}
                            style={{ width: "70%" }}
                            disabled={!user?.job_openings_id}
                          />
                          {user?.photo ? (
                            <button
                              onClick={() => {
                                setLink(user?.photo);
                                setTitle("Profile Photo");
                              }}
                              data-toggle="modal"
                              data-target="#DocumentsModal"
                              className="btn btn-nex btn-sm my-1 ml-3"
                              title="View"
                            >
                              View
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                        {submitClicked && !user?.resume ? (
                          <p className="text-danger">Please upload photo</p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Resume</label>
                        <br />
                        <div className="row">
                          <input
                            type="file"
                            name=""
                            className="form-control ml-2"
                            onChange={(e) => {
                              addAttachment(e, "resume");
                            }}
                            style={{ width: "70%" }}
                            disabled={!user?.job_openings_id}
                            accept="application/pdf"
                          />
                          {user?.resume ? (
                            <button
                              onClick={() => {
                                setLink(user?.resume);
                                setTitle("Resume");
                              }}
                              data-toggle="modal"
                              data-target="#DocumentsModal"
                              className="btn btn-nex btn-sm my-1 ml-3"
                              title="View"
                            >
                              View
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                        {submitClicked && !user?.resume ? (
                          <p className="text-danger">Please upload resume</p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <Button type="primary mt-4 mt-1" onClick={handleSubmit}>
                          Submit Application
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobApplication;

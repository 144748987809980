import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../../utils/AssetsReferenceTypes";
import { getFileUrl } from "../../../../Helpers/Helpers";
import { message } from "antd";
import axios from "axios";
import { NAAC } from "../../../../utils/apiConstants";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
const toolbar = [
  "undo",
  "redo",
  "|",
  "heading",
  "|",
  "bold",
  "italic",
  "|",
  "link",
  "blockQuote",
  "insertTable",
  "|",
  "bulletedList",
  "numberedList",
  "outdent",
  "alignment",
  "indent",
  "|",
  "imageUpload",
];
const maxWords = 500;
const title = "nep_parameters";
const sub_title = "appropriate_integration";

export default function AppropriateIntegrationOfIndianKnowledgeSystem({
  setLoading,
  setUpdatedData,
}) {
  const [data, setData] = useState({
    id: "",
    status: "",
    indian_knowledge_system: "",
    bilingual_mode: "",
    courses_in_indian_languages: "",
    efforts_to_promote_indian_culture: "",
    good_practices_to_integrate_indian_knowledge_system: "",
  });
  let emp_id = sessionStorage.getItem("employee_id");
  const [wordCount, setWordCount] = useState({
    indian_knowledge_system: 0,
    bilingual_mode: 0,
    courses_in_indian_languages: 0,
    efforts_to_promote_indian_culture: 0,
    good_practices_to_integrate_indian_knowledge_system: 0,
  });
  const countWords = (text) => {
    const words = text.trim().split(/\s+/); // Split by spaces and trim extra spaces
    return words.filter((word) => word.length > 0).length; // Filter out empty words
  };
  const handleEditorChange = (editor, key) => {
    const editorData = editor.getData();
    const currentWordCount = countWords(editorData);

    if (currentWordCount <= maxWords) {
      setData((prevValue) => ({
        ...prevValue,
        [key]: editorData,
      }));
    }
    setWordCount((prev) => ({ ...prev, [key]: currentWordCount }));
  };
  const addAttachment = async (file) => {
    try {
      const response = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `Resume`,
        file.name.split(".")[1],
        setLoading,
        file
      );
      return response;
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("File upload failed");
      throw error;
    }
  };

  const createUploadAdapter = (loader) => {
    return {
      upload: async () => {
        try {
          const file = await loader.file;
          // Directly get the uploaded file URL
          const uploadedUrl = await addAttachment(file);
          console.log("Uploaded image URL:", uploadedUrl);
          // Return the URL in the format CKEditor expects
          return { default: uploadedUrl };
        } catch (error) {
          console.error("Upload failed:", error);
          throw error;
        }
      },
    };
  };
  function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return createUploadAdapter(loader);
    };
  }
  const validateData = () => {
    const requiredFields = [
      "indian_knowledge_system",
      "bilingual_mode",
      "courses_in_indian_languages",
      "efforts_to_promote_indian_culture",
      "good_practices_to_integrate_indian_knowledge_system",
    ];

    for (const field of requiredFields) {
      if (wordCount[field] === 0) {
        toast.error(`Please fill in the ${field.split("_").join(" ")} field`);
        return false;
      }
      if (wordCount[field] > maxWords) {
        toast.error(
          `Please reduce the number of words in the ${field
            .split("_")
            .join(" ")} field`
        );
        return false;
      }
    }
    return true;
  };
  const handleSaveOrSubmit = async (e, status) => {
    let {
      indian_knowledge_system,
      bilingual_mode,
      courses_in_indian_languages,
      efforts_to_promote_indian_culture,
      good_practices_to_integrate_indian_knowledge_system,
    } = data;
    let jsonResponse = JSON.stringify({
      indian_knowledge_system,
      bilingual_mode,
      courses_in_indian_languages,
      efforts_to_promote_indian_culture,
      good_practices_to_integrate_indian_knowledge_system,
    });
    e.preventDefault();
    // List all required fields
    if (!validateData()) return;
    const info = {
      ...data,
      title: title,
      response: jsonResponse,
      sub_title: sub_title,
      created_by: emp_id,
      updated_by: emp_id,
      status,
      application_id: 1,
    };
    console.log("info", info);
    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/nep-parameters`,
      data: info,
    };
    await axios(config)
      .then((res) => {
        setUpdatedData(uuidv4());
        toast.success(
          `${
            status == "DRAFT"
              ? "Successfully saved as draft"
              : "Successfully submitted for review"
          } `
        );
        getFormData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const handleSaveAsDraft = (e) => {
    handleSaveOrSubmit(e, "DRAFT");
  };
  const handleSubmitForReview = (e) => {
    handleSaveOrSubmit(e, "REVIEW");
  };
  const getFormData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/nep-parameters/${sub_title}`,
    };

    await axios(config)
      .then((res) => {
        let backendData = res.data[0];
        if (backendData) {
          const responseObj = JSON.parse(backendData?.response || "");
          setData((prevState) => ({
            ...prevState,
            id: backendData.id,
            status: backendData.status,
            ...responseObj,
          }));
        }
        // console.log("data fetched is", res.data);
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  useEffect(() => {
    getFormData();
  }, []);
  return (
    <>
      <div>
        <div className="d-flex flex-column mb-3">
          <p className="h5">
            4. Appropriate integration of Indian Knowledge System (teaching in
            Indian Language, culture, using online courses)
          </p>
          <span className="text-danger">
            *Note - Maximum word allowed is 500 words
          </span>
        </div>
        <span
          className={`text-white d-inline-block text-center px-4 py-1 shadow-sm small rounded-pill ${
            data?.status === "DRAFT"
              ? "bg-primary"
              : data?.status === "REVIEW"
              ? "bg-warning"
              : data?.status === "REJECTED"
              ? "bg-danger"
              : data?.status === "APPROVED"
              ? "bg-success"
              : "bg-secondary"
          }`}
          style={{ width: "fit-content" }}
        >
          {data?.status === "DRAFT"
            ? "In Draft"
            : data?.status === "REVIEW"
            ? "Submitted for review"
            : data?.status === "REJECTED"
            ? "Rejected"
            : data?.status === "APPROVED"
            ? "Approved"
            : "Not Started"}
        </span>
      </div>

      <div>
        <p>
          a) Delineate the strategy and details regarding the integration of the
          Indian Knowledge System (teaching in Indian Language, culture, etc.)
          into the curriculum using both offline and online courses.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.indian_knowledge_system}
          onChange={(event, editor) =>
            handleEditorChange(editor, "indian_knowledge_system")
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.indian_knowledge_system >= maxWords ? "text-danger" : ""
            }`}
          >
            {wordCount.indian_knowledge_system}/{maxWords}{" "}
            {wordCount.indian_knowledge_system > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div>
        <p>
          b) What are the institution's plans to train its faculties to provide
          classroom delivery in a bilingual mode (English and vernacular)?
          Provide the details.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.bilingual_mode}
          onChange={(event, editor) =>
            handleEditorChange(editor, "bilingual_mode")
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.bilingual_mode >= maxWords ? "text-danger" : ""
            }`}
          >
            {wordCount.bilingual_mode}/{maxWords}{" "}
            {wordCount.bilingual_mode > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div>
        <p>
          c) Provide the details of the degree courses taught in Indian
          languages and bilingually in the institution.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.courses_in_indian_languages}
          onChange={(event, editor) =>
            handleEditorChange(editor, "courses_in_indian_languages")
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.courses_in_indian_languages >= maxWords
                ? "text-danger"
                : ""
            }`}
          >
            {wordCount.courses_in_indian_languages}/{maxWords}{" "}
            {wordCount.courses_in_indian_languages > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div>
        <p>
          d) Describe the efforts of the institution to preserve and promote the
          following: <br />
          i) Indian languages (Sanskrit, Pali, Prakrit, and classical, tribal,
          and endangered languages, etc.) <br />
          ii) Indian ancient traditional knowledge <br />
          iii) Indian Arts <br />
          iv) Indian Culture and traditions.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.efforts_to_promote_indian_culture}
          onChange={(event, editor) =>
            handleEditorChange(editor, "efforts_to_promote_indian_culture")
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.efforts_to_promote_indian_culture >= maxWords
                ? "text-danger"
                : ""
            }`}
          >
            {wordCount.efforts_to_promote_indian_culture}/{maxWords}{" "}
            {wordCount.efforts_to_promote_indian_culture > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div>
        <p>
          e) Describe any good practice/s of the institution pertaining to the
          appropriate integration of the Indian Knowledge System (teaching in
          Indian Language, culture, using online courses) in view of NEP 2020.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.good_practices_to_integrate_indian_knowledge_system}
          onChange={(event, editor) =>
            handleEditorChange(
              editor,
              "good_practices_to_integrate_indian_knowledge_system"
            )
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.good_practices_to_integrate_indian_knowledge_system >=
              maxWords
                ? "text-danger"
                : ""
            }`}
          >
            {wordCount.good_practices_to_integrate_indian_knowledge_system}/
            {maxWords}{" "}
            {wordCount.good_practices_to_integrate_indian_knowledge_system >
            maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div className="text-right mt-5">
        <button className="btn btn-primary mr-2" onClick={handleSaveAsDraft}>
          Save as Draft
        </button>
        <button className="btn btn-warning" onClick={handleSubmitForReview}>
          <span>Submit for Review</span>
        </button>
      </div>
    </>
  );
}

import React, { useState } from "react";
import { Form, Input, message } from "antd";
import { toast } from "react-toastify";

const JobApplicationFeePage = ({
  onFinish,
  handleSubmit,
  initialValues,
  prev,
}) => {
  const [formData, setFormData] = useState({
    amountPaid: initialValues ? initialValues : 0,
  });
  const [form] = Form.useForm();
  console.log("initialValues", initialValues);
  const handleFeeSubmit = () => {
    handleSubmit();
  };
  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Job Application Fee Payment</h2>

      <div className="d-flex flex-column gap-4 justify-content-center align-items-center">
        <Form
          form={form}
          initialValues={formData}
          layout="vertical"
          style={{ width: "320px" }}
        >
          <Form.Item
            label="Amount Paid"
            name="amountPaid"
            rules={[
              { required: true, message: "Please enter the amount paid" },
              {
                validator: (_, value) =>
                  value && value > 0
                    ? Promise.resolve()
                    : Promise.reject("Amount must be greater than 0"),
              },
            ]}
          >
            <Input
              value={formData.amountPaid}
              onChange={(e) => onFinish(e.target.value)}
              type="number"
              placeholder="Enter amount paid"
            />
          </Form.Item>
        </Form>
      </div>
      <div className="d-flex align-items-baseline justify-content-between gap-2 mt-10">
        <button className="btn btn-secondary" onClick={prev}>
          Previous
        </button>
        <button className="btn btn-success" onClick={handleFeeSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default JobApplicationFeePage;

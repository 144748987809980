import React from "react";
import "./Dashboard.scss";
import ReactApexChart from "react-apexcharts";
import Calender from "../../../Components/Calender/Calender";
import axios from "axios";
import Chart from "react-apexcharts";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import {
  DASHBOARD_HR,
  SUPERADMIN_DASHBOARD,
  MEDICAL_SUPERADMIN_DASHBOARD,
  ANNOUNCEMENTS,
  ADD_EVENT,
} from "../../../utils/apiConstants";
import { SESSION_ROLE } from "../../../utils/sessionStorageContants";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";
import { Button, List, Table, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router/routerConfig";
import moment from "moment";
import { Empty } from "antd";
import ViewAnnouncementModal from "../../Communicate/ViewAnnouncementModal";

function DashboardHR({ setLoading, collegeId }) {
  const [pendingEvents, setPendingEvents] = useState([]);
  const [announcementId, setAnnouncementId] = useState(null);
  const formatMonthYear = (date) => {
    const d = new Date(date);
    const moptions = { day: "2-digit", month: "short", year: "numeric" };
    return d?.toLocaleDateString("en-US", moptions);
  };

  const navigate = useNavigate();

  const getCollegeData = () =>
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;

  var emp_id = sessionStorage.getItem("employee_id");

  //States
  const [data, setData] = useState();

  const [collegeData, setCollegeData] = useState(getCollegeData());

  console.log(data?.college_staff?.map((s) => s?.count));
  console.log(data?.college_staff?.map((s) => String(s.college_id)));
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getDashboardData = async () => {
    const config = {
      method: "get",
      url:
        emp_id == 502
          ? `${MEDICAL_SUPERADMIN_DASHBOARD}`
          : `${SUPERADMIN_DASHBOARD}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("dashboard data - ", res.data);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        // toast.error(err.response.data.message);
      });
  };
  const [allData, setAllData] = useState([]);
  const getAlldata = async () => {
    const config = {
      method: "get",
      url: `${ANNOUNCEMENTS}?type=announcement`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);

        setAllData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };
  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  const dayDifference = (date) => {
    let today = moment(new Date());
    let newDate = moment(date);

    return today.diff(newDate, "days");
  };
  useEffect(() => {
    getAlldata();
  }, []);
  const upcomingInterviewsColumn = [
    "S.NO",
    "Name",
    "Role",
    "Interview Date",
    "Status",
  ];
  const appliedApplications = [
    "S.NO",
    "Name",
    "Phone",
    "Role",
    "Gender",
    "Applied Date",
    "Status",
  ];
  const upcomingInterviewsData = [
    {
      key: "1",
      name: `${data?.recentInterviews?.first_name} ${data?.recentInterviews?.last_name}`,
      interviewDate: formatMonthYear(data?.recentInterviews?.interviewDate),
      numberOfDaysLeft: dayDifference(data?.recentInterviews?.interviewDate),
    },
  ];
  // //Get Data
  // const getData = () => {
  //   setLoading(1);
  //   const config = {
  //     method: "get",
  //     url: DASHBOARD_HR + "?role=" + sessionStorage.getItem(SESSION_ROLE),
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   axios(config)
  //     .then((res) => {
  //       setLoading(0);
  //       setData(res.data.data);
  //     })
  //     .catch((err) => {
  //       setLoading(0);
  //       toast.error("Something went wrong");
  //       console.log(err);
  //     });
  // };
  const optionsEmployee1 = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    stroke: {
      width: [1, 1],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1, 2, 3],
    },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "15%",
      },
    },
    // labels: ['ENG', 'MED', 'AYU', 'LAW', 'NUR', 'ART', 'COM',
    //   'ITC'
    // ],
    labels: data?.employee_data?.map((s) => s.code) || [],
    markers: {
      size: 0,
    },
    yaxis: {
      title: {
        text: "Staff Count",
      },
      min: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;
        },
      },
    },
    colors: ["#1B2850", "green", "red"],
  };
  const colors = ["#364277", "#FF9F43"];

  const [notifications, setNotifications] = useState([
    "University wins national championship in robotics competition.",
    "New scholarship program announced for underprivileged students.",
    "Research team discovers new species of marine life in the campus lake.",
    "University ranked in top 10 for computer science programs by prestigious magazine.",
    "Renowned author to give keynote speech at the annual literature festival.",
    "University receives grant for new research facility where students get various opportunities.",
    "Alumni association to host networking event for recent graduates.",
    "Faculty member awarded prestigious fellowship for groundbreaking research.",
    "University to host international conference on climate change.",
    "Student-run charity event raises record amount for local homeless shelter.",
  ]);

  const [events, setEvents] = useState([
    "Guest lecture on Machine Learning by Dr. Smith",
    "Workshop on Web Development and Andriod development",
    "University womens coding ninja hackathon",
    "Career fair to find jobs which suits best for you",
    "Seminar on Renewable Energy and its importance",
    "Panel discussion on Artificial Intelligence",
    "Coding competition conducted by Google",
    "Seminar on Cybersecurity and cloud computing",
    "Workshop on Mobile App Development",
    "Networking event to learn firewalls and their importance",
  ]);
  let collegeids = sessionStorage.getItem("college_id");
  console.log("CCC", collegeids, typeof collegeids);
  const [eventdata, seteventData] = useState();

  const geteventdata = async () => {
    try {
      const config = {
        method: "get",
        url: `${ADD_EVENT}?type=event`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        },
      };

      const res = await axios(config);
      seteventData(res.data.data);
    } catch (err) {
      console.error(err);
      toast.error("Something Went Wrong");
    }
  };
  console.log("PendingData", pendingEvents);

  const getPendingEvents = async () => {
    const config = {
      method: "get",
      // url: `${ADD_EVENT}?college_id=${collegeId}`,
      url: `${ADD_EVENT}/${emp_id}?college_id=${collegeId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    axios(config)
      .then((res) => {
        let response = res.data.data.sort((a, b) => b.id - a.id);
        let announcement = response.map((item) => ({
          ...item,
          from_date: item.from_date.split("T")[0],
          to_date: item.to_date.split("T")[0],
          type: capitalizeFirstLetter(item.type),
        }));

        setPendingEvents(announcement);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };
  useEffect(() => {
    geteventdata();
    getPendingEvents();
  }, []);
  const allAnnouncementsColumns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => (
        <span
          className={`badge badge-${
            record?.type == "Announcement"
              ? "success"
              : record?.type == "Event"
              ? "warning"
              : "danger"
          } p-2`}
        >
          {record?.type}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <span className={`badge badge-warning p-2`}>
          {record?.status === "ACTIVE" ? "Pending" : "Approved"}
        </span>
      ),
    },
    {
      title: "From Date",
      dataIndex: "from_date",
      key: "from_date",
    },
    {
      title: "To Date",
      dataIndex: "to_date",
      key: "to_date",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <button
          data-toggle="modal"
          data-target="#ViewModalAnnouncement"
          onClick={() => {
            setAnnouncementId(record?.application_id);
          }}
          className="btn-sm btn-nex"
        >
          View
        </button>
      ),
    },
  ];
  const seriesEmployee1 = [
    {
      name: "Total Staff",
      type: "column",
      // data: [44, 55, 41, 67, 22, 43, 21, 41],
      data: data?.employee_data?.map((s) => s.employee_count) || [],
      color: "#4D44B5",
    },
    {
      name: "Present",
      type: "area",
      // data: [23, 11, 22, 27, 13, 22, 37, 21],
      data: data?.employee_data?.map((s) => s.present) || [],
      color: "green",
    },
    {
      name: "Absent",
      type: "line",
      // data: [30, 25, 36, 30, 45, 35, 64, 52],
      data: data?.employee_data?.map((s) => s.absent) || [],
      color: "red",
    },
  ];
  //Chart Data 1
  const overviewChartOptions = {
    series: [0, 0, 0, 0, 0],
    labels: ["Ayurveda", "Management", "Engineering", "Law", "Arts"],
    chart: {
      width: 180,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100px",
            height: "100px",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  //Chart Data 2
  const overviewChartOptions2 = {
    series: [
      {
        name: "Male",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Female",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 430,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: [
          "Ayrveda",
          "Engineering",
          "Law",
          "Dental",
          "Management",
          "Arts",
          "Commerce",
        ],
      },
    },
  };

  // useEffect(() => {
  //   getDgetata();
  // }, [sessionStorage.getItem(SESSION_ROLE)]);

  useEffect(() => {
    getDashboardData();
  }, []);

  useState(() => {
    setCollegeData(getCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);
  console.log(
    "present",
    data?.employee_data?.reduce((acc, curr) => acc + parseInt(curr?.present), 0)
  );
  return (
    <div className="DashboardHR">
      <div className="main-content">
        <div className="page-content">
          <ViewAnnouncementModal application_id={announcementId} />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">
                    Hello , {sessionStorage.getItem("emp_name")}
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Home</li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <div
                    className="card"
                    // style={{
                    //   borderRadius: "10px",
                    //   backgroundColor: "#364277",
                    //   color: "white",
                    // }}
                  >
                    <div className="card-body" style={{}}>
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Number of Staff
                          </p>
                          <h4 className="mb-0">
                            <b>
                              {data?.employee_data?.reduce(
                                (acc, curr) => acc + curr?.employee_count,
                                0
                              )}
                            </b>
                          </h4>
                        </div>
                        <div>
                          <i
                            className="ri-group-line font-size-48"
                            style={{ fontSize: "50px", color: "#111D5E" }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="card"
                    // style={{
                    //   borderRadius: "10px",
                    //   backgroundColor: "#FF9F43",
                    //   color: "white",
                    // }}
                  >
                    <div className="card-body" style={{}}>
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Teaching Staff
                          </p>
                          <h4 className="mb-0">
                            <b>
                              {data?.employee_data?.reduce(
                                (acc, curr) =>
                                  acc + parseInt(curr?.teaching_staff_count),
                                0
                              )}
                            </b>
                          </h4>
                        </div>
                        <div>
                          <i
                            className="ri-user-2-line font-size-48"
                            style={{ fontSize: "50px", color: "#111D5E" }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div
                    className="card"
                    // style={{
                    //   borderRadius: "10px",
                    //   backgroundColor: "#364277",
                    //   color: "white",
                    // }}
                  >
                    <div className="card-body" style={{}}>
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Non Teaching Staff
                          </p>
                          <h4 className="mb-0">
                            <b>
                              {data?.employee_data?.reduce(
                                (acc, curr) =>
                                  acc +
                                  parseInt(curr?.non_teaching_staff_count),
                                0
                              )}
                            </b>
                          </h4>
                        </div>
                        <div>
                          <i
                            className="ri-user-3-line font-size-48"
                            style={{ fontSize: "50px", color: "#111D5E" }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="card"
                    // style={{
                    //   borderRadius: "10px",
                    //   backgroundColor: "#FF9F43",
                    //   color: "white",
                    // }}
                  >
                    <div className="card-body" style={{}}>
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Number of Absent Staff
                          </p>
                          <h4 className="mb-0">
                            <b>
                              {/* {parseInt(
                                data?.employee_data?.reduce(
                                  (acc, curr) =>
                                    acc + parseInt(curr?.employee_count),
                                  0
                                )
                              ) -
                                parseInt(
                                  data?.employee_data?.reduce(
                                    (acc, curr) =>
                                      acc + parseInt(curr?.present),
                                    0
                                  )
                                )} */}
                              40
                            </b>
                          </h4>
                        </div>
                        <div>
                          <i
                            className="ri-team-line font-size-48"
                            style={{ fontSize: "50px", color: "#111D5E" }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <div className="card ">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Upcoming Interviews</h4>

                      <div
                        className="table-responsive"
                        style={{ overflowY: "auto", height: "250px" }}
                      >
                        <table className="table table-bordered table-sm">
                          {upcomingInterviewsColumn.map((item) => (
                            <th scope="col" key={item}>
                              {item}
                            </th>
                          ))}
                          {Array.isArray(data?.recentInterviews) &&
                          data?.recentInterviews.length > 0 ? (
                            data?.recentInterviews.map((item, index) => (
                              <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>
                                  {item?.first_name} {item?.last_name}
                                </td>
                                <td>{item?.job_name}</td>
                                <td>{item?.interview_date}</td>
                                <td>
                                  <span
                                    className={`badge badge-soft-${
                                      item?.selection_status ===
                                      "PENDING_APPLICATION"
                                        ? "warning"
                                        : item?.selection_status ===
                                          "CALLED_FOR_INTERVIEW"
                                        ? "primary"
                                        : item?.selection_status ===
                                          "SELECTED_INTERVIEW"
                                        ? "success"
                                        : "danger"
                                    }`}
                                  >
                                    {item?.selection_status ===
                                    "PENDING_APPLICATION"
                                      ? "Review Pending"
                                      : item.selection_status ===
                                        "CALLED_FOR_INTERVIEW"
                                      ? "Called for Interview"
                                      : item.selection_status ===
                                        "REJECTED_APPLICATION"
                                      ? "Application Rejected"
                                      : item.selection_status ===
                                        "SELECTED_INTERVIEW"
                                      ? "Selected Candidate"
                                      : item.selection_status}
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan="6">
                                <div className="d-flex justify-content-center flex-column align-items-center p-2">
                                  <Empty
                                    description={
                                      <Typography.Text>
                                        <strong>
                                          No upcoming interviews are scheduled
                                          yet.
                                        </strong>
                                      </Typography.Text>
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                      <div className="row justify-content-center mt-2">
                        <button
                          type="button"
                          className="btn btn-nex mt-2 btn- "
                          onClick={() => {
                            navigate(ROUTES.HR.Interview.ReviewJobApplications);
                          }}
                        >
                          View All
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card ">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Applied Applications</h4>

                      <div
                        className="table-responsive"
                        style={{ overflowY: "auto", height: "250px" }}
                      >
                        <table className="table table-bordered table-sm ">
                          {appliedApplications.map((item) => (
                            <th scope="col" key={item}>
                              {item}
                            </th>
                          ))}
                          {Array.isArray(data?.pendingApplications) &&
                          data?.pendingApplications.length > 0 ? (
                            data?.pendingApplications?.map((item, index) => (
                              <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>
                                  {item?.first_name} {item?.last_name}
                                </td>
                                <td>{item?.phone}</td>
                                <td>{item?.job_name}</td>
                                <td>{item?.gender}</td>
                                <td>{formatDate(item?.createdAt)}</td>

                                <td>
                                  <span
                                    className={`badge badge-soft-${
                                      item?.selection_status ===
                                      "PENDING_APPLICATION"
                                        ? "warning"
                                        : item?.selection_status ===
                                          "CALLED_FOR_INTERVIEW"
                                        ? "primary"
                                        : item?.selection_status ===
                                          "SELECTED_INTERVIEW"
                                        ? "success"
                                        : "danger"
                                    }`}
                                  >
                                    {item?.selection_status ===
                                    "PENDING_APPLICATION"
                                      ? "Review Pending"
                                      : item.selection_status ===
                                        "CALLED_FOR_INTERVIEW"
                                      ? "Called for Interview"
                                      : item.selection_status ===
                                        "REJECTED_APPLICATION"
                                      ? "Application Rejected"
                                      : item.selection_status ===
                                        "SELECTED_INTERVIEW"
                                      ? "Selected Candidate"
                                      : item.selection_status}
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan="7">
                                <div className="d-flex justify-content-center flex-column align-items-center p-2">
                                  <Empty
                                    description={
                                      <Typography.Text>
                                        No applicants applied yet
                                      </Typography.Text>
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                      <div className="row justify-content-center mt-2">
                        <button
                          type="button"
                          className="btn btn-nex mt-2 btn- "
                          onClick={() => {
                            navigate(ROUTES.HR.Interview.ReviewJobApplications);
                          }}
                        >
                          View All
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="card " style={{ height: "492px" }}>
                        <div className="card-body">
                          {/* <h4 className="card-title mb-4">
                            {emp_id == 502
                              ? "Department wise Staff Attendance"
                              : "Faculty wise Staff Attendance"}
                          </h4>
                          <div id="chart">
                            <Chart
                              options={optionsEmployee1}
                              series={seriesEmployee1}
                              type="line"
                              height={405}
                            />
                          </div> */}
                          <h4 className="card-title mb-4">Pending Events</h4>
                          <div className="table-responsive">
                            <Table
                              dataSource={pendingEvents}
                              columns={allAnnouncementsColumns}
                              pagination={false}
                              scroll={{ y: 405 }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card ">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Announcement</h4>
                      <div style={{ overflowY: "auto", height: "365px" }}>
                        {/* <List
                          size="small"
                          bordered
                          dataSource={notifications}
                          renderItem={(item, index) => 
                          <List.Item>
                            <>
                              <i className="ri-notification-2-line mr-2 px-2 py-1 text-white" style={{backgroundColor:colors[index % colors.length], borderRadius: "20%"}}></i>  
                              <Link to="" style={{color: "black"}}>{item.length > 75 ? `${item.substring(0, 75)}...` : item} <span style={{ color: "gray" }}>- May 15, 2024</span></Link>
                            </>
                          </List.Item>}
                          
                        /> */}

                        <List
                          size="small"
                          bordered
                          dataSource={allData
                            ?.filter((s) => s.type == "announcement")
                            ?.map((i, k) => i)}
                          renderItem={(item, index) => (
                            <List.Item>
                              <span className="d-flex justify-content-start gap-2 align-items-start">
                                <i
                                  className="ri-notification-2-line mr-2 px-2 py-1 text-white"
                                  style={{
                                    backgroundColor:
                                      colors[index % colors.length],
                                    borderRadius: "20%",
                                  }}
                                ></i>
                                <Link to="" style={{ color: "black" }}>
                                  {item?.title?.length > 35
                                    ? `${item?.title?.substring(0, 35)}...`
                                    : item?.title}{" "}
                                  <span style={{ color: "gray" }}>
                                    - {formatMonthYear(item?.to_date)}
                                  </span>
                                </Link>
                              </span>
                            </List.Item>
                          )}
                        />
                      </div>
                      <div className="row justify-content-center mt-2">
                        <div className="col-md-6">
                          <button
                            type="button"
                            className="btn btn-nex mt-2 btn- mw-100 w-100"
                            onClick={() => {
                              navigate(
                                ROUTES.Registar.Communicate.Announcements
                              );
                            }}
                          >
                            View All
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card ">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Upcoming Events</h4>

                      <div style={{ overflowY: "auto", height: "365px" }}>
                        {/* <List
                          size="small"
                          bordered
                          dataSource={events}
                          renderItem={(item, index) => 
                          <List.Item>
                            <>
                              <img
                                src="https://cdn2.iconfinder.com/data/icons/admin-tools-2/25/image2-512.png"
                                style={{width: "30px", height: "30px", borderRadius:"10%"}}
                                className="mr-2"
                              />
                              <Link to="" style={{color: "black"}}>{item.length > 75 ? `${item.substring(0, 75)}...` : item} <span style={{ color: "gray" }}>- May 15, 2024</span></Link>
                            </>
                          </List.Item>}
                          
                        /> */}
                        <List
                          size="small"
                          bordered
                          dataSource={eventdata?.filter(
                            (event) => event.college_id == collegeids
                          )}
                          renderItem={(item, index) => (
                            <List.Item>
                              <span className="d-flex justify-content-start gap-2 align-items-start">
                                <i
                                  className="ri-calendar-event-line mr-2 px-2 py-1 text-white"
                                  style={{
                                    backgroundColor:
                                      colors[index % colors.length],
                                    borderRadius: "20%",
                                  }}
                                ></i>
                                {/* <img
                                  src={"https://cdn2.iconfinder.com/data/icons/admin-tools-2/25/image2-512.png"}
                                  style={{ width: "30px", height: "30px", borderRadius: "10%" }}
                                  className="mr-2"
                                  alt="https://cdn2.iconfinder.com/data/icons/admin-tools-2/25/image2-512.png"
                                /> */}
                                <Link to="" style={{ color: "black" }}>
                                  {item?.title?.length > 75
                                    ? `${item.substring(0, 75)}...`
                                    : item?.title}{" "}
                                  <span style={{ color: "gray" }}>
                                    - {formatMonthYear(item?.to_date)}
                                  </span>
                                </Link>
                              </span>
                            </List.Item>
                          )}
                        />
                      </div>
                      <div className="row justify-content-center mt-2">
                        <div className="col-md-6">
                          <button
                            type="button"
                            className="btn btn-nex mt-2 btn- mw-100 w-100"
                            onClick={() => {
                              navigate(
                                ROUTES.Registar.Communicate.UpcomingEvents
                              );
                            }}
                          >
                            View All
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div
                  className="col-md-6 row d-flex"
                  style={{ maxwidth: "500px" }}
                >
                  <div className="col-12">
                    <div className="card ">
                      <div
                        className="card-body p-5"
                        style={{ maxHeight: "600px", maxWidth: "600px" }}
                      >
                        <h4 className="card-title mb-4">Overview</h4>
                        <div id="chart">
                          <ReactApexChart
                            options={overviewChartOptions}
                            series={overviewChartOptions.series}
                            type="donut"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="card ">
                      <div
                        className="card-body"
                        style={{ maxHeight: "600px", maxWidth: "600px" }}
                      >
                        <h4 className="card-title mb-4">Male vs Female</h4>
                        <div id="chart">
                          <ReactApexChart
                            options={overviewChartOptions2.options}
                            series={overviewChartOptions2.series}
                            type="bar"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="col-md-12 mb-3">
                  <div className="card p-3 w-100">
                    <h3 className="my-3">Event Calendar</h3>
                    <Calender setLoading={setLoading} collegeId={collegeId} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHR;

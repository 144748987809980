import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { useState, useRef, useEffect } from "react";
import { Button } from "antd";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";
import getUniversityDetails from "../../../utils/universityDetails.api";
import Nodata from "../../../Components/NoData/Nodata";
import {
  GET_ALL_EMPLOYEE_SHIFT,
  GET_EMPLOYEE_DETAILS_WITH_SHIFT,
} from "../../../utils/apiConstants";
import ModalCreateShift from "../../../modals/Shift/ModalCreateShift";
import ModalAssignEmpShift from "../../../modals/Shift/ModalAssignEmpShift";

function EmployeeShift({ setLoading, collegeId }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [type, setType] = useState();
  const [edit, setEdit] = useState();
  const [empData, setEmpData] = useState([]);
  const [allData, setAllData] = useState();
  const [displayData, setDisplayData] = useState([]);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const getEmpData = () => {
    setLoading(1);
    const year = new Date().getFullYear();
    const config = {
      method: "get",
      url: `${GET_EMPLOYEE_DETAILS_WITH_SHIFT}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };
    axios(config)
      .then((res) => {
        setLoading(0);
        setEmpData(res.data.data);
        setDisplayData(res.data.data);
        console.log("employees with shifts - ", res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: GET_ALL_EMPLOYEE_SHIFT,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log(res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    getEmpData();
    // getAlldata();
  }, []);

  const tableRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Employee Shift-" + new Date().getFullYear(),
    sheet: "Employee Shift",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  // const handlePrint = () => {
  //   // Make the table visible
  //   tableRef.current.style.display = "table";

  //   // Delay the PDF export
  //   setTimeout(() => {
  //     PrintRecipt();
  //     // Hide the table again if needed
  //     tableRef.current.style.display = "none";
  //   }, 1); // Adjust the delay as needed
  // };

  const college_id = sessionStorage.getItem("college_id");

  const [universityData, setUniversityData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUniversityDetails();
        setUniversityData(data);
      } catch (error) {
        console.error(`Error fetching university details: ${error.message}`);
      }
    };

    fetchData();
  }, []);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  // console.log("edit -", edit);
  console.log("type -", type);
  console.log("universityData -", universityData);

  return (
    <div className="EmployeeShift">
      <ModalCreateShift
        reloadData={getData}
        type={type}
        data={edit}
        setLoading={setLoading}
      />
      <ModalAssignEmpShift
        allData={allData}
        getEmpData={getEmpData}
        reloadData={getData}
        empData={empData}
        edit={edit}
        setLoading={setLoading}
        displayData={displayData}
        setDisplayData={setDisplayData}
      />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Employee Shift</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/">Shift</a>
                      </li>
                      <li className="breadcrumb-item active">Employee Shift</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="container">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-8">
                      <Button
                        type="primary"
                        className="px-2 mr-2"
                        style={{ background: "green", borderColor: "green" }}
                        data-toggle="modal"
                        data-target="#ModalCreateShift"
                        onClick={() => {
                          setType("add");
                          setEdit();
                        }}
                      >
                        + Add
                      </Button>
                    </div>
                    <div className="col-md-4">
                      <Button
                        type="primary"
                        className="px-2 ml-2 mr-3 float-right"
                        onClick={onDownload}
                      >
                        Excel
                      </Button>
                      <Button
                        type="primary"
                        className="px-2 ml-2 float-right"
                        onClick={handlePrint}
                      >
                        PDF
                      </Button>
                    </div>
                  </div>

                  <div>
                    {data && data.length !== 0 ? (
                      data.map((i, key) => (
                        <div
                          className="row my-3 mx-2 p-3 border rounded role-div flex-nowrap shadow"
                          onClick={() => {
                            setType("edit");
                            setEdit(i);
                          }}
                        >
                          <div
                            className="col-10 d-flex justify-content-between"
                            key={key}
                          >
                            <div className="role-title mb-1">
                              {i.title}
                              <span
                                className="badge badge-soft-success ml-2"
                                style={{ fontSize: "0.8em" }}
                              >
                                <span className="ml-2">
                                  {i?.from_time} To {i?.to_time}
                                </span>
                              </span>
                            </div>
                            <div className="role-code"></div>
                          </div>
                          <div
                            data-toggle="modal"
                            data-target="#ModalAssignEmpShift"
                            onClick={() => {
                              setType("edit");
                              setEdit(i);
                            }}
                            className="ml-3"
                          >
                            <i
                              className="fa fa-tags "
                              aria-hidden="true"
                              style={{
                                color: "#364277",
                                cursor: "pointer",
                              }}
                              onmouseover="this.style.color='orange'"
                              onmouseout="this.style.color='#364277'"
                            />{" "}
                            Assign
                          </div>
                          <div
                            data-toggle="modal"
                            data-target="#ModalCreateShift"
                            onClick={() => {
                              setType("edit");
                              setEdit(i);
                            }}
                            className="ml-3"
                          >
                            <span>
                              <i className="fa fa-edit " aria-hidden="true" />{" "}
                              Edit
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <Nodata />
                    )}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
                <div
                  style={{
                    display: "none",
                  }}
                >
                  <table
                    id="table_id"
                    ref={tableRef}
                    className="display table table-bordered  nowrap table-hover "
                    style={{
                      borderCollapse: "collapse",
                      borderSpacing: 0,
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th colSpan="11" className="text-center">
                          <img
                            src={
                              universityData ? universityData?.logo_primary : ""
                            }
                            width={400}
                          />
                          <br />
                          <h5 className="text-center mt-4">Shift Details</h5>
                        </th>
                      </tr>
                    </thead>
                    <thead className="mt-4">
                      <tr>
                        <th>Sl.No.</th>
                        <th>Shift</th>
                        <th>Description</th>
                        <th>From Time</th>
                        <th>To Time</th>
                        <th>Working Hours</th>
                        <th>Minimum Working hr</th>
                        <th>Week Off</th>
                        <th>Buffer Time</th>
                        <th>Duplicate Punch Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.length == 0 ? (
                        <tr>
                          <td colSpan={11}>
                            <Nodata />
                          </td>
                        </tr>
                      ) : (
                        data?.map((i, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{i?.title}</td>
                              <td>{i?.description}</td>
                              <td>{i?.from_time}</td>
                              <td>{i?.to_time}</td>
                              <td>{i?.working_hours}</td>
                              <td>{i?.minimum_working_hours}</td>
                              {/* <td>{i?.week_off}</td> */}
                              <td>
                                {i?.week_off
                                  ? i.week_off
                                      .split(",")
                                      .map((week) => {
                                        if (week === "2")
                                          return "Second Saturday";
                                        if (week === "4")
                                          return "Fourth Saturday";
                                        return null;
                                      })
                                      .join(", ")
                                  : ""}
                              </td>
                              <td>{i?.buffer_time}</td>
                              <td>{i?.duplicate_punch}</td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeShift;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Toggler from "../../../Components/Toggler/Toggler";
import {
  COLLEGE_SPECIALIZATION,
  COURSES,
  DEPARTMENTS,
} from "../../../utils/apiConstants";
import { toast } from "react-toastify";
import Select from "react-select";
function ModalDepartments(props) {
  const [College, setCollege] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [department, setDepartment] = useState([]);
  const [departmentsOptions, setDepartmentOptions] = useState([]);
  const [checkbox, setCheckbox] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  var emp_id = sessionStorage.getItem("employee_id");

  const CollegeData = JSON.parse(localStorage.getItem("ALL_DATA")).college;
  const allProgramData = JSON.parse(localStorage.getItem("ALL_DATA")).program;
  const allSpecializationData = JSON.parse(
    localStorage.getItem("ALL_DATA")
  ).specialization;
  const getData = async () => {
    const config = {
      method: "get",
      url: COURSES,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        // console.log("getData", res.data.data);
        setDepartmentOptions(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const config = {
    method: props.type === "edit" ? "put" : "post",
    url: `${DEPARTMENTS}${props.type === "edit" ? "/" + props.data.id : ""}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
    },
    data: {
      name: name,
      description: desc,
      college_id: College,
      status: checkbox ? "ACTIVE" : "INACTIVE",
      department_id: specialization,
      department_course_id: department,
    },
  };
  // console.log("program", config.data);
  const clearData = () => {
    setCollege("");
    setDepartment("");
    setSpecialization("");
    setCheckbox(true);
    setName("");
    setDesc("");
  };

  const SubmitModal = async () => {
    props.setLoading(1);

    await axios(config)
      .then((res) => {
        props.setLoading(0);
        // console.log(res);
        clearData();
        toast.success("Course added successfully Successfully");
        props.reloadData();
      })
      .catch((err) => {
        props.setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    if (props.type === "edit") {
      if (props.data) {
        setName(props.data.name);
        setDesc(props.data.description);
        setCollege(props.data.college_id);
        setDepartment(props.data.department_course_id);
        setSpecialization(props.data.department_id);
        setCheckbox(props.data.status === "ACTIVE" ? true : false);
      }
    }

    if (props.type === "add") {
      clearData();
    }
    getData();
  }, [props.data, props.type]);

  useEffect(() => {
    if (emp_id == 502) {
      setCollege(1111011);
    }
  }, []);

  // const [programData, setProgramData] = useState([]);

  // const selectProgram = (id) => {
  //     console.log(id)
  //     // setProgramDat(allSpecializationData.filter(item.colleg_type_id => item.program_id == id));
  // }
  // console.log("program", typeof program);

  return (
    <div className="ModalDepartments">
      <div
        className="modal fade"
        id="ModalDepartments"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered mw-100 w-75"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {props.type === "edit" ? "Edit" : "Add"} Course
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>
                      {" "}
                      Course Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Course Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>
                      {" "}
                      Course Code <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Course Code"
                      value={specialization}
                      onChange={(e) => {
                        setSpecialization(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {emp_id != 502 ? (
                  <div className="col-12">
                    <div className="form-group">
                      <label>
                        {" "}
                        Faculty <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name=""
                        className={`form-control ${
                          props.type === "edit" ? "cursor-disable" : ""
                        }`}
                        value={College}
                        disabled={props.type === "edit" ? true : false}
                        onChange={(e) => {
                          setCollege(e.target.value);
                        }}
                      >
                        <option value="">Select the Faculty</option>
                        {CollegeData?.map((i, key) => (
                          <option value={i.id} key={key}>
                            {i.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="col-12">
                  <div className="form-group">
                    <label>
                      Department <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      isMulti={true}
                      // isDisabled={props.type === "edit"}
                      className={props.type === "edit" ? "disable" : ""}
                      options={departmentsOptions?.map((i) => ({
                        value: i.id,
                        label: `${i.name} (${
                          allProgramData.find((item) => item.id == i.program_id)
                            .name || ""
                        } )`,
                      }))}
                      value={departmentsOptions
                        .filter((option) => department?.includes(option.id))
                        .map((option) => {
                          return {
                            value: option.id,
                            label: `${option.name}
                             ( ${
                               allProgramData.find(
                                 (item) => item.id == option.program_id
                               ).name
                             })`,
                          };
                        })}
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : [];
                        setDepartment(selectedValues); // Update state with selected values
                      }}
                      placeholder="Select the Department"
                    />
                  </div>
                </div>
                {/* <div className="col-12">
                                    <div className="form-group">
                                        <label>
                                            {" "}
                                            Specialization <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <select
                                            name=""
                                            className={`form-control ${props.type === 'edit' ? 'cursor-disable' : ''}`}
                                            value={specialization}
                                            disabled={props.type === 'edit' ? true : false}
                                            onChange={(e) => { setSpecialization(e.target.value) }}
                                        >
                                            <option value="">Select the Specialization</option>
                                            {specializationData.map((i, key) => (
                                                <option value={i.id} key={key}>{i.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div> */}
                {/* <div className="col-12">
                                    <div className="form-group">
                                        <label>
                                            {" "}
                                            Code <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control ${props.type==='edit'?'cursor-disable':''}`}
                                            name="followup"
                                            id=""
                                            readOnly={props.type==='edit'?true:false}
                                            value={code}
                                            onChange={(e) => { setCode(e.target.value) }}
                                        />
                                    </div>
                                </div> */}

                <div className="col-12">
                  <div className="form-group">
                    <label> Remark</label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="followup"
                      id=""
                      readOnly=""
                      value={desc}
                      onChange={(e) => {
                        setDesc(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {props.type === "edit" ? (
                  <div className="col-12 mb-3">
                    <Toggler
                      defaultChecked={checkbox}
                      checked={checkbox}
                      checkboxValue={(e) => {
                        setCheckbox(e.target.checked);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row d-flex justify-content-between px-2">
                <button
                  className="btn btn-danger btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    SubmitModal();
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalDepartments;

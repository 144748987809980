import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../../utils/AssetsReferenceTypes";
import { getFileUrl } from "../../../../Helpers/Helpers";
import { message } from "antd";
import axios from "axios";
import { NAAC } from "../../../../utils/apiConstants";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
const toolbar = [
  "undo",
  "redo",
  "|",
  "heading",
  "|",
  "bold",
  "italic",
  "|",
  "link",
  "blockQuote",
  "insertTable",
  "|",
  "bulletedList",
  "numberedList",
  "outdent",
  "alignment",
  "indent",
  "|",
  "imageUpload",
];
const maxWords = 500;
const title = "nep_parameters";
const sub_title = "distance_education";

export default function DistanceEducation({ setLoading, setUpdatedData }) {
  const [data, setData] = useState({
    id: "",
    status: "",
    vocational_courses_through_ODL: "",
    technological_tools: "",
    good_practices_for_distance_education: "",
  });
  let emp_id = sessionStorage.getItem("employee_id");
  const [wordCount, setWordCount] = useState({
    vocational_courses_through_ODL: 0,
    technological_tools: 0,
    good_practices_for_distance_education: 0,
  });
  const countWords = (text) => {
    const words = text.trim().split(/\s+/); // Split by spaces and trim extra spaces
    return words.filter((word) => word.length > 0).length; // Filter out empty words
  };
  const handleEditorChange = (editor, key) => {
    const editorData = editor.getData();
    const currentWordCount = countWords(editorData);

    if (currentWordCount <= maxWords) {
      setData((prevValue) => ({
        ...prevValue,
        [key]: editorData,
      }));
    }
    setWordCount((prev) => ({ ...prev, [key]: currentWordCount }));
  };
  const addAttachment = async (file) => {
    try {
      const response = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `Resume`,
        file.name.split(".")[1],
        setLoading,
        file
      );
      return response;
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("File upload failed");
      throw error;
    }
  };

  const createUploadAdapter = (loader) => {
    return {
      upload: async () => {
        try {
          const file = await loader.file;
          // Directly get the uploaded file URL
          const uploadedUrl = await addAttachment(file);
          console.log("Uploaded image URL:", uploadedUrl);
          // Return the URL in the format CKEditor expects
          return { default: uploadedUrl };
        } catch (error) {
          console.error("Upload failed:", error);
          throw error;
        }
      },
    };
  };
  function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return createUploadAdapter(loader);
    };
  }
  const validateData = () => {
    const requiredFields = [
      "vocational_courses_through_ODL",
      "technological_tools",
      "good_practices_for_distance_education",
    ];

    for (const field of requiredFields) {
      if (wordCount[field] === 0) {
        toast.error(`Please fill in the ${field.split("_").join(" ")} field`);
        return false;
      }
      if (wordCount[field] > maxWords) {
        toast.error(
          `Please reduce the number of words in the ${field
            .split("_")
            .join(" ")} field`
        );
        return false;
      }
    }
    return true;
  };
  const handleSaveOrSubmit = async (e, status) => {
    let {
      vocational_courses_through_ODL,
      technological_tools,
      good_practices_for_distance_education,
    } = data;
    let jsonResponse = JSON.stringify({
      vocational_courses_through_ODL,
      technological_tools,
      good_practices_for_distance_education,
    });
    e.preventDefault();
    // List all required fields
    if (!validateData()) return;
    const info = {
      ...data,
      title: title,
      response: jsonResponse,
      sub_title: sub_title,
      created_by: emp_id,
      updated_by: emp_id,
      status,
      application_id: 1,
    };
    console.log("info", info);
    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/nep-parameters`,
      data: info,
    };
    await axios(config)
      .then((res) => {
        setUpdatedData(uuidv4());
        toast.success(
          `${
            status == "DRAFT"
              ? "Successfully saved as draft"
              : "Successfully submitted for review"
          } `
        );
        getFormData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const handleSaveAsDraft = (e) => {
    handleSaveOrSubmit(e, "DRAFT");
  };
  const handleSubmitForReview = (e) => {
    handleSaveOrSubmit(e, "REVIEW");
  };
  const getFormData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/nep-parameters/${sub_title}`,
    };

    await axios(config)
      .then((res) => {
        let backendData = res.data[0];
        if (data) {
          const responseObj = JSON.parse(backendData?.response || "");
          setData((prevState) => ({
            ...prevState,
            id: backendData.id,
            status: backendData.status,
            ...responseObj,
          }));
        }
        // console.log("data fetched is", res.data);
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  useEffect(() => {
    getFormData();
  }, []);
  return (
    <>
      <div>
        <div className="d-flex flex-column mb-3">
          <p className="h5">6. Distance education/online education</p>
          <span className="text-danger">
            *Note - Maximum word allowed is 500 words
          </span>
        </div>
        <span
          className={`text-white d-inline-block text-center px-4 py-1 shadow-sm small rounded-pill ${
            data?.status === "DRAFT"
              ? "bg-primary"
              : data?.status === "REVIEW"
              ? "bg-warning"
              : data?.status === "REJECTED"
              ? "bg-danger"
              : data?.status === "APPROVED"
              ? "bg-success"
              : "bg-secondary"
          }`}
          style={{ width: "fit-content" }}
        >
          {data?.status === "DRAFT"
            ? "In Draft"
            : data?.status === "REVIEW"
            ? "Submitted for review"
            : data?.status === "REJECTED"
            ? "Rejected"
            : data?.status === "APPROVED"
            ? "Approved"
            : "Not Started"}
        </span>
      </div>

      <div>
        <p>
          a) Delineate the possibilities of offering vocational courses through
          ODL mode in the institution.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.vocational_courses_through_ODL}
          onChange={(event, editor) =>
            handleEditorChange(editor, "vocational_courses_through_ODL")
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.vocational_courses_through_ODL >= maxWords
                ? "text-danger"
                : ""
            }`}
          >
            {wordCount.vocational_courses_through_ODL}/{maxWords}{" "}
            {wordCount.vocational_courses_through_ODL > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div>
        <p>
          b) Describe the development and use of technological tools for
          teaching-learning activities. Provide details about the institutional
          efforts towards blended learning.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.technological_tools}
          onChange={(event, editor) =>
            handleEditorChange(editor, "technological_tools")
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.technological_tools >= maxWords ? "text-danger" : ""
            }`}
          >
            {wordCount.technological_tools}/{maxWords}{" "}
            {wordCount.technological_tools > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div>
        <p>
          c) Describe any good practices of the institution pertaining to
          distance education/online education in view of NEP 2020.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.good_practices_for_distance_education}
          onChange={(event, editor) =>
            handleEditorChange(editor, "good_practices_for_distance_education")
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.good_practices_for_distance_education >= maxWords
                ? "text-danger"
                : ""
            }`}
          >
            {wordCount.good_practices_for_distance_education}/{maxWords}{" "}
            {wordCount.good_practices_for_distance_education > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div className="text-right mt-5">
        <button className="btn btn-primary mr-2" onClick={handleSaveAsDraft}>
          Save as Draft
        </button>
        <button className="btn btn-warning" onClick={handleSubmitForReview}>
          <span>Submit for Review</span>
        </button>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import {
  Select,
  Input,
  DatePicker,
  Form,
  Button,
  FormInstance,
  Tabs,
  Table,
} from "antd";
import { JOB_APPLICATIONS } from "../../../utils/apiConstants";
import DocumentsModal from "../../../modals/Students/DocumentsModal";
const { TextArea } = Input;

function JobApplicationProfile({ collegeId, setLoading }) {
  const [photoLink, setPhotoLink] = useState("");
  const [resumeLink, setResumeLink] = useState("");
  const [educationDetails, setEducationDetails] = useState([]);
  const [documentDetails, setDocumentDetails] = useState([]);
  console.log("photoLink", photoLink);
  console.log("resumeLink", resumeLink);
  console.log("documentDetails", documentDetails);
  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      console.log("error while formating date :: ", dateString);
      return dateString;
    }
  }

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const navigate = useNavigate();

  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  const [data, setData] = useState([]);

  const id = useParams().id;

  const getJobApplication = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${JOB_APPLICATIONS}?id=${id}`,
    };

    await axios(config)
      .then((res) => {
        console.log("application data in Profile- ", res.data.data);
        setData(res.data.data[0]);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getJobApplication();
  }, []);

  useEffect(() => {
    console.log("data from jobApplication", data);
    if (data && data.documents) {
      let educationArray = JSON.parse(data?.educationDetails);
      let documentsArray = JSON.parse(data?.documents);

      setDocumentDetails(
        documentsArray.map((item) => ({
          ...item,
          documentType: item.documentType.toUpperCase(),
        }))
      );
      setEducationDetails(
        educationArray.map((item) => ({
          ...item,
          educationType: item.educationType.toUpperCase(),
        }))
      );

      let photoArray = documentsArray.find(
        (item) => item.documentType === "photo"
      );
      setPhotoLink(photoArray?.documentUpload);

      let resumeObj = documentsArray.find(
        (item) => item.documentType === "resume"
      );
      setResumeLink(resumeObj?.documentUpload);
    }
  }, [data]);
  const validDocumentDetails = documentDetails.filter(
    (doc) => doc.documentType && doc.documentUpload
  );

  const validEducationDetails = educationDetails.filter(
    (edu) => edu.educationType && edu.universityName && edu.collegeName
  );
  console.log(educationDetails);
  const educationColumns = [
    {
      title: "S.No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (_, __, index) => index + 1, // Display the row index
      width: "50px", // Set a fixed width for the serial number column
    },
    {
      title: "Education Type",
      dataIndex: "educationType",
      key: "educationType",
      responsive: ["sm"],
    },
    {
      title: "University Name",
      dataIndex: "universityName",
      key: "universityName",
      responsive: ["sm"],
    },
    {
      title: "School / College Name",
      dataIndex: "collegeName",
      key: "collegeName",
      responsive: ["sm"],
    },
    {
      title: "Grade / GPA / Percentage",
      dataIndex: "grade",
      key: "grade",
      responsive: ["sm"],
    },
    {
      title: "From (Month/Year)",
      dataIndex: "fromYear",
      key: "fromYear",
      responsive: ["sm"],
    },
    {
      title: "To (Month/Year)",
      dataIndex: "toYear",
      key: "toYear",
      responsive: ["sm"],
    },
  ];
  const documentsColumns = [
    {
      title: "S.No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (_, __, index) => index + 1, // Display the row index
      width: "50px", // Set a fixed width for the serial number column
    },
    {
      title: "Document Type",
      dataIndex: "documentType",
      key: "documentType",
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <button
          className="mt-3 ml-1 btn btn-nex btn-sm"
          data-toggle="modal"
          data-target="#DocumentsModal"
          onClick={() => {
            setTitle(record?.documentType?.toUpperCase());
            setLink(record?.documentUpload);
          }}
        >
          View
        </button>
      ),
    },
  ];
  const items = [
    {
      key: "personalInformation",
      label: "Personal Information",
      children: (
        <table
          style={{ borderCollapse: "collapse" }}
          width="100%"
          className="p-2"
        >
          <tr>
            <th width="30%" className="text-left px-1 py-1">
              Full name
            </th>
            <td width="70%" className="text-left">
              <b>:</b> {data?.first_name} {data?.last_name}
            </td>
          </tr>
          <tr>
            <th width="30%" className="text-left px-1 py-1">
              Date of Birth
            </th>
            <td width="70%" className="text-left">
              <b>:</b> {formatDate(data?.dob?.split("T")[0])}
            </td>
          </tr>
          <tr>
            <th width="30%" className="text-left px-1 py-1">
              Age
            </th>
            <td width="70%" className="text-left">
              <b>:</b> {calculateAge(data?.dob)}
            </td>
          </tr>
          <tr>
            <th width="30%" className="text-left px-1 py-1">
              Gender
            </th>
            <td width="70%" className="text-left">
              <b>:</b> {data?.gender}
            </td>
          </tr>
          <tr>
            <th width="30%" className="text-left px-1 py-1">
              Nationality
            </th>
            <td width="70%" className="text-left">
              <b>:</b> {data?.nationality}
            </td>
          </tr>
          <tr>
            <th width="30%" className="text-left px-1 py-1">
              Country
            </th>
            <td width="70%" className="text-left">
              <b>:</b> {data?.country}
            </td>
          </tr>
          <tr>
            <th width="30%" className="text-left px-1 py-1">
              State
            </th>
            <td width="70%" className="text-left">
              <b>:</b> {data?.state}
            </td>
          </tr>
          <tr>
            <th width="30%" className="text-left px-1 py-1">
              City
            </th>
            <td width="70%" className="text-left">
              <b>:</b> {data?.city}
            </td>
          </tr>
          <tr>
            <th width="30%" className="text-left px-1 py-1">
              Current Address
            </th>
            <td width="70%" className="text-left">
              <b>:</b> {data?.current_address}
            </td>
          </tr>
          <tr>
            <th width="30%" className="text-left px-1 py-1">
              Permanent Address
            </th>
            <td width="70%" className="text-left">
              <b>:</b> {data?.permanent_address}
            </td>
          </tr>
          <tr>
            <th width="30%" className="text-left px-1 py-1">
              Father Name
            </th>
            <td width="70%" className="text-left">
              <b>:</b> {data?.father_name}
            </td>
          </tr>
          <tr>
            <th width="30%" className="text-left px-1 py-1">
              Mother Name
            </th>
            <td width="70%" className="text-left">
              <b>:</b> {data?.mother_name}
            </td>
          </tr>
          <tr>
            <th width="30%" className="text-left px-1 py-1">
              Marital Status
            </th>
            <td width="70%" className="text-left">
              <b>:</b> {data?.marital_status}
            </td>
          </tr>
          <tr>
            <th width="30%" className="text-left px-1 py-1">
              Work Experience
            </th>
            <td width="70%" className="text-left">
              <b>:</b> {data?.work_experience}
            </td>
          </tr>
        </table>
      ),
    },
    {
      key: "educationDetails",
      label: "Education Details",
      children: (
        <div className="table-responsive">
          {validEducationDetails.length > 0 ? (
            <Table
              columns={educationColumns}
              dataSource={validEducationDetails}
              pagination={false}
              style={{ marginTop: 20 }}
            />
          ) : (
            <p>No education details available</p>
          )}
        </div>
      ),
    },
    {
      key: "documents",
      label: "Documents",
      children: (
        <div className="table-responsive">
          {validDocumentDetails.length > 0 ? (
            <Table
              columns={documentsColumns}
              dataSource={validDocumentDetails}
              pagination={false}
              style={{ marginTop: 20 }}
            />
          ) : (
            <p>No documents available</p>
          )}
        </div>
      ),
    },
  ];
  return (
    <div>
      <DocumentsModal title={title} img={link} setLink={setLink} />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center ">
                  <button
                    className="btn btn-nex d-flex justify-content-center align-items-center rounded-pill mb-1 mr-3"
                    onClick={() => {
                      navigate("/interview/reviewJobApplications");
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">Applicant Profile</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="col-12">
                  <div className="card">
                    <div className="box p-2 text-center">
                      <h6 className="mt-3 text-primary">
                        <b>Applicant Profile</b>
                      </h6>
                      <img
                        className="profile-user-img img-responsive rounded-circle mx-auto d-block mt-4"
                        src={photoLink}
                        alt="Profile photo"
                        width="50%"
                        style={{
                          aspectRatio: "1/1",
                          border: "2px solid black",
                        }}
                      />
                      <h5 className="mt-3">
                        {data?.first_name?.toUpperCase()}{" "}
                        {data?.last_name?.toUpperCase()}
                      </h5>
                      {/* <p>({data?.phone})</p> */}
                      <table
                        style={{ borderCollapse: "collapse" }}
                        width="100%"
                        className="mt-4 p-2"
                      >
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Email
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {data?.email}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Phone
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {data?.phone}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Applied role
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {data?.job_name}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Applied date
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b>{" "}
                            {formatDate(data?.createdAt?.split("T")[0])}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Application Status
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b>{" "}
                            <span
                              className={`badge badge-soft-${
                                data?.selection_status === "PENDING_APPLICATION"
                                  ? "warning"
                                  : data?.selection_status ===
                                    "CALLED_FOR_INTERVIEW"
                                  ? "primary"
                                  : data?.selection_status ===
                                    "SELECTED_INTERVIEW"
                                  ? "success"
                                  : "danger"
                              }`}
                            >
                              {data?.selection_status}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="col-12">
                  <div className="card">
                    <div className="box p-4">
                      <h6 className="text-primary">
                        <b>Applicant Details</b>
                      </h6>
                      {/* <table
                        style={{ borderCollapse: "collapse" }}
                        width="100%"
                        className="mt-4 p-2"
                      >
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Full name
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {data?.first_name} {data?.last_name}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Date of Birth
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {formatDate(data?.dob?.split("T")[0])}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Nationality
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {data?.nationality}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Country
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {data?.country}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            State
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {data?.state}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            City
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {data?.city}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Current Address
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {data?.current_address}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Permanent Address
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {data?.permanent_address}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Father Name
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {data?.father_name}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Mother Name
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {data?.mother_name}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Marital Status
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {data?.marital_status}
                          </td>
                        </tr>
                        <tr>
                          <th
                            width="30%"
                            className="text-start px-1 py-1"
                            style={{ verticalAlign: "top" }}
                          >
                            Qualification
                          </th>
                          <td width="70%" className="text-left">
                            <div className="d-flex gap-1">
                              <b>:</b>
                              <div>
                                {data?.qualification}
                                {educationDetails.length > 0 &&
                                  educationDetails.map((item) => (
                                    <div
                                      className="d-flex "
                                      key={item.educationType}
                                    >
                                      <span
                                        style={{
                                          width: "100px",
                                          whiteSpace: "break-spaces",
                                        }}
                                      >
                                        {item.educationType?.toUpperCase()}
                                      </span>
                                      <span>
                                        {item.grade && (
                                          <span>
                                            <strong>grades:</strong>{" "}
                                            {item.grade}
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" className="text-left px-1 py-1">
                            Work Experience
                          </th>
                          <td width="70%" className="text-left">
                            <b>:</b> {data?.work_experience}
                          </td>
                        </tr>
                      </table> */}
                      <Tabs
                        defaultActiveKey="personalInformation"
                        items={items}
                      />
                      {/* <button
                        className="mt-3 ml-1 btn btn-nex btn-sm"
                        data-toggle="modal"
                        data-target="#DocumentsModal"
                        onClick={() => {
                          setLink(resumeLink);
                          setTitle("Applicant Resume");
                        }}
                      >
                        View Resume
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobApplicationProfile;

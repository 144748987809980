import axios from "axios";
import React from "react";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import useEmployee from "../../../Hooks/Employee/useEmployee";
import {
  INVENTORY_ADD_ITEM,
  INVENTORY_ISSUE_ITEM,
  INVENTORY_ITEM_CATEGORY,
  INVENTORY_GET_ITEM_BYID,
  INVENTORY_ISSUE_ITEM_BYID,
  INVENTORY_GET_ITEM,
} from "../../../utils/Inventory.apiConst";
import {
  ALL_DATA,
  LOCAL_COLLEGE,
  LOCAL_JOBROLES,
} from "../../../utils/LocalStorageConstants";
import {
  SESSION_EMPLOYEE_ID,
  SESSION_ROLE,
} from "../../../utils/sessionStorageContants";
import Select from "react-select";
import { EMPLOYEE } from "../../../utils/apiConstants";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import Nodata from "../../../Components/NoData/Nodata";

function IssueItem({ setLoading, collegeId }) {
  const [employee] = useEmployee(collegeId);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());
  const department_id = sessionStorage.getItem("department_id");
  const college_id = sessionStorage.getItem("employee_id");

  // const userRole = sessionStorage.getItem(SESSION_ROLE);
  const userRole = sessionStorage.getItem("employee_id");
  console.log("userRole -", userRole);
  const [user, setUser] = useState({
    issue_usertype_id: "",
    issue_to_id: "",
    issue_by_id: userRole,
    issue_by_department: department_id,
    // issue_date: "",
    issue_date: new Date().toISOString().split("T")[0], // Set default to current date
    return_date: "",
    issue_note: "",
    issue_itemcategory_id: "",
    issue_item_id: "",
    issue_quantity: "",
    college_id: "",
  });
  const [data, setData] = useState([]);
  const [itemCategory, setItemCategory] = useState([]);
  const [items, setItems] = useState([]);
  const [itemid, setItemid] = useState();
  const [stocks, setStocks] = useState();
  const [issueItems, setIssueItems] = useState([]);

  const [edit, setEdit] = useState(0);
  const [editId, setEditId] = useState();

  const handleCollegeChange = (selectedOption) => {
    setUser((prevUser) => ({
      ...prevUser,
      college_id: selectedOption?.value,
    }));
  };

  const [employeeOpt, setEmployeeOpt] = useState([]);

  const [employeeId, setEmployeeId] = useState("");

  const getEmployeeData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${EMPLOYEE}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        setEmployeeOpt(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    getEmployeeData();
  }, [user?.college_id]);

  const getInventoryItemsData = async () => {
    setLoading(1);

    const config = {
      method: "get",
      url: `${INVENTORY_ISSUE_ITEM}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        const filteredData = res.data.data?.filter(
          (s) => s?.fetchIssueItem.item_status == "Issued"
        );
        // setIssueItems(res.data.data);
        setIssueItems(filteredData);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInventoryItemsData();
  }, []);

  const clearData = () => {
    setUser({
      issue_usertype_id: "",
      issue_to_id: "",
      issue_by_id: userRole,
      issue_date: "",
      return_date: "",
      issue_note: "",
      issue_itemcategory_id: "",
      issue_item_id: "",
      issue_quantity: "",
      college_id: "",
      issue_by_department: "",
    });
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   setUser((prevValue) => ({
  //     ...prevValue,
  //     [name]: value,
  //   }));

  //   console.log(user);
  // };

  const handleEdit = () => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${INVENTORY_ISSUE_ITEM}/${editId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        getdata();
        clearData();
        toast.success("Success");
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
      });
  };

  const getdata = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_ISSUE_ITEM_BYID}/${user?.issue_item_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        setLoading(0);

        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
        // toast.error("Something Went Wrong");
      });

    const config1 = {
      method: "get",
      url: `${INVENTORY_GET_ITEM_BYID}/${user?.issue_item_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        console.log(res.data.data);
        // setStocks(res.data.data[0].add_item_stock);
        setStocks(res.data.data[0]);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
        // toast.error("Something Went Wrong");
      });
  };

  // get Item Category

  const getdataItemCategory = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios({
      ...config,
      url: `${INVENTORY_ITEM_CATEGORY}`,
    })
      .then((res) => {
        setLoading(0);
        setItemCategory(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Somethin went wrong");
      });
  };

  useEffect(() => {
    getdataItemCategory();
  }, []);

  // useEffect(() => {
  //   if (user?.issue_itemcategory_id) getdata();
  //   else setData([]);
  // }, [user?.issue_itemcategory_id]);

  useEffect(() => {
    getdata();
  }, []);

  // get ItemS

  const getdataItems = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios({
      ...config,
      url: `${INVENTORY_ADD_ITEM}`,
    })
      .then((res) => {
        setLoading(0);
        setItems(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Somethin went wrong");
      });
  };

  useEffect(() => {
    getdataItems();
  }, []);

  useEffect(() => {
    if (user?.issue_item_id) getdata();
    else setData([]);
  }, [user?.issue_item_id]);

  const handleSubmit = async () => {
    if (!user.college_id) return toast.error("Please Select Faculty");
    if (!user.issue_to_id)
      return toast.error("Please Select Employee To Issue Item");
    if (!user.issue_date) return toast.error("Please Select Issue Date");
    if (!user.issue_itemcategory_id)
      return toast.error("Please Select Item Category");
    if (!user.issue_item_id) return toast.error("Please Select Item");
    if (!user.issue_quantity) return toast.error("Please Select Quantity");

    setLoading(1); // Start loading

    const config = {
      method: "post",
      url: `${INVENTORY_ISSUE_ITEM}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        issue_by_id: userRole,
      },
    };

    try {
      // Submit the form
      const res = await axios(config);
      console.log(res);

      toast.success("Success");
      getdata(); // Fetch any additional data if necessary

      // Fetch the updated inventory items
      await getInventoryItemsData();

      // Wait for a short delay to ensure the state is updated before printing
      setTimeout(() => {
        handlePrint(); // Trigger the print after the delay
      }, 1000); // 1 second delay to allow state update
    } catch (err) {
      console.log(err);
      toast.error("Something Went Wrong");
    } finally {
      setLoading(0); // Stop loading after the request finishes
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleCategoryChange = (selectedOption) => {
    setUser((prevState) => ({
      ...prevState,
      issue_itemcategory_id: selectedOption ? selectedOption.value : null, // Store selected value
    }));
  };

  const handleItemChange = (selectedOption) => {
    setUser((prevState) => ({
      ...prevState,
      issue_item_id: selectedOption ? selectedOption.value : null, // Store selected value
    }));
  };

  // Convert item categories and items into options for react-select
  const itemCategoryOptions = itemCategory?.map((i) => ({
    value: i.id,
    label: i.item_category_name,
  }));

  const itemOptions = items?.map((i) => ({
    value: i.addItem.id,
    label: i.addItem.item_name,
  }));

  const issuedItems =
    issueItems?.length > 0
      ? issueItems
          .filter((item) => {
            return item.fetchIssueItem?.issue_item_id == user?.issue_item_id; // Loose comparison
          })
          .reduce((total, currentItem) => {
            // console.log("Current Item Structure:", currentItem);
            const issueQuantity =
              currentItem?.fetchIssueItem?.issue_quantity || 0; // Default to 0 if no quantity
            // console.log("Adding issue quantity:", issueQuantity); // Debugging the quantity being added
            return total + issueQuantity;
          }, 0) // Start with 0 as the initial value for the sum
      : 0;

  // Calculate available quantity by subtracting issuedItems from the stock quantity
  const availableQuantity = stocks?.add_item_stock
    ? stocks.add_item_stock - issuedItems
    : 0; // Default to 0 if stock data is not available

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "issue_quantity") {
      const quantity = parseInt(value, 10);
      if (quantity > availableQuantity) {
        toast.error(`Available quantity is only ${availableQuantity}`);
        return;
      }
    }

    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  ///////////Print OPTION///////////////

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    const formattedTime = `${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
    return `${formattedDate} ${formattedTime}`;
  };

  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Issue Item List-" + new Date().getFullYear(),
    sheet: "Issue Item List",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  // console.log("dataOne -", dataOne);
  console.log("user.issue_item_id -", user?.issue_item_id);
  console.log("issuedItems -", issuedItems);
  console.log("availableQuantity -", availableQuantity);

  // console.log("stocks -", stocks);
  // console.log("issueItems -", issueItems);
  // console.log("user -", user);

  return (
    <div ClassName="IssueItem">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Issue Item</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Inventory</a>
                      </li>
                      <li className="breadcrumb-item active"> Issue Item </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Creteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Select Faculty
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <Select
                            className="form-group"
                            name="college_id"
                            value={collegeOpt.find(
                              (option) => option.value === user?.college_id
                            )}
                            onChange={handleCollegeChange}
                            options={[
                              ...collegeOpt.map((i) => {
                                return {
                                  value: i.id,
                                  label: `${i.name}`,
                                };
                              }),
                            ]}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Issue To<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            name="issue_to_id"
                            className="basic-single"
                            classNamePrefix="select"
                            options={[
                              ...employeeOpt
                                ?.filter(
                                  (s) => s.college_id == user?.college_id
                                )
                                ?.map((i) => ({
                                  label: i.first_name + " " + i.last_name,
                                  value: i.id,
                                })),
                            ]}
                            value={
                              user?.issue_to_id
                                ? {
                                    label:
                                      employeeOpt.find(
                                        (i) => i.id == user?.issue_to_id
                                      )?.first_name +
                                      " " +
                                      employeeOpt.find(
                                        (i) => i.id == user?.issue_to_id
                                      )?.last_name,
                                    value: user?.issue_to_id,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              setUser((prevState) => ({
                                ...prevState, // maintain the previous state
                                issue_to_id: selectedOption
                                  ? selectedOption.value
                                  : "",
                              }));
                            }}
                            placeholder="Select Employee"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Issue Date <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            name="issue_date"
                            id="issue_date"
                            value={user?.issue_date}
                            placeholder="Enter Email Address"
                            required=""
                            wtx-context="0879542D-53FF-42C4-8360-7D9F79864275"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Note</label>
                          <textarea
                            onChange={handleChange}
                            name="issue_note"
                            id=""
                            cols={1}
                            rows={1}
                            className="form-control"
                            placeholder="Enter Note"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Item Category{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            name="issue_itemcategory_id"
                            id="issue_itemcategory_id"
                            value={itemCategoryOptions.find(
                              (option) =>
                                option.value === user.issue_itemcategory_id
                            )}
                            onChange={handleCategoryChange}
                            options={itemCategoryOptions}
                            placeholder="Select Item Category"
                            classNamePrefix="react-select"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Item <span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            name="issue_item_id"
                            id="issue_item_id"
                            value={itemOptions.find(
                              (option) => option.value === user.issue_item_id
                            )}
                            onChange={handleItemChange}
                            options={itemOptions}
                            placeholder="Select Item"
                            classNamePrefix="react-select"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Quantity<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="issue_quantity"
                            value={user?.issue_quantity}
                            id="quantity"
                            onChange={handleChange}
                            placeholder="Enter Quantity"
                            required=""
                            wtx-context="6F709C20-DB87-42D7-9A4D-2D16DB018B82"
                          />
                          <small>Available : {availableQuantity}</small>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 mt-2">
                        {edit ? (
                          <button
                            className="btn btn-nex btn-rounded float-right  "
                            type="submit"
                            name="submit"
                            onClick={(e) => handleEdit(e)}
                          >
                            Save Changes
                          </button>
                        ) : (
                          <button
                            className="btn btn-nex btn-rounded float-right  "
                            type="submit"
                            name="submit"
                            onClick={(e) => handleSubmit(e)}
                            // onClick={(e) => {
                            //   handleSubmit(e);
                            //   handlePrint();
                            // }}
                          >
                            <i className="fa fa-save" aria-hidden="true" /> Save
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>
            <div style={{ display: "none" }}>
              <table
                className="table table-bordered table-hover mt-2"
                ref={tableRef}
              >
                <thead>
                  <tr>
                    <th colSpan={10}>
                      <div className="row">
                        <div className="col-12">
                          <p className="text-dark float-right">
                            Printed On - {getCurrentDate()}
                          </p>
                        </div>
                        <div className="col-12">
                          <h3 className="text-primary text-center">
                            <img
                              className=""
                              src="../../../assets/images/Nexenstial Logo.jpg"
                              width="80px"
                              height="60px"
                            />
                            <span className="ml-3">
                              {
                                collegeOpt?.find((s) => s.id == college_id)
                                  ?.name
                              }
                            </span>
                          </h3>
                        </div>
                        <div className="col-12 mt-4 text-center">
                          <h4>ISSUED ITEMS LIST {"  "} </h4>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th> Sl. No.</th>
                    <th>Item</th>
                    <th>Item Category</th>
                    <th>Issue Date</th>
                    <th>Issue To</th>
                    <th>Issue By</th>
                    <th>Quantity</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {issueItems && issueItems.length !== 0 ? (
                    issueItems?.map((data, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>{data?.addItem.item_name}</td>
                        <td> {data?.itemCategory.item_category_name}</td>
                        <td>
                          {" "}
                          {data?.fetchIssueItem.issue_date?.split("T")[0]}
                        </td>

                        <td>
                          {data?.issueTo?.first_name +
                            " " +
                            data?.issueTo?.last_name}
                        </td>

                        <td>
                          {data?.issueBy?.first_name +
                            " " +
                            data?.issueBy?.last_name}
                        </td>
                        <td> {data?.fetchIssueItem.issue_quantity}</td>
                        <td>
                          {" "}
                          <span> {data?.fetchIssueItem.item_status}</span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      {" "}
                      <td colSpan={8}>
                        <div align="center">
                          <Nodata />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IssueItem;

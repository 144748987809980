import React from "react";
import { useEffect } from "react";

function DocumentsModal({ title, img, setLink }) {
  let extension = img?.slice(img?.length - 3);
  console.log("imgInModal -", img);
  // useEffect(() => {
  //   console.log("Document Modal Opened with img -", img);

  //   // Listen for Bootstrap modal 'shown' event to trigger state updates
  //   const modalElement = document.getElementById("DocumentsModal");

  //   const handleShown = () => {
  //     console.log("Modal shown, img:", img);
  //   };

  //   // Add the event listener when the modal is shown
  //   modalElement?.addEventListener("shown.bs.modal", handleShown);

  //   // Cleanup event listener on unmount
  //   return () => {
  //     modalElement?.removeEventListener("shown.bs.modal", handleShown);
  //   };
  // }, [img, title]);
  return (
    <div
      className="modal fade text-left"
      id="DocumentsModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ zIndex: 1060 }} // Adjust the z-index for DocumentsModal
    >
      <div
        className="modal-dialog modal-dialog-centered mw-100 w-75"
        role="document"
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {title}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => {
                  setLink("");
                }}
              >
                ×
              </span>
            </button>
          </div>
          <div className="modal-body text-center">
            {/* {extension == "pdf" ? (
              <object
                data={img}
                type="application/pdf"
                width="100%"
                height="600px"
              ></object>
            ) : (
              <img src={img} width={500} alt="Image" />
            )} */}
            {extension == "png" || extension == "jpg" || extension == "jpeg" ? (
              <div className="d-flex justify-content-center">
                <div style={{ width: "500px" }}>
                  <img src={img} width={"100%"} height={"100%"} alt="Image" />
                </div>
              </div>
            ) : (
              // <object
              //   data={img}
              //   type="application/pdf"
              //   width="100%"
              //   height="600px"
              // ></object>
              <iframe
                src={img}
                width="100%"
                height="600px"
                title="PDF Document"
                style={{ border: "none" }}
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentsModal;

import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Popconfirm, Table } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { toast } from "react-toastify";
import { NAAC } from "../../../../utils/apiConstants";

export default function ChairsInstituted({ setUpdatedData, setLoading }) {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const [badge, setBadge] = useState("");
  const emp_id = sessionStorage.getItem("employee_id");

  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleAdd = () => {
    form.resetFields();
    setEditingRecord(null);
    setIsModalVisible(true);
  };
  const handleDelete = (identifier) => {
    const updatedData = data.filter((item) => {
      if (item.id === identifier) {
        item.status = "INACTIVE";
        return true;
      }
      return item.tempId !== identifier;
    });

    setData(updatedData);
  };

  const getFormData = async () => {
    setLoading(1);

    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/chairs-instituted/1`,
    };
    await axios(config)
      .then((res) => {
        setData(res.data);
        let fetchedData = res.data;
        setBadge(() => {
          if (
            fetchedData?.length > 0 &&
            fetchedData.every((item) => item.status === "REVIEW")
          ) {
            return "REVIEW";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "DRAFT")
          ) {
            return "DRAFT";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "APPROVED")
          ) {
            return "APPROVED";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "REJECTED")
          ) {
            return "REJECTED";
          }
        });
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  useEffect(() => {
    getFormData();
  }, []);

  const handleSave = () => {
    form.validateFields().then((values) => {
      if (editingRecord) {
        setData(
          data.map((item) =>
            (item.id && item.id === editingRecord.id) ||
            (item.tempId && item.tempId === editingRecord.tempId)
              ? { ...item, ...values, updated_by: emp_id }
              : item
          )
        );
      } else {
        setData([
          ...data,
          {
            ...values,
            tempId: uuidv4(),
            created_by: emp_id,
            updated_by: emp_id,
            university_profile_id: 1,
            status: "DRAFT",
          },
        ]);
      }
      setIsModalVisible(false);
    });
  };
  const handleFormSubmission = async (status) => {
    if (data.length === 0) {
      return toast.error("Please add at least one teaching faculty detail");
    }

    // Separate data for backend operations
    const toDelete = data.filter(
      (item) => item.status === "INACTIVE" && item.id
    );
    const toUpdate = data.filter(
      (item) => item.status !== "INACTIVE" && item.id
    );
    const toCreate = data.filter((item) => !item.id);

    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/chairs-instituted`,
      data: { toDelete, toUpdate, toCreate, status },
    };

    try {
      const res = await axios(config);
      toast.success(
        status === "REVIEW"
          ? "Successfully submitted for review"
          : "Successfully saved as draft"
      );
      setUpdatedData(uuidv4());
      getFormData();
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    } finally {
      setLoading(0);
    }
  };

  const handleSaveAsDraft = (e) => {
    e.preventDefault();
    handleFormSubmission("DRAFT");
  };

  const handleSaveAndSubmitForReview = (e) => {
    e.preventDefault();
    handleFormSubmission("REVIEW");
  };

  const columns = [
    {
      title: "Sl. No.",

      key: "sl_no",
      render: (_, record, index) => <p>{index + 1}</p>,
    },
    {
      title: "Name of the Department",
      dataIndex: "department_name",
      key: "department_name",
    },
    {
      title: "Name of the Chair",
      dataIndex: "chair_name",
      key: "chair_name",
    },
    {
      title: "Name of the Sponsor Organization/Agency",
      dataIndex: "sponsor_organization",
      key: "sponsor_organization",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div className="d-flex gap-1">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Delete this record?"
            onConfirm={() => handleDelete(record.id || record.tempId)}
            okText="Yes"
            cancelText="No"
            placement="topRight"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <>
      <Modal
        title={editingRecord ? "Edit Chair Information" : "Add New Chair"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSave}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Name of the Department"
            name="department_name"
            rules={[
              { required: true, message: "Please enter the department name" },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="Name of the Chair"
            name="chair_name"
            rules={[{ required: true, message: "Please enter the chair name" }]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="Name of the Sponsor Organization/Agency"
            name="sponsor_organization"
            rules={[
              {
                required: true,
                message: "Please enter the sponsor organization",
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
        </Form>
      </Modal>

      <div className="d-flex justify-content-between align-items-start mb-3">
        <div className="d-flex flex-column gap-2">
          <h5 className="mb-0">Chairs Instituted by the University</h5>
          <span
            className={`text-white d-inline-block text-center px-4 py-1 shadow-sm text-xs rounded-pill ${
              badge === "DRAFT"
                ? "bg-primary"
                : badge === "REVIEW"
                ? "bg-warning"
                : badge === "REJECTED"
                ? "bg-danger"
                : badge === "APPROVED"
                ? "bg-success"
                : "bg-secondary"
            }`}
            style={{ width: "fit-content" }}
          >
            {badge === "DRAFT"
              ? "In Draft"
              : badge === "REVIEW"
              ? "Submitted for review"
              : badge === "REJECTED"
              ? "Rejected"
              : badge === "APPROVED"
              ? "Approved"
              : "Not Started"}
          </span>
        </div>
        <Button type="primary" onClick={handleAdd} icon={<PlusOutlined />}>
          Add New Row
        </Button>
      </div>

      <div className="table-responsive shadow-sm">
        <Table
          className="table table-bordered"
          dataSource={data.filter((item) => item.status !== "INACTIVE")}
          columns={columns}
          rowKey={(record) => record.id || record.tempId}
          pagination={false}
        />
      </div>

      <div className="d-flex justify-content-end gap-2 my-3">
        <button className="btn btn-primary" onClick={handleSaveAsDraft}>
          Save as Draft
        </button>
        <button
          className="btn btn-warning"
          onClick={handleSaveAndSubmitForReview}
        >
          Submit for Review
        </button>
      </div>
    </>
  );
}

import { setRef } from "@fullcalendar/core";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { toast } from "react-toastify";
import Nodata from "../../../../Components/NoData/Nodata";
import useEmployee from "../../../../Hooks/Employee/useEmployee";
import ModalCustomApprover from "../../../../modals/HR/Employer/ModalCustomApprover";
import {
  CUSTOM_APPROVER,
  EMPLOYEE,
  EMPLOYEE_SQL,
} from "../../../../utils/apiConstants";
import { LOCAL_COLLEGE } from "../../../../utils/LocalStorageConstants";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";

function LeaveCustomApprover({ setLoading, collegeId }) {
  // const [employeeOpt] = useEmployee(collegeId)
  const [employeeOpt, setEmployeeOpt] = useState([]);
  const [employee, setEmployee] = useState();

  const [data, setData] = useState([]);

  const [type, setType] = useState("");

  const [edit, setEdit] = useState("");

  const [role, setRole] = useState(
    sessionStorage.getItem("role") ? sessionStorage.getItem("role") : null
  );

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${CUSTOM_APPROVER}?college_id=${collegeId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
    setLoading(0);
  };

  const getEmployee = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE_SQL}?college_id=${collegeId}&status=ACTIVE`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        console.log("employees - ", res.data.data);
        let temp = [];
        let employee = res.data.data;
        employee?.map((i, key) => {
          let obj = {};
          obj["value"] = i?.id;
          obj["label"] =
            i?.first_name + " " + i?.last_name + " (" + i?.role + ")";
          temp.push(obj);
        });
        setEmployeeOpt(temp);
        console.log("emp opt - ", temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployee();
    getData();
  }, []);

  useEffect(() => {
    setRole(
      sessionStorage.getItem("role") ? sessionStorage.getItem("role") : null
    );
  }, []);

  const tableRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: " Leave Custom Approver List ",
    sheet: "Users",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  //   const handlePrint = () => {
  //     PrintRecipt();
  //   };

  const handlePrint = () => {
    // Make the table visible
    tableRef.current.style.display = "table";

    // Delay the PDF export
    setTimeout(() => {
      PrintRecipt();
      // Hide the table again if needed
      tableRef.current.style.display = "none";
    }, 1); // Adjust the delay as needed
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  return (
    <div className="LeaveCustomApprover">
      <ModalCustomApprover
        employeeOpt={employeeOpt}
        setLoading={setLoading}
        collegeId={collegeId}
        reloadData={getData}
        data={edit}
        type={type}
      />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Custom Approver</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/">Employer</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Custom Approver
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}

            <div className="container">
              <div className="card">
                <div className="card-body">
                  <div className="row d-flex justify-content-end  p-3">
                    {role !== "ADMIN" ? (
                      <button
                        className="btn btn-rounded btn-success btn-outline px-4"
                        data-toggle="modal"
                        data-target="#ModalCustomApprover"
                        onClick={() => {
                          setEdit();
                          setType("add");
                        }}
                      >
                        Add +
                      </button>
                    ) : null}
                    <button
                      className="btn btn-primary rounded-pill ml-3 float-right"
                      onClick={onDownload}
                    >
                      Export Excel
                    </button>
                    <button
                      className="btn btn-primary rounded-pill ml-2"
                      onClick={handlePrint}
                    >
                      Export PDF
                    </button>
                  </div>

                  <div>
                    {data && data?.length !== 0 ? (
                      data.map((i, key) =>
                        role !== "ADMIN" ? (
                          <div
                            className="row my-3 mx-2 p-3 border rounded role-div flex-nowrap shadow"
                            data-toggle="modal"
                            data-target="#ModalCustomApprover"
                            onClick={() => {
                              setEdit(i);
                              setType("edit");
                            }}
                          >
                            <div className="col-11" key={key}>
                              <div className="role-title">{i.name}</div>
                              <div className="role-code">
                                {
                                  employeeOpt?.find(
                                    (s) => s.value == i?.employee_id
                                  )?.label
                                }
                              </div>
                            </div>
                            <div className="col-1 d-flex align-items-center justify-content-end">
                              {">"}
                            </div>
                          </div>
                        ) : (
                          // <div
                          //   className="row my-3 mx-2 p-3 border rounded role-div flex-nowrap shadow"
                          //   // data-toggle="modal"
                          //   // data-target="#ModalCustomApprover"
                          //   // onClick={role !== 'ADMIN' ? ()=>{ setEdit(i);setType('edit')} : null}
                          // >
                          <div
                            className="row my-3 mx-2 p-3 border rounded role-div flex-nowrap shadow"
                            data-toggle="modal"
                            data-target="#ModalCustomApprover"
                            onClick={() => {
                              setEdit(i);
                              setType("edit");
                            }}
                          >
                            <div className="col-11" key={key}>
                              <div className="role-title">{i.name}</div>
                              <div className="role-code">
                                {
                                  employeeOpt?.find(
                                    (s) => s.value == i?.employee_id
                                  )?.label
                                }
                              </div>
                            </div>
                            <div className="col-1 d-flex align-items-center justify-content-end">
                              {">"}
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <Nodata />
                    )}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <table
                id="table_id"
                ref={tableRef}
                className="display table table-bordered  nowrap table-hover "
                style={{
                  borderCollapse: "collapse",
                  borderSpacing: 0,
                  width: "100%",
                  display: "none",
                }}
              >
                <div className="col-12 text-center">
                  {/* {data.length > 0 && (
    <div>
      <h4 className="text-center">
      Leave Custom Approver List -
        {data[0]?.college_id && collegeOpt?.find((s) => s.id === data[0]?.college_id)?.name}
      </h4>
     <span> <p className="text-right float-right"> Printed On - {" " + getCurrentDate()} </p></span>
    </div>
)} */}
                  <thead>
                    <tr>
                      <td colSpan={10}>
                        <div>
                          <h4 className="text-center">
                            Leave Custom Approver List -{" "}
                            {data?.length > 0 &&
                              data[0]?.college_id &&
                              collegeOpt?.find(
                                (s) => s.id === data[0]?.college_id
                              )?.name}
                          </h4>
                          <span>
                            <p className="text-right float-right">
                              Printed On - {getCurrentDate()}
                            </p>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Sl.No.</th>
                      <th>Custom Approver</th>
                      <th>Employee Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length == 0 ? (
                      <tr>
                        <td colSpan={10}>
                          <Nodata />
                        </td>
                      </tr>
                    ) : (
                      data.map((i, key) => {
                        return (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{i?.name}</td>
                            <td>
                              {
                                employeeOpt?.find(
                                  (s) => s.value == i?.employee_id
                                )?.label
                              }
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </div>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaveCustomApprover;

import React from "react";

export default function StatusBadges() {
  return (
    <div className="d-flex justify-content-end gap-3 flex-wrap">
      <div className="d-flex gap-1 align-items-center">
        <div
          className="bg-secondary rounded-circle me-2"
          style={{ width: "12px", height: "12px" }}
        ></div>
        <span>In Progress</span>
      </div>
      <div className="d-flex gap-1 align-items-center">
        <div
          className="bg-primary rounded-circle me-2"
          style={{ width: "12px", height: "12px" }}
        ></div>
        <span>In Draft</span>
      </div>
      <div className="d-flex gap-1 align-items-center">
        <div
          className="bg-danger rounded-circle me-2"
          style={{ width: "12px", height: "12px" }}
        ></div>
        <span>Rejected</span>
      </div>
      <div className="d-flex gap-1 align-items-center">
        <div
          className="bg-warning rounded-circle me-2"
          style={{ width: "12px", height: "12px" }}
        ></div>
        <span>Submitted</span>
      </div>
      <div className="d-flex gap-1 align-items-center">
        <div
          className="bg-success rounded-circle me-2"
          style={{ width: "12px", height: "12px" }}
        ></div>
        <span>Approved</span>
      </div>
    </div>
  );
}

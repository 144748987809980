import React from "react";
import { Link, useLocation } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Stepper({ data }) {
  const location = useLocation();
  const currentPathname = location.pathname;

  const updatedSteps = data.map((item) => ({
    ...item,
    current: item.href === currentPathname,
  }));

  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="d-flex align-items-center justify-content-start justify-content-lg-center list-unstyled overflow-auto py-3"
      >
        {updatedSteps.map((step, stepIdx) => (
          <li
            key={step.id}
            className={classNames(
              stepIdx !== updatedSteps.length - 1 ? "pe-2" : "",
              "position-relative",
              "mx-2"
            )}
            // onClick={() => setUpdateData("hi")}
          >
            {step.current ? (
              // Current Step Styling
              <>
                <div
                  className="position-absolute top-50 start-0 end-0 d-flex align-items-center"
                  aria-hidden="true"
                >
                  <div className="w-100 border-top border-2 border-gray" />
                </div>
                <Link
                  to={step.href}
                  className="position-relative d-flex align-items-center justify-content-center bg-secondary rounded-circle border border-2 text-white"
                  style={{ width: "32px", height: "32px" }}
                  aria-current="step"
                >
                  <span>{step.id}</span>
                </Link>
              </>
            ) : step.status === "APPROVED" ? (
              // Approved Step Styling
              <>
                <div
                  className="position-absolute top-50 start-0 end-0 d-flex align-items-center"
                  aria-hidden="true"
                >
                  <div className="w-100 h-100 border-top border-2 border-success" />
                </div>
                <Link
                  to={step.href}
                  className="position-relative d-flex align-items-center justify-content-center bg-success rounded-circle border border-success text-white"
                  style={{ width: "32px", height: "32px" }}
                >
                  <span>{step.id}</span>
                </Link>
              </>
            ) : step.status === "DRAFT" ? (
              // Draft Step Styling
              <>
                <div
                  className="position-absolute top-50 start-0 end-0 d-flex align-items-center"
                  aria-hidden="true"
                >
                  <div className="w-100 h-100 border-top border-2 border-primary" />
                </div>
                <Link
                  to={step.href}
                  className="position-relative d-flex align-items-center justify-content-center bg-primary rounded-circle border border-2 border-primary text-white"
                  style={{ width: "32px", height: "32px" }}
                >
                  <span>{step.id}</span>
                </Link>
              </>
            ) : step.status === "REJECTED" ? (
              // Rejected Step Styling
              <>
                <div
                  className="position-absolute top-50 start-0 end-0 d-flex align-items-center"
                  aria-hidden="true"
                >
                  <div className="w-100 border-top border-2 border-danger" />
                </div>
                <Link
                  to={step.href}
                  className="position-relative d-flex align-items-center justify-content-center bg-danger rounded-circle border border-2 border-danger text-white"
                  style={{ width: "32px", height: "32px" }}
                >
                  <span>{step.id}</span>
                </Link>
              </>
            ) : step.status === "REVIEW" ? (
              // Submitted Step Styling
              <>
                <div
                  className="position-absolute top-50 start-0 end-0 d-flex align-items-center"
                  aria-hidden="true"
                >
                  <div className="w-100 border-top border-2 border-warning" />
                </div>
                <Link
                  to={step.href}
                  className="position-relative d-flex align-items-center justify-content-center bg-warning rounded-circle border border-2 border-warning text-white"
                  style={{ width: "32px", height: "32px" }}
                >
                  <span>{step.id}</span>
                </Link>
              </>
            ) : (
              // Upcoming or Not Started Step Styling
              <>
                <div
                  className="position-absolute top-50 start-0 end-0 d-flex align-items-center"
                  aria-hidden="true"
                >
                  <div className="w-100 border-top border-2 border-secondary" />
                </div>
                <Link
                  to={step.href}
                  className="position-relative d-flex align-items-center justify-content-center bg-light border border-secondary text-secondary rounded-circle"
                  style={{ width: "32px", height: "32px" }}
                >
                  <span>{step.id}</span>
                </Link>
              </>
            )}
            {/* Step Label */}
            <div className="mt-2 text-center">
              <span className="small">{step.name}</span>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { INVENTORY_PURCHASE_ORDER } from "../../../utils/Inventory.apiConst";
import { toast } from "react-toastify";
import { MdOutlineDelete, MdOutlineEdit, MdMailOutline } from "react-icons/md";
import { FaRegFilePdf } from "react-icons/fa";
import { LuFileSymlink } from "react-icons/lu";
import { Switch } from "antd";
const ViewPurchaseOrders = ({ setLoading }) => {
  const [data, setData] = useState([]);
  const [dataById, setDataById] = useState([]);
  let { id } = useParams();
  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_PURCHASE_ORDER}/menu-items`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        console.log("Menu Items Data", res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  const getDataById = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_PURCHASE_ORDER}/id/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        console.log("data by id", res.data.data);
        setDataById(res.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  useEffect(() => {
    getData();
    getDataById();
  }, []);
  useEffect(() => {
    getDataById();
  }, [id]);
  const tableHead = [
    "Items & Description",
    "Ordered",
    "Status",
    "Rate",
    "Amount",
  ];
  console.log("dataById", dataById);
  return (
    <div className="ViewPurchaseOrders">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="card ">
                  <div className="card-body">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-3 border-right table-responsive overflow-scroll">
                          <table className="table table-hover table-bordered ">
                            <tbody>
                              {data && data.length > 0
                                ? data.map((item, i) => (
                                    <tr
                                      className={`   ${
                                        id == item?.id ? "bg-light" : ""
                                      }`}
                                      key={i}
                                    >
                                      <td>
                                        <Link
                                          to={`/registrar/PurchaseOrder/view/details/${item?.id}`}
                                          className="d-flex flex-column gap-2"
                                          style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                          }}
                                        >
                                          <div className="d-flex justify-content-between align-items-center">
                                            <h6>{item?.vendor_name}</h6>
                                            <h6>Rs. {item?.total}</h6>
                                          </div>
                                          <div className="d-flex justify-content-between align-items-center">
                                            <span>{item?.purchase_order}</span>
                                            <span>{item?.date}</span>
                                          </div>
                                          <span>{item?.status}</span>
                                        </Link>
                                      </td>
                                    </tr>
                                  ))
                                : ""}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-9">
                          <h5>{dataById?.purchase_order}</h5>
                          <div className="bg-light d-flex align-items-center">
                            <button className="btn btn-link d-flex gap-1 align-items-center">
                              <MdOutlineEdit />
                              Edit
                            </button>
                            <div
                              className="vr mx-1"
                              style={{
                                height: "24px",
                                borderLeft: "1px solid #000",
                              }}
                            ></div>
                            <button className="btn btn-link d-flex gap-1 align-items-center">
                              <MdOutlineDelete />
                              Delete
                            </button>
                            <div
                              className="vr mx-1"
                              style={{
                                height: "24px",
                                borderLeft: "1px solid #000",
                              }}
                            ></div>
                            <button className="btn btn-link d-flex gap-1 align-items-center">
                              <MdMailOutline />
                              Send Mail
                            </button>
                            <div
                              className="vr mx-1"
                              style={{
                                height: "24px",
                                borderLeft: "1px solid #000",
                              }}
                            ></div>
                            <button className="btn btn-link d-flex gap-1 align-items-center">
                              <FaRegFilePdf />
                              Print
                            </button>
                            <div
                              className="vr mx-1"
                              style={{
                                height: "24px",
                                borderLeft: "1px solid #000",
                              }}
                            ></div>
                            <button className="btn btn-link d-flex gap-1 align-items-center">
                              <LuFileSymlink />
                              Receive
                            </button>
                          </div>
                          <div className="m-4">
                            <div className="d-flex justify-content-end align-items-center gap-2">
                              show PDF view <Switch />
                            </div>
                            <div className="row p-4">
                              <div className="col-lg-7 col-md-6">
                                <div className="d-flex flex-column gap-3">
                                  <div>
                                    <h4>Purchase Order</h4>
                                    <div>
                                      <p>Purchase Order#</p>{" "}
                                      <span>
                                        <strong>
                                          {dataById?.purchase_order}
                                        </strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    style={{ width: "fit-content" }}
                                    className="badge badge-secondary"
                                  >
                                    {dataById?.status}
                                  </div>
                                  <div className="row py-3 gap-1">
                                    <div className="col-md-4">
                                      <h6 className="text-uppercase">
                                        Reference#
                                      </h6>
                                    </div>
                                    <div className="col-md-6">
                                      {dataById?.reference}
                                    </div>
                                    <div className="col-md-4">
                                      <h6 className="text-uppercase">
                                        Order Date
                                      </h6>
                                    </div>
                                    <div className="col-md-6">
                                      {dataById?.date}
                                    </div>
                                    <div className="col-md-4">
                                      <h6 className="text-uppercase">
                                        Delivery Date
                                      </h6>
                                    </div>
                                    <div className="col-md-6">
                                      {dataById?.delivery_date}
                                    </div>
                                    <div className="col-md-4">
                                      <h6 className="text-uppercase">
                                        Payment Terms
                                      </h6>
                                    </div>
                                    <div className="col-md-6">
                                      {dataById?.payment_terms}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-5 col-md-6">
                                <div className="d-flex flex-column gap-3">
                                  <div className="mb-3">
                                    <h6 className="text-uppercase">
                                      Vendor Address
                                    </h6>
                                    <span>
                                      <strong>
                                        {dataById?.delivery_address}
                                      </strong>
                                    </span>
                                  </div>
                                  <div className="pt-3">
                                    <h6 className="text-uppercase">
                                      Delivery Address
                                    </h6>
                                    {dataById?.delivery_address}
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="table-responsive">
                                  <table className="table">
                                    <thead>
                                      {tableHead.map((item) => (
                                        <th key={item}>{item}</th>
                                      ))}
                                    </thead>
                                    <tbody>
                                      {dataById &&
                                        dataById?.items_data?.map((item, i) => (
                                          <tr>
                                            <td>{item?.itemDetails}</td>
                                            <td>{item?.quantity}</td>
                                            <td>
                                              <div className="d-flex flex-column gap-1">
                                                <span>0 Received</span>
                                                <span>0 Billed</span>
                                              </div>
                                            </td>
                                            <td>{item?.rate}</td>
                                            <td>{item?.amount}</td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div
                                style={{ marginLeft: "auto" }}
                                className="col-md-6 my-2"
                              >
                                <div className="row">
                                  <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                    <h6 className="text-uppercase ">
                                      Sub Total
                                    </h6>
                                    <div className="d-flex gap-1">
                                      <span>Total Quantity</span>
                                      <span>
                                        {dataById?.items_data?.length}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                    {dataById?.total_amount_data?.subTotal}
                                  </div>
                                  <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                    <span>Discount</span>
                                  </div>
                                  <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                    <p>
                                      {" "}
                                      {
                                        dataById?.total_amount_data?.discount
                                      } % {`=>`}{" "}
                                      <span className="text-danger">
                                        (-) Rs.
                                        {dataById?.total_amount_data
                                          ?.discountAmount ||
                                          (
                                            dataById?.total_amount_data
                                              ?.subTotal /
                                            dataById?.total_amount_data
                                              ?.discount
                                          ).toFixed(2)}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col-md-6 mb-3 text-justify d-flex flex-column align-items-end ">
                                    {dataById?.total_amount_data?.selectedTax}
                                  </div>
                                  <div className="col-md-6 text-danger mb-3 d-flex flex-column align-items-end ">
                                    (-) Rs.
                                    {
                                      dataById?.total_amount_data
                                        ?.selectedTaxAmount
                                    }
                                  </div>
                                  <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                    Adjustment
                                  </div>
                                  <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                    Rs.{" "}
                                    {
                                      dataById?.total_amount_data
                                        ?.adjustmentAmount
                                    }
                                  </div>
                                  <div
                                    className="bg-secondary w-100 mt-3 mb-2"
                                    style={{ height: "1px" }}
                                  ></div>
                                  <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                    <strong>Total</strong>
                                  </div>
                                  <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                    <strong>
                                      Rs. {dataById?.total_amount_data?.total}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPurchaseOrders;

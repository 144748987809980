import React, { useState } from "react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import SelectTag from "../../../../Components/SelectTag";
import InputTag from "../../../../Components/InputTag";

export default function MinorityRecognition() {
  return (
    <>
      <span className="font-weight-bold">5. Recognition Details</span>
      <span className="text-white whitespace-nowrap w-28 text-center bg-gray-400 px-4 py-1 shadow-sm text-xs rounded-2xl">
        Not Started
      </span>

      <form className="d-flex flex-column gap-5">
        <InputTag type="text" name="2f-of-ugc" label="2F of UGC" />
        <InputTag type="text" name="12b-of-ugc" label="12B of UGC" />

        <div className="flex justify-start my-3  mb-3">
          <button className="btn btn-primary">Save as Draft</button>
          <button className="btn btn-warning">
            <span>Submit for Review</span>
          </button>
        </div>
      </form>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../../utils/AssetsReferenceTypes";
import { getFileUrl } from "../../../../Helpers/Helpers";
import { message } from "antd";
import axios from "axios";
import { NAAC } from "../../../../utils/apiConstants";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useReactToPrint } from "react-to-print";
const toolbar = [
  "undo",
  "redo",
  "|",
  "heading",
  "|",
  "bold",
  "italic",
  "|",
  "link",
  "blockQuote",
  "insertTable",
  "|",
  "bulletedList",
  "numberedList",
  "outdent",
  "alignment",
  "indent",
  "|",
  "imageUpload",
];
const maxWords = 500;
const title = "nep_parameters";
const sub_title = "multidisciplinary";
export default function Multidisciplinary({ setLoading, setUpdatedData }) {
  const [data, setData] = useState({
    id: "",
    status: "",
    plan_of_institution_to_transform: "",
    integration_of_humanities: "",
    flexible_and_innovative_curricula: "",
    plan_for_multidisciplinary_flexible_curriculum: "",
    plans_to_engage_in_multidisciplinary_research: "",
    good_practice_of_institution_to_promote_Multidisciplinary: "",
  });
  let emp_id = sessionStorage.getItem("employee_id");
  const [allData, setAllData] = useState({});
  const [wordCount, setWordCount] = useState({
    plan_of_institution_to_transform: 0,
    integration_of_humanities: 0,
    flexible_and_innovative_curricula: 0,
    plan_for_multidisciplinary_flexible_curriculum: 0,
    plans_to_engage_in_multidisciplinary_research: 0,
    good_practice_of_institution_to_promote_Multidisciplinary: 0,
  });
  const pdfRef = useRef();
  const printPdf = useReactToPrint({
    content: () => pdfRef.current,
  });
  const handlePrint = () => {
    pdfRef.current.style.display = "block";
    setTimeout(() => {
      printPdf();
      pdfRef.current.style.display = "none";
    }, 1);
  };
  const getALlData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      //  Make this url dynamic after adding the university or college id to the university-profile table
      url: `${NAAC}/get-nep-parameters/get-all-data`,
    };

    await axios(config)
      .then((res) => {
        let data = res.data;
        if (data) {
          setAllData(data);
        }
        console.log("data fetched is", res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const countWords = (text) => {
    const words = text.trim().split(/\s+/); // Split by spaces and trim extra spaces
    return words.filter((word) => word.length > 0).length; // Filter out empty words
  };
  const handleEditorChange = (editor, key) => {
    const editorData = editor.getData();
    const currentWordCount = countWords(editorData);

    if (currentWordCount <= maxWords) {
      setData((prevValue) => ({
        ...prevValue,
        [key]: editorData,
      }));
    }
    setWordCount((prev) => ({ ...prev, [key]: currentWordCount }));
  };
  const addAttachment = async (file) => {
    try {
      const response = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `Resume`,
        file.name.split(".")[1],
        setLoading,
        file
      );
      return response;
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("File upload failed");
      throw error;
    }
  };

  const createUploadAdapter = (loader) => {
    return {
      upload: async () => {
        try {
          const file = await loader.file;
          // Directly get the uploaded file URL
          const uploadedUrl = await addAttachment(file);
          console.log("Uploaded image URL:", uploadedUrl);
          // Return the URL in the format CKEditor expects
          return { default: uploadedUrl };
        } catch (error) {
          console.error("Upload failed:", error);
          throw error;
        }
      },
    };
  };
  function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return createUploadAdapter(loader);
    };
  }
  const validateData = () => {
    const requiredFields = [
      "plan_of_institution_to_transform",
      "integration_of_humanities",
      "flexible_and_innovative_curricula",
      "plan_for_multidisciplinary_flexible_curriculum",
      "plans_to_engage_in_multidisciplinary_research",
      "good_practice_of_institution_to_promote_Multidisciplinary",
    ];

    for (const field of requiredFields) {
      if (wordCount[field] === 0) {
        toast.error(`Please fill in the ${field.split("_").join(" ")} field`);
        return false;
      }
      if (wordCount[field] > maxWords) {
        toast.error(
          `Please reduce the number of words in the ${field
            .split("_")
            .join(" ")} field`
        );
        return false;
      }
    }
    return true;
  };
  const handleSaveOrSubmit = async (e, status) => {
    let {
      plan_of_institution_to_transform,
      integration_of_humanities,
      flexible_and_innovative_curricula,
      plan_for_multidisciplinary_flexible_curriculum,
      plans_to_engage_in_multidisciplinary_research,
      good_practice_of_institution_to_promote_Multidisciplinary,
    } = data;
    let jsonResponse = JSON.stringify({
      plan_of_institution_to_transform,
      integration_of_humanities,
      flexible_and_innovative_curricula,
      plan_for_multidisciplinary_flexible_curriculum,
      plans_to_engage_in_multidisciplinary_research,
      good_practice_of_institution_to_promote_Multidisciplinary,
    });
    e.preventDefault();
    // List all required fields
    if (!validateData()) return;
    const info = {
      ...data,
      title: title,
      response: jsonResponse,
      sub_title: sub_title,
      created_by: emp_id,
      updated_by: emp_id,
      status,
      application_id: 1,
    };
    console.log("info", info);
    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/nep-parameters`,
      data: info,
    };
    await axios(config)
      .then((res) => {
        setUpdatedData(uuidv4());
        toast.success(
          `${
            status == "DRAFT"
              ? "Successfully saved as draft"
              : "Successfully submitted for review"
          } `
        );
        getFormData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const handleSaveAsDraft = (e) => {
    handleSaveOrSubmit(e, "DRAFT");
  };
  const handleSubmitForReview = (e) => {
    handleSaveOrSubmit(e, "REVIEW");
  };
  const getFormData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/nep-parameters/${sub_title}`,
    };

    await axios(config)
      .then((res) => {
        let backendData = res.data[0];
        if (data) {
          const responseObj = JSON.parse(backendData?.response || "");
          setData((prevState) => ({
            ...prevState,
            id: backendData.id,
            status: backendData.status,
            ...responseObj,
          }));
        }
        // console.log("data fetched is", res.data);
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  useEffect(() => {
    getFormData();
    getALlData();
  }, []);
  return (
    <>
      <div>
        <div className="d-flex justify-content-end">
          {data.status !== "" ? (
            <button type="button" class="btn btn-primary" onClick={handlePrint}>
              Export PDF
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex flex-column mb-3">
          <p className="h5">1. Multidisciplinary / Interdisciplinary</p>
          <span className="text-danger">
            *Note - Maximum word allowed is {maxWords} words
          </span>
        </div>
        <span
          className={`text-white d-inline-block text-center px-4 py-1 shadow-sm small rounded-pill ${
            data?.status === "DRAFT"
              ? "bg-primary"
              : data?.status === "REVIEW"
              ? "bg-warning"
              : data?.status === "REJECTED"
              ? "bg-danger"
              : data?.status === "APPROVED"
              ? "bg-success"
              : "bg-secondary"
          }`}
          style={{ width: "fit-content" }}
        >
          {data?.status === "DRAFT"
            ? "In Draft"
            : data?.status === "REVIEW"
            ? "Submitted for review"
            : data?.status === "REJECTED"
            ? "Rejected"
            : data?.status === "APPROVED"
            ? "Approved"
            : "Not Started"}
        </span>
      </div>

      <div className="mb-3">
        <p className="fw-bold">
          a) Delineate the vision/plan of institution to transform itself into a
          holistic multidisciplinary institution.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.plan_of_institution_to_transform}
          onChange={(event, editor) =>
            handleEditorChange(editor, "plan_of_institution_to_transform")
          }
        />{" "}
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.plan_of_institution_to_transform >= maxWords
                ? "text-danger"
                : ""
            }`}
          >
            {wordCount.plan_of_institution_to_transform}/{maxWords}{" "}
            {wordCount.plan_of_institution_to_transform > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div className="mb-3">
        <p className="fw-bold">
          b) Delineate the Institutional approach towards the integration of
          humanities and science with STEM and provide the detail of programs
          with combinations.
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.integration_of_humanities}
          onChange={(event, editor) =>
            handleEditorChange(editor, "integration_of_humanities")
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.integration_of_humanities >= maxWords
                ? "text-danger"
                : ""
            }`}
          >
            {wordCount.integration_of_humanities}/{maxWords}{" "}
            {wordCount.integration_of_humanities > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div className="mb-3">
        <p className="fw-bold">
          c) Does the institution offer flexible and innovative curricula that
          include credit-based courses and projects in areas such as community
          engagement and service, environmental education, and value-based
          education?
        </p>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.flexible_and_innovative_curricula}
          onChange={(event, editor) =>
            handleEditorChange(editor, "flexible_and_innovative_curricula")
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.flexible_and_innovative_curricula >= maxWords
                ? "text-danger"
                : ""
            }`}
          >
            {wordCount.flexible_and_innovative_curricula}/{maxWords}{" "}
            {wordCount.flexible_and_innovative_curricula > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div className="mb-3">
        <p className="fw-bold">
          d) What is the institutional plan for offering a multidisciplinary
          flexible curriculum that enables multiple entries and exits at the end
          of 1st, 2nd, and 3rd years of undergraduate education while
          maintaining rigor?
        </p>

        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.plan_for_multidisciplinary_flexible_curriculum}
          onChange={(event, editor) =>
            handleEditorChange(
              editor,
              "plan_for_multidisciplinary_flexible_curriculum"
            )
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.plan_for_multidisciplinary_flexible_curriculum >=
              maxWords
                ? "text-danger"
                : ""
            }`}
          >
            {wordCount.plan_for_multidisciplinary_flexible_curriculum}/
            {maxWords}{" "}
            {wordCount.plan_for_multidisciplinary_flexible_curriculum > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div className="mb-3">
        <p className="fw-bold">
          e) What are the institutional plans to engage in more
          multidisciplinary research endeavors to address pressing societal
          challenges?
        </p>

        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.plans_to_engage_in_multidisciplinary_research}
          onChange={(event, editor) =>
            handleEditorChange(
              editor,
              "plans_to_engage_in_multidisciplinary_research"
            )
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.plans_to_engage_in_multidisciplinary_research >=
              maxWords
                ? "text-danger"
                : ""
            }`}
          >
            {wordCount.plans_to_engage_in_multidisciplinary_research}/{maxWords}{" "}
            {wordCount.plans_to_engage_in_multidisciplinary_research > maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>

      <div className="mb-3">
        <p className="fw-bold">
          f) Describe any good practice/s of the institution to promote a
          Multidisciplinary / Interdisciplinary approach in view of NEP 2020.
        </p>

        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: toolbar,
            extraPlugins: [CustomUploadAdapterPlugin], // Attach the upload adapter
          }}
          data={data.good_practice_of_institution_to_promote_Multidisciplinary}
          onChange={(event, editor) =>
            handleEditorChange(
              editor,
              "good_practice_of_institution_to_promote_Multidisciplinary"
            )
          }
        />
        <div className="text-left mt-2">
          <span className="font-weight-bold">Words:</span>
          <span
            className={`${
              wordCount.good_practice_of_institution_to_promote_Multidisciplinary >=
              maxWords
                ? "text-danger"
                : ""
            }`}
          >
            {
              wordCount.good_practice_of_institution_to_promote_Multidisciplinary
            }
            /{maxWords}{" "}
            {wordCount.good_practice_of_institution_to_promote_Multidisciplinary >
            maxWords
              ? "Exceeded the word limit"
              : ""}
          </span>
        </div>
      </div>
      {data.status !== "" &&
      allData?.multidisciplinary &&
      Array.isArray(allData?.multidisciplinary) ? (
        <div ref={pdfRef} style={{ display: "none" }}>
          <div className="m-5 d-flex flex-column gap-4">
            <h3>1. Institutional preparedness for NEP</h3>
            <div>
              <table className="table table-bordered nowrap table-hover text-justify">
                <tbody>
                  <tr>
                    <th colSpan={2}>
                      <h5> Multidisciplinary/interdisciplinary</h5>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        a) Delineate the vision/plan of institution to transform
                        itself into a holistic multidisciplinary institution.
                      </strong>
                    </td>
                    <td>
                      {allData?.multidisciplinary[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.multidisciplinary[0]?.response
                            ).plan_of_institution_to_transform,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        b) Delineate the Institutional approach towards the
                        integration of humanities and science with STEM and
                        provide the detail of programs with combinations.
                      </strong>
                    </td>
                    <td>
                      {allData?.multidisciplinary[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.multidisciplinary[0]?.response
                            ).integration_of_humanities,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        c) Does the institution offer flexible and innovative
                        curricula that include credit-based courses and projects
                        in areas such as community engagement and service,
                        environmental education, and value-based education?
                      </strong>
                    </td>
                    <td>
                      {allData?.multidisciplinary[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.multidisciplinary[0]?.response
                            ).flexible_and_innovative_curricula,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        d) What is the institutional plan for offering a
                        multidisciplinary flexible curriculum that enables
                        multiple entries and exits at the end of 1st, 2nd, and
                        3rd years of undergraduate education while maintaining
                        rigor?
                      </strong>
                    </td>
                    <td>
                      {allData?.multidisciplinary[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.multidisciplinary[0]?.response
                            ).plan_for_multidisciplinary_flexible_curriculum,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        e) What are the institutional plans to engage in more
                        multidisciplinary research endeavors to address pressing
                        societal challenges?
                      </strong>
                    </td>
                    <td>
                      {allData?.multidisciplinary[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.multidisciplinary[0]?.response
                            ).plans_to_engage_in_multidisciplinary_research,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        f) Describe any good practice/s of the institution to
                        promote a Multidisciplinary / Interdisciplinary approach
                        in view of NEP 2020.
                      </strong>
                    </td>
                    <td>
                      {allData?.multidisciplinary[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.multidisciplinary[0]?.response
                            )
                              .good_practice_of_institution_to_promote_Multidisciplinary,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div>
              <table className="table table-bordered nowrap table-hover text-justify">
                <tbody>
                  <tr>
                    <th colSpan={2}>
                      <h5>2. Academic bank of credits (ABC)</h5>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        a) Describe the initiatives taken by the institution to
                        fulfil the requirement of Academic bank of credits as
                        proposed in NEP 2020.
                      </strong>
                    </td>
                    <td>
                      {allData?.academic_bank[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.academic_bank[0]?.response
                            ).initiatives_to_academic_bank,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        b) Whether the institution has registered under the ABC
                        to permit its learners to avail the benefit of multiple
                        entries and exit during the chosen programme? Provide
                        details.
                      </strong>
                    </td>
                    <td>
                      {allData?.academic_bank[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.academic_bank[0]?.response
                            ).is_registered_under_ABC,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        c) Describe the efforts of the institution for seamless
                        collaboration, internationalization of education, joint
                        degrees between Indian and foreign institutions, and to
                        enable credit transfer.
                      </strong>
                    </td>
                    <td>
                      {allData?.academic_bank[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.academic_bank[0]?.response
                            ).internationalization_of_education,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        d) How faculties are encouraged to design their own
                        curricular and pedagogical approaches within the
                        approved framework, including textbook, reading material
                        selections, assignments, and assessments etc.
                      </strong>
                    </td>
                    <td>
                      {allData?.academic_bank[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.academic_bank[0]?.response
                            ).faculties_design_own_curricular,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        e) Describe any good practice/s of the institution
                        pertaining to the implementation of Academic bank of
                        credits (ABC) in the institution in view of NEP 2020.
                      </strong>
                    </td>
                    <td>
                      {allData?.academic_bank[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.academic_bank[0]?.response
                            ).good_practice_to_pertain_ABC,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div>
              <table className="table table-bordered nowrap table-hover text-justify">
                <tbody>
                  <tr>
                    <th colSpan={2}>
                      <h5>3. Skill Development</h5>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        a) Describe the efforts made by the institution to
                        strengthen the vocational education and soft skills of
                        students in alignment with National Skills
                        Qualifications Framework.
                      </strong>
                    </td>
                    <td>
                      {allData?.skill_development[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.skill_development[0]?.response
                            ).efforts_strengthen_vocational_education,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        b) Provide the details of the programmes offered to
                        promote vocational education and its integration into
                        mainstream education.
                      </strong>
                    </td>
                    <td>
                      {allData?.skill_development[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.skill_development[0]?.response
                            ).promote_vocational_education,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        c) How the institution is providing Value-based
                        education to inculcate positivity amongst the learner
                        that includes the development of humanistic, ethical,
                        Constitutional, and universal human values of truth
                        (satya), righteous conduct (dharma), peace (shanti),
                        love (prem), nonviolence (ahimsa), scientific temper,
                        citizenship values, and life-skills.
                      </strong>
                    </td>
                    <td>
                      {allData?.skill_development[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.skill_development[0]?.response
                            ).value_based_education,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        d) How faculties are encouraged to design their own
                        curricular and pedagogical approaches within the
                        approved framework, including textbook, reading material
                        selections, assignments, and assessments etc.
                      </strong>
                    </td>
                    <td>
                      {allData?.skill_development[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.skill_development[0]?.response
                            ).list_of_institution_efforts,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        e) Describe any good practice/s of the institution
                        pertaining to the implementation of Academic bank of
                        credits (ABC) in the institution in view of NEP 2020.
                      </strong>
                    </td>
                    <td>
                      {allData?.skill_development[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.skill_development[0]?.response
                            ).skill_development,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div>
              <table className="table table-bordered nowrap table-hover text-justify">
                <tbody>
                  <tr>
                    <th colSpan={2}>
                      <h5>
                        4. Appropriate integration of Indian Knowledge System
                        (teaching in Indian Language, culture, using online
                        courses)
                      </h5>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        a) Delineate the strategy and details regarding the
                        integration of the Indian Knowledge System (teaching in
                        Indian Language, culture, etc.) into the curriculum
                        using both offline and online courses.
                      </strong>
                    </td>
                    <td>
                      {allData?.appropriate_integration[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.appropriate_integration[0]?.response
                            ).indian_knowledge_system,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        b) What are the institution's plans to train its
                        faculties to provide classroom delivery in a bilingual
                        mode (English and vernacular)? Provide the details.
                      </strong>
                    </td>
                    <td>
                      {allData?.appropriate_integration[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.appropriate_integration[0]?.response
                            ).bilingual_mode,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        c) Provide the details of the degree courses taught in
                        Indian languages and bilingually in the institution.
                      </strong>
                    </td>
                    <td>
                      {allData?.appropriate_integration[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.appropriate_integration[0]?.response
                            ).courses_in_indian_languages,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        d) Describe the efforts of the institution to preserve
                        and promote the following: <br />
                        i) Indian languages (Sanskrit, Pali, Prakrit, and
                        classical, tribal, and endangered languages, etc.){" "}
                        <br />
                        ii) Indian ancient traditional knowledge <br />
                        iii) Indian Arts <br />
                        iv) Indian Culture and traditions.
                      </strong>
                    </td>
                    <td>
                      {allData?.appropriate_integration[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.appropriate_integration[0]?.response
                            ).efforts_to_promote_indian_culture,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        e) Describe any good practice/s of the institution
                        pertaining to the appropriate integration of the Indian
                        Knowledge System (teaching in Indian Language, culture,
                        using online courses) in view of NEP 2020.
                      </strong>
                    </td>
                    <td>
                      {allData?.appropriate_integration[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.appropriate_integration[0]?.response
                            )
                              .good_practices_to_integrate_indian_knowledge_system,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table className="table table-bordered nowrap table-hover text-justify">
                <tbody>
                  <tr>
                    <th colSpan={2}>
                      <h5>5. Focus on Outcome based education (OBE)</h5>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        a) Describe the institutional initiatives to transform
                        its curriculum towards Outcome based Education (OBE).
                      </strong>
                    </td>
                    <td>
                      {allData?.outcome_based_education[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.outcome_based_education[0]?.response
                            ).outcome_based_education,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <br />
                  <br />
                  <br />
                  <tr>
                    <td>
                      <strong>
                        b) Explain the efforts made by the institution to
                        capture the Outcome based education in teaching and
                        learning practices.
                      </strong>
                    </td>
                    <td>
                      {allData?.outcome_based_education[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.outcome_based_education[0]?.response
                            ).capture_outcome_based_education,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        c) Describe any good practices of the institution
                        pertaining to the Outcome based education (OBE) in view
                        of NEP 2020.
                      </strong>
                    </td>
                    <td>
                      {allData?.outcome_based_education[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.outcome_based_education[0]?.response
                            ).good_practices_for_outcome_based_education,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table className="table table-bordered nowrap table-hover text-justify">
                <tbody>
                  <tr>
                    <th colSpan={2}>
                      <h5>6. Distance education/online education</h5>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        a) Delineate the possibilities of offering vocational
                        courses through ODL mode in the institution.
                      </strong>
                    </td>
                    <td>
                      {allData?.distance_education[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.distance_education[0]?.response
                            ).vocational_courses_through_ODL,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        b) Describe the development and use of technological
                        tools for teaching-learning activities. Provide details
                        about the institutional efforts towards blended
                        learning.
                      </strong>
                    </td>
                    <td>
                      {allData?.distance_education[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.distance_education[0]?.response
                            ).technological_tools,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        c) Describe any good practices of the institution
                        pertaining to distance education/online education in
                        view of NEP 2020.
                      </strong>
                    </td>
                    <td>
                      {allData?.distance_education[0]?.response ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              allData?.distance_education[0]?.response
                            ).good_practices_for_distance_education,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="text-right mt-5">
        <button className="btn btn-primary mr-2" onClick={handleSaveAsDraft}>
          Save as Draft
        </button>
        <button className="btn btn-warning" onClick={handleSubmitForReview}>
          <span>Submit for Review</span>
        </button>
      </div>
    </>
  );
}

let roleName = sessionStorage.getItem("role");
let role =
  roleName === "SUPERADMIN"
    ? "registarar"
    : roleName === "Pincipal" ||
      roleName === "Principal" ||
      roleName === "PRINC"
    ? "principal"
    : "employee";
export default function nep_parameters_stepper_data(data) {
  return [
    {
      id: 1,
      name: "Step 1",
      href: `/${role}/naac/nep-parameters/multidisciplinary`,
      status: data?.multidisciplinary,
    },
    {
      id: 2,
      name: "Step 2",
      href: `/${role}/naac/nep-parameters/academic-bank-of-credits`,
      status: data?.academic_bank,
    },
    {
      id: 3,
      name: "Step 3",
      href: `/${role}/naac/nep-parameters/skill-development`,
      status: data?.skill_development,
    },
    {
      id: 4,
      name: "Step 4",
      href: `/${role}/naac/nep-parameters/appropriate-integration-of-indian-knowledge-system`,
      status: data?.appropriate_integration,
    },
    {
      id: 5,
      name: "Step 5",
      href: `/${role}/naac/nep-parameters/focus-on-outcome-based-education`,
      status: data?.outcome_based_education,
    },
    {
      id: 6,
      name: "Step 6",
      href: `/${role}/naac/nep-parameters/distance-education`,
      status: data?.distance_education,
    },
  ];
}

import axios from "axios";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";
import { FEE_PENDING_COLLEGE_WISE } from "../../../utils/fees.apiConst";
import { useDownloadExcel } from "react-export-table-to-excel";

const ReportFeePendingCollege = ({ setLoading }) => {
  const [data, setData] = useState([]);
  const [hosteldata, sethostelData] = useState([]);

  const [session, setSession] = useState("");

  const navigate = useNavigate();

  const tableRef = useRef();

  const getData = async () => {
    if (!session) return toast.error("Session is required");
    setLoading(1);

    const config = {
      method: "get",
      url: `${FEE_PENDING_COLLEGE_WISE}/${session}`,
    };

    await axios(config)
      .then((res) => {
        console.log("Error Data", res.data.data);
        setData(res.data.data.CollegeFees);
        sethostelData(res.data.data.HostelFees);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
    setLoading(0);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });
  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">FEE REPORTS DATE WISE</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 p-2">
                      College and Hostel Wise Due Fees Report
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card p-3">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="">Session</label>
                        <select
                          name=""
                          id=""
                          value={session}
                          onChange={(e) => setSession(e.target.value)}
                          className="form-control"
                        >
                          <option value="">Select Session</option>
                          {sessionOpt.map((i, key) => (
                            <option key={key} value={i.id}>
                              {i.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-12">
                        <button
                          onClick={getData}
                          className="btn btn-primary rounded-pill"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-3 card">
                  <div className="card-title">College wise due fees</div>
                  {data?.length != 0 ? (
                    <div className="row my-3">
                      <div className="col-md-12 d-flex justify-content-end">
                        <button
                          onClick={onDownload}
                          className="btn btn-success"
                        >
                          Export
                        </button>
                      </div>
                    </div>
                  ) : null}

                  <div className="card-contend">
                    <div className="table-responsive">
                      <table ref={tableRef} className="table table-bordered">
                        <tr>
                          <th>Sl No</th>
                          <th>College</th>
                          <th>College Due Fees</th>
                          <th>Hostel Due Fees</th>
                        </tr>
                        {data &&
                          data?.map((i, key) => (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{i?.name}</td>
                              <td>
                                ₹{" "}
                                {String(i?.pending)?.replace(
                                  /(\d)(?=(\d\d)+\d$)/g,
                                  "$1,"
                                )}
                              </td>
                              <td>
                                {String(
                                  hosteldata.find((s) => s?.name == i?.name)
                                    ?.pending
                                    ? hosteldata.find((s) => s?.name == i?.name)
                                        ?.pending
                                    : "NA"
                                )?.replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")}
                              </td>
                            </tr>
                          ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportFeePendingCollege;

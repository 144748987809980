let roleName = sessionStorage.getItem("role");
let role =
  roleName === "SUPERADMIN"
    ? "registarar"
    : roleName === "Pincipal" ||
      roleName === "Principal" ||
      roleName === "PRINC"
    ? "principal"
    : "employee";
export default function extended_profile_stepper_data(data) {
  return [
    {
      id: 1.1,
      name: "Step 1.1",
      href: `/${role}/naac/extended-profile/number-of-courses-offered-by-the-institution`,
      status: data?.programs_offered,
    },
    {
      id: 1.2,
      name: "Step 1.2",
      href: `/${role}/naac/extended-profile/number-of-departments-offering-academic-programs`,
      status: data?.departments_offering,
    },
    {
      id: 2.1,
      name: "Step 2.1",
      href: `/${role}/naac/extended-profile/number-of-students-year-wise`,
      status: data?.number_of_students,
    },
    {
      id: 2.2,
      name: "Step 2.2",
      href: `/${role}/naac/extended-profile/number-of-outgoing-students`,
      status: data?.outgoing_students,
    },
    {
      id: 2.3,
      name: "Step 2.3",
      href: `/${role}/naac/extended-profile/number-of-students-appeared-in-exam`,
      status: data?.number_of_students_appeared_in_exam,
    },
    {
      id: 2.4,
      name: "Step 2.4",
      href: `/${role}/naac/extended-profile/number-of-revaluation-application`,
      status: "rejected",
      status: data?.number_of_revaluations,
    },

    {
      id: 3.1,
      name: "Step 3.1",
      href: `/${role}/naac/extended-profile/number-of-courses-in-all-programs`,
      status: data?.number_of_courses_in_all_programs,
    },
    {
      id: 3.2,
      name: "Step 3.2",
      href: `/${role}/naac/extended-profile/number-of-full-time-teachers`,
      status: data?.fulltime_teachers,
    },
    {
      id: 3.3,
      name: "Step 3.3",
      href: `/${role}/naac/extended-profile/number-of-sanctioned-posts`,
      status: data?.sanctioned_posts,
    },
    {
      id: 4.1,
      name: "Step 4.1",
      href: `/${role}/naac/extended-profile/number-of-eligible-application-received`,
      status: data?.number_of_eligible_applications,
    },
    {
      id: 4.2,
      name: "Step 4.2",
      href: `/${role}/naac/extended-profile/number-of-seats-earmarked-for-reserved-category`,
      status: data?.seats_for_reserved_category,
    },
    {
      id: 4.3,
      name: "Step 4.3",
      href: `/${role}/naac/extended-profile/total-number-of-classrooms-and-seminar-halls`,
      status: data?.classrooms_and_seminar_hall,
    },
    {
      id: 4.4,
      name: "Step 4.4",
      href: `/${role}/naac/extended-profile/number-of-computers`,
      status: data?.number_of_computers,
    },
    {
      id: 4.5,
      name: "Step 4.5",
      href: `/${role}/naac/extended-profile/total-expenditure-excluding-salary`,
      status: data?.expenditure,
    },
  ];
}

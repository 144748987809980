import { BASE_URL } from "./apiConstants";

//Fee Discount
export const ACCOUNT_FEE_DIS = `${BASE_URL}/api/fee/discount`;

//Fee Type
export const ACCOUNT_FEE_TYPE = `${BASE_URL}/api/fee/type`;

//Fee Group
export const ACCOUNT_FEE_GROUP = `${BASE_URL}/api/fee/group`;

//Fee Type amount
export const ACCOUNT_FEE_TYPE_AMOUNT = `${BASE_URL}/api/fee/amount`;

export const FEE_TYPE_AMOUNT = `${BASE_URL}/api/fee/fee-type-amount`;

//Fee Master
export const ACCOUNT_FEE_MASTER = `${BASE_URL}/api/fee/master`;

export const ACCOUNT_FEE_DETAILS = `${BASE_URL}/api/fee/student`;

export const ACCOUNT_FEE_BULK = `${BASE_URL}/api/fee/bulk`;

import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Popconfirm, Select, Table } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { toast } from "react-toastify";
import { NAAC } from "../../../../utils/apiConstants";

const { Option } = Select;

export default function TemporaryTeachers({ setUpdatedData, setLoading }) {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const [badge, setBadge] = useState("");
  const emp_id = sessionStorage.getItem("employee_id");

  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleAdd = () => {
    form.resetFields();
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const handleDelete = (identifier) => {
    const updatedData = data.filter((item) => {
      if (item.id === identifier) {
        item.status = "INACTIVE";
        return true;
      }
      return item.tempId !== identifier;
    });

    setData(updatedData);
  };

  const getFormData = async () => {
    setLoading(1);

    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/temporary-teachers/1`,
    };
    await axios(config)
      .then((res) => {
        setData(res.data);
        let fetchedData = res.data;
        setBadge(() => {
          if (
            fetchedData?.length > 0 &&
            fetchedData.every((item) => item.status === "REVIEW")
          ) {
            return "REVIEW";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "DRAFT")
          ) {
            return "DRAFT";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "APPROVED")
          ) {
            return "APPROVED";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "REJECTED")
          ) {
            return "REJECTED";
          }
        });
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  useEffect(() => {
    getFormData();
  }, []);

  const handleSave = () => {
    form.validateFields().then((values) => {
      if (editingRecord) {
        setData(
          data.map((item) =>
            (item.id && item.id === editingRecord.id) ||
            (item.tempId && item.tempId === editingRecord.tempId)
              ? { ...item, ...values, updated_by: emp_id }
              : item
          )
        );
      } else {
        setData([
          ...data,
          {
            ...values,
            tempId: uuidv4(),
            created_by: emp_id,
            updated_by: emp_id,
            university_profile_id: 1,
            status: "DRAFT",
          },
        ]);
      }
      setIsModalVisible(false);
    });
  };
  const handleFormSubmission = async (status) => {
    if (data.length === 0) {
      return toast.error("Please add at least one teaching faculty detail");
    }

    // Separate data for backend operations
    const toDelete = data.filter(
      (item) => item.status === "INACTIVE" && item.id
    );
    const toUpdate = data.filter(
      (item) => item.status !== "INACTIVE" && item.id
    );
    const toCreate = data.filter((item) => !item.id);

    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/temporary-teachers`,
      data: { toDelete, toUpdate, toCreate, status },
    };

    try {
      const res = await axios(config);
      toast.success(
        status === "REVIEW"
          ? "Successfully submitted for review"
          : "Successfully saved as draft"
      );
      setUpdatedData(uuidv4());
      getFormData();
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    } finally {
      setLoading(0);
    }
  };

  const handleSaveAsDraft = (e) => {
    e.preventDefault();
    handleFormSubmission("DRAFT");
  };

  const handleSaveAndSubmitForReview = (e) => {
    e.preventDefault();
    handleFormSubmission("REVIEW");
  };

  // const columns = [
  //   {
  //     title: "Highest Qualification",
  //     dataIndex: "highest_qualification",
  //     key: "highest_qualification",
  //   },
  //   {
  //     title: "Professor Male",
  //     dataIndex: "professor_male",
  //     key: "professor_male",
  //   },
  //   {
  //     title: "Professor Female",
  //     dataIndex: "professor_female",
  //     key: "professor_female",
  //   },
  //   {
  //     title: "Professor Others",
  //     dataIndex: "professor_other",
  //     key: "professor_other",
  //   },
  //   {
  //     title: "Associate Male",
  //     dataIndex: "associate_male",
  //     key: "associate_male",
  //   },
  //   {
  //     title: "Associate Female",
  //     dataIndex: "associate_female",
  //     key: "associate_female",
  //   },
  //   {
  //     title: "Associate Others",
  //     dataIndex: "associate_other",
  //     key: "associate_other",
  //   },
  //   {
  //     title: "Assistant Male",
  //     dataIndex: "assistant_male",
  //     key: "assistant_male",
  //   },
  //   {
  //     title: "Assistant Female",
  //     dataIndex: "assistant_female",
  //     key: "assistant_female",
  //   },
  //   {
  //     title: "Assistant Others",
  //     dataIndex: "assistant_other",
  //     key: "assistant_other",
  //   },
  //   {
  //     title: "Total",
  //     dataIndex: "total",
  //     key: "total",
  //   },
  //   {
  //     title: "Actions",
  //     key: "actions",
  //     render: (_, record) => (
  //       <div className="d-flex gap-1">
  //         <Button
  //           type="link"
  //           icon={<EditOutlined />}
  //           onClick={() => handleEdit(record)}
  //         >
  //           Edit
  //         </Button>
  //         <Popconfirm
  //           title="Delete this record?"
  //           onConfirm={() => handleDelete(record.id || record.tempId)}
  //           okText="Yes"
  //           cancelText="No"
  //           placement="topRight"
  //         >
  //           <Button type="link" danger icon={<DeleteOutlined />}>
  //             Delete
  //           </Button>
  //         </Popconfirm>
  //       </div>
  //     ),
  //   },
  // ];
  const handleValuesChange = (changedValues, allValues) => {
    // Destructure with default values to handle cases where the input might be undefined
    const {
      professor_male = 0,
      professor_female = 0,
      professor_other = 0,
      associate_male = 0,
      associate_female = 0,
      associate_other = 0,
      assistant_male = 0,
      assistant_female = 0,
      assistant_other = 0,
    } = allValues;
    // Calculate totals
    const total =
      (Number(professor_male) || 0) +
      (Number(professor_female) || 0) +
      (Number(professor_other) || 0) +
      (Number(associate_male) || 0) +
      (Number(associate_female) || 0) +
      (Number(associate_other) || 0) +
      (Number(assistant_male) || 0) +
      (Number(assistant_female) || 0) +
      (Number(assistant_other) || 0);
    // Set total fields in the form
    form.setFieldsValue({
      total: total,
    });
  };
  const calculateTotal = () => {
    let professorMaleTotal = 0;
    let professorFemaleTotal = 0;
    let professorOtherTotal = 0;

    let associateMaleTotal = 0;
    let associateFemaleTotal = 0;
    let associateOtherTotal = 0;

    let assistantMaleTotal = 0;
    let assistantFemaleTotal = 0;
    let assistantOtherTotal = 0;
    let total = 0;

    data.length > 0 &&
      data.forEach((item) => {
        professorMaleTotal += Number(item?.professor_male);
        professorFemaleTotal += Number(item?.professor_female);
        professorOtherTotal += Number(item?.professor_other);
        associateMaleTotal += Number(item?.associate_male);
        associateFemaleTotal += Number(item?.associate_female);
        associateOtherTotal += Number(item?.associate_other);
        assistantMaleTotal += Number(item?.assistant_male);
        assistantFemaleTotal += Number(item?.assistant_female);
        assistantOtherTotal += Number(item?.assistant_other);
        total += Number(item?.total);
      });
    return {
      professorMaleTotal,
      professorFemaleTotal,
      professorOtherTotal,
      assistantMaleTotal,
      assistantFemaleTotal,
      assistantOtherTotal,
      associateMaleTotal,
      associateFemaleTotal,
      associateOtherTotal,
      total,
    };
  };
  const columns = [
    {
      title: "Highest Qualification",
      dataIndex: "highest_qualification",
      key: "highest_qualification",
      align: "center",
      fixed: "left",
    },
    {
      title: "Professor",

      className: "table-bordered",
      children: [
        {
          title: "Male",
          dataIndex: "professor_male",
          key: "professor_male",
          align: "center",
        },
        {
          title: "Female",
          dataIndex: "professor_female",
          key: "professor_female",
          align: "center",
        },
        {
          title: "Other",
          dataIndex: "professor_other",
          key: "professor_other",
          align: "center",
        },
      ],
    },
    {
      title: "Associate",
      children: [
        {
          title: "Male",
          dataIndex: "associate_male",
          key: "associate_male",
          align: "center",
        },
        {
          title: "Female",
          dataIndex: "associate_female",
          key: "associate_female",
          align: "center",
        },
        {
          title: "Other",
          dataIndex: "associate_other",
          key: "associate_other",
          align: "center",
        },
      ],
    },
    {
      title: "Assistant",
      children: [
        {
          title: "Male",
          dataIndex: "assistant_male",
          key: "assistant_male",
          align: "center",
        },
        {
          title: "Female",
          dataIndex: "assistant_female",
          key: "assistant_female",
          align: "center",
        },
        {
          title: "Other",
          dataIndex: "assistant_other",
          key: "assistant_other",
          align: "center",
        },
      ],
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      render: (_, record) => (
        <div className="d-flex gap-1">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Delete this record?"
            onConfirm={() => handleDelete(record.id || record.tempId)}
            okText="Yes"
            cancelText="No"
            placement="topRight"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <>
      <Modal
        title={
          editingRecord ? "Edit Temporary Teacher" : "Add New Temporary Teacher"
        }
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSave}
        okText="Save"
        cancelText="Cancel"
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            professor_male: 0,
            professor_female: 0,
            professor_other: 0,
            associate_male: 0,
            associate_female: 0,
            associate_other: 0,
            assistant_male: 0,
            assistant_female: 0,
            assistant_other: 0,
            total: 0,
          }}
          onValuesChange={handleValuesChange}
        >
          <Form.Item
            label="Highest Qualification"
            name="highest_qualification"
            rules={[
              { required: true, message: "Please select a qualification" },
            ]}
          >
            <Select placeholder="Select Qualification">
              <Option value="D.Dsc/D.DLit">D.Dsc/D.DLit</Option>
              <Option value="PhD">PhD</Option>
              <Option value="M Phil">M Phil</Option>
              <Option value="PG">PG</Option>
            </Select>
          </Form.Item>
          <h5 className="h5 text-primary">Professor</h5>
          <div className="row">
            <div className="col">
              <Form.Item label=" Male" name="professor_male">
                <Input min={0} type="number" />
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item label=" Female" name="professor_female">
                <Input min={0} type="number" />
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item label=" Others" name="professor_other">
                <Input min={0} type="number" />
              </Form.Item>
            </div>
          </div>
          <h5 className="h5 text-primary">Associate</h5>
          <div className="row">
            <div className="col">
              <Form.Item label=" Male" name="associate_male">
                <Input min={0} type="number" />
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item label=" Female" name="associate_female">
                <Input min={0} type="number" />
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item label=" Others" name="associate_other">
                <Input min={0} type="number" />
              </Form.Item>
            </div>
          </div>
          <h5 className="h5 text-primary">Assistant</h5>
          <div className="row">
            <div className="col">
              <Form.Item label=" Male" name="assistant_male">
                <Input min={0} type="number" />
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item label=" Female" name="assistant_female">
                <Input min={0} type="number" />
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item label=" Others" name="assistant_other">
                <Input min={0} type="number" />
              </Form.Item>
            </div>
          </div>

          <Form.Item label="Total" name="total">
            <Input type="number" readOnly />
          </Form.Item>
        </Form>
      </Modal>

      <div className="d-flex justify-content-between align-items-start mb-3">
        <div className="d-flex flex-column gap-2">
          <h5 className="mb-0">Temporary Teachers Data</h5>
          <span
            className={`text-white d-inline-block text-center px-4 py-1 shadow-sm text-xs rounded-pill ${
              badge === "DRAFT"
                ? "bg-primary"
                : badge === "REVIEW"
                ? "bg-warning"
                : badge === "REJECTED"
                ? "bg-danger"
                : badge === "APPROVED"
                ? "bg-success"
                : "bg-secondary"
            }`}
            style={{ width: "fit-content" }}
          >
            {badge === "DRAFT"
              ? "In Draft"
              : badge === "REVIEW"
              ? "Submitted for review"
              : badge === "REJECTED"
              ? "Rejected"
              : badge === "APPROVED"
              ? "Approved"
              : "Not Started"}
          </span>
        </div>
        <Button type="primary" onClick={handleAdd} icon={<PlusOutlined />}>
          Add New Row
        </Button>
      </div>

      <div className="table-responsive shadow-sm">
        <Table
          className="table table-bordered"
          dataSource={data.filter((item) => item.status !== "INACTIVE")}
          columns={columns}
          rowKey={(record) => record.id || record.tempId}
          pagination={false}
          summary={() => {
            const {
              professorMaleTotal,
              professorFemaleTotal,
              professorOtherTotal,
              assistantMaleTotal,
              assistantFemaleTotal,
              assistantOtherTotal,
              associateMaleTotal,
              associateFemaleTotal,
              associateOtherTotal,
              total,
            } = calculateTotal();
            return data.length > 0 ? (
              <Table.Summary.Row className="text-center">
                <Table.Summary.Cell>
                  <strong>Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <strong>{professorMaleTotal}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <strong>{professorFemaleTotal}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <strong>{professorOtherTotal}</strong>
                </Table.Summary.Cell>

                <Table.Summary.Cell>
                  <strong>{associateMaleTotal}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <strong>{associateFemaleTotal}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <strong>{associateOtherTotal}</strong>
                </Table.Summary.Cell>

                <Table.Summary.Cell>
                  <strong>{assistantMaleTotal}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <strong>{assistantFemaleTotal}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <strong>{assistantOtherTotal}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <strong>{total}</strong>
                </Table.Summary.Cell>

                <Table.Summary.Cell></Table.Summary.Cell>
              </Table.Summary.Row>
            ) : (
              ""
            );
          }}
        />
      </div>

      <div className="d-flex justify-content-end gap-2 my-3">
        <button className="btn btn-primary" onClick={handleSaveAsDraft}>
          Save as Draft
        </button>
        <button
          className="btn btn-warning"
          onClick={handleSaveAndSubmitForReview}
        >
          Submit for Review
        </button>
      </div>
    </>
  );
}

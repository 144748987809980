import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Table } from "ant-table-extensions";

export default function ModalStaffBulkResult({
  showBulkResult,
  setShowBulkResult,
  bulkResult,
}) {
  const [result, setResult] = useState({ counts: {}, data: [] });

  useEffect(() => {
    if (bulkResult) {
      setResult(bulkResult);
      console.log(result?.data);
    }
  }, [bulkResult]);

  const columns = [
    {
      title: "S No.",
      dataIndex: "s_no",
      key: "s_no",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
  ];
  return (
    <>
      <Modal
        zIndex={2000}
        title="Bulk CSV Insert Result:"
        centered
        open={showBulkResult}
        onOk={() => {
          setShowBulkResult(false);
        }}
        onCancel={() => {
          setShowBulkResult(false);
        }}
        width={800}
      >
        <br />
        <p>
          <span className="text-success">
            Employees Successfully Created : {result?.counts?.success}
          </span>
          <br />
          <span className="text-danger">
            Employees Failed to create : {result?.counts?.errors}
          </span>
        </p>

        {result?.counts?.errors > 0 && (
          <section>
            <br />
            <h5>Details of Failed Employee creations.</h5>
            {/* <br /> */}
            <Table
              columns={columns}
              dataSource={result?.data}
              exportable
              pagination={{ pageSize: 25 }}
            />
          </section>
        )}
      </Modal>
    </>
  );
}

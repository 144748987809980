import * as React from "react";
import { Box, Stack } from "@mui/material";
import {
  FolderIcon,
  FolderOpenIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { styled } from "@mui/material/styles";
import Loader from "../../../Components/Loader/Loader";

// Sub Pages
const Metric_1_1_2 = React.lazy(() => import("./sub-pages/Metric_1_1_2"));
const Metric_1_1_3 = React.lazy(() => import("./sub-pages/Metric_1_1_3"));
const Metric_1_2_1 = React.lazy(() => import("./sub-pages/Metric_1_2_1"));
const Metric_1_2_2 = React.lazy(() => import("./sub-pages/Metric_1_2_2"));
const Metric_1_2_3 = React.lazy(() => import("./sub-pages/Metric_1_2_3"));
const Metric_1_3_2 = React.lazy(() => import("./sub-pages/Metric_1_3_2"));
const Metric_1_3_3 = React.lazy(() => import("./sub-pages/Metric_1_3_3"));
const Metric_1_3_4 = React.lazy(() => import("./sub-pages/Metric_1_3_4"));
const Metric_1_4_1 = React.lazy(() => import("./sub-pages/Metric_1_4_1"));
const Metric_1_4_2 = React.lazy(() => import("./sub-pages/Metric_1_4_2"));
const Metric_2_1_1 = React.lazy(() => import("./sub-pages/Metric_2_1_1"));
const Metric_2_1_2 = React.lazy(() => import("./sub-pages/Metric_2_1_2"));
const Metric_2_2_2 = React.lazy(() => import("./sub-pages/Metric_2_2_2"));
const Metric_2_3_3 = React.lazy(() => import("./sub-pages/Metric_2_3_3"));
const Metric_2_4_1 = React.lazy(() => import("./sub-pages/Metric_2_4_1"));
const Metric_2_4_2 = React.lazy(() => import("./sub-pages/Metric_2_4_2"));
const Metric_2_4_3 = React.lazy(() => import("./sub-pages/Metric_2_4_3"));
const Metric_2_4_4 = React.lazy(() => import("./sub-pages/Metric_2_4_4"));
const Metric_2_5_1 = React.lazy(() => import("./sub-pages/Metric_2_5_1"));
const Metric_2_5_2 = React.lazy(() => import("./sub-pages/Metric_2_5_2"));
const Metric_2_5_4 = React.lazy(() => import("./sub-pages/Metric_2_5_4"));
const Metric_2_6_3 = React.lazy(() => import("./sub-pages/Metric_2_6_3"));
const Metric_2_7_1 = React.lazy(() => import("./sub-pages/Metric_2_7_1"));
const Metric_3_1_1 = React.lazy(() => import("./sub-pages/Metric_3_1_1"));
const Metric_3_1_2 = React.lazy(() => import("./sub-pages/Metric_3_1_2"));
const Metric_3_1_3 = React.lazy(() => import("./sub-pages/Metric_3_1_3"));
const Metric_3_1_4 = React.lazy(() => import("./sub-pages/Metric_3_1_4"));
const Metric_3_1_5 = React.lazy(() => import("./sub-pages/Metric_3_1_5"));
const Metric_3_1_6 = React.lazy(() => import("./sub-pages/Metric_3_1_6"));
const Metric_3_2_1 = React.lazy(() => import("./sub-pages/Metric_3_2_1"));
const Metric_3_2_2 = React.lazy(() => import("./sub-pages/Metric_3_2_2"));
const Metric_3_2_3 = React.lazy(() => import("./sub-pages/Metric_3_2_3"));
const Metric_3_3_1 = React.lazy(() => import("./sub-pages/Metric_3_3_1"));
const Metric_3_3_2 = React.lazy(() => import("./sub-pages/Metric_3_3_2"));
const Metric_3_3_3 = React.lazy(() => import("./sub-pages/Metric_3_3_3"));
const Metric_3_4_1 = React.lazy(() => import("./sub-pages/Metric_3_4_1"));
const Metric_3_4_2 = React.lazy(() => import("./sub-pages/Metric_3_4_2"));
const Metric_3_4_3 = React.lazy(() => import("./sub-pages/Metric_3_4_3"));
const Metric_3_4_4 = React.lazy(() => import("./sub-pages/Metric_3_4_4"));
const Metric_3_4_5 = React.lazy(() => import("./sub-pages/Metric_3_4_5"));
const Metric_3_4_6 = React.lazy(() => import("./sub-pages/Metric_3_4_6"));
const Metric_3_4_7 = React.lazy(() => import("./sub-pages/Metric_3_4_7"));
const Metric_3_4_8 = React.lazy(() => import("./sub-pages/Metric_3_4_8"));
const Metric_3_4_9 = React.lazy(() => import("./sub-pages/Metric_3_4_9"));
const Metric_3_5_1 = React.lazy(() => import("./sub-pages/Metric_3_5_1"));
const Metric_3_5_2 = React.lazy(() => import("./sub-pages/Metric_3_5_2"));
const Metric_3_6_2 = React.lazy(() => import("./sub-pages/Metric_3_6_2"));
const Metric_3_6_3 = React.lazy(() => import("./sub-pages/Metric_3_6_3"));
const Metric_3_6_4 = React.lazy(() => import("./sub-pages/Metric_3_6_4"));
const Metric_3_7_1 = React.lazy(() => import("./sub-pages/Metric_3_7_1"));
const Metric_3_7_2 = React.lazy(() => import("./sub-pages/Metric_3_7_2"));
const Metric_4_1_3 = React.lazy(() => import("./sub-pages/Metric_4_1_3"));
const Metric_4_1_4 = React.lazy(() => import("./sub-pages/Metric_4_1_4"));
const Metric_4_2_2 = React.lazy(() => import("./sub-pages/Metric_4_2_2"));
const Metric_4_2_3 = React.lazy(() => import("./sub-pages/Metric_4_2_3"));
const Metric_4_2_4 = React.lazy(() => import("./sub-pages/Metric_4_2_4"));
const Metric_4_3_1 = React.lazy(() => import("./sub-pages/Metric_4_3_1"));
const Metric_4_3_2 = React.lazy(() => import("./sub-pages/Metric_4_3_2"));
const Metric_4_3_3 = React.lazy(() => import("./sub-pages/Metric_4_3_3"));
const Metric_4_3_4 = React.lazy(() => import("./sub-pages/Metric_4_3_4"));
const Metric_4_3_5 = React.lazy(() => import("./sub-pages/Metric_4_3_5"));
const Metric_4_4_1 = React.lazy(() => import("./sub-pages/Metric_4_4_1"));
const Metric_5_1_1 = React.lazy(() => import("./sub-pages/Metric_5_1_1"));
const Metric_5_1_2 = React.lazy(() => import("./sub-pages/Metric_5_1_2"));
const Metric_5_1_3 = React.lazy(() => import("./sub-pages/Metric_5_1_3"));
const Metric_5_1_4 = React.lazy(() => import("./sub-pages/Metric_5_1_4"));
const Metric_5_1_5 = React.lazy(() => import("./sub-pages/Metric_5_1_5"));
const Metric_5_2_1 = React.lazy(() => import("./sub-pages/Metric_5_2_1"));
const Metric_5_2_2 = React.lazy(() => import("./sub-pages/Metric_5_2_2"));
const Metric_5_2_3 = React.lazy(() => import("./sub-pages/Metric_5_2_3"));
const Metric_5_3_1 = React.lazy(() => import("./sub-pages/Metric_5_3_1"));
const Metric_5_3_3 = React.lazy(() => import("./sub-pages/Metric_5_3_3"));
const Metric_5_4_2 = React.lazy(() => import("./sub-pages/Metric_5_4_2"));
const Metric_6_2_3 = React.lazy(() => import("./sub-pages/Metric_6_2_3"));
const Metric_6_3_2 = React.lazy(() => import("./sub-pages/Metric_6_3_2"));
const Metric_6_3_3 = React.lazy(() => import("./sub-pages/Metric_6_3_3"));
const Metric_6_3_4 = React.lazy(() => import("./sub-pages/Metric_6_3_4"));
const Metric_6_4_2 = React.lazy(() => import("./sub-pages/Metric_6_4_2"));
const Metric_6_4_3 = React.lazy(() => import("./sub-pages/Metric_6_4_3"));
const Metric_6_5_2 = React.lazy(() => import("./sub-pages/Metric_6_5_2"));
const Metric_6_5_3 = React.lazy(() => import("./sub-pages/Metric_6_5_3"));
const Metric_7_1_2 = React.lazy(() => import("./sub-pages/Metric_7_1_2"));
const Metric_7_1_4 = React.lazy(() => import("./sub-pages/Metric_7_1_4"));
const Metric_7_1_5 = React.lazy(() => import("./sub-pages/Metric_7_1_5"));
const Metric_7_1_6 = React.lazy(() => import("./sub-pages/Metric_7_1_6"));
const Metric_7_1_7 = React.lazy(() => import("./sub-pages/Metric_7_1_7"));
const Metric_7_1_10 = React.lazy(() => import("./sub-pages/Metric_7_1_10"));

const StyledTreeItem = styled(TreeItem)(({ rootnode }) => {
  const borderColor = "#c7c7c7";

  return {
    position: "relative",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 19,
      left: -18,
      top: 12,
      borderBottom: !rootnode ? `2px dashed ${borderColor}` : "none",
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 16,
      paddingLeft: 18,
      borderLeft: `2px dashed ${borderColor}`,
    },
    [`& .${treeItemClasses.content}`]: {
      borderRadius: "5px",
    },
    "& .MuiTreeItem-label": {
      fontSize: 16,
    },
  };
});

const ShowTreeComponent = (props) => {
  const { children, activeTree, selectedTree } = props;

  return activeTree ? (
    <div hidden={activeTree[0] !== selectedTree}>
      <Box mx={2}>{children}</Box>
    </div>
  ) : null;
};
class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <div>Error loading component.</div>;
    }
    return this.props.children;
  }
}

export default function FolderTreeSidebar(props) {
  // Tree Structure Initialization
  const qif_quantitative_data = props.data;
  const [loading, setLoading] = React.useState(0);
  const [expanded, setExpanded] = React.useState([]);
  const [activeTree, setActiveTree] = React.useState();
  function getNodeIdsWithChildren(data) {
    const result = [];

    function extractNodeIds(nodeData) {
      for (const node of nodeData) {
        if (node.children && node.children.length > 0) {
          result.push(node.nodeId);
          extractNodeIds(node.children);
        }
      }
    }

    extractNodeIds(data);

    return result;
  }

  // Populate expanded state with all node IDs with children on component mount

  React.useEffect(() => {
    const arraySet = getNodeIdsWithChildren(qif_quantitative_data);
    setExpanded(arraySet);

    function findFirstLeafNode(node) {
      if (node.children && node.children.length > 0) {
        return findFirstLeafNode(node.children[0]);
      }
      return node.nodeId;
    }

    if (qif_quantitative_data && qif_quantitative_data.length > 0) {
      const firstLeafNodeId = findFirstLeafNode(qif_quantitative_data[0]);
      setActiveTree([firstLeafNodeId]);
    }
  }, [qif_quantitative_data]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  function getNodeIdsWithChildren(data) {
    const result = [];

    function extractNodeIds(nodeData) {
      for (const node of nodeData) {
        if (node.children && node.children.length > 0) {
          result.push(node.nodeId);
          extractNodeIds(node.children);
        }
      }
    }

    extractNodeIds(data);

    return result;
  }

  const handleExpandClick = () => {
    const arraySet = getNodeIdsWithChildren(qif_quantitative_data);
    setExpanded(arraySet); // Expands all nodes
  };

  const handleCollapseClick = () => {
    setExpanded([]); // Collapses all nodes
  };

  const handleSelect = (event, selectedTree) => {
    const arraySet = getNodeIdsWithChildren(qif_quantitative_data);
    !arraySet.includes(selectedTree[0]) && setActiveTree(selectedTree);
  };

  return (
    <>
      <Box>
        <Loader loading={loading} />
        <Stack spacing={3} direction={{ xs: "column", md: "row" }}>
          <Box
            width={{ xs: "100%", md: "17%" }}
            sx={{ position: "relative", minWidth: "220px" }}
          >
            <TreeView
              sx={{
                p: 1,
                border: 2,
                borderColor: "#eee",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                gap: 1,
                maxHeight: "750px",
                overflow: "auto",
                scrollbarColor: "#A8A8A8 #F1F1F1 ",
              }}
              aria-label="controlled"
              defaultCollapseIcon={<FolderOpenIcon />}
              defaultExpandIcon={<FolderIcon />}
              expanded={expanded}
              onNodeToggle={handleToggle}
              defaultEndIcon={<DocumentTextIcon />}
              onNodeSelect={handleSelect}
              multiSelect
            >
              {qif_quantitative_data.map((folder, index) => (
                <StyledTreeItem
                  rootnode="true"
                  nodeId={folder.nodeId}
                  label={folder.label}
                  key={index}
                >
                  {folder.children.map((sub_folder, index2) => (
                    <StyledTreeItem
                      nodeId={sub_folder.nodeId}
                      label={sub_folder.label}
                      key={index2}
                    >
                      {sub_folder.children.map((file, index3) => (
                        <StyledTreeItem
                          nodeId={file.nodeId}
                          label={file.label}
                          key={index3}
                        />
                      ))}
                    </StyledTreeItem>
                  ))}
                </StyledTreeItem>
              ))}
            </TreeView>

            {/* <Box sx={{ mt: 1, display: "flex", gap: "2px" }}>
              <button
                className="flex justify-center text-sm items-center bg-[#2b6cb3] hover:bg-gray-900 hover:shadow-lg   text-white px-3 py-2 w-full whitespace-nowrap rounded-md transition-all duration-150"
                onClick={handleExpandClick}
              >
                <span>Expand all</span>
              </button>
              <button
                className="flex justify-center text-sm items-center bg-[#2b6cb3] hover:bg-gray-900 hover:shadow-lg   text-white  px-3 py-2 w-full whitespace-nowrap rounded-md transition-all duration-150"
                onClick={handleCollapseClick}
              >
                <span>Collapse all</span>
              </button>
            </Box> */}
          </Box>
          <ErrorBoundary>
            <Box width={{ xs: "100%", md: "82%" }}>
              <React.Suspense fallback={<div>loading..</div>}>
                <ShowTreeComponent activeTree={activeTree} selectedTree="100">
                  <Metric_1_1_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="3">
                  <Metric_1_1_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="5">
                  <Metric_1_2_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="300">
                  <Metric_1_2_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="7">
                  <Metric_1_2_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="9">
                  <Metric_1_3_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="10">
                  <Metric_1_3_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="101">
                  <Metric_1_3_4 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="12">
                  <Metric_1_4_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="13">
                  <Metric_1_4_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="16">
                  <Metric_2_1_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="17">
                  <Metric_2_1_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="19">
                  <Metric_2_2_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="21">
                  <Metric_2_3_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="23">
                  <Metric_2_4_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="24">
                  <Metric_2_4_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="25">
                  <Metric_2_4_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="102">
                  <Metric_2_4_4 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="104">
                  <Metric_2_5_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="105">
                  <Metric_2_5_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="106">
                  <Metric_2_5_4 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="27">
                  <Metric_2_6_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="29">
                  <Metric_2_7_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="32">
                  <Metric_3_1_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="33">
                  <Metric_3_1_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="107">
                  <Metric_3_1_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="108">
                  <Metric_3_1_4 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="109">
                  <Metric_3_1_5 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="110">
                  <Metric_3_1_6 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="34">
                  <Metric_3_1_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="111">
                  <Metric_3_2_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="36">
                  <Metric_3_2_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="112">
                  <Metric_3_2_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="38">
                  <Metric_3_3_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="39">
                  <Metric_3_3_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="40">
                  <Metric_3_3_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="130">
                  <Metric_3_4_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="42">
                  <Metric_3_4_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="43">
                  <Metric_3_4_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="44">
                  <Metric_3_4_4 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="113">
                  <Metric_3_4_5 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="114">
                  <Metric_3_4_6 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="115">
                  <Metric_3_4_7 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="116">
                  <Metric_3_4_8 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="117">
                  <Metric_3_4_9 setLoading={setLoading} />
                </ShowTreeComponent>
                {/* <ShowTreeComponent activeTree={activeTree} selectedTree="47">
                <Metric_3_5_1 setLoading={setLoading}/>
              </ShowTreeComponent> */}
                <ShowTreeComponent activeTree={activeTree} selectedTree="47">
                  <Metric_3_5_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="119">
                  <Metric_3_6_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="120">
                  <Metric_3_6_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="121">
                  <Metric_3_6_4 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="123">
                  <Metric_3_7_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="124">
                  <Metric_3_7_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="50">
                  <Metric_4_1_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="51">
                  <Metric_4_1_4 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="53">
                  <Metric_4_2_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="54">
                  <Metric_4_2_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="55">
                  <Metric_4_2_4 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="125">
                  <Metric_4_3_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="57">
                  <Metric_4_3_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="58">
                  <Metric_4_3_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="126">
                  <Metric_4_3_4 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="127">
                  <Metric_4_3_5 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="60">
                  <Metric_4_4_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="63">
                  <Metric_5_1_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="64">
                  <Metric_5_1_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="65">
                  <Metric_5_1_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="66">
                  <Metric_5_1_4 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="67">
                  <Metric_5_1_5 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="69">
                  <Metric_5_2_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="70">
                  <Metric_5_2_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="71">
                  <Metric_5_2_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="73">
                  <Metric_5_3_1 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="74">
                  <Metric_5_3_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="76">
                  <Metric_5_4_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="79">
                  <Metric_6_2_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="81">
                  <Metric_6_3_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="82">
                  <Metric_6_3_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="83">
                  <Metric_6_3_4 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="85">
                  <Metric_6_4_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="128">
                  <Metric_6_4_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="129">
                  <Metric_6_5_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="87">
                  <Metric_6_5_3 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="90">
                  <Metric_7_1_2 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="91">
                  <Metric_7_1_4 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="92">
                  <Metric_7_1_5 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="93">
                  <Metric_7_1_6 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="94">
                  <Metric_7_1_7 setLoading={setLoading} />
                </ShowTreeComponent>
                <ShowTreeComponent activeTree={activeTree} selectedTree="95">
                  <Metric_7_1_10 setLoading={setLoading} />
                </ShowTreeComponent>
              </React.Suspense>
            </Box>
          </ErrorBoundary>
        </Stack>
      </Box>
    </>
  );
}

import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import InputTag from "../../../../Components/InputTag.jsx";

export default function DetailsRegardingSubmissionOfAnnualQualityAssuranceReports() {
  return (
    <>
      <span className="font-medium">
        24. Details regarding submission of Annual Quality Assurance Reports
        (AQAR) to NAAC
      </span>
      <span className="text-white whitespace-nowrap w-28 text-center bg-gray-400 px-4 py-1 shadow-sm text-xs rounded-2xl">
        Not Started
      </span>

      <form className="d-flex flex-column gap-5">
        <InputTag type="date" name="AQAR-1" label="AQAR-(i)" />

        <InputTag type="date" name="AQAR-2" label="AQAR-(ii)" />

        <InputTag type="date" name="AQAR-3" label="AQAR-(iii)" />

        <InputTag type="date" name="AQAR-4" label="AQAR-(iv)" />

        <div className="flex justify-start my-3  mb-3">
          <button className="btn btn-primary">Save as Draft</button>
          <button className="btn btn-warning">
            <span>Submit for Review</span>
          </button>
        </div>
      </form>
    </>
  );
}

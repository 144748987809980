import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";

function Check({ data }) {
  const componentRef = useRef();

  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const PrintRecipt = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    PrintRecipt();
  }, [data]);

  return (
    <div className="" ref={componentRef}>
      {data?.map((i, k) => {
        return (
          <div
            className=" container p-3 d-none d-print-block"
            style={{ breakAfter: "page" }}
          >
            <div className="row d-none d-print-block">
              <div className="col-sm-12">
                <img
                  src="../assets/images/kbn-header.png"
                  width="80%"
                  alt="kbn-header.png"
                  srcset=""
                />
              </div>
            </div>
            <div className="d-none d-print-block p-5">
              <div className="row">
                <div className="col-sm-12 mb-3">
                  <h2
                    className="text-center text-uppercase heading "
                    style={{ paddingBottom: "5px" }}
                  >
                    SALARY CERTIFICATE
                  </h2>
                  <h6 className="stm">
                    This is to certify that{" "}
                    {i?.salary_details?.gender?.toLowerCase() === "female"
                      ? "Mrs."
                      : "Mr."}{" "}
                    <span className="font-weight-bolder">
                      {i?.salary_details?.first_name}{" "}
                      {i?.salary_details?.last_name}{" "}
                    </span>{" "}
                    is working as a
                    <span className="font-weight-bolder">
                      {i?.salary_details?.role}
                    </span>
                    in Khaja Bandanawaz University, Kalaburagi.{" "}
                    {i?.salary_details?.gender?.toLowerCase() === "female"
                      ? "She"
                      : "He"}{" "}
                    is getting{" "}
                    {i?.salary_details?.gender?.toLowerCase() === "female"
                      ? "her"
                      : "his"}{" "}
                    salary emoluments for the month of{" "}
                    <span className="font-weight-bolder">
                      {months?.find((s) => s.value == i.month)?.label}-{i.year}.
                    </span>
                  </h6>
                </div>
              </div>

              <div className="row mt-3 text-center d-print-none ">
                <div className="col-sm-12 col-md-12">
                  <table className="table table-bordered slip-table">
                    <tr>
                      <th>P</th>
                      <th>A</th>
                      <th>WO</th>
                      <th>H</th>
                      {i?.leave_details?.map((i, k) => {
                        return <th>{i.leave_type}</th>;
                      })}
                    </tr>
                    <tr>
                      <td>{i?.salary_details?.p_count}</td>
                      <td>{i?.salary_details?.a_count}</td>
                      <td>{i?.salary_details?.wo_count}</td>
                      <td>{i?.holidays}</td>
                      {i?.leave_details?.map((i, k) => {
                        return <td>{i.number_of_days}</td>;
                      })}
                    </tr>
                  </table>
                </div>
              </div>
              <div className="row mt-3 text-center">
                <div className="col-sm-6">
                  <div>
                    <table className="table table-bordered slip-table">
                      <thead>
                        <tr>
                          <th colSpan={2}>
                            <h5>EARNING</h5>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th className="text-left">Basic Salary</th>
                          <td className="text-right">
                            {parseInt(i?.basic_salary)?.toLocaleString(
                              "en-IN",
                              {
                                currency: "INR",
                                minimumFractionDigits: 0,
                              }
                            )}
                          </td>
                        </tr>
                        {/* {i?.salary_details?.pf_salary ? (
                                <tr>
                                  <th className="text-left">PF Salary</th>
                                  <td className="text-right">
                                    {(
                                      i?.salary_details?.pf_salary * 0.12
                                    )?.toLocaleString("en-IN", {
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )} */}
                        {i?.earning?.map((j, k) => {
                          return (
                            <tr>
                              <th className="text-left">{j.title}</th>
                              <td className="text-right">
                                {(j.type == "percentage"
                                  ? Math.round(
                                      parseInt(i?.basic_salary * j.percentage) /
                                        100
                                    )
                                  : j?.earning_id == 7
                                  ? Math.round(
                                      j.amount -
                                        (j.amount /
                                          (i?.salary_details?.p_count +
                                            i?.salary_details?.a_count)) *
                                          i?.salary_details?.a_count
                                    )
                                  : j.amount
                                )?.toLocaleString("en-IN", {
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </td>
                            </tr>
                          );
                        })}
                        {i?.bonus?.map((j, k) => {
                          return (
                            <tr>
                              <th className="text-left">{j.title}</th>
                              <td className="text-right">
                                {(j.type == "percentage"
                                  ? Math.round(
                                      (parseInt(i?.basic_salary) *
                                        j.percentage) /
                                        100
                                    )
                                  : j.amount
                                )?.toLocaleString("en-IN", {
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </td>
                            </tr>
                          );
                        })}
                        {[
                          ...Array(
                            Math.max(
                              0,
                              i?.deduction?.length -
                                i?.earning?.length -
                                i?.bonus?.length
                            )
                          ),
                        ].map((_, index) => (
                          <tr>
                            <td className="text-left">-</td>
                            <td className="text-right">-</td>
                          </tr>
                        ))}
                        {i?.salary_details?.pf_salary ? (
                          <tr>
                            <td className="text-left">-</td>
                            <td className="text-right">-</td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <th className="text-left">Gross Salary</th>
                          <td className="text-right">
                            {(
                              parseInt(i?.basic_salary) +
                              parseInt(i?.earning_amount) +
                              parseInt(i?.bonus_amount)
                            )?.toLocaleString("en-IN", {
                              currency: "INR",
                              minimumFractionDigits: 0,
                            })}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div>
                    <table className="table table-bordered slip-table">
                      <thead>
                        <tr>
                          <th colSpan={2}>
                            <h5>DEDUCTION</h5>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                                <th className="text-left">Paid Leave</th>
                                <td className="text-right">
                                  {(
                                    (i?.leave_details
                                      ?.filter((s) => s.paid_leave == 1)
                                      ?.reduce(
                                        (acc, curr) =>
                                          acc +
                                          Math.round(
                                            curr?.paid_leave_amount *
                                              curr?.number_of_days
                                          ),
                                        0
                                      ) || 0) +
                                    parseInt(
                                      (i?.salary_details?.a_count -
                                        i?.leave_details?.reduce(
                                          (i, k) => i + k.number_of_days,
                                          0
                                        )) *
                                        (parseInt(
                                          i?.salary_details?.basic_salary
                                        ) /
                                          new Date(
                                            i?.year,
                                            parseInt(i?.month),
                                            0
                                          ).getDate())
                                    )
                                  )?.toLocaleString("en-IN", {
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </td>
                              </tr> */}
                        {i?.salary_details?.pf_salary ? (
                          <tr>
                            <th className="text-left">PF</th>
                            <td className="text-right">
                              {(
                                i?.salary_details?.pf_salary * 0.12
                              )?.toLocaleString("en-IN", {
                                currency: "INR",
                                minimumFractionDigits: 0,
                              })}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {i?.deduction?.map((j, k) => {
                          return (
                            <tr>
                              <th className="text-left">{j.title}</th>
                              <td className="text-right">
                                {(j.type == "percentage"
                                  ? Math.round(
                                      (parseInt(i?.basic_salary) *
                                        j.percentage) /
                                        100
                                    )
                                  : j.amount
                                )?.toLocaleString("en-IN", {
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </td>
                            </tr>
                          );
                        })}
                        {[
                          ...Array(
                            Math.max(
                              0,
                              i?.bonus?.length +
                                i?.earning?.length -
                                i?.deduction?.length
                            )
                          ),
                        ].map((_, index) => (
                          <tr>
                            <td className="text-left">-</td>
                            <td className="text-right">-</td>
                          </tr>
                        ))}
                        <tr>
                          <td className="text-left">-</td>
                          <td className="text-right">-</td>
                        </tr>
                        <tr>
                          <th className="text-left">Total Deduction</th>
                          <td className="text-right">
                            {i?.deduction_amount?.toLocaleString("en-IN", {
                              currency: "INR",
                              minimumFractionDigits: 0,
                            })}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <table className="table table-bordered slip-table">
                    <thead>
                      <tr>
                        <th className="text-center" width="50%">
                          NET Pay
                        </th>
                        <th className="text-center">
                          {i?.net_salary?.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 0,
                          })}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-12">
                  <h6>
                    This is auto generated pay slip, hence signature is not
                    required.
                  </h6>
                </div>
              </div>
            </div>
            <div className="print_footer_top_bulk"></div>
            <div className="row d-none d-print-block print_footer_bulk mt-5">
              <div className="col-sm-12  ">
                <img
                  src="../assets/images/kbn_footer.png"
                  width="100%"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Check;

import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { HOSTELPAYMENTDATA } from "../../../utils/fees.apiConst";
import { useDownloadExcel } from "react-export-table-to-excel";
import { college_title } from "../../../Data/main";
import Nodata from "../../../Components/NoData/Nodata";
import { toast } from "react-toastify";

function HostelFeeCollectionReport({ setLoading }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  // const [user, setUser] = useState({
  //   from_date: "",
  //   to_date: "",
  // });

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  const [user, setUser] = useState({
    from_date: new Date().toISOString().split("T")[0], // Set to current date
    to_date: new Date().toISOString().split("T")[0], // Set to current date
  });

  const { type1 } = state;

  console.log(type1);

  const [dates, setDates] = useState([]);

  let count = 0;

  const [data, setData] = useState([]);

  const [details, setDetails] = useState([]);
  const [sum, setSum] = useState();

  const tableRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getData = async () => {
    const config = {
      method: "get",
      url: `${HOSTELPAYMENTDATA}?to_date=${user?.to_date}&from_date=${user?.from_date}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("hostel payments - ", res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Hostel Fee Collection",
    sheet: "Users",
  });

  return (
    <div>
      {" "}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-sm btn-nex d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">
                    Date wise Hostel Fee Collection Report
                  </h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="card-title">Select Criteria</div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Date</label>
                      <input
                        type="date"
                        name="from_date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.from_date}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Date</label>
                      <input
                        type="date"
                        name="to_date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.to_date}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex">
                      <button className="btn btn-nex mt-4" onClick={getData}>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div className="card-title">{type1} Hostel Fee Reports</div>
                    <button className="btn btn-nex" onClick={onDownload}>
                      Excel
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive  fixTableHeadPage">
                      <table className="table table-bordered" ref={tableRef}>
                        <thead>
                          <tr>
                            <th colSpan={14}>{college_title}</th>
                          </tr>
                          <th className="text-uppercase" colSpan={14}>
                            DATE WISE HOSTEL FEE REPORT :{" "}
                            {formatDate(user?.from_date)} to{" "}
                            {formatDate(user?.to_date)}
                          </th>
                          <tr>
                            <th>Sl.No</th>
                            {/* <th>Date </th> */}
                            <th>Student Name</th>
                            <th>Admission No.</th>
                            <th>University No.</th>
                            <th>Date</th>
                            <th>Hostel</th>
                            <th>Transaction Id</th>
                            <th>Mode</th>
                            <th>Note</th>
                            <th className="text-center">Collected By</th>
                            <th className="text-right">Paid</th>
                            <th className="text-right">Discount</th>
                            <th className="text-right">Fine</th>
                            <th className="text-right">Total</th>
                          </tr>
                        </thead>

                        {data && data?.length != 0 ? (
                          data?.map((i, key) => (
                            <>
                              <tr>
                                <td>{key + 1}</td>
                                <td>{i?.name}</td>
                                <td>{i?.user_id}</td>
                                <td>{i?.university_number}</td>
                                <td>{formatDate(i?.transaction_date)}</td>
                                <td>{i?.hostel_name}</td>
                                <td>{i?.transaction_id}</td>
                                <td>{i?.type}</td>
                                <td>{i?.note}</td>
                                <td>
                                  {i?.first_name} {i?.last_name}
                                </td>
                                <td className="text-right">
                                  {parseInt(i?.amount)?.toLocaleString(
                                    "en-IN",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    }
                                  )}
                                </td>
                                <td className="text-right">
                                  {parseInt(i?.discount)?.toLocaleString(
                                    "en-IN",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    }
                                  )}
                                </td>
                                <td className="text-right">
                                  {parseInt(i?.fine)?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </td>
                                <td className="text-right">
                                  {parseInt(
                                    i?.amount + i?.fine
                                  )?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </td>
                              </tr>
                            </>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={14}>
                              <Nodata />
                            </td>
                          </tr>
                        )}

                        <tfoot>
                          {data && data?.length != 0 ? (
                            <tr>
                              <th colSpan={9} className="text-right">
                                Grand Total :
                              </th>
                              <th className="text-right">
                                <strong>
                                  {data
                                    .reduce(
                                      (total, i) => total + parseInt(i?.amount),
                                      0
                                    )
                                    ?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                                </strong>
                              </th>
                              <th className="text-right">
                                <strong>
                                  {data
                                    .reduce(
                                      (total, i) =>
                                        total + parseInt(i?.discount),
                                      0
                                    )
                                    ?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                                </strong>
                              </th>
                              <th className="text-right">
                                <strong>
                                  {data
                                    .reduce(
                                      (total, i) => total + parseInt(i?.fine),
                                      0
                                    )
                                    ?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                                </strong>
                              </th>

                              <th className="text-right">
                                <strong>
                                  {data
                                    .reduce(
                                      (total, i) =>
                                        total + parseInt(i?.amount + i?.fine),
                                      0
                                    )
                                    ?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                                </strong>
                              </th>
                            </tr>
                          ) : null}
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HostelFeeCollectionReport;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../../Components/Loader/Loader";
import ModalPayRollEarning from "../../../../modals/HR/PayRoll/ModalPayRollEarning";
import ModalPayRollPayScale from "../../../../modals/HR/PayRoll/ModalPayRollPayScale";
import { PAYROLL_PAYSCALE } from "../../../../utils/apiConstants";
import Nodata from "../../../../Components/NoData/Nodata";
import { LOCAL_JOBROLES } from "../../../../utils/LocalStorageConstants";

function PayRollPayScale({ setLoading }) {
  const [data, setData] = useState([]);
  const [type, setType] = useState("");
  const [edit, setEdit] = useState();

  const getData = async () => {
    const config = {
      method: "get",
      url: `${PAYROLL_PAYSCALE}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("payscale - ", res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
        console.log(err);
      });
  };

  // const getEmpData = () => {

  //     const config = {
  //       method: "get",
  //       url: `${}`,
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
  //       },
  //     };

  //     setLoading(1);
  //     axios(config)
  //       .then((res) => {
  //         setLoading(0);
  //         setEmpData(res.data.data);
  //         setDisplayData(res.data.data);
  //         console.log('employees with earnings - ', res.data.data);
  //       })
  //       .catch((err) => {
  //         setLoading(0);
  //         toast.error("Something Went Wrong");
  //         console.log(err);
  //       });
  //   };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="PayRollEarning">
      <ModalPayRollPayScale
        type={type}
        data={edit}
        reloadData={getData}
        setLoading={setLoading}
      />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="">Pay Scale</h4>
                    <br />
                    <div className="row">
                      <div className="col-md-6">
                        <h5 className="mt-4">Pay Scale List</h5>
                      </div>
                      <div className="col-md-6">
                        <button
                          className="btn btn-rounded btn-success float-right mb-3"
                          data-toggle="modal"
                          data-target="#ModalPayRollPayScale"
                          onClick={() => {
                            setType("add");
                            setEdit();
                          }}
                        >
                          Add +
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Sl No.</th>
                              <th>Title</th>
                              <th>Description</th>
                              <th>Basic Salary</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((i, k) => {
                              return (
                                <tr>
                                  <td>{k + 1}</td>
                                  <td>{i?.title}</td>
                                  <td>{i?.description}</td>
                                  <td>
                                    {i?.basic_salary?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                                  </td>
                                  <td>
                                    <acronym title="Edit">
                                      <a
                                        className="badge badge-light"
                                        data-toggle="modal"
                                        data-target="#ModalPayRollPayScale"
                                        onClick={() => {
                                          setType("edit");
                                          setEdit(i);
                                        }}
                                      >
                                        <i
                                          className="fa fa-edit "
                                          aria-hidden="true"
                                        />
                                      </a>
                                    </acronym>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayRollPayScale;

export const ASSET_EMPLOYEE_EXPERIENCE = "EMPLOYEE_VERIF";
export const ASSET_EMPLOYEE_DOCUMENT = "EMPLOYEE_DOCUMENT";
export const ASSET_EMPLOYEE_IMAGE = "EMPLOYEE_IMAGE";
export const ASSET_EXAMINATION = "EXAMINATION";
export const ASSET_HOMEOMEDIA = "HOMEOMEDIA";
export const ASSET_MEDIA = "HOMEOMEDIA";
export const ASSET_PUBLICATION = "RESEARCH_DOCUMENT";
export const ASSET_NOTIFICATIONS = "ADMISSION_DOCUMENT";
export const ASSET_STUDENT_EXPERIENCE = "STUDENT_VERIF";
export const ASSET_STUDENT_DOCUMENT = "STUDENT_DOCUMENT";
export const ASSET_STUDENT_IMAGE = "STUDENT_IMAGE";
export const ASSET_NAAC = "NAAC";

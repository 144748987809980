import { Table } from "antd";
import React from "react";
const columns = [
  {
    title: "Academic Unit",
    dataIndex: "academicUnit",
    key: "academicUnit",
  },
  {
    title: "Metric Number",
    dataIndex: "metricNumber",
    key: "metricNumber",
  },
  {
    title: "Submission Status",
    dataIndex: "submissionStatus",
    key: "submissionStatus",
  },
];
const MyDownloads = () => {
  return (
    <div className="shadow-xl p-3 border my-2 mb-20 bg-white">
      <Table columns={columns} />
    </div>
  );
};

export default MyDownloads;

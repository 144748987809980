import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    marginBottom: 10,
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
    fontWeight: "bold",
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
});
const ExecutiveSummaryPdf = ({ data }) => {
  return (
    <div
      class="modal fade "
      id="executiveSummary"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Modal title
            </h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <PDFViewer style={{ width: "100%", minHeight: "400px" }}>
              <MyDocument data={data} />
            </PDFViewer>
          </div>
        </div>
      </div>
    </div>
  );
};
const MyDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>Introductory Note</Text>
        <Text style={styles.text}>{data.introductory_note}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.header}>Criterion-wise Summary</Text>
        <Text style={styles.text}>{data.criterion_wise_summary}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.header}>
          Strengths, Weaknesses, Opportunities, and Challenges
        </Text>
        <Text style={styles.text}>
          {data.strength_weaknesses_opportunities_and_challenges}
        </Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.header}>Additional Information</Text>
        <Text style={styles.text}>{data.additional_information}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.header}>Overall Conclusive Explication</Text>
        <Text style={styles.text}>{data.over_all_conclusive_explication}</Text>
      </View>
    </Page>
  </Document>
);
export default ExecutiveSummaryPdf;

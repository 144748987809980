import React, { useEffect, useState } from "react";
import InputTag from "../../../../Components/InputTag";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { Upload } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import { NAAC } from "../../../../utils/apiConstants";
import { v4 as uuidv4 } from "uuid";
import {
  DeleteOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { getFileUrl } from "../../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../../utils/AssetsReferenceTypes";
const title = "institute";
const sub_title = "number_of_computers";
const NumberOfComputers = ({ setLoading, setUpdatedData }) => {
  const [recordId, setRecordId] = useState(1);
  const [data, setData] = useState({
    id: "",
    number_of_computers: "",
    status: "",
  });
  const [badge, setBadge] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const emp_id = sessionStorage.getItem("employee_id");
  console.log("data", data);
  const getFormData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      url: `${NAAC}/extended-profile/${sub_title}`,
    };
    try {
      const res = await axios(config);
      let result = res?.data[0];
      console.log("result", result);
      setData((prev) => ({
        ...prev,
        id: result?.id,
        number_of_computers: result?.response,
        status: result?.status,
      }));
      setBadge(result?.status);
      setFileUrl(result?.document_url);
      setRecordId(result?.id);
      setUploadedFile(result?.document_name);
    } catch (err) {
      // toast.error("Failed to fetch data");
      console.log(err);
    } finally {
      setLoading(0);
    }
  };
  const handleDraftOrSubmit = async (e, status) => {
    e.preventDefault();
    setLoading(1);
    // Set up the common information fields
    let info = {
      ...data,
      status: status,
      response: data.number_of_computers,
      document_url: fileUrl,
      document_name: uploadedFile,
      title: title,
      application_id: 1,
      sub_title: sub_title,
      ...(recordId
        ? { id: recordId, updated_by: emp_id }
        : { created_by: emp_id, updated_by: emp_id }),
    };
    console.log("info", info);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/extended-profile`,
      data: info,
    };

    await axios(config)
      .then((res) => {
        console.log("response", res);
        toast.success("Successfully saved the data");
        getFormData(); // Refetch data to update view
        setUpdatedData(uuidv4());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => setLoading(0));
  };
  const handleSaveAsDraft = (e) => {
    handleDraftOrSubmit(e, "DRAFT");
  };
  const handleSubmitForReview = (e) => {
    handleDraftOrSubmit(e, "REVIEW");
  };
  const extractFileName = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf(".");
    return fileName.substring(0, lastDotIndex);
  };
  const addAttachment = async (file) => {
    try {
      const fileLink = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        "Resume",
        extractFileName(file?.name),
        setLoading,
        file
      );
      setFileUrl(fileLink);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("File upload failed");
    }
  };
  const beforeUpload = (file) => {
    setUploadedFile(file?.name);
    addAttachment(file);
    return false;
  };
  useEffect(() => {
    getFormData();
  }, []);
  return (
    <div>
      {/* Header Section */}
      <div className="d-flex justify-content-between align-items-end w-100 mb-4">
        <div className="d-flex flex-column gap-2">
          <span className="fw-semibold h5">
            Total number of computers in the campus for academic purpose
          </span>
          <span
            className={`text-white d-inline-block text-center px-4 py-1 shadow-sm text-xs rounded-pill ${
              badge === "DRAFT"
                ? "bg-primary"
                : badge === "REVIEW"
                ? "bg-warning"
                : badge === "REJECTED"
                ? "bg-danger"
                : badge === "APPROVED"
                ? "bg-success"
                : "bg-secondary"
            }`}
            style={{ width: "fit-content" }}
          >
            {badge === "DRAFT"
              ? "In Draft"
              : badge === "REVIEW"
              ? "Submitted for review"
              : badge === "REJECTED"
              ? "Rejected"
              : badge === "APPROVED"
              ? "Approved"
              : "Not Started"}
          </span>
        </div>
      </div>
      {/* Input Section */}
      <div className="mb-4">
        <InputTag
          type="number"
          name="number_of_computers"
          label="Number Of Computers"
          value={data.number_of_computers}
          onChange={(e) =>
            setData((prev) => ({
              ...prev,
              number_of_computers: e.target.value,
            }))
          }
        />
      </div>
      {/* Upload Section */}
      <div className="mb-4">
        <div className="py-3 d-flex flex-column gap-3">
          <span className="fw-medium">Upload Data Template</span>
          <div className="d-flex align-items-center gap-3">
            <DocumentArrowDownIcon
              className="me-2"
              style={{ height: "24px", width: "24px" }}
            />
            <a
              href="/templates/Extended Profile 4.3 Template.xlsx"
              className="text-primary text-decoration-underline"
              download
            >
              4.3 Template
            </a>
          </div>
          {fileUrl ? (
            <div className="d-flex">
              <div className="mr-2">
                <a
                  className=" btn btn-primary mr-4"
                  style={{ width: "fit-content" }}
                  href={fileUrl}
                  download
                  target="_blank"
                >
                  <DownloadOutlined /> Download
                </a>
              </div>
              <button onClick={() => setFileUrl("")} className="btn btn-danger">
                <DeleteOutlined /> Delete
              </button>
            </div>
          ) : (
            <Upload accept=".pdf,.xlsx,.docx,.zip" beforeUpload={beforeUpload}>
              <button className="btn btn-primary">
                <UploadOutlined /> Browse
              </button>
            </Upload>
          )}

          {fileUrl ? (
            <div style={{ marginTop: "10px" }}>{uploadedFile}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* Action Buttons */}
      <div className="d-flex justify-content-end gap-3 mb-4">
        <button className="btn btn-primary" onClick={handleSaveAsDraft}>
          Save as Draft
        </button>
        <button
          className="btn btn-warning text-dark"
          onClick={handleSubmitForReview}
        >
          <span>Submit for Review</span>
        </button>
      </div>
    </div>
  );
};

export default NumberOfComputers;

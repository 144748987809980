import { Button, DatePicker, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { international } from "../../../../Data/Countries/international";
import { JOB_APPLICATIONS, JOB_OPENINGS } from "../../../../utils/apiConstants";
import axios from "axios";
import { toast } from "react-toastify";
import DocumentsModal from "../../../../modals/Students/DocumentsModal";
import ModalViewJobOpening from "../ModalViewJobOpening";

const { TextArea } = Input;

const PersonalInformation = ({
  onFinish,
  initialValues,
  next,
  jobOpts,
  jobs,
  setJobId,
}) => {
  const [genderRequired, setGenderRequired] = useState(null);
  const [user, setUser] = useState(
    initialValues || {
      job_openings_id: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      dob: "",
      gender: "",
      nationality: "",
      country: "",
      state: "",
      city: "",
      current_address: "",
      permanent_address: "",
      father_name: "",
      mother_name: "",
      marital_status: "",
      work_experience: "",
      date: "",
      job_name: "",
    }
  );
  const [submitClicked, setSubmitClicked] = useState(false);
  const [countries, setCountries] = useState([]);
  const [dobMsg, setDobMsg] = useState("");

  const [opening, setOpening] = useState([]);

  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const setCountryAndState = async () => {
    let arr = [];
    await international?.map((item, key) => {
      arr.push({ label: item?.country, value: item?.country });
    });
    setCountries(arr);
  };
  const handleSaveNext = (value) => {
    onFinish(value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    setCountryAndState();
  }, []);
  console.log(jobOpts, "jobOpts");

  const onJobOptChange = (value) => {
    setJobId(value);
    setUser({
      ...user,
      job_openings_id: value,
      job_name: jobs?.find((s) => s.id == value)?.title,
    });
  };
  // console.log("JobName", user.job_name);

  const onJobOptSearch = (value) => {
    console.log("search:", value);
  };

  const validateAndSetDob = (date, d) => {
    const age = calculateAge(new Date(d));

    console.log(age);

    if (
      age >= jobOpts?.find((s) => s.value == user?.job_openings_id)?.from_age &&
      age <= jobOpts?.find((s) => s.value == user?.job_openings_id)?.to_age
    ) {
      setUser({ ...user, dob: d, date: date });
      return true;
    } else {
      setUser({ ...user, dob: "", date: "" });
      return false;
    }
  };

  useEffect(() => {
    console.log(user);
  }, [user]);

  useEffect(() => {
    const gender = jobOpts?.find(
      (s) => s.value == user?.job_openings_id
    )?.gender_required;
    setGenderRequired(gender);

    setOpening(jobs?.find((s) => s.id == user?.job_openings_id));
  }, [user?.job_openings_id]);
  const handleSubmit = async () => {
    if (
      !user.first_name ||
      !user.last_name ||
      !user.dob ||
      !user.state ||
      !user.city ||
      !user.gender ||
      !user.phone ||
      !user.email ||
      !user.job_openings_id ||
      !user.nationality ||
      !user.country ||
      !user.state ||
      !user.city ||
      !user.current_address ||
      !user.permanent_address
    ) {
      setSubmitClicked(true);
      return toast.error("Please fill in all the required fields");
    }
    setSubmitClicked(false);
    console.log("data to backend - ", user);
    onFinish(user);
    next();
  };
  return (
    <div>
      <DocumentsModal title={title} img={link} setLink={setLink} />
      <ModalViewJobOpening opening={opening} />

      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0">Job Application Form</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div>
            <div className="row">
              <div className="col-md-6 ">
                <label>Job Openings</label>
                <br />
                <Select
                  showSearch
                  placeholder="Select Job Opening"
                  optionFilterProp="children"
                  onChange={onJobOptChange}
                  onSearch={onJobOptSearch}
                  filterOption={filterOption}
                  value={user?.job_openings_id}
                  options={jobOpts}
                  style={{ width: "80%" }}
                />
                {user?.job_openings_id ? (
                  <button
                    data-toggle="modal"
                    data-target="#ModalViewJobOpening"
                    className="btn btn-nex btn-sm my-1 ml-3"
                    title="View"
                  >
                    View
                  </button>
                ) : (
                  <></>
                )}
                {submitClicked && !user?.job_openings_id ? (
                  <p className="text-danger">Please select job opening</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6">
                <label>Application Date</label>
                <br />
                <Input
                  type="text"
                  value={new Date().toDateString()}
                  disabled="true"
                  style={{ width: "80%" }}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label>First Name</label>
                <br />
                <Input
                  type="text"
                  name="first_name"
                  value={user?.first_name}
                  onChange={handleChange}
                  disabled={!user?.job_openings_id}
                  style={{ width: "80%" }}
                />
                {submitClicked && !user?.first_name ? (
                  <p className="text-danger">Please enter first name</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label>Last Name</label>
                <br />
                <Input
                  type="text"
                  name="last_name"
                  value={user?.last_name}
                  onChange={handleChange}
                  style={{ width: "80%" }}
                  disabled={!user?.job_openings_id}
                />
                {submitClicked && !user?.last_name ? (
                  <p className="text-danger">Please enter last name</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label>Personal email</label>
                <br />
                <Input
                  type="text"
                  name="email"
                  value={user?.email}
                  disabled={!user?.job_openings_id}
                  onChange={handleChange}
                  style={{ width: "80%" }}
                />
                {submitClicked && !user?.email ? (
                  <p className="text-danger">Please enter valid email</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label>Phone</label>
                <br />
                <Input
                  type="number"
                  name="phone"
                  value={user?.phone}
                  onChange={handleChange}
                  disabled={!user?.job_openings_id}
                  style={{ width: "80%" }}
                />
                {submitClicked && !user?.phone ? (
                  <p className="text-danger">Please enter phone number</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label>Date of Birth : </label>
                <br />
                <DatePicker
                  onChange={(date, dateString) => {
                    if (!validateAndSetDob(date, dateString)) {
                      setDobMsg(
                        `Applicants with age between ${
                          jobOpts?.find((s) => s.value == user?.job_openings_id)
                            ?.from_age
                        } and ${
                          jobOpts?.find((s) => s.value == user?.job_openings_id)
                            ?.to_age
                        } can apply.`
                      );
                    } else {
                      setDobMsg("");
                    }
                    // setUser({ ...user, dob: dateString, date: date });
                  }}
                  value={user?.date}
                  style={{ width: "80%" }}
                  disabled={!user?.job_openings_id}
                />
                {dobMsg ? (
                  <p className="text-danger">{dobMsg}</p>
                ) : submitClicked && !user?.dob ? (
                  <p className="text-danger">Please select Date of Birth</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label>Gender</label>
                <br />
                <Select
                  showSearch
                  placeholder="Select Gender"
                  optionFilterProp="children"
                  onChange={(value) => {
                    setUser({ ...user, gender: value });
                  }}
                  filterOption={filterOption}
                  value={user?.gender}
                  disabled={!user?.job_openings_id}
                  options={
                    genderRequired == "all"
                      ? [
                          {
                            value: "MALE",
                            label: "Male",
                          },
                          {
                            value: "FEMALE",
                            label: "Female",
                          },
                          {
                            value: "OTHERS",
                            label: "Others",
                          },
                        ]
                      : genderRequired == "male"
                      ? [
                          {
                            value: "MALE",
                            label: "Male",
                          },
                        ]
                      : genderRequired == "female"
                      ? [
                          {
                            value: "FEMALE",
                            label: "Female",
                          },
                        ]
                      : [
                          {
                            value: "MALE",
                            label: "Male",
                          },
                          {
                            value: "FEMALE",
                            label: "Female",
                          },
                          {
                            value: "OTHERS",
                            label: "Others",
                          },
                        ]
                  }
                  style={{ width: "80%" }}
                />
                {submitClicked && !user?.gender ? (
                  <p className="text-danger">Please select gender</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label>Nationality</label>
                <br />
                <Select
                  showSearch
                  placeholder="Select Nationality"
                  optionFilterProp="children"
                  onChange={(value) => {
                    setUser({
                      ...user,
                      nationality: value,
                      country: value == "INDIAN" ? "India" : "",
                    });
                  }}
                  filterOption={filterOption}
                  value={user?.nationality}
                  disabled={!user?.job_openings_id}
                  options={[
                    {
                      value: "INDIAN",
                      label: "Indian",
                    },
                    {
                      value: "INTERNATIONAL",
                      label: "International",
                    },
                  ]}
                  style={{ width: "80%" }}
                />
                {submitClicked && !user?.nationality ? (
                  <p className="text-danger">Please select nationality</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label>Country</label>
                <br />
                <Select
                  showSearch
                  placeholder="Select country"
                  optionFilterProp="children"
                  onChange={(value) => {
                    setUser({ ...user, country: value, state: "" });
                  }}
                  filterOption={filterOption}
                  value={user?.country}
                  disabled={
                    !user?.job_openings_id || user?.nationality == "INDIAN"
                  }
                  options={countries}
                  style={{ width: "80%" }}
                />
                {submitClicked && !user?.country ? (
                  <p className="text-danger">Please select country</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label>State</label>
                <br />
                <Select
                  showSearch
                  placeholder="Select state"
                  optionFilterProp="children"
                  onChange={(value) => {
                    setUser({ ...user, state: value });
                  }}
                  filterOption={filterOption}
                  value={user?.state}
                  disabled={!user?.job_openings_id}
                  options={international
                    ?.find((s) => s.country == user?.country)
                    ?.states?.map((i, k) => {
                      return { value: i, label: i };
                    })}
                  style={{ width: "80%" }}
                />
                {submitClicked && !user?.state ? (
                  <p className="text-danger">Please select state</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label>City</label>
                <br />
                <Input
                  type="text"
                  name="city"
                  value={user?.city}
                  onChange={handleChange}
                  disabled={!user?.job_openings_id}
                  style={{ width: "80%" }}
                />
                {submitClicked && !user?.city ? (
                  <p className="text-danger">Please enter city</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label>Current Address</label>
                <br />
                <TextArea
                  type="text"
                  name="current_address"
                  value={user?.current_address}
                  onChange={handleChange}
                  disabled={!user?.job_openings_id}
                  style={{ width: "80%" }}
                />
                {submitClicked && !user?.current_address ? (
                  <p className="text-danger">Please enter current address</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label>Permanent Address</label>
                <br />
                <TextArea
                  type="text"
                  name="permanent_address"
                  value={user?.permanent_address}
                  onChange={handleChange}
                  disabled={!user?.job_openings_id}
                  style={{ width: "80%" }}
                />
                {submitClicked && !user?.permanent_address ? (
                  <p className="text-danger">Please enter permanent address</p>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label>Father name</label>
                <br />
                <Input
                  type="text"
                  name="father_name"
                  value={user?.father_name}
                  disabled={!user?.job_openings_id}
                  onChange={handleChange}
                  style={{ width: "80%" }}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label>Mother name</label>
                <br />
                <Input
                  type="text"
                  name="mother_name"
                  value={user?.mother_name}
                  disabled={!user?.job_openings_id}
                  onChange={handleChange}
                  style={{ width: "80%" }}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label>Martial Status</label>
                <br />
                <Select
                  showSearch
                  placeholder="Select marital status"
                  optionFilterProp="children"
                  onChange={(value) => {
                    setUser({ ...user, marital_status: value });
                  }}
                  filterOption={filterOption}
                  value={user?.marital_status}
                  disabled={!user?.job_openings_id}
                  options={[
                    {
                      value: "married",
                      label: "Married",
                    },
                    {
                      value: "single",
                      label: "Single",
                    },
                  ]}
                  style={{ width: "80%" }}
                />
                {submitClicked && !user?.marital_status ? (
                  <p className="text-danger">Please select marital status</p>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label>Work Experience</label>
                <br />
                <Input
                  type="text"
                  name="work_experience"
                  value={user?.work_experience}
                  disabled={!user?.job_openings_id}
                  onChange={handleChange}
                  style={{ width: "80%" }}
                />
              </div>

              <div className="d-flex justify-content-end w-full mt-4 mr-5">
                <button className="btn btn-success" onClick={handleSubmit}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;

import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Nodata from "../../../Components/NoData/Nodata";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
} from "../../../utils/Academics.apiConst";
import {
  ACCOUNT_FEE_TYPE_AMOUNT,
  ACCOUNT_FEE_GROUP,
  FEE_TYPE_AMOUNT,
} from "../../../utils/Accounts.apiConst";
import { STUDENT_ADMISSION } from "../../../utils/apiConstants";
import { FEE_STUDENT, ASSIGN_FEE_MASTER } from "../../../utils/fees.apiConst";
import {
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
  LOCAL_EMPLOYEE,
} from "../../../utils/LocalStorageConstants";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";

const FeeCollectionAssignFee = ({ setLoading, collegeId }) => {
  //Master is Fetching using States of useLocation

  const navigate = useNavigate();
  const location = useLocation();

  const emp_id = sessionStorage.getItem("employee_id");

  const data = location.state;

  const [feeTypeOpt, setFeeTypeOpt] = useState([]);

  const [classOpt, setClassOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [studentData, setStudentData] = useState([]);

  const [department, setDepartment] = useState("");

  const [feeGroupData, setFeeGroupData] = useState();

  const [session, setSession] = useState("");

  const [status, setStatus] = useState("ACTIVE");

  const [nationality, setNationality] = useState("");

  const [promotes, setPromotes] = useState([]);

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [filteredStudentData, setFilteredStudentData] = useState([true]);

  const [class_id, setClassId] = useState("");
  const [semesterId, setSemesterId] = useState("");

  const [selectedArr, setSelectedArr] = useState([]);

  const [studentFeeData, setStudentFeeData] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  const [employee, setEmployee] = useState(
    JSON.parse(localStorage.getItem(LOCAL_EMPLOYEE))
  );

  console.log(employee);

  const getDepartmentOpt = () => {
    return localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;
  };
  const getProgramOpt = () => {
    return localStorage.getItem(LOCAL_PROGRAM)
      ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
      : null;
  };

  const departmentOpt = getDepartmentOpt().filter(
    (s) => s.college_id == collegeId
  );
  const programOpt = getProgramOpt();

  const getAllDatas = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    const [data1, data2, data3, data4] = await Promise.all([
      await axios({
        ...config,
        url: `${FEE_TYPE_AMOUNT}?college_id=${collegeId}`,
      })
        .then((res) => {
          setFeeTypeOpt(res.data.data);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        }),
      await axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${collegeId}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        }),
      await axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${collegeId}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),
      await axios({
        ...config,
        url: `${ACCOUNT_FEE_GROUP}`,
      })
        .then((res) => {
          setFeeGroupData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),
    ]);
    setLoading(0);
  };

  const getStudentsData = async () => {
    if (!session) return toast.error("Please Select Session");
    if (!department) return toast.error("Please Select Department");
    if (!class_id) return toast.error("Please Select Class");
    if (!semesterId) return toast.error("Please Select Semester");

    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      // Fetch all data
      setLoading(1);
      const response = await axios({
        url: `${STUDENT_ADMISSION}/session/all?college_id=${collegeId}&department_id=${department}&class_id=${class_id}&semester_id=${semesterId}&session_id=${session}&status_student=${status}`,
        ...config,
      });

      let responseData = response.data.data;

      console.log("student data - ", response.data.data);
      setStudentData(responseData);

      setStudentFeeData(responseData?.filter((s) => s?.fee_id == data?.id));
      const selectedUserIds = responseData
        ?.filter((i) => i?.fee_id == data?.id)
        ?.map((i) => i.user_id);
      setSelectedArr(selectedUserIds);

      let studentAmountData = [];

      let amountData = [];

      console.log("data - ", data);

      for (let i in data?.fee_type) {
        amountData.push({
          fee_amount_type_id: data?.fee_type[i],
          fee_type_id: data?.fee_type_details?.find(
            (s) => s.id == data?.fee_type[i]
          )?.fee_type_id,
          amount: feeTypeOpt.find((s) => s.id == data?.fee_type[i])?.amount,
          initial_amount: feeTypeOpt.find((s) => s.id == data?.fee_type[i])
            ?.amount,
        });
      }

      for (let i in responseData) {
        responseData[i]["fee"] = amountData;
      }

      console.log("student amount data - ", responseData);

      setFilteredStudentData(responseData);
      setLoading(0);
    } catch (err) {
      setLoading(0);
      console.log(err);
      toast.error("Something Went Wrong");
    }
  };

  // Call getStudentsData when the search button is clicked
  const handleSearchClick = () => {
    getStudentsData();
  };

  useEffect(() => {}, [selectedArr]);

  const handleSelectedArr = (e, eid) => {
    if (e.target.checked == false) {
      if (selectedArr.length == 0) return;
      if (selectedArr.length == 1) {
        setSelectedArr([]);
        return;
      }
      const d = [];
      for (let index = 0; index < selectedArr.length; index++) {
        if (eid !== selectedArr[index]) {
          d.push(selectedArr[index]);
        }
      }
      setSelectedArr(d);
    }
    if (e.target.checked == true) {
      setSelectedArr([...selectedArr, eid]);
    }
  };

  const handleSubmit = async (i) => {
    console.log("selected array - ", selectedArr);

    if (!data?.id || !data.fee_group_id) {
      return toast.error("Bad Request");
    }
    if (promotes.length == 0) {
      return toast.error("No students Selected");
    }
    const config = {
      method: "post",
      url: ASSIGN_FEE_MASTER,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        students: promotes,
        fee_master_id: data.id,
        fee_group_id: data?.fee_group_id,
        assigned_by: emp_id,
      },
    };
    console.log("data to backend - ", {
      students: promotes,
      fee_master_id: data.id,
      fee_group_id: data?.fee_group_id,
      assigned_by: emp_id,
    });
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        handleSearchClick();
        setPromotes([]);
        toast.success("Success");
      })
      .catch((err) => {
        setLoading(0);
        handleSearchClick();
        setPromotes([]);
        toast.error("Something Went Wrong");
      });
  };

  const toggleSelectAll = () => {
    setSelectAllChecked((prev) => !prev);
    // console.log('data - ', filteredStudentData);
    const updatedPromotes = filteredStudentData
      ?.filter((s) => !s.fee_id)
      ?.map((d) => ({
        id: d.student_semester_id,
        fee: d.fee,
      }));
    setPromotes(selectAllChecked ? [] : updatedPromotes);
  };

  const togglePromotion = (i) => {
    const isPromoted = promotes?.some((d) => d.id === i?.student_semester_id);

    if (isPromoted) {
      setPromotes((prevPromotes) =>
        prevPromotes?.filter((student) => student.id != i?.student_semester_id)
      );
    } else {
      setPromotes((prevPromotes) => [
        ...prevPromotes,
        { id: i?.student_semester_id, fee: i?.fee },
      ]);
    }
  };

  const handleAmountChange = (e, studentSemesterId, feeIndex, val) => {
    const newValue = val ? val : e.target.value;
    const updatedData = filteredStudentData.map((student) => {
      if (student.student_semester_id === studentSemesterId) {
        return {
          ...student,
          fee: student.fee.map((fee, index) => {
            if (index === feeIndex) {
              if (1)
                return {
                  ...fee,
                  amount: newValue,
                };
            }
            return fee;
          }),
        };
      }
      return student;
    });
    setFilteredStudentData(updatedData);
  };

  useEffect(() => {
    console.log("student amount data - ", filteredStudentData);
  }, [filteredStudentData]);

  useEffect(() => {
    if (selectAll == true) {
      if (studentData.length != 0) {
        setSelectedArr(
          studentData
            ?.filter(
              (s) =>
                s.sem_status == "ACTIVE" && (s.fee_id == null || s.fee_id == 0)
            )
            ?.map((i) => i?.student_semester_id)
        );
      }
    } else {
      setSelectedArr([]);
    }
  }, [selectAll]);

  useEffect(() => {
    console.log("selected students - ", promotes);
  }, [promotes]);

  useEffect(() => {
    getAllDatas();
  }, []);

  return (
    <div className="FeeCollectionAssignFee">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center">
                <button
                  className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <i className="ri-arrow-left-line"></i>
                </button>
                <h4 className="mb-0">Assign Fee Master</h4>
              </div>
            </div>
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Assign Students</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Fee Collection</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        <a href="javascript:void(0)"> Fee Master</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Assign Students
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select criteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">
                            Session
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            value={session}
                            onChange={(e) => {
                              setSession(e.target.value);
                            }}
                            className="form-control"
                          >
                            <option value="">Select Session</option>
                            {sessionOpt?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">
                            Department<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            value={department}
                            onChange={(e) => {
                              setDepartment(e.target.value);
                            }}
                            className="form-control"
                          >
                            <option value="">Select Department</option>
                            {departmentOpt?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name},{" "}
                                {
                                  programOpt?.find((s) => s.id == i.program_id)
                                    ?.name
                                }
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Class<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            value={class_id}
                            onChange={(e) => {
                              setClassId(e.target.value);
                            }}
                            className="form-control"
                          >
                            <option value=""> Select Class</option>
                            {classOpt
                              ?.filter((s) => s?.department_id == department)
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">
                            Semester<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            value={semesterId}
                            onChange={(e) => {
                              setSemesterId(e.target.value);
                            }}
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == class_id)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex float-right"
                          type="submit"
                          name="submit"
                          value="fees"
                          onClick={handleSearchClick}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>
            {/* container-fluid */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <h5>
                          {
                            feeGroupData?.find(
                              (s) => s.id == data?.fee_group_id
                            )?.name
                          }{" "}
                          -{" "}
                          {
                            feeGroupData?.find(
                              (s) => s.id == data?.fee_group_id
                            )?.description
                          }
                        </h5>
                      </div>
                      <div className="col-md-8">
                        <button
                          type="submit"
                          className="allot-fees btn btn-success pull-right mr-3 mb-3"
                          onClick={handleSubmit}
                        >
                          <i className="fa fa-save " /> Save{" "}
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        {/* <div > */}
                        <table id="datatable" className="table table-hover ">
                          <tbody>
                            <tr>
                              <th>All</th>
                              <th>
                                <div className="checkbox mb0 mt0">
                                  <input
                                    type="checkbox"
                                    id="select-all"
                                    checked={selectAllChecked}
                                    onChange={toggleSelectAll}
                                  />
                                </div>
                              </th>
                              <th>Admission No.</th>
                              <th>Student Name</th>
                              {data?.fee_type?.map((i, key) => (
                                <th>
                                  <b>
                                    {
                                      feeTypeOpt.find((s) => s.id == i)
                                        ?.fee_type_id
                                    }
                                    (
                                    {feeTypeOpt
                                      .find((s) => s.id == i)
                                      ?.amount?.toLocaleString("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 0,
                                      })}
                                    )
                                  </b>
                                </th>
                              ))}
                              <th>Total Fee</th>
                            </tr>
                            {filteredStudentData?.map((i, key) => (
                              <tr
                                key={key}
                                style={{
                                  backgroundColor: `${
                                    i?.fee?.reduce(
                                      (total, i) => total + parseInt(i?.amount),
                                      0
                                    ) ==
                                    data?.fee_type?.reduce((acc, fee) => {
                                      const feeType = feeTypeOpt.find(
                                        (s) => s.id == fee
                                      );
                                      if (feeType) {
                                        acc += feeType.amount;
                                      }
                                      return acc;
                                    }, 0)
                                      ? "white"
                                      : "#fffdd0"
                                  }`,
                                }}
                              >
                                <td>{key + 1}</td>
                                <td>
                                  <div className="checkbox d-flex mb0 mt0">
                                    {0 ? (
                                      <input
                                        type="checkbox"
                                        checked={false}
                                        disabled={true}
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        checked={promotes?.some(
                                          (student) =>
                                            student.id === i.student_semester_id
                                        )}
                                        disabled={i.fee_id}
                                        onChange={() => togglePromotion(i)}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td>{i?.user_id}</td>
                                <td>{i?.name}</td>
                                {i?.fee_id ? (
                                  <>
                                    <td colSpan={i?.fee?.length + 1}>
                                      Fee Master Already Assigned
                                    </td>
                                    <td>
                                      {
                                        employee?.find(
                                          (s) => s.id == i?.assigned_by
                                        )?.first_name
                                      }{" "}
                                      {
                                        employee?.find(
                                          (s) => s.id == i?.assigned_by
                                        )?.last_name
                                      }
                                    </td>
                                  </>
                                ) : (
                                  i?.fee?.map((j, key) => {
                                    return (
                                      <td key={key}>
                                        <input
                                          type="number"
                                          value={j?.amount}
                                          disabled={promotes?.some(
                                            (student) =>
                                              student.id ===
                                              i.student_semester_id
                                          )}
                                          onChange={(e) =>
                                            handleAmountChange(
                                              e,
                                              i?.student_semester_id,
                                              key,
                                              0
                                            )
                                          }
                                          onFocus={(e) => {}}
                                          onBlur={(e) => {
                                            console.log("im called");
                                            if (j?.amount < j?.initial_amount) {
                                              handleAmountChange(
                                                e,
                                                i?.student_semester_id,
                                                key,
                                                j?.initial_amount
                                              );
                                            }
                                          }}
                                        />
                                      </td>
                                    );
                                  })
                                )}
                                {i?.fee_id ? (
                                  <></>
                                ) : (
                                  <td>
                                    {i?.fee
                                      ?.reduce(
                                        (total, i) =>
                                          total + parseInt(i?.amount),
                                        0
                                      )
                                      ?.toLocaleString("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 0,
                                      })}
                                  </td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {studentData.length == 0 ? <Nodata /> : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* end col */}
          </div>{" "}
          {/* end row */}
        </div>
        {/* End Page-content */}
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">© College Name</div>
              <div className="col-sm-6">
                <div className="text-sm-right d-none d-sm-block">
                  Crafted with <i className="mdi mdi-heart text-danger" /> by{" "}
                  <a href="https://www.nexenstial.com" target="_blank">
                    Nexenstial LLP.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    </div>
  );
};

export default FeeCollectionAssignFee;

import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Flex } from "antd";
export default function ModalStaffBulkTemplateDownload({
  showBulkTemplate,
  setShowBulkTemplate,
}) {
  return (
    <>
      <Modal
        title="Download CSV Template of Staff Bulk Upload"
        centered
        open={showBulkTemplate}
        onOk={() => setShowBulkTemplate(false)}
        onCancel={() => setShowBulkTemplate(false)}
        footer={null}
        width={700}
        zIndex={1021}
      >
        <br />

        <p>
          1. All the Fields mentioned in CSV file are required before Upload.
        </p>
        <p>
          2. Department id of Particular college is mentioned in{" "}
          <Link to={"/department"}>Department List</Link>
        </p>
        <p>
          3. Accepted Date Formats{" "}
          <b>( DD-MM-YYYY , D-M-YYYY , DD/MM/YYYY , D/M/YYYY)</b>
        </p>

        <br />

        <Flex justify="center" align="center">
          <a
            href="https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/kbn_assets/templets/sample_staff.csv"
            download
          >
            <Button
              type="primary"
              style={{ backgroundColor: "#1cbb8c", color: "#fff" }}
            >
              <Flex justify="center" align="center" gap={4}>
                <i class="ri-file-excel-2-fill"></i>
                <span>Download Template</span>
              </Flex>
            </Button>
          </a>
        </Flex>
        <br />
      </Modal>
    </>
  );
}

import React from "react";
import axios from "axios";
// import { toast } from "react-toastify";
import { useState, useEffect } from "react";
// import EditBasicSalaryModal from "./EditBasicSalaryModal";
import { PAYROLL_SALARY } from "../utils/apiConstants";
// import logo from "./../assets/images/reports/profile.png";

function ViewBasicSalaryModal({ modalData, earningData, deductionData }) {
  const [loading, setLoading] = useState(0);

  // Function to display the amount or percentage based on the earning/deduction type
  const getValueByType = (items, title, type) => {
    if (!items || !items[title]) {
      return "-"; // Return default if items or specific title is not defined
    }

    const item = items[title];

    return type === "amount" ? item.amount || "-" : item.percentage || "-";
  };

  const [userSalary, setUserSalary] = useState();

  const id = modalData ? modalData?.employee_id : "";

  const getEmployeeSalary = async () => {
    const config = {
      method: "get",
      url: `${PAYROLL_SALARY}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        let s = res.data.data[0];
        setUserSalary(s);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });
  };

  useEffect(() => {
    getEmployeeSalary();
  }, [modalData]);

  console.log("modalData -", modalData);
  console.log("id -", id);
  console.log("userSalary -", userSalary);

  return (
    <div
      className="modal fade"
      id="ViewBasicSalaryModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      {/* <EditBasicSalaryModal editData={editData} /> */}
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div className="modal-content">
          {/* <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              STAFF BASIC SALARY
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div> */}

          <div className="modal-header">
            <div className="row w-100">
              <div className="col-6">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  STAFF BASIC SALARY
                </h5>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <button
                  type="button"
                  className="close ml-2"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
          </div>

          <div className="modal-body">
            <table className="table table-bordered table-sm mt-2">
              <tbody>
                <tr>
                  <td
                    rowSpan={4}
                    className="align-middle text-center"
                    width="150"
                  >
                    <img
                      src={
                        modalData?.photo
                          ? modalData?.photo
                          : // : "../assets/images/reports/profile.png"
                            "../assets/images/user_default_img.png"
                      }
                      alt="Profile"
                      className="img-fluid rounded"
                    />
                  </td>
                  <td colSpan={3}>
                    <strong>Name:</strong> {modalData?.employee_name}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <strong>Department:</strong> {modalData?.department_name}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Designation:</strong>{" "}
                    {modalData?.designation || modalData?.role}
                  </td>
                  <td>
                    <strong>Employee Category:</strong>{" "}
                    {modalData?.employee_category}
                  </td>
                  <td>
                    <strong>Group:</strong> {modalData?.group}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Basic Salary:</strong>{" "}
                    {userSalary ? userSalary?.basic_salary : ""}
                  </td>
                  <td>
                    <strong>Pay Band:</strong>{" "}
                    {userSalary ? userSalary?.pay_band : ""}
                  </td>
                  <td>
                    <strong>Grade:</strong>{" "}
                    {userSalary ? userSalary?.grade : ""}
                  </td>
                </tr>
                {/* Earnings and Deductions */}
                <tr>
                  <td colSpan={4}>
                    <div className="row mt-4">
                      {/* Earnings Section */}
                      <div className="col-6">
                        <h6 className="text-center">Earnings</h6>
                        <table className="table table-bordered table-sm">
                          <thead>
                            <tr>
                              {earningData.map((earning, index) => (
                                <th key={index} className="text-center">
                                  {earning.title}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {earningData.map((earning, index) => {
                                const amount = getValueByType(
                                  modalData.earnings,
                                  earning.title,
                                  "amount"
                                );
                                const percentage = getValueByType(
                                  modalData.earnings,
                                  earning.title,
                                  "percentage"
                                );
                                return (
                                  <td key={index} className="text-center">
                                    {amount !== "-"
                                      ? `${amount}`
                                      : percentage !== "-"
                                      ? `${percentage}%`
                                      : "-"}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {/* Deductions Section */}
                      <div className="col-6">
                        <h6 className="text-center">Deductions</h6>
                        <table className="table table-bordered table-sm">
                          <thead>
                            <tr>
                              {deductionData.map((deduction, index) => (
                                <th key={index} className="text-center">
                                  {deduction.title}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {deductionData.map((deduction, index) => {
                                const amount = getValueByType(
                                  modalData.deductions,
                                  deduction.title,
                                  "amount"
                                );
                                const percentage = getValueByType(
                                  modalData.deductions,
                                  deduction.title,
                                  "percentage"
                                );
                                return (
                                  <td key={index} className="text-center">
                                    {amount !== "-"
                                      ? `${amount}`
                                      : percentage !== "-"
                                      ? `${percentage}%`
                                      : "-"}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewBasicSalaryModal;

import {
  CaretDownOutlined,
  CodeOutlined,
  DownloadOutlined,
  MoreOutlined,
  ReloadOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, message, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { RiFileTransferLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { INVENTORY_PURCHASE_ORDER } from "../../../utils/Inventory.apiConst";
import axios from "axios";
import { toast } from "react-toastify";
const PurchaseOrder = ({ setLoading }) => {
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState("All");
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const navigate = useNavigate();
  const handleButtonClick = (e) => {
    // message.info("Click on left button.");
    // console.log("click left button", e);
    navigate("create");
  };
  const handleMenuClick = (e) => {
    message.info("Click on menu item.");
    console.log("click", e);
  };

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_PURCHASE_ORDER}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        console.log("Purchase Order Data", res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Purchase Order#",
      dataIndex: "purchase_order",
    },
    {
      title: "Reference",
      dataIndex: "reference",
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Received",
      dataIndex: "received",
    },
    {
      title: "Billed",
      dataIndex: "billed",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, record, index) => (
        <div>{record?.total_amount_data?.total}</div>
      ),
    },
    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  console.log("setSelectedRowKeys", selectedRowKeys);
  const handleDelete = () => {
    const newData = data.filter((item) => !selectedRowKeys.includes(item.id));
    console.log("newData", newData);
    setData(newData);
    setSelectedRowKeys([]); // Clear selection after deletion
  };

  const itemsMore = [
    {
      key: "1",
      label: "Sort buy",
      children: [
        {
          key: "1-1",
          label: "Created Time",
        },
        {
          key: "1-2",
          label: "Date",
        },
        {
          key: "1-3",
          label: "Purchase Order",
        },
        {
          key: "1-4",
          label: "Vendor Name",
        },
        {
          key: "1-5",
          label: "Amount",
        },
        {
          key: "1-6",
          label: "Delivery Date",
        },
        {
          key: "1-7",
          label: "Last Modified Time",
        },
      ],
    },
    {
      type: "divider",
    },
    {
      key: "2",
      icon: <DownloadOutlined />,
      label: "Import Purchase Orders",
    },
    {
      key: "3",
      icon: <UploadOutlined />,
      label: "Export Purchase Orders",
    },
    {
      type: "divider",
    },
    {
      key: "4",
      icon: <DownloadOutlined />,
      label: "Import Purchase Receives",
    },
    {
      key: "5",
      icon: <UploadOutlined />,
      label: "Export Purchase Receives",
    },
    {
      type: "divider",
    },
    {
      key: "6",
      icon: <UploadOutlined />,
      label: "Export Current View",
    },
    {
      type: "divider",
    },
    {
      key: "7",
      icon: <SettingOutlined />,
      label: "Preferences",
    },
    {
      key: "8",
      icon: <CodeOutlined />,
      label: "Manage Custom Fields",
    },
    { type: "divider" },
    {
      key: "9",
      icon: <ReloadOutlined />,
      label: "Refresh List",
    },
  ];
  const items = [
    {
      label: "Create New Purchase Order",
      key: "1",
    },
    {
      label: "Create New Purchase Order(Pre GST)",
      key: "2",
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  useEffect(() => {
    getData();
  }, []);
  // console.log("data", data);
  return (
    <div className="PurchaseOrder">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0"> Purchase Order </h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row d-flex border-bottom justify-content-between">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="validationCustom01"
                          >
                            <h6 className="mb-0">
                              {selectedPurchaseOrder} Purchase Order
                            </h6>
                          </label>
                          <Select
                            className="w-100"
                            showSearch
                            placeholder="Select a person"
                            optionFilterProp="label"
                            onChange={(value) =>
                              setSelectedPurchaseOrder(value)
                            }
                            value={selectedPurchaseOrder}
                            // onSearch={onSearch}
                            options={[
                              {
                                value: "All",
                                label: "All",
                              },
                              {
                                value: "Draft",
                                label: "Draft",
                              },
                              {
                                value: "Pending Approval",
                                label: "Pending Approval",
                              },
                              {
                                value: "Approved",
                                label: "Approved",
                              },
                              {
                                value: "Issued",
                                label: "Issued",
                              },
                              {
                                value: "Partially Billed",
                                label: "Partially Billed",
                              },
                              {
                                value: "Closed",
                                label: "Closed",
                              },
                              {
                                value: "Cancelled",
                                label: "Cancelled",
                              },
                              {
                                value: "Partially Received",
                                label: "Partially Received",
                              },
                              {
                                value: "Received",
                                label: "Received",
                              },
                              {
                                value: "Pending Received",
                                label: "Pending Received",
                              },
                              {
                                value: "Dropshiped",
                                label: "Dropshiped",
                              },
                              {
                                value: "Backorder",
                                label: "Backorder",
                              },
                              {
                                value: "Unbilled Backorder",
                                label: "Unbilled Backorder",
                              },
                              {
                                value: "Billed & Not Received",
                                label: "Billed & Not Received",
                              },
                              {
                                value: "Received & Not Billed",
                                label: "Received & Not Billed",
                              },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-2 align-items-center px-2">
                        <button className="btn text-nowrap btn-outline-primary d-flex gap-2 align-items-center">
                          <RiFileTransferLine />
                          In Transit Receives
                        </button>

                        <Dropdown.Button
                          type="primary"
                          menu={menuProps}
                          onClick={handleButtonClick}
                          icon={<CaretDownOutlined />}
                        >
                          + New
                        </Dropdown.Button>
                        {/* <button
                          onClick={() => {
                            setType("add");
                            setEdit();
                          }}
                          className="btn btn-primary text-nowrap"
                          data-toggle="modal"
                          data-target="#ModalPurchaseOrder"
                        >
                          Add +
                        </button> */}
                        <Dropdown
                          menu={{
                            items: itemsMore,
                            onClick: handleMenuClick,
                          }}
                        >
                          <a
                            className="btn btn-outline-secondary"
                            onClick={(e) => e.preventDefault()}
                          >
                            <MoreOutlined />
                          </a>
                        </Dropdown>
                      </div>
                    </div>

                    {data && data.length > 0 ? (
                      <div className="d-flex flex-column gap-2 my-2">
                        <div>
                          <Button
                            type="primary"
                            danger
                            onClick={handleDelete}
                            disabled={selectedRowKeys.length === 0}
                          >
                            Delete Selected
                          </Button>
                        </div>
                        <Table
                          rowSelection={{
                            type: "checkbox",
                            ...rowSelection,
                          }}
                          columns={columns}
                          dataSource={data}
                          rowKey={(record) => record.id}
                          onRow={(record) => {
                            return {
                              onClick: () => {
                                navigate(`view/details/${record.id}`);
                              },
                            };
                          }}
                        />
                      </div>
                    ) : (
                      <div className="d-flex my-5 p-5 flex-column justify-content-center align-items-center">
                        <h3>Start Managing Your Purchase Activities!</h3>
                        <p>
                          Create, customize, and send professional Purchase
                          Orders to your vendors.
                        </p>
                        <button className="btn btn-primary">
                          CREATE NEW PURCHASE ORDER
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrder;

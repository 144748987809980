import { Select, Table, Tag } from "antd";
import React, { useState } from "react";

const columns = [
  {
    title: "Data From",
    dataIndex: "dataFrom",
    key: "dataFrom",
  },
  {
    title: "QIF",
    dataIndex: "QIF",
    key: "QIF",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, { status }) => (
      <>
        {status.map((tag, index) => (
          <div
            key={index}
            className={`p-2 badge text-center w-100 rounded text-white ${
              tag === "Not Started"
                ? "bg-secondary"
                : tag === "In Draft"
                ? "bg-primary"
                : tag === "Submitted"
                ? "bg-warning"
                : tag === "Rejected"
                ? "bg-danger"
                : tag === "Approved"
                ? "bg-success"
                : "bg-info"
            }`}
          >
            {tag}
          </div>
        ))}
      </>
    ),
  },
  {
    title: "QnM Weightage",
    dataIndex: "QnMWeightage",
    key: "QnMWeightage",
  },
  {
    title: "QnM Cr. GPA",
    dataIndex: "QnMCrGPA",
    key: "QnMCrGPA",
  },
  {
    title: "Weighted Grade Point",
    dataIndex: "WeightedGradePoint",
    key: "WeightedGradePoint",
  },
  {
    title: "QlM Weightage",
    dataIndex: "QlMWeightage",
    key: "QlMWeightage",
  },
  {
    title: "QlM Cr. GPA",
    dataIndex: "QlMCrGPA",
    key: "QlMCrGPA",
  },
  {
    title: "Total Cr. GPA",
    dataIndex: "TotalCrGPA",
    key: "TotalCrGPA",
  },
];

const data = [
  {
    key: "Engineering",
    dataFrom: "Engineering",
    status: [],
    children: [
      {
        key: "ENG 1.1",
        QIF: "1",
        dataFrom: "",
        status: ["Not Started"],
        QnMWeightage: "30%",
        QnMCrGPA: "3.5",
        WeightedGradePoint: "2.9",
        QlMWeightage: "70%",
        QlMCrGPA: "4.0",
        TotalCrGPA: "3.8",
        children: [
          {
            key: "ENG 1.1.1",
            QIF: "1.1",
            status: ["Not Started"],
            QnMWeightage: "20%",
            QnMCrGPA: "3.2",
            WeightedGradePoint: "2.7",
            QlMWeightage: "80%",
            QlMCrGPA: "4.0",
            TotalCrGPA: "3.8",
          },
          {
            key: "ENG 1.1.2",
            QIF: "1.2",
            status: ["In Draft"],
            QnMWeightage: "25%",
            QnMCrGPA: "3.0",
            WeightedGradePoint: "2.5",
            QlMWeightage: "75%",
            QlMCrGPA: "3.8",
            TotalCrGPA: "3.5",
          },
        ],
      },
      {
        key: "ENG 2",
        QIF: "2",
        dataFrom: "",
        status: ["Submitted"],
        QnMWeightage: "40%",
        QnMCrGPA: "3.6",
        WeightedGradePoint: "2.8",
        QlMWeightage: "60%",
        QlMCrGPA: "4.0",
        TotalCrGPA: "3.7",
        children: [
          {
            key: "ENG 2.1.1",
            QIF: "2.1",
            status: ["Submitted"],
            QnMWeightage: "20%",
            QnMCrGPA: "3.2",
            WeightedGradePoint: "2.7",
            QlMWeightage: "80%",
            QlMCrGPA: "4.0",
            TotalCrGPA: "3.8",
          },
          {
            key: "ENG 2.1.2",
            QIF: "2.2",
            status: ["In Draft"],
            QnMWeightage: "25%",
            QnMCrGPA: "3.0",
            WeightedGradePoint: "2.5",
            QlMWeightage: "75%",
            QlMCrGPA: "3.8",
            TotalCrGPA: "3.5",
          },
        ],
      },
      // {
      //   key: "3",
      //   QIF: "3",
      //   dataFrom: "",
      //   status: ["Not Started"],
      //   QnMWeightage: "50%",
      //   QnMCrGPA: "3.1",
      //   WeightedGradePoint: "2.9",
      //   QlMWeightage: "50%",
      //   QlMCrGPA: "3.9",
      //   TotalCrGPA: "3.5",
      // },
    ],
  },
  {
    key: "MBA",
    dataFrom: "MBA",
    status: [],
    children: [
      {
        key: "MBA 1.1",
        QIF: "1",
        dataFrom: "",
        status: ["Not Started"],
        QnMWeightage: "30%",
        QnMCrGPA: "3.5",
        WeightedGradePoint: "2.9",
        QlMWeightage: "70%",
        QlMCrGPA: "4.0",
        TotalCrGPA: "3.8",
        children: [
          {
            key: "MBA 1.1.1",
            QIF: "1.1",
            status: ["Not Started"],
            QnMWeightage: "20%",
            QnMCrGPA: "3.2",
            WeightedGradePoint: "2.7",
            QlMWeightage: "80%",
            QlMCrGPA: "4.0",
            TotalCrGPA: "3.8",
          },
          {
            key: "MBA 1.1.2",
            QIF: "1.2",
            status: ["In Draft"],
            QnMWeightage: "25%",
            QnMCrGPA: "3.0",
            WeightedGradePoint: "2.5",
            QlMWeightage: "75%",
            QlMCrGPA: "3.8",
            TotalCrGPA: "3.5",
          },
        ],
      },
      {
        key: "MBA 2",
        QIF: "2",
        dataFrom: "",
        status: ["Submitted"],
        QnMWeightage: "40%",
        QnMCrGPA: "3.6",
        WeightedGradePoint: "2.8",
        QlMWeightage: "60%",
        QlMCrGPA: "4.0",
        TotalCrGPA: "3.7",
        children: [
          {
            key: "MBA 2.1.1",
            QIF: "2.1",
            status: ["Submitted"],
            QnMWeightage: "20%",
            QnMCrGPA: "3.2",
            WeightedGradePoint: "2.7",
            QlMWeightage: "80%",
            QlMCrGPA: "4.0",
            TotalCrGPA: "3.8",
          },
          {
            key: "MBA 2.1.2",
            QIF: "2.2",
            status: ["In Draft"],
            QnMWeightage: "25%",
            QnMCrGPA: "3.0",
            WeightedGradePoint: "2.5",
            QlMWeightage: "75%",
            QlMCrGPA: "3.8",
            TotalCrGPA: "3.5",
          },
        ],
      },
      // {
      //   key: "3",
      //   QIF: "3",
      //   dataFrom: "",
      //   status: ["Not Started"],
      //   QnMWeightage: "50%",
      //   QnMCrGPA: "3.1",
      //   WeightedGradePoint: "2.9",
      //   QlMWeightage: "50%",
      //   QlMCrGPA: "3.9",
      //   TotalCrGPA: "3.5",
      // },
    ],
  },
];

export default function PerformanceTracking() {
  const [expandedRowKeys, setExpandedRowKeys] = useState([
    "MBA",
    "Engineering",
  ]);
  return (
    <div className="mb-4">
      <div className="shadow-xl p-3 border my-2 mb-16 bg-white ">
        <div className="d-flex flex-column mb-4">
          <div className="flex justify-content-between flex-column mb-3 align-items-start  ">
            <h3>Performance Tracking</h3>
          </div>
          <Select
            defaultValue="KLETechnologicalUniversity"
            // style={{ width: "50%" }}
            className="w-50 my-2"
            options={[
              {
                value: "KLETechnologicalUniversity",
                label: "KLE Technological University",
              },
              {
                value: "engineering",
                label: "Engineering",
              },
              {
                value: "hotelMangement",
                label: "Hotel Mangement",
              },
              {
                value: "law",
                label: "Law",
              },
              {
                value: "MBA",
                label: "MBA",
              },
            ]}
          />

          <hr />

          {/* Table */}
          <div className="table-responsive">
            <Table
              className="table"
              columns={columns}
              dataSource={data}
              expandable={{
                expandedRowKeys,
                onExpandedRowsChange: (expandedRows) => {
                  console.log("expandedRowKeys", expandedRowKeys);
                  console.log("expandedRows", expandedRows);
                  setExpandedRowKeys(expandedRows);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

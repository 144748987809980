import { Menu } from "antd";
import {
  BarChartOutlined,
  UnorderedListOutlined,
  DatabaseOutlined,
  DownloadOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { EMPLOYEE_NAAC } from "../../utils/apiConstants";
import axios from "axios";
import { SESSION_EMPLOYEE_ID } from "../../utils/sessionStorageContants";

const NAAC = ({ setLoading }) => {
  const [isAuthor, setIsAuthor] = useState("");
  const emp_id = sessionStorage.getItem(SESSION_EMPLOYEE_ID);
  const navigate = useNavigate();
  const location = useLocation();
  let roleName = sessionStorage.getItem("role");
  let role =
    roleName === "SUPERADMIN" || roleName === "registarar"
      ? "registarar"
      : roleName === "Pincipal" ||
        roleName === "Principal" ||
        roleName === "PRINC"
      ? "principal"
      : "employee";
  const getIsAuthorOrReviewer = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE_NAAC}/author-or-reviewer/${emp_id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then(async (res) => {
        // console.log("isAuthorOrReviewer", res?.data?.isAuthorOrReviewer);
        setIsAuthor(res?.data?.isAuthorOrReviewer);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const items =
    role === "registarar"
      ? [
          {
            key: "dashboard",
            label: "Dashboard",
            icon: <BarChartOutlined />,
            children: [
              {
                key: `/${role}/naac/activity-tracking`,
                label: "Activity Tracking",
                icon: <ArrowRightOutlined />,
              },
              {
                key: `/${role}/naac/performance-tracking`,
                label: "Performance Tracking",
                icon: <ArrowRightOutlined />,
              },
              {
                key: `/${role}/naac/status-reports`,
                label: "Status Reports",
                icon: <ArrowRightOutlined />,
              },
            ],
          },
          {
            key: "data-collection",
            label: "Data Collection",
            icon: <UnorderedListOutlined />,
            children: [
              {
                key: "naac-data",
                label: "NAAC Data",
                icon: <DatabaseOutlined />,
                children: [
                  {
                    key: `/${role}/naac/executive-summary`,
                    label: "Executive Summary",
                    icon: <ArrowRightOutlined />,
                  },
                  {
                    key: `/${role}/naac/profile`,
                    label: "Profile",
                    icon: <ArrowRightOutlined />,
                  },
                  {
                    key: `/${role}/naac/extended-profile`,
                    label: "Extended Profile",
                    icon: <ArrowRightOutlined />,
                  },
                  {
                    key: `/${role}/naac/nep-parameters`,
                    label: "NEP Parameters",
                    icon: <ArrowRightOutlined />,
                  },
                  {
                    key: `/${role}/naac/qif-qualitative`,
                    label: "QIF Qualitative",
                    icon: <ArrowRightOutlined />,
                  },
                  {
                    key: `/${role}/naac/qif-quantitative`,
                    label: "QIF Quantitative",
                    icon: <ArrowRightOutlined />,
                  },
                ],
              },
              {
                key: `/${role}/naac/my-downloads`,
                label: "My Downloads",
                icon: <DownloadOutlined />,
              },
            ],
          },
        ]
      : [
          {
            key: "data-collection",
            label: "Data Collection",
            icon: <UnorderedListOutlined />,
            children: [
              {
                key: "naac-data",
                label: "NAAC Data",
                icon: <DatabaseOutlined />,
                children: [
                  {
                    key: `/${role}/naac/executive-summary`,
                    label: "Executive Summary",
                    icon: <ArrowRightOutlined />,
                  },
                  {
                    key: `/${role}/naac/profile`,
                    label: "Profile",
                    icon: <ArrowRightOutlined />,
                  },
                  {
                    key: `/${role}/naac/extended-profile`,
                    label: "Extended Profile",
                    icon: <ArrowRightOutlined />,
                  },
                  {
                    key: `/${role}/naac/nep-parameters`,
                    label: "NEP Parameters",
                    icon: <ArrowRightOutlined />,
                  },
                  {
                    key: `/${role}/naac/qif-qualitative`,
                    label: "QIF Qualitative",
                    icon: <ArrowRightOutlined />,
                  },
                  {
                    key: `/${role}/naac/qif-quantitative`,
                    label: "QIF Quantitative",
                    icon: <ArrowRightOutlined />,
                  },
                ],
              },
              {
                key: `/${role}/naac/my-downloads`,
                label: "My Downloads",
                icon: <DownloadOutlined />,
              },
            ],
          },
        ];
  let arrayOfKeys = [
    `/${role}/naac/activity-tracking`,
    `/${role}/naac/performance-tracking`,
    `/${role}/naac/status-reports`,
    `/${role}/naac/executive-summary`,
    `/${role}/naac/extended-profile`,
    `/${role}/naac/profile`,
    `/${role}/naac/nep-parameters`,
    `/${role}/naac/qif-qualitative`,
    `/${role}/naac/qif-quantitative`,
  ];
  const pathname = location.pathname;
  let findActiveKey = arrayOfKeys.filter((item) => pathname.includes(item));

  const handleOnclick = (e) => {
    navigate(e.key);
  };
  useEffect(() => {
    getIsAuthorOrReviewer();
  }, []);
  return (
    <div className="d-flex w-100">
      {/* Sidebar Menu */}
      <Menu
        style={{
          width: 256,
        }}
        onClick={handleOnclick}
        defaultSelectedKeys={findActiveKey}
        defaultOpenKeys={["data-collection", "dashboard", "naac-data"]}
        mode="inline"
        items={items}
      />

      {/* Content Section */}
      <div className="p-4 flex-grow-1 overflow-auto">
        <div className="min-w-100">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default NAAC;

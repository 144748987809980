import React, { useEffect, useState } from "react";
import InputTag from "../../../../Components/InputTag";
import SelectTag from "../../../../Components/SelectTag";
import { toast } from "react-toastify";
import axios from "axios";
import { NAAC } from "../../../../utils/apiConstants";
import { v4 as uuidv4 } from "uuid";

const IntegratedPrograms = ({ setUpdatedData, setLoading }) => {
  const [formData, setFormData] = useState({
    id: "",
    integrated_programs_offered: "Yes",
    total_number_of_integrated_programmes: "",
    status: "",
  });
  const emp_id = sessionStorage.getItem("employee_id");
  const handleOnchange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSaveAsDraft = async (e) => {
    e.preventDefault();
    setLoading(1);

    // Check if all required fields are filled
    if (!formData.integrated_programs_offered) {
      toast.error(
        "Please specify if the university offers integrated programs"
      );
      return;
    }
    if (!formData.total_number_of_integrated_programmes) {
      toast.error("Please enter the total number of integrated programs");
      return;
    }

    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/integrated-programs`,
      data: {
        ...formData,
        status: "DRAFT",
        university_profile_id: 1,
        created_by: emp_id,
        updated_by: emp_id,
      },
    };
    await axios(config)
      .then(() => {
        toast.success("Successfully saved as draft");
        getFormData();
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  const handleSubmitForReview = async (e) => {
    e.preventDefault();
    setLoading(1);

    if (!formData.integrated_programs_offered) {
      toast.error(
        "Please specify if the university offers integrated programs"
      );
      return;
    }
    if (!formData.total_number_of_integrated_programmes) {
      toast.error("Please enter the total number of integrated programs");
      return;
    }

    const config = {
      method: "patch",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/integrated-programs`,
      data: {
        ...formData,
        status: "REVIEW",
        university_profile_id: 1,
        created_by: emp_id,
        updated_by: emp_id,
      },
    };
    await axios(config)
      .then(() => {
        toast.success("Successfully submitted for review");
        getFormData();
        setLoading(0);
        setUpdatedData(uuidv4);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  const getFormData = async () => {
    setLoading(1);

    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/integrated-programs/1`,
    };
    await axios(config)
      .then((res) => {
        const data = res.data[0];
        if (data) {
          setFormData(data);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  useEffect(() => {
    getFormData();
  }, []);

  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex flex-column gap-2">
        <span className="fw-semibold h5"> Integrated Programmes</span>

        <span
          className={`text-white text-center px-4 py-1 shadow-sm text-xs rounded-pill ${
            formData.status === "DRAFT"
              ? "bg-primary"
              : formData.status === "REVIEW"
              ? "bg-warning"
              : formData.status === "REJECTED"
              ? "bg-danger"
              : formData.status === "APPROVED"
              ? "bg-success"
              : "bg-secondary"
          }`}
          style={{ width: "fit-content" }}
        >
          {formData.status === "DRAFT"
            ? "In Draft"
            : formData.status === "REVIEW"
            ? "Submitted for Review"
            : formData.status === "REJECTED"
            ? "Rejected"
            : formData.status === "APPROVED"
            ? "Approved"
            : "Not Started"}
        </span>
      </div>

      <form className="mt-3">
        <div className="row g-3">
          <div className="col-12 col-sm-6">
            <SelectTag
              name="integrated_programs_offered"
              label="Does the University offer any Integrated Programmes?"
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  integrated_programs_offered: e.target.value,
                }))
              }
              value={formData.integrated_programs_offered}
              data={[
                { id: "yes", value: "Yes" },
                { id: "no", value: "No" },
              ]}
              required
            />
          </div>
          <div className="col-12 col-sm-6">
            <InputTag
              type="number"
              name="total_number_of_integrated_programmes"
              label="Total Number of Integrated Programmes"
              onChange={handleOnchange}
              value={formData.total_number_of_integrated_programmes}
              required
            />
          </div>
        </div>

        <div className="d-flex justify-content-end gap-3 my-4">
          <button
            type="submit"
            className="btn btn-primary text-sm px-3 py-2"
            onClick={handleSaveAsDraft}
          >
            Save as Draft
          </button>
          <button
            className="btn btn-warning text-sm text-dark px-3 py-2"
            onClick={handleSubmitForReview}
          >
            Submit for Review
          </button>
        </div>
      </form>
    </div>
  );
};

export default IntegratedPrograms;

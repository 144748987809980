import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { REPORT_STAFF_BASIC_SALARY } from "../utils/Reports.apiConst";
import {
  DEPARTMENTS,
  EMPLOYEE,
  EMPLOYEE_GENERATED_PAYSLIP,
  PAYROLL_DEDUCTION,
  PAYROLL_EARNING,
  PAYROLL_EMPLOYEES,
} from "../utils/apiConstants";
import Nodata from "../Components/NoData/Nodata";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import {
  LOCAL_COLLEGE,
  LOCAL_JOBROLES,
  LOCAL_PROGRAM,
} from "../utils/LocalStorageConstants";
import Select from "react-select";
import ViewBasicSalaryModal from "../modals/ViewBasicSalaryModal";
import EditBasicSalaryModal from "../modals/EditBasicSalaryModal";
import SortNames from "../Services/sortedNames";
import { sessionOpt } from "../Data/jsonData/Academics/Academics";

function BasicSalaryReport1({ setLoading }) {
  let count = 0;
  const [data, setData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [data1, setData1] = useState([]);
  const [currMonthDays, setCurrMonthDays] = useState(0);
  const [earningData, setEarningData] = useState([]);
  const [deductionData, setDeductionData] = useState([]);
  const [department, setDepartment] = useState("");
  const [attData, setAttData] = useState([]);
  const [status, setStatus] = useState("ACTIVE");
  const [category, setCategory] = useState("ALL");
  const [group, setGroup] = useState("ALL");

  const [modalData, setModalData] = useState([]);
  const [editData, setEditData] = useState([]);

  const jobPositionOpt = JSON.parse(localStorage.getItem(LOCAL_JOBROLES));

  let role = sessionStorage.getItem("role");

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("2024");

  const [departmentOpt, setDepartmentOpt] = useState([]);
  const [empData, setEmpData] = useState([]);
  const [holidays, setHolidays] = useState(0);
  const employeeId = sessionStorage.getItem("employee_id");
  const college_id = sessionStorage.getItem("college_id");
  const [empDetails, setEmpDetails] = useState([]);
  const [empColleges, setEmpColleges] = useState([]);

  const getCollegesData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegesData());

  const getEmployeeandDepartments = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE}/${employeeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        let clgs =
          res.data.data["multi_clg_id"] != "[undefined]" &&
          res.data.data["multi_clg_id"] != "undefined"
            ? JSON.parse(res.data.data["multi_clg_id"])
            : "";
        setEmpColleges(collegeOpt.filter((item) => clgs?.includes(item.id)));
        setEmpDetails(res.data.data);
        // console.log("emp details - ", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: `${DEPARTMENTS}?college_id=${college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        setDepartmentOpt(res.data.data);
        // console.log("emp details - ", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployeeandDepartments();
  }, []);

  ////////////to set default value as first option////////////
  const getFilteredDepartments = () => {
    if (role === "SUPERADMIN" || role === "ADMIN" || role === "SUACC") {
      // Add "ALL" option for SUPERADMIN or ADMIN roles
      return [{ id: "ALL", name: "All", program_id: null }, ...departmentOpt];
    } else {
      const multiDeptIds =
        empDetails?.multi_dept_id && empDetails?.multi_dept_id != "[undefined]"
          ? JSON.parse(empDetails.multi_dept_id)
          : [];
      return departmentOpt?.filter((d) => multiDeptIds.includes(d.id));
    }
  };

  // Get filtered departments and default department ID
  const filteredDepartments = getFilteredDepartments();
  const defaultDepartmentId = filteredDepartments?.[0]?.id || null;

  // Set department to the first option if it's not already set
  useEffect(() => {
    if (!department && defaultDepartmentId) {
      setDepartment(defaultDepartmentId); // Set department to defaultDepartmentId
    }
  }, [defaultDepartmentId, department]);

  const getData = async () => {
    if (!year) return toast.error("Please Select Year");
    if (!month) return toast.error("Please Select Month");
    setLoading(1);
    const daysInCurrentMonth = new Date(year, parseInt(month), 0).getDate();
    setCurrMonthDays(daysInCurrentMonth);
    const config = {
      method: "get",
      url: `${REPORT_STAFF_BASIC_SALARY}?college_id=${college_id}&department_id=${department}&category=${category}&status=${status}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setData1(res.data.finalArr);
        const x = new Set();

        let filteredData = res.data.data
          ?.map((post) => {
            const userRole = post?.role;
            const userGroup = jobPositionOpt?.find(
              (j) => j.id == userRole
            )?.group;

            let groupName = userGroup;

            // Custom grouping logic for predefined groups
            if (
              post?.designation == "Pro Chancellor" ||
              post?.designation == "Vice Chancellor" ||
              post?.designation == "Pro Vice Chancellor - I" ||
              post?.designation == "Director- Campus" ||
              post?.designation == "Assistant Director Social Media Marketing"
            ) {
              groupName = "STATUTORY POSITIONS";
            } else if (post?.designation === "Professor") {
              groupName = "PROFESSOR";
            } else if (
              post?.designation === "ASSISTANT PROFESSOR" ||
              post?.designation == "Assistant Professor & HOD"
            ) {
              groupName = "ASSISTANT PROFESSOR";
            } else if (post?.designation === "Lecturer") {
              groupName = "LECTURER";
            } else if (post?.designation === "Associate Professor") {
              groupName = "ASSOCIATE PROFESSOR";
            } else if (post?.designation === "Senior Resident") {
              groupName = "SENIOR RESIDENT";
            } else if (post?.designation === "Junior Resident") {
              groupName = "JUNIOR RESIDENT";
            } else if (post?.designation === "Tutor") {
              groupName = "TUTOR";
            }

            x.add(groupName);
            return {
              ...post,
              group: groupName, // Assign the appropriate group name
            };
          })
          ?.filter((post) => {
            if (group === "ALL") {
              return true;
            } else {
              return post.group === group;
            }
          });

        // Convert the set into an array and ensure the groups are in the required order
        let tempArr = Array.from(x);

        // Define the exact order for predefined groups
        const predefinedGroupOrder = [
          "STATUTORY POSITIONS",
          "PROFESSOR",
          "ASSISTANT PROFESSOR",
          "ASSOCIATE PROFESSOR",
          "LECTURER",
          "SENIOR RESIDENT",
          "JUNIOR RESIDENT",
          "TUTOR",
        ];

        // Remove predefined groups from the main array
        const remainingGroups = tempArr.filter(
          (group) => !predefinedGroupOrder.includes(group)
        );

        // Sort the remaining groups alphabetically
        remainingGroups.sort((a, b) => a.localeCompare(b));

        // Combine predefined groups (in required order) with the sorted remaining groups
        tempArr = [...predefinedGroupOrder, ...remainingGroups];

        // Filter the groups based on the filteredData to exclude empty groups
        const nonEmptyGroups = tempArr.filter((group) =>
          filteredData.some((post) => post.group === group)
        );

        setGroups(nonEmptyGroups);

        // Now sort filteredData based on the predefined group order
        let sortedData = filteredData.sort((a, b) => {
          const groupA = predefinedGroupOrder.indexOf(a?.group);
          const groupB = predefinedGroupOrder.indexOf(b?.group);

          if (groupA !== -1 && groupB !== -1) {
            return groupA - groupB; // Both are predefined, use predefined order
          } else if (groupA !== -1) {
            return -1; // a is predefined, put it first
          } else if (groupB !== -1) {
            return 1; // b is predefined, put it first
          } else {
            // Neither is predefined, sort alphabetically
            return a?.group.localeCompare(b?.group);
          }
        });

        setData(sortedData);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: `${EMPLOYEE_GENERATED_PAYSLIP}/1?year=${year}&month=${month}&college_id=${college_id}&check=1`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config2)
      .then((res) => {
        setLoading(0);
        console.log("Check Here", res.data);
        res.data.attData = res.data.attData?.filter((s) => s != null);
        setAttData(res.data.attData);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  const getCollegeData = async () => {
    setLoading(1);
    try {
      const [earningRes, deductionRes] = await Promise.all([
        axios.get(PAYROLL_EARNING, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        }),
        axios.get(PAYROLL_DEDUCTION, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        }),
      ]);
      setEarningData(earningRes.data.data);
      setDeductionData(deductionRes.data.data);
    } catch (error) {
      console.error(error);
      toast.error("Something Went Wrong");
    } finally {
      setLoading(0);
    }
  };

  useEffect(() => {
    getCollegeData();
  }, []);

  // Aggregate the data by employee ID
  const aggregateData = (data) => {
    const aggregated = data.reduce((acc, item) => {
      const {
        user_id,
        earning_id,
        basic_salary,
        earning_title,
        earning_amount,
        earning_percentage,
        deduction_id,
        deduction_title,
        deduction_amount,
        deduction_percentage,
      } = item;

      if (!acc[user_id]) {
        acc[user_id] = {
          ...item,
          earnings: {},
          deductions: {},
        };
      }

      if (basic_salary) {
        acc[user_id].basic_salary = basic_salary;
      }

      if (earning_title) {
        acc[user_id].earnings[earning_title] = {
          amount: earning_amount,
          percentage: earning_percentage,
          earning_id: earning_id,
        };
      }

      if (deduction_title) {
        acc[user_id].deductions[deduction_title] = {
          amount: deduction_amount,
          percentage: deduction_percentage,
          deduction_id: deduction_id,
        };
      }

      return acc;
    }, {});

    // const sortedNames = Object.values(aggregated).sort((a, b) => {
    //   // Regular expression to remove designations
    //   const removePrefix = (str) =>
    //     str
    //       .replace(/^(Mr\.?|Mr\. ?|Mrs\.?|Ms\.?|Dr\.?|Dr\. ?)\s+/i, "")
    //       .replace(/^(\.?|\. ?)\s+/i, "");

    //   const nameA = removePrefix(a.employee_name).split(" ").join("");

    //   const nameB = removePrefix(b.employee_name).split(" ").join("");

    //   // Compare the names after removing prefixes
    //   return nameA.localeCompare(nameB);
    // });

    return Object.values(aggregated);
  };

  const aggregatedData = aggregateData(data);

  // Helper function to get the amount or percentage for a specific title based on type
  const getValueByType = (items, title, type) => {
    const item = items[title] || {};
    return type === "amount" ? item.amount || "-" : item.percentage || "-";
  };

  ////////////////////

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    const formattedTime = `${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
    return `${formattedDate} ${formattedTime}`;
  };

  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Basic Salary Report",
    sheet: "Basic Salary Report",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  useEffect(() => {
    if (editData) {
      // This will run every time `editData` is updated.
      // console.log("Updated editData: ", editData);
    }
  }, [editData]);

  return (
    <div>
      <ViewBasicSalaryModal
        modalData={modalData}
        earningData={earningData}
        deductionData={deductionData}
      />
      <EditBasicSalaryModal
        editData={editData}
        earningData={earningData}
        deductionData={deductionData}
        data={data}
      />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">EMPLOYEE BASIC SALARY REPORT</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Year<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="role"
                            name="role"
                            className="form-control"
                            value={year}
                            onChange={(e) => {
                              setYear(e.target.value);
                            }}
                          >
                            <option value="">Select year</option>
                            {sessionOpt?.map((data, key) => {
                              return (
                                <option key={key} value={data.id}>
                                  {data.name?.split("-")[0]}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Select Month<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="role"
                            name="role"
                            className="form-control"
                            value={month}
                            onChange={(e) => {
                              setMonth(e.target.value);
                            }}
                          >
                            <option value="">Select Month</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row float-right mr-5 mt-3">
                      <button
                        className="btn btn-nex btn-rounded"
                        id="searchButtonBasicSalary"
                        type="submit"
                        name="submit"
                        onClick={getData}
                      >
                        <i className="fa fa-search" aria-hidden="true" /> Search
                      </button>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <h6 class="">EMPLOYEE BASIC SALARY REPORT</h6>
                {/* <hr /> */}
                <div className="row d-flex justify-content-end p-1">
                  <button
                    onClick={onDownload}
                    className="btn btn-success btn-sm ml-auto"
                  >
                    {" "}
                    <i class="ri-file-excel-2-line mr-2"></i>
                    Export Excel
                  </button>
                  <button
                    className="btn btn-primary btn-sm ml-2 mr-3"
                    onClick={handlePrint}
                  >
                    {" "}
                    <i class="bi bi-file-earmark-pdf mr-2"></i>
                    Export PDF
                  </button>
                </div>
                <br />
                <div className="table-responsive fixTableHead Scroll-overfolw-y">
                  <table className="table table-bordered table-hover mt-2">
                    <thead>
                      <tr>
                        <th
                          colSpan={
                            earningData.length + deductionData.length + 13
                          }
                        >
                          {collegeOpt?.find((s) => s.id == college_id)?.name}
                        </th>
                      </tr>
                      <tr>
                        <th rowSpan="2">Sl.No.</th>
                        <th rowSpan="2">Employee Name</th>
                        <th rowSpan="2">Designation</th>
                        <th rowSpan="2">Department</th>
                        <th rowSpan="2">Employee Category</th>
                        {/* <th rowSpan="2">Group</th> */}
                        <th rowSpan="2">Payband</th>
                        <th rowSpan={2}>Total Days</th>
                        <th rowSpan={2}>Total Present Days</th>
                        <th rowSpan="2">Basic Salary</th>

                        {/* Earnings main heading with dynamic columns */}
                        <th
                          className="text-center"
                          colSpan={earningData.length}
                        >
                          Earnings
                        </th>

                        <th rowSpan={2}>Total Earnings</th>
                        {/* Deductions main heading with dynamic columns */}
                        <th
                          className="text-center"
                          colSpan={deductionData.length}
                        >
                          Deductions
                        </th>
                        <th rowSpan={2}>Total Deductions</th>
                        <th rowSpan={2}>Net Amount</th>
                        <th rowSpan="2" className="text-center">
                          Action
                        </th>
                      </tr>

                      <tr>
                        {/* Dynamic columns for Earnings based on type */}
                        {earningData.map((earning, index) => (
                          <th key={index}>{earning.title}</th>
                        ))}

                        {/* Dynamic columns for Deductions based on type */}
                        {deductionData.map((deduction, index) => (
                          <th key={index}>{deduction.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {aggregatedData && aggregatedData.length !== 0 ? (
                        groups?.map((item, key) => {
                          let totalBasicSalary = 0;
                          let totalEarnings = Array(earningData.length).fill(0); // Array to store total for each earning type
                          let totalDeductions = Array(
                            deductionData.length
                          ).fill(0); // Array to store total for each deduction type

                          return (
                            <>
                              <tr className="bg-dark text-light">
                                <th colSpan={25} className="text-center">
                                  {item == "A" ||
                                  item == "B" ||
                                  item == "C" ||
                                  item == "D" ||
                                  item == "E"
                                    ? `CADRE - Group - ${item}`
                                    : item}
                                </th>
                              </tr>
                              {aggregatedData
                                ?.filter(
                                  (s4) => s4?.group == item && s4.user_id != 1
                                )
                                ?.sort(
                                  (a, b) => a.arrangements - b.arrangements
                                )
                                ?.map((employee, key) => {
                                  count += 1;

                                  // Calculate basic salary and earnings/deductions totals
                                  totalBasicSalary += employee.basic_salary;

                                  // Dynamic Earnings data
                                  const employeeEarnings = earningData.map(
                                    (earning) => {
                                      const amount = getValueByType(
                                        employee.earnings,
                                        earning.title,
                                        "amount"
                                      );
                                      const percentage = getValueByType(
                                        employee.earnings,
                                        earning.title,
                                        "percentage"
                                      );
                                      const calculatedEarnings =
                                        percentage !== "-" &&
                                        percentage !== undefined
                                          ? Math.round(
                                              (employee.basic_salary *
                                                percentage) /
                                                100
                                            )
                                          : amount !== "-" &&
                                            amount !== undefined
                                          ? Math.round(amount)
                                          : 0; // Default to 0

                                      totalEarnings[
                                        earningData.indexOf(earning)
                                      ] += calculatedEarnings; // Add to total earnings
                                      return calculatedEarnings;
                                    }
                                  );

                                  // Dynamic Deductions data
                                  const employeeDeductions = deductionData.map(
                                    (deduction) => {
                                      const amount = getValueByType(
                                        employee.deductions,
                                        deduction.title,
                                        "amount"
                                      );
                                      const percentage = getValueByType(
                                        employee.deductions,
                                        deduction.title,
                                        "percentage"
                                      );
                                      const calculatedDeduction =
                                        percentage !== "-" &&
                                        percentage !== undefined
                                          ? Math.round(
                                              (employee.basic_salary *
                                                percentage) /
                                                100
                                            )
                                          : amount !== "-" &&
                                            amount !== undefined
                                          ? Math.round(amount)
                                          : 0; // Default to 0

                                      totalDeductions[
                                        deductionData.indexOf(deduction)
                                      ] += calculatedDeduction; // Add to total deductions
                                      return calculatedDeduction;
                                    }
                                  );

                                  return (
                                    <tr key={count}>
                                      <td>{count}</td>
                                      <td>{employee.employee_name}</td>
                                      <td>
                                        {employee.designation || employee.role}
                                      </td>
                                      <td>{employee.department_name}</td>
                                      <td>{employee.employee_category}</td>
                                      {/* <td>{employee.group}</td> */}
                                      <td>{employee.pay_band}</td>
                                      <td>
                                        {
                                          attData?.find(
                                            (s) => s.id == employee?.user_id
                                          )?.totalPresent
                                        }
                                      </td>
                                      <td className="text-center">
                                        {currMonthDays}
                                      </td>
                                      <td className="text-center">
                                        {employee.basic_salary}
                                      </td>

                                      {/* Dynamic Earnings data */}
                                      {employeeEarnings.map(
                                        (earning, index) => (
                                          <td key={index}>
                                            {earning !== 0 ? `${earning}` : "-"}
                                          </td>
                                        )
                                      )}
                                      <td>
                                        {Math.round(
                                          data1?.find(
                                            (s) =>
                                              s.employee_id ===
                                              employee?.employee_id
                                          )?.totalEarnings || 0
                                        )}
                                      </td>

                                      {/* Dynamic Deductions data */}
                                      {employeeDeductions.map(
                                        (deduction, index) => (
                                          <td key={index}>
                                            {deduction !== 0
                                              ? `${deduction}`
                                              : "-"}
                                          </td>
                                        )
                                      )}
                                      <td>
                                        {Math.round(
                                          data1?.find(
                                            (s) =>
                                              s.employee_id ===
                                              employee?.employee_id
                                          )?.totalDeductions || 0
                                        )}
                                      </td>
                                      <td>
                                        {/* {(() => {
                                          const employeeData = data1?.find(
                                            (s) =>
                                              s.employee_id ===
                                              employee?.employee_id
                                          );
                                          const netAmount =
                                            employeeData?.netAmount;

                                          return isNaN(netAmount)
                                            ? 0
                                            : Math.round(netAmount);
                                        })()} */}
                                        {attData?.find(
                                          (s) => s.id == employee?.user_id
                                        )?.net_salary
                                          ? attData?.find(
                                              (s) => s.id == employee?.user_id
                                            )?.net_salary
                                          : 0}
                                      </td>

                                      <td>
                                        <div className="d-flex ">
                                          <button
                                            className="btn btn-primary btn-sm"
                                            data-toggle="modal"
                                            data-target="#ViewBasicSalaryModal"
                                            onClick={() => {
                                              setModalData(employee);
                                            }}
                                          >
                                            View
                                          </button>
                                          <button
                                            className="btn btn-info btn-sm ml-2"
                                            data-toggle="modal"
                                            data-target="#EditBasicSalaryModal"
                                            onClick={() => {
                                              setEditData(employee);
                                            }}
                                          >
                                            Edit
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}

                              {/* Total Row for the Group */}
                              <tr className="bg-secondary text-white font-weight-bold">
                                <td colSpan={8} className="text-center">
                                  Total:
                                </td>
                                <td>{totalBasicSalary}</td>
                                {/* Total Earnings */}
                                {totalEarnings.map((totalEarning, index) => (
                                  <td key={index}>
                                    {totalEarning !== 0
                                      ? `${totalEarning}`
                                      : "0"}
                                  </td>
                                ))}
                                <td>
                                  {Math.round(
                                    aggregatedData
                                      .filter((s) => s.group === item)
                                      .reduce(
                                        (acc, emp) =>
                                          acc +
                                          (data1?.find(
                                            (s) =>
                                              s.employee_id === emp?.employee_id
                                          )?.totalEarnings || 0),
                                        0
                                      ) || 0
                                  )}
                                </td>
                                {/* Total Deductions */}
                                {totalDeductions.map(
                                  (totalDeduction, index) => (
                                    <td key={index}>
                                      {totalDeduction !== 0
                                        ? `${totalDeduction}`
                                        : "0"}
                                    </td>
                                  )
                                )}
                                <td>
                                  {Math.round(
                                    aggregatedData
                                      .filter((s) => s.group === item)
                                      .reduce(
                                        (acc, emp) =>
                                          acc +
                                          (data1?.find(
                                            (s) =>
                                              s.employee_id === emp?.employee_id
                                          )?.totalDeductions || 0),
                                        0
                                      ) || 0
                                  )}
                                </td>

                                {/* Total Net Salary  */}
                                <td>
                                  {Math.round(
                                    aggregatedData
                                      .filter((s) => s.group === item)
                                      .reduce(
                                        (acc, emp) =>
                                          acc +
                                          Number(
                                            attData?.find(
                                              (s) => s.id === emp?.employee_id
                                            )?.net_salary || 0
                                          ),
                                        0
                                      ) || 0
                                  )}
                                </td>
                                <td></td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan={
                              earningData.length + deductionData.length + 19
                            }
                          >
                            <div align="center">
                              <Nodata />
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "none" }}>
            <table
              className="table table-bordered table-hover mt-2"
              ref={tableRef}
            >
              <thead>
                <tr>
                  <th colSpan={earningData.length + deductionData.length + 12}>
                    <div className="row">
                      <div className="col-12">
                        <p className="text-dark float-right">
                          Printed On - {getCurrentDate()}
                        </p>
                      </div>
                      <div className="col-12">
                        <h3 className="text-primary text-center">
                          <img
                            className=""
                            src="../../../assets/images/Nexenstial Logo.jpg"
                            width="80px"
                            height="60px"
                          />
                          <span className="ml-3">
                            {collegeOpt?.find((s) => s.id == college_id)?.name}
                          </span>
                        </h3>
                      </div>
                      <div className="col-12 mt-4 text-center">
                        <h4>EMPLOYEE BASIC SALARY REPORT </h4>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>

              <thead>
                <tr>
                  <th colSpan={earningData.length + deductionData.length + 11}>
                    {collegeOpt?.find((s) => s.id == college_id)?.name}
                  </th>
                </tr>
                <tr>
                  <th rowSpan="2">Sl.No.</th>
                  <th rowSpan="2">Employee Name</th>
                  <th rowSpan="2">Designation</th>
                  <th rowSpan="2">Department</th>
                  <th rowSpan="2">Employee Category</th>
                  {/* <th rowSpan="2">Group</th> */}
                  <th rowSpan="2">Payband</th>
                  <th rowSpan={2}>Total Days</th>
                  <th rowSpan={2}>Total Present Days</th>
                  <th rowSpan="2">Basic Salary</th>

                  {/* Earnings main heading with dynamic columns */}
                  <th className="text-center" colSpan={earningData.length}>
                    Earnings
                  </th>

                  <th rowSpan={2}>Total Earnings</th>
                  {/* Deductions main heading with dynamic columns */}
                  <th className="text-center" colSpan={deductionData.length}>
                    Deductions
                  </th>
                  <th rowSpan={2}>Total Deductions</th>
                  <th rowSpan={2}>Net Amount</th>
                </tr>

                <tr>
                  {/* Dynamic columns for Earnings based on type */}
                  {earningData.map((earning, index) => (
                    <th key={index}>{earning.title}</th>
                  ))}

                  {/* Dynamic columns for Deductions based on type */}
                  {deductionData.map((deduction, index) => (
                    <th key={index}>{deduction.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {aggregatedData && aggregatedData.length !== 0 ? (
                  groups?.map((item, key) => {
                    let totalBasicSalary = 0;
                    let totalEarnings = Array(earningData.length).fill(0); // Array to store total for each earning type
                    let totalDeductions = Array(deductionData.length).fill(0); // Array to store total for each deduction type
                    return (
                      <>
                        <tr className="">
                          <th colSpan={24} className="text-center">
                            {item == "A" ||
                            item == "B" ||
                            item == "C" ||
                            item == "D" ||
                            item == "E"
                              ? `CADRE - Group - ${item}`
                              : item}
                          </th>
                        </tr>
                        {aggregatedData
                          ?.filter((s4) => s4?.group == item && s4.user_id != 1)
                          ?.map((employee, key) => {
                            count += 1;

                            // Calculate basic salary and earnings/deductions totals
                            totalBasicSalary += employee.basic_salary;

                            // Dynamic Earnings data
                            const employeeEarnings = earningData.map(
                              (earning) => {
                                const amount = getValueByType(
                                  employee.earnings,
                                  earning.title,
                                  "amount"
                                );
                                const percentage = getValueByType(
                                  employee.earnings,
                                  earning.title,
                                  "percentage"
                                );
                                const calculatedEarnings =
                                  percentage !== "-" && percentage !== undefined
                                    ? Math.round(
                                        (employee.basic_salary * percentage) /
                                          100
                                      )
                                    : amount !== "-" && amount !== undefined
                                    ? Math.round(amount)
                                    : 0; // Default to 0

                                totalEarnings[earningData.indexOf(earning)] +=
                                  calculatedEarnings; // Add to total earnings
                                return calculatedEarnings;
                              }
                            );

                            // Dynamic Deductions data
                            const employeeDeductions = deductionData.map(
                              (deduction) => {
                                const amount = getValueByType(
                                  employee.deductions,
                                  deduction.title,
                                  "amount"
                                );
                                const percentage = getValueByType(
                                  employee.deductions,
                                  deduction.title,
                                  "percentage"
                                );
                                const calculatedDeduction =
                                  percentage !== "-" && percentage !== undefined
                                    ? Math.round(
                                        (employee.basic_salary * percentage) /
                                          100
                                      )
                                    : amount !== "-" && amount !== undefined
                                    ? Math.round(amount)
                                    : 0; // Default to 0

                                totalDeductions[
                                  deductionData.indexOf(deduction)
                                ] += calculatedDeduction; // Add to total deductions
                                return calculatedDeduction;
                              }
                            );

                            return (
                              <tr key={count}>
                                <td>{count}</td>
                                <td>{employee.employee_name}</td>
                                <td>{employee.designation || employee.role}</td>
                                <td>{employee.department_name}</td>
                                <td>{employee.employee_category}</td>
                                {/* <td>{employee.group}</td> */}
                                <td>{employee.pay_band}</td>
                                <td>
                                  {
                                    attData?.find(
                                      (s) => s.id == employee?.user_id
                                    )?.totalPresent
                                  }
                                </td>
                                <td>{currMonthDays}</td>
                                <td>{employee.basic_salary}</td>

                                {/* Dynamic Earnings data */}
                                {employeeEarnings.map((earning, index) => (
                                  <td key={index}>
                                    {earning !== 0 ? `${earning}` : "-"}
                                  </td>
                                ))}
                                <td>
                                  {Math.round(
                                    data1?.find(
                                      (s) =>
                                        s.employee_id === employee?.employee_id
                                    )?.totalEarnings || 0
                                  )}
                                </td>

                                {/* Dynamic Deductions data */}
                                {employeeDeductions.map((deduction, index) => (
                                  <td key={index}>
                                    {deduction !== 0 ? `${deduction}` : "-"}
                                  </td>
                                ))}
                                <td>
                                  {Math.round(
                                    data1?.find(
                                      (s) =>
                                        s.employee_id === employee?.employee_id
                                    )?.totalDeductions || 0
                                  )}
                                </td>
                                <td>
                                  {(() => {
                                    const employeeData = data1?.find(
                                      (s) =>
                                        s.employee_id === employee?.employee_id
                                    );
                                    const netAmount =
                                      employeeData?.netAmount ?? 0;

                                    return Math.round(netAmount);
                                  })()}
                                  ;
                                </td>
                              </tr>
                            );
                          })}

                        {/* Total Row for the Group */}
                        <tr className="font-weight-bold">
                          <td colSpan={8} className="text-center">
                            Total:
                          </td>
                          <td>{totalBasicSalary}</td>
                          {/* Total Earnings */}
                          {totalEarnings.map((totalEarning, index) => (
                            <td key={index}>
                              {totalEarning !== 0 ? `${totalEarning}` : "0"}
                            </td>
                          ))}
                          <td>
                            {Math.round(
                              aggregatedData
                                .filter((s) => s.group === item)
                                .reduce(
                                  (acc, emp) =>
                                    acc +
                                    (data1?.find(
                                      (s) => s.employee_id === emp?.employee_id
                                    )?.totalEarnings || 0),
                                  0
                                ) || 0
                            )}
                          </td>
                          {/* Total Deductions */}
                          {totalDeductions.map((totalDeduction, index) => (
                            <td key={index}>
                              {totalDeduction !== 0 ? `${totalDeduction}` : "0"}
                            </td>
                          ))}
                          <td>
                            {Math.round(
                              aggregatedData
                                .filter((s) => s.group === item)
                                .reduce(
                                  (acc, emp) =>
                                    acc +
                                    (data1?.find(
                                      (s) => s.employee_id === emp?.employee_id
                                    )?.totalDeductions || 0),
                                  0
                                ) || 0
                            )}
                          </td>
                          <td>
                            {/* {(() => {
                              const netAmount =
                                totalEarnings.reduce(
                                  (acc, curr) => acc + curr,
                                  0
                                ) -
                                totalDeductions.reduce(
                                  (acc, curr) => acc + curr,
                                  0
                                );
                              return netAmount >= 0 ? Math.round(netAmount) : 0;
                            })()} */}
                            {Math.round(
                              aggregatedData
                                .filter((s) => s.group === item)
                                .reduce(
                                  (acc, emp) =>
                                    acc +
                                    (data1?.find(
                                      (s) => s.employee_id === emp?.employee_id
                                    )?.totalEarnings || 0),
                                  0
                                ) || 0
                            ) -
                              Math.round(
                                aggregatedData
                                  .filter((s) => s.group === item)
                                  .reduce(
                                    (acc, emp) =>
                                      acc +
                                      (data1?.find(
                                        (s) =>
                                          s.employee_id === emp?.employee_id
                                      )?.totalDeductions || 0),
                                    0
                                  ) || 0
                              )}{" "}
                          </td>
                          <td></td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={earningData.length + deductionData.length + 19}
                    >
                      <div align="center">
                        <Nodata />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasicSalaryReport1;

import React, { useState, useEffect } from "react";
import getUniversityDetails from "../../../utils/universityDetails.api";

const FeeCollectionFeeReciept = ({
  data,
  mainData,
  subData,
  collegeId,
  collegeOpt,
  classData,
  departmentData,
}) => {
  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  function inWords(num) {
    if (!num) return;
    if ((num = num.toString()).length > 9) return "overflow";
    var n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
        : "";
    return str;
  }

  //UNIVERSITY DETAILS START
  const [universityData, setUniversityData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUniversityDetails();
        setUniversityData(data);
      } catch (error) {
        console.error(`Error fetching university details: ${error.message}`);
      }
    };
    console.log("data - ", data);
    console.log("main data - ", mainData);
    fetchData();
  }, []);

  //UNIVERSITY DETAILS END
  return (
    <div className="FeeCollectionFeeReciept">
      <br />
      <div className="container">
        <div className=" row d-flex align-items-center">
          <div className="col-sm-3">
            <img src={universityData.logo_secondary} alt="" width="100%" />
          </div>
          <div className="col-sm-9 mt-2 text-center">
            {/* <h5>
              {universityData.name}, {universityData.city}
            </h5> */}

            <h4>{collegeOpt?.find((s) => s.id == collegeId)?.name}</h4>
            <p>
              Contact : {universityData.phone} Email : {universityData.email}
            </p>
          </div>
        </div>
        <br />
        <div className="row bg-dark text-white justify-content-center">
          <div className="p-2">FEE RECEIPT</div>
        </div>
        <h6 className="text-center mt-2">College Copy</h6>
        <div className="row">
          <div className="col-sm-6">
            <h6>
              <b>Name : {mainData?.name}</b>
            </h6>
            <h6>
              <b>Enrollment No. : {mainData?.user_id}</b>
            </h6>
            <h6>Department : {mainData?.department_name}</h6>
            <h6>
              Class : {mainData?.class_name} ({mainData?.sem_name})
            </h6>
          </div>
          <div className="col-sm-6 text-right">
            <h6>
              <b>
                Date :{" "}
                {data?.transaction_date
                  ?.split("T")[0]
                  ?.split("-")
                  ?.reverse()
                  ?.join(".")}
              </b>
            </h6>

            <h6>Invoice No. : {data?.transaction_id}</h6>
            <h6>
              Collected by : {data?.first_name} {data?.last_name}
            </h6>
          </div>
        </div>
        <br />
        <div className="row text-white bg-dark p-2">
          <div className="col-sm-4" style={{ borderRight: "1px solid white" }}>
            Fee Name
          </div>
          <div className="col-sm-2">Amount</div>
          <div className="col-sm-2"></div>
          {/* <div className="col-sm-2">Discount</div> */}
          <div className="col-sm-2">Fine</div>
          <div className="col-sm-2">Total Paid</div>
        </div>

        <div className="row pt-3">
          <div className="col-sm-4" style={{ borderRight: "1px solid black" }}>
            {mainData?.fee_type_name}
          </div>
          <div className="col-sm-2">
            {data?.amount?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
            })}
          </div>
          <div className="col-sm-2"></div>
          {/* <div className="col-sm-2">{data?.discount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</div> */}
          <div className="col-sm-2">
            {data?.fine?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
            })}
          </div>
          {/* <div className="col-sm-2">
            {(data?.amount + data?.fine)?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
            })}
          </div> */}
          <div className="col-sm-2">
            {data?.amount?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
            })}
          </div>
        </div>
        <br />
        <br />
        <hr />
        <div className="row pt-3">
          <div className="col-sm-4" style={{ borderRight: "1px solid black" }}>
            <b>Grand Total</b>
          </div>
          <div className="col-sm-2">
            <b>
              {data?.amount?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              })}
            </b>
          </div>
          <div className="col-sm-2"></div>
          {/* <div className="col-sm-2"><b>{data?.discount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</b></div> */}
          <div className="col-sm-2">
            <b>
              {data?.fine?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              })}
            </b>
          </div>
          <div className="col-sm-2">
            {/* <b>
              {(data?.amount + data?.fine)?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              })}
            </b> */}
            <b>
              {data?.amount?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              })}
            </b>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="row">
          <div className="col-12">
            <table>
              {/* <tr>
            <td>Grand Total</td>
            <td>: {data?.amount?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
          </tr> */}
              <tr>
                <td>In Words</td>
                <td style={{ textTransform: "capitalize" }}>
                  : {inWords(data?.amount - data?.discount + data?.fine)} rupees
                  only
                </td>
              </tr>
              <tr>
                <td>Payment Mode</td>
                <td>: {data?.type}</td>
              </tr>
              <tr>
                <td>Note/Remark</td>
                <td>: {data?.note}</td>
              </tr>
            </table>
          </div>
          <div className="col-12">
            <h6 className="text-right mr-3">
              <b>Reciever's Signature</b>
            </h6>
          </div>
        </div>
      </div>

      <br />
      <hr style={{ border: "none", borderTop: "1px dotted black" }} />
      <br />
      <div className="container">
        <div className=" row d-flex align-items-center">
          <div className="col-sm-3">
            <img src={universityData.logo_secondary} alt="" width="100%" />
          </div>
          <div className="col-sm-9 mt-2 text-center">
            <h4>{collegeOpt?.find((s) => s.id == collegeId)?.name}</h4>
            <p>
              Contact : {universityData.phone} Email : {universityData.email}
            </p>
          </div>
        </div>
        <br />
        <div className="row bg-dark text-white justify-content-center">
          <div className="p-2">FEE RECEIPT</div>
        </div>
        <h6 className="text-center mt-2 p-2">Student Copy</h6>
        <div className="row">
          <div className="col-sm-6">
            <h6>
              <b>Name : {mainData?.name}</b>
            </h6>
            <h6>
              <b>Enrollment No. : {mainData?.user_id}</b>
            </h6>
            <h6>Department : {mainData?.department_name}</h6>
            <h6>
              Class : {mainData?.class_name} ({mainData?.sem_name})
            </h6>
          </div>
          <div className="col-sm-6 text-right">
            <h6>
              <b>
                Date :{" "}
                {data?.transaction_date
                  ?.split("T")[0]
                  ?.split("-")
                  ?.reverse()
                  ?.join(".")}
              </b>
            </h6>

            <h6>Invoice No. : {data?.transaction_id}</h6>
            <h6>
              Collected by : {data?.first_name} {data?.last_name}
            </h6>
          </div>
        </div>
        <br />
        <div className="row text-white bg-dark p-2">
          <div className="col-sm-4" style={{ borderRight: "1px solid white" }}>
            Fee Name
          </div>
          <div className="col-sm-2">Amount</div>
          <div className="col-sm-2"></div>
          {/* <div className="col-sm-2">Discount</div> */}
          <div className="col-sm-2">Fine</div>
          <div className="col-sm-2">Total Paid</div>
        </div>

        <div className="row pt-3">
          <div className="col-sm-4" style={{ borderRight: "1px solid black" }}>
            {mainData?.fee_type_name}
          </div>
          <div className="col-sm-2">
            {data?.amount?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
            })}
          </div>
          <div className="col-sm-2"></div>
          {/* <div className="col-sm-2">{data?.discount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</div> */}
          <div className="col-sm-2">
            {data?.fine?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
            })}
          </div>
          <div className="col-sm-2">
            {/* {(data?.amount + data?.fine)?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
            })} */}
            {data?.amount?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
            })}
          </div>
        </div>
        <br />
        <br />
        <hr />
        <div className="row pt-3">
          <div className="col-sm-4" style={{ borderRight: "1px solid black" }}>
            <b>Grand Total</b>
          </div>
          <div className="col-sm-2">
            <b>
              {data?.amount?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              })}
            </b>
          </div>
          <div className="col-sm-2"></div>
          {/* <div className="col-sm-2"><b>{data?.discount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</b></div> */}
          <div className="col-sm-2">
            <b>
              {data?.fine?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              })}
            </b>
          </div>
          <div className="col-sm-2">
            <b>
              {/* {(data?.amount + data?.fine)?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              })} */}
              {data?.amount?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              })}
            </b>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="row">
          <div className="col-12">
            <table>
              {/* <tr>
            <td>Grand Total</td>
            <td>: {data?.amount?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
          </tr> */}
              <tr>
                <td>In Words</td>
                <td style={{ textTransform: "capitalize" }}>
                  : {inWords(data?.amount - data?.discount + data?.fine)} rupees
                  only
                </td>
              </tr>
              <tr>
                <td>Payment Mode</td>
                <td>: {data?.type}</td>
              </tr>
              <tr>
                <td>Note/Remark</td>
                <td>: {data?.note}</td>
              </tr>
            </table>
          </div>
          <div className="col-12">
            <h6 className="text-right mr-3">
              <b>Reciever's Signature</b>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeCollectionFeeReciept;

import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { PlusIcon } from "@heroicons/react/24/outline";

import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { NAAC } from "../../../../utils/apiConstants";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
dayjs.extend(customParseFormat);

const { Option } = Select;
const { TextArea } = Input;
const dateFormat = "YYYY/MM/DD";
export default function DetailsOfUcgRecognition({
  setUpdatedData,
  setLoading,
}) {
  const [data, setData] = useState([]);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [badge, setBadge] = useState("");
  const [form] = Form.useForm();
  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };
  const handleAdd = () => {
    form.resetFields();
    setEditingRecord(null);
    setIsModalVisible(true);
  };
  let emp_id = sessionStorage.getItem("employee_id");

  const handleDelete = (identifier) => {
    const updatedData = data.filter((item) => {
      if (item.id === identifier) {
        // For existing items with `id`, mark as INACTIVE
        item.status = "INACTIVE";
        return true; // Keep in the array to send in `toDelete`
      }
      // For new items with `tempId` and no `id`, remove from array
      return item.tempId !== identifier;
    });

    setData(updatedData);
  };

  const handleSave = () => {
    form.validateFields().then((values) => {
      if (editingRecord) {
        // Editing an existing record
        setData(
          data.map((item) =>
            (item.id && item.id === editingRecord.id) ||
            (item.tempId && item.tempId === editingRecord.tempId)
              ? { ...item, ...values, updated_by: emp_id }
              : item
          )
        );
      } else {
        // Adding a new record without `id`
        setData([
          ...data,
          {
            ...values,
            tempId: uuidv4(),
            created_by: emp_id,
            updated_by: emp_id,
            university_profile_id: 1,
            status: "DRAFT",
          },
        ]);
      }
      setIsModalVisible(false);
    });
  };
  const columns = [
    {
      title: "Under Section",
      dataIndex: "under_section",
      key: "under_section",
    },
    {
      title: "Date of recognition",
      dataIndex: "date_of_recognition",
      key: "date_of_recognition",
    },
    {
      title: "Remarks (If any)",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record?.id || record?.tempId)}
            okText="Yes"
            cancelText="No"
            placement="topRight"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  const getFormData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      //  Make this url dynamic after adding the university or college id to the university-profile table
      url: `${NAAC}/ugc-recognition/1`,
    };
    await axios(config)
      .then((res) => {
        setData(res.data);
        let fetchedData = res.data;
        setBadge(() => {
          if (
            fetchedData?.length > 0 &&
            fetchedData.every((item) => item.status === "REVIEW")
          ) {
            return "REVIEW";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "DRAFT")
          ) {
            return "DRAFT";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "APPROVED")
          ) {
            return "APPROVED";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "REJECTED")
          ) {
            return "REJECTED";
          }
        });
        setLoading(1);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  const handleFormSubmission = async (status) => {
    if (data.length === 0) {
      return toast.error("Please add at least one recognition detail");
    }

    // Separate data for backend operations
    const toDelete = data.filter(
      (item) => item.status === "INACTIVE" && item.id
    );
    const toUpdate = data.filter(
      (item) => item.status !== "INACTIVE" && item.id
    );
    const toCreate = data.filter((item) => !item.id);

    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/ugc-recognition`,
      data: { toDelete, toUpdate, toCreate, status },
    };

    try {
      const res = await axios(config);
      toast.success(
        status === "REVIEW"
          ? "Successfully submitted for review"
          : "Successfully saved as draft"
      );
      setUpdatedData(uuidv4());
      getFormData(); // Refetch data to update view
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    } finally {
      setLoading(0);
    }
  };
  const handleSaveASDraft = (e) => {
    e.preventDefault();
    handleFormSubmission("DRAFT");
  };

  const handleSaveAndSubmitForReview = (e) => {
    e.preventDefault();
    handleFormSubmission("REVIEW");
  };
  useEffect(() => {
    getFormData();
  }, []);
  // console.log("details of recognition details", data);
  return (
    <>
      <Modal
        title={editingRecord ? "Edit Record" : "Add New Record"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSave}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Under Section"
            name="under_section"
            rules={[{ required: true, message: "Please select the section" }]}
          >
            <Select className="form-select">
              <Option value="2f of UGC">2f of UGC</Option>
              <Option value="12B of UGC">12B of UGC</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Date of recognition"
            name="date_of_recognition"
            rules={[
              {
                required: true,
                message: "Please input the date of recognition",
              },
            ]}
            getValueFromEvent={(e) => e?.format(dateFormat)}
            getValueProps={(e) => ({ value: e ? dayjs(e) : "" })}
          >
            <DatePicker format={dateFormat} className="form-control" />
          </Form.Item>

          <Form.Item label="Remarks (If any)" name="remarks">
            <TextArea rows={4} className="form-control" />
          </Form.Item>
        </Form>
      </Modal>

      <div className="d-flex justify-content-between align-items-end w-100">
        <div className="d-flex flex-column gap-2">
          <span className="fw-semibold h5 mb-2">
            Details of UGC Recognition
          </span>
          <span
            className={`text-white d-inline-block text-center px-4 py-1 shadow-sm text-xs rounded-pill ${
              badge === "DRAFT"
                ? "bg-primary"
                : badge === "REVIEW"
                ? "bg-warning"
                : badge === "REJECTED"
                ? "bg-danger"
                : badge === "APPROVED"
                ? "bg-success"
                : "bg-secondary"
            }`}
            style={{ width: "fit-content" }}
          >
            {badge === "DRAFT"
              ? "In Draft"
              : badge === "REVIEW"
              ? "Submitted for review"
              : badge === "REJECTED"
              ? "Rejected"
              : badge === "APPROVED"
              ? "Approved"
              : "Not Started"}
          </span>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <Button type="primary" onClick={handleAdd} icon={<PlusOutlined />}>
          Add New Row
        </Button>
      </div>

      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-0 d-flex flex-column">
          <div className="overflow-auto">
            <div className="table-responsive shadow-sm">
              <Table
                className="table table-bordered"
                dataSource={data?.filter((item) => item.status !== "INACTIVE")}
                columns={columns}
                rowKey="id"
                pagination={false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end gap-2 my-3">
        <button
          className="btn btn-primary text-sm px-3 py-2"
          onClick={handleSaveASDraft}
        >
          Save as Draft
        </button>
        <button
          className="btn btn-warning text-sm text-dark px-3 py-2"
          onClick={handleSaveAndSubmitForReview}
        >
          Submit for Review
        </button>
      </div>
    </>
  );
}

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Nodata from "../../../Components/NoData/Nodata";
import { ROUTES } from "../../../Router/routerConfig";
import {
  ALL_DATA,
  BASE_URL,
  EMPLOYEE,
  EMPLOYEE_SQL,
  EMPLOYEE_DELETE,
  EMPLOYEE_EDUCATION,
  EMPLOYEE_EMPLOYEMENT_TERMS,
  EMPLOYEE_EXPERIENCE,
  EMPLOYEE_LEGAL_DOCUMENTS,
  EMPLOYEE_PLACEMENT,
  EMPLOYEE_PUBLICATION,
  EMPLOYEE_TRAINING,
  GET_ALL_USERS,
  EMPLOYEE_PROGRESS,
  DEPARTMENTS,
  EMPLOYEE_SQL_DATA,
} from "../../../utils/apiConstants";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_JOBROLES,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
import { Http } from "../../../Services/Services";
import Swal from "sweetalert2";
import { Progress } from "antd";
import Select from "react-select";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import ModalResendCredentials from "./ModalResendCredentials";

function ViewStaff({ setLoading, collegeId }) {
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const local_departments = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));

  const [Employee, setEmployee] = useState([]);
  const [progressData, setProgressData] = useState([]);

  const [employeeData, setEmployeeData] = useState([]);
  const [displayData, setDisplayData] = useState([]);

  const [credDisplayData, setCredDisplayData] = useState([]);

  const [users, setUsers] = useState([]);

  const jobPositionOpt = JSON.parse(localStorage.getItem(LOCAL_JOBROLES));

  // console.log("jobPositionOpt -", localStorage.getItem("JOB_ROLES"));

  const [department, setDepartment] = useState("");
  const [status, setStatus] = useState("ACTIVE");
  const [category, setCategory] = useState("ALL");
  const [group, setGroup] = useState("ALL");
  const getJobRoles = () => {
    return localStorage.getItem(LOCAL_JOBROLES)
      ? JSON.parse(localStorage.getItem(LOCAL_JOBROLES))
      : [];
  };

  const getProgramOpt = () => {
    return localStorage.getItem(LOCAL_PROGRAM)
      ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
      : null;
  };

  const [programOpt, setProgramOpt] = useState(getProgramOpt());

  useEffect(() => {
    setProgramOpt(getProgramOpt());
  }, [localStorage.getItem(LOCAL_PROGRAM)]);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  // const [departmentOpt, setDepartmentOpt] = useState(
  //   JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  // );

  let role = sessionStorage.getItem("role");

  const [departmentOpt, setDepartmentOpt] = useState([]);
  const employeeId = sessionStorage.getItem("employee_id");
  const college_id = sessionStorage.getItem("college_id");
  const [empDetails, setEmpDetails] = useState([]);
  const [empColleges, setEmpColleges] = useState([]);

  const getEmployeeandDepartments = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE}/${employeeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        let clgs =
          res.data.data["multi_clg_id"] != "[undefined]" &&
          res.data.data["multi_clg_id"] != "undefined"
            ? JSON.parse(res.data.data["multi_clg_id"])
            : "";
        setEmpColleges(collegeOpt.filter((item) => clgs?.includes(item.id)));
        setEmpDetails(res.data.data);
        // console.log("emp details - ", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: `${DEPARTMENTS}?college_id=${college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        setDepartmentOpt(res.data.data);
        // console.log("emp details - ", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployeeandDepartments();
  }, []);

  ////////////to set default value as first option////////////
  // Function to get filtered departments based on role
  // const getFilteredDepartments = () => {
  //   if (role === "SUPERADMIN" || role === "ADMIN") {
  //     return departmentOpt;
  //   } else {
  //     const multiDeptIds =
  //       empDetails?.multi_dept_id && empDetails?.multi_dept_id !== "[undefined]"
  //         ? JSON.parse(empDetails.multi_dept_id)
  //         : [];
  //     return departmentOpt?.filter((d) => multiDeptIds.includes(d.id));
  //   }
  // };

  const getFilteredDepartments = () => {
    if (role === "SUPERADMIN" || role === "ADMIN") {
      // Add "ALL" option for SUPERADMIN or ADMIN roles
      return [{ id: "ALL", name: "All", program_id: null }, ...departmentOpt];
    } else {
      const multiDeptIds =
        empDetails?.multi_dept_id && empDetails?.multi_dept_id != "[undefined]"
          ? JSON.parse(empDetails.multi_dept_id)
          : [];
      return departmentOpt?.filter((d) => multiDeptIds.includes(d.id));
    }
  };

  // Get filtered departments and default department ID
  const filteredDepartments = getFilteredDepartments();
  const defaultDepartmentId = filteredDepartments?.[0]?.id || null;

  // Set department to the first option if it's not already set
  useEffect(() => {
    if (!department && defaultDepartmentId) {
      setDepartment(defaultDepartmentId); // Set department to defaultDepartmentId
    }
  }, [defaultDepartmentId, department]);

  // // Generate options for react-select
  // const departmentOptions = filteredDepartments?.map((i) => ({
  //   value: i.id,
  //   label: `${i.name}, ${programOpt?.find((s) => s.id === i.program_id)?.name}`,
  // }));

  //ALL
  // Generate options for react-select
  const departmentOptions = filteredDepartments?.map((i) => ({
    value: i.id,
    label:
      i.id === "ALL"
        ? "All"
        : `${i.name}, ${
            programOpt?.find((s) => s.id === i.program_id)?.name || ""
          }`,
  }));

  // Find default department value
  const defaultDepartment = departmentOptions?.find(
    (option) => option.value === department
  );

  const [modalData, setModalData] = useState(null);

  const [flag, setFlag] = useState(false);

  const [jobRoles, setJobRoles] = useState(getJobRoles);

  const changeDir = (dir, key) => {
    navigate(`${dir}/${key}`);
  };

  const getEmpProgress = async () => {
    console.log("hi");
    setLoading(1);
    const config = {
      method: "get",
      url: `${EMPLOYEE_PROGRESS}?college_id=${collegeId}&status=${status}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then(async (res) => {
        console.log("progress - ", res.data.data);
        setProgressData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = async () => {
    setLoading(1);
    console.log("hi1");
    const config = {
      method: "get",
      // url: `${EMPLOYEE_SQL}?college_id=${collegeId}&status=${status}`,
      url: `${EMPLOYEE_SQL_DATA}?college_id=${collegeId}&department_id=${department}&status=${status}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        setEmployee(res.data.data);
        setFlag((flag) => !flag);
        // console.log("emp data - ", res.data.data);
        setEmployeeData(res.data.data);
        setDisplayData(res.data.data);
        setCredDisplayData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        // toast.error(err.response.data.message);
        console.log(err);
      });
    console.log("hi2");
    const config2 = {
      method: "get",
      url: `${GET_ALL_USERS}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    setLoading(1);
    await axios(config2)
      .then((res) => {
        setLoading(0);
        setUsers(res.data.data);
        // console.log("users - ", res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
    console.log("hi3");
  };

  // Get the current date in YYYY-MM-DD format
  const currentDate = new Date().toISOString().split("T")[0];

  const deleteEmployee = (reason, date) => {
    console.log(modalData);
    var url;
    var headers;
    if (modalData.status == "ACTIVE") {
      url =
        EMPLOYEE_DELETE +
        `/${modalData.id}` +
        `?status=INACTIVE&reason=${encodeURIComponent(
          reason
        )}&date=${encodeURIComponent(date)}`;
      headers = {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      };
    } else if (modalData.status == "INACTIVE") {
      url = EMPLOYEE_DELETE + `/${modalData.id}` + `?status=ACTIVE`;
      headers = {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      };
    }
    // console.log("reason - ", reason);
    // console.log("date - ", date);
    setLoading(1);
    Http.post(url)
      .then((res) => {
        setLoading(0);
        setFlag((flag) => !flag);
        toast.success("Employee Deleted Successfully");
        getData();
        getEmpProgress();
        Swal.fire("Deleted!", "Employee has been deleted", "success");
      })
      .catch((err) => {
        setLoading(0);
        toast.error(
          err.response.data.message || "Can't able to delete Employee"
        );
        console.log(err);
      });
    setLoading(0);
  };

  useEffect(() => {
    getData();
  }, [status, department]);

  useEffect(() => {
    getEmpProgress();
  }, [status]);

  useEffect(() => {
    if (category == "ALL") {
      setDisplayData(employeeData);
    } else {
      setLoading(1);
      let temp = users.filter((s) => s.employee_category == category);
      // console.log("temp - ", temp);
      let displayData2 = employeeData.filter((s) =>
        temp.some((user) => user.id === s.user_id)
      );
      setDisplayData(displayData2);
      setLoading(0);
    }
  }, [category]);

  // const selectRole = (id) => {
  //   if (id == "all") {
  //     return setEmployeeData(Employee);
  //   }

  //   setEmployeeData(Employee.filter((item) => item.role == id));
  // };

  const selectRole = (id) => {
    if (id === "all") {
      setEmployeeData(Employee);
      setDisplayData(Employee);
    } else {
      setEmployeeData(Employee.filter((item) => item.role === id));
      setDisplayData(Employee.filter((item) => item.role === id));
    }
  };

  const [selectedRole, setSelectedRole] = useState({
    value: "all",
    label: "ALL",
  });

  const tableRef = useRef();
  const excelRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: excelRef.current,
    filename: " Staff Details List ",
    sheet: "Users",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const [data, setData] = useState([]);
  const [placementsData, setPlacementsData] = useState([]);
  const [educationdata, setEducationData] = useState([]);
  const [termsdata, setTermsData] = useState([]);
  const [experiencedata, setExperienceData] = useState([]);
  const [docsdata, setDocsData] = useState([]);
  const [publicadata, setPublicaData] = useState([]);
  const [trainingdata, setTrainingData] = useState([]);
  const [allempData, setAllEmpData] = useState([]);
  const [localDepartment, setLocalDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  const getAllData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${EMPLOYEE}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        console.log(res);
        // toast.success("Data Success");
        setData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        // toast.error(err.response.data.message)
        setLoading(0);
      });

    const config2 = {
      method: "get",
      url: `${EMPLOYEE_PLACEMENT}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config2)
      .then((res) => {
        console.log(res);
        setPlacementsData(res.data.data);
        // toast.success("data fetched")
        setLoading(0);
      })
      .catch((err) => {
        // toast.error(err.response.data.message)
        setLoading(0);
      });

    const config3 = {
      method: "get",
      url: `${EMPLOYEE_EMPLOYEMENT_TERMS}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config3)
      .then((res) => {
        console.log(res);
        setTermsData(res.data.data);
        // toast.success("data fetched")
        setLoading(0);
      })
      .catch((err) => {
        // toast.error(err.response.data.message)
        setLoading(0);
      });

    const config4 = {
      method: "get",
      url: `${EMPLOYEE_EDUCATION}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config4)
      .then((res) => {
        setLoading(0);
        console.log(res);
        setEducationData(res.data.data);
        // toast.success("data fetched")
      })
      .catch((err) => {
        setLoading(0);
        // toast.error(err.response.data.message)
      });

    const config5 = {
      method: "get",
      url: `${EMPLOYEE_EXPERIENCE}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config5)
      .then((res) => {
        setLoading(0);
        console.log(res);
        setExperienceData(res.data.data);
        // toast.success("data fetched")
      })
      .catch((err) => {
        setLoading(0);
        // toast.error(err.response.data.message)
      });

    const config6 = {
      method: "get",
      url: `${EMPLOYEE_TRAINING}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config6)
      .then((res) => {
        setLoading(0);
        console.log(res);
        setTrainingData(res.data.data);
        // toast.success("data fetched")
      })
      .catch((err) => {
        setLoading(0);
        // toast.error(err.response.data.message)
      });

    const config7 = {
      method: "get",
      url: `${EMPLOYEE_LEGAL_DOCUMENTS}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config7)
      .then((res) => {
        setLoading(0);
        console.log(res);
        setDocsData(res.data.data);
        // toast.success("data fetched")
      })
      .catch((err) => {
        setLoading(0);
        // toast.error(err.response.data.message);
      });

    const config8 = {
      method: "get",
      url: `${EMPLOYEE_PUBLICATION}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config8)
      .then((res) => {
        fetchProgressData();
        setLoading(0);
        console.log(res);
        setPublicaData(res.data.data);
        // toast.success("data fetched")
      })
      .catch((err) => {
        setLoading(0);
        // toast.error(err.response.data.message)
      });

    const config12 = {
      method: "get",
      url: `${EMPLOYEE}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config12)
      .then((res) => {
        setAllEmpData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setLoading(0);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  const handlePrint = () => {
    tableRef.current.style.display = "table";

    setTimeout(() => {
      PrintRecipt();
      tableRef.current.style.display = "none";
    }, 10);
  };

  const [score, setScore] = useState(null);

  const fetchProgressData = async () => {
    try {
      const response = await axios.get(
        BASE_URL + "/api/employee/profile-progress/"
      );
      setScore(response.data.score);
    } catch (error) {
      console.error("Error fetching profile progress:", error);
    }
  };

  // useEffect(() => {
  //   fetchProgressData();
  // }, []);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  const totalEmployees = employeeData.length;
  const totalMales =
    employeeData?.filter((item) => item.gender?.toLowerCase() == "male")
      .length || 0;
  const totalFemales =
    employeeData?.filter((item) => item.gender?.toLowerCase() == "female")
      .length || 0;
  const totalOthers =
    employeeData?.filter((item) => item.gender?.toLowerCase() == "others")
      .length || 0;

  console.log("docsdata -", docsdata);
  console.log("displayData -", displayData);

  return (
    <div className="ViewStaff">
      <>
        <ModalResendCredentials
          empData={employeeData}
          displayData={credDisplayData}
          setDisplayData={setCredDisplayData}
          setLoading={setLoading}
        />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <form action="" method="POST">
                {/* start page title */}
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">View Staff </h4>
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            <a href="javascript: void(0);">Human Resource</a>
                          </li>
                          <li className="breadcrumb-item active">
                            {" "}
                            View Staff{" "}
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end page title */}
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title">Select Criteria</h2>
                        <br />
                        <div className="row">
                          <div className="col-md-4 d-none">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                Designation
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <Select
                                id="role"
                                name="role"
                                value={selectedRole}
                                options={[
                                  { value: "all", label: "ALL" },
                                  ...jobRoles.map((data) => ({
                                    value: data.id,
                                    label: data.name,
                                  })),
                                ]}
                                onChange={(selectedOption) => {
                                  setSelectedRole(selectedOption);
                                  selectRole(selectedOption.value); // Call selectRole with the value
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Department
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <Select
                                value={defaultDepartment || null}
                                onChange={(selectedOption) => {
                                  setDepartment(
                                    selectedOption ? selectedOption.value : null
                                  ); // Update department with selected value
                                }}
                                options={departmentOptions}
                                placeholder="Select Department"
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="">Category</label>
                              <select
                                className="form-control"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                <option value="ALL">All</option>
                                <option value="TEACHING STAFF">
                                  Teaching Staff
                                </option>
                                <option value="NON-TEACHING STAFF">
                                  Non Teaching Staff
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="">Group</label>
                              <select
                                className="form-control"
                                value={group}
                                onChange={(e) => setGroup(e.target.value)}
                              >
                                <option value="ALL">All</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="">Status</label>
                              <select
                                className="form-control"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="INACTIVE">INACTIVE</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end card */}
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          {/* <h2 className="card-title">Staff List</h2> */}
                          <h2 className="card-title">
                            Staff List (T - {totalEmployees}, M -{totalMales}, F
                            -{totalFemales}, O - {totalOthers}
                            {/* {Math.abs(
                              parseInt(totalEmployees) -
                                parseInt(totalMales) -
                                parseInt(totalFemales)
                            )} */}
                            )
                          </h2>
                        </div>
                        <div className="col-md-6">
                          <button
                            className="btn btn-nex float-right"
                            onClick={onDownload}
                          >
                            <i class="ri-file-excel-2-line mr-1"></i> Excel
                          </button>
                          <button
                            className="btn btn-nex mr-2 float-right"
                            onClick={handlePrint}
                          >
                            <i class="bi bi-file-earmark-pdf mr-1"></i> PDF
                          </button>
                          <button
                            className="btn btn-nex mr-2 float-right"
                            data-toggle="modal"
                            data-target="#ModalResendCredentials"
                            onClick={() => setDisplayData(employeeData)}
                          >
                            <i class="ri-mail-send-line mr-1"></i> Send
                            Credentials
                          </button>
                        </div>

                        <div className="col-md-6">
                          <input
                            type="text"
                            id="myFilter"
                            className="form-control"
                            placeholder="Search Employee with name"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row" id="myItems">
                        {/* {console.log(jobPositionOpt)} */}
                        {Object.entries(
                          displayData
                            ?.filter((post) => {
                              if (search === "") {
                                return post;
                              } else if (
                                (
                                  post.first_name.toLowerCase() +
                                  " " +
                                  post.last_name.toLowerCase()
                                ).includes(search.toLowerCase())
                              ) {
                                return post;
                              }
                            })
                            .filter((post) => {
                              if (group === "ALL") {
                                return post;
                              } else {
                                // Assuming the grouping attribute is 'group', change it to your actual attribute
                                // return post.group === group;
                                return (
                                  group ==
                                  jobPositionOpt?.find(
                                    (j) =>
                                      j.id ==
                                      users?.find((s) => s.id == post.user_id)
                                        ?.role
                                  )?.group
                                );
                              }
                            })
                            .reduce((acc, data) => {
                              const role = jobPositionOpt?.find(
                                (s) => s?.id === data?.role
                              )?.name;
                              if (!acc[role]) {
                                acc[role] = [];
                              }
                              acc[role].push(data);
                              return acc;
                            }, {})
                        ).length !== 0 ? (
                          Object.entries(
                            displayData
                              ?.filter((post) => {
                                if (search === "") {
                                  return post;
                                } else if (
                                  (
                                    post.first_name.toLowerCase() +
                                    " " +
                                    post.last_name.toLowerCase()
                                  ).includes(search.toLowerCase())
                                ) {
                                  return post;
                                }
                              })
                              .filter((post) => {
                                if (group === "ALL") {
                                  return post;
                                } else {
                                  // Assuming the grouping attribute is 'group', change it to your actual attribute
                                  // return post.group === group;
                                  return (
                                    group ==
                                    jobPositionOpt?.find(
                                      (j) =>
                                        j.id ==
                                        users?.find((s) => s.id == post.user_id)
                                          ?.role
                                    )?.group
                                  );
                                }
                              })
                              .reduce((acc, data) => {
                                const role = jobPositionOpt?.find(
                                  (s) => s?.id === data?.role
                                )?.name;
                                if (!acc[role]) {
                                  acc[role] = [];
                                }
                                acc[role].push(data);
                                return acc;
                              }, {})
                          ).map(([role, employees]) => (
                            <>
                              <div
                                className="shadow"
                                style={{
                                  width: "100%",
                                  backgroundColor: "#f5f5f5",
                                  padding: "10px",
                                  fontWeight: "600",
                                  margin: "15px 10px 15px 10px",
                                  borderRadius: "10px",
                                }}
                              >
                                {role.toUpperCase()}
                              </div>
                              {employees.map((data, key) => (
                                <div
                                  className="col-lg-6 col-xl-3 col-md-6 col-sm-12 crd"
                                  key={key}
                                >
                                  <div className="card card-default p-2">
                                    <a
                                      href=""
                                      className="media text-secondary"
                                      data-toggle="modal"
                                      data-target={`#modal-contact-open`}
                                      onClick={() => setModalData(data)}
                                    >
                                      <img
                                        src={`${
                                          data?.photo
                                            ? data?.photo
                                            : "../assets/images/user_default_img.png"
                                        }`}
                                        className="mr-3 img-fluid rounded-circle"
                                        alt="Avatar Image"
                                        width="30%"
                                        style={{ aspectRatio: "1/1" }}
                                      />
                                      <div className="media-body">
                                        <h5 className="mt-0 mb-2 text-dark">
                                          {data.first_name +
                                            " " +
                                            data.last_name}
                                        </h5>
                                        <ul className="list-unstyled text-smoke text-smoke">
                                          <li className="d-flex mb-1">
                                            <i className="mdi mdi-account mr-1 text-info" />
                                            <span
                                              style={{ fontSize: "0.7rem" }}
                                            >
                                              {
                                                jobPositionOpt?.find(
                                                  (s) => s?.id === data?.role
                                                )?.name
                                              }
                                            </span>
                                          </li>

                                          <li className="d-flex mb-1">
                                            <i class="ri-git-merge-line mr-1 text-success"></i>
                                            <span
                                              style={{ fontSize: "0.7rem" }}
                                              className="text-truncate mw-100 w-75"
                                            >
                                              {/* {
                                                local_departments.find(
                                                  (s) =>
                                                    s.id === data.department_id
                                                )?.name
                                              } */}
                                              {
                                                departmentOpt.find(
                                                  (s) =>
                                                    s.id === data.department_id
                                                )?.name
                                              }
                                            </span>
                                          </li>
                                          <li className="d-flex mb-1">
                                            <i class="ri-creative-commons-by-line mr-1 text-secondary"></i>
                                            <span
                                              style={{ fontSize: "0.7rem" }}
                                            >
                                              {
                                                users.find(
                                                  (s) => s.id === data.user_id
                                                )?.employee_category
                                              }
                                            </span>
                                          </li>
                                          <li className="d-flex mb-1">
                                            <i className="mdi mdi-phone mr-1 text-primary" />
                                            <span
                                              style={{ fontSize: "0.8rem" }}
                                            >
                                              {data.phone}
                                            </span>
                                          </li>
                                          <li className="d-flex mb-1 text-danger">
                                            <i class="ri-fingerprint-line mr-1"></i>
                                            <span
                                              style={{ fontSize: "0.8rem" }}
                                            >
                                              {data.biometric_id}
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                      <Progress
                                        className="mt-4"
                                        type="dashboard"
                                        // percent={data?.score}
                                        percent={
                                          progressData.find(
                                            (s) => s.id == data?.id
                                          )?.score
                                        }
                                        // size={"small"}
                                        size={40}
                                      />
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </>
                          ))
                        ) : (
                          <div className="col-12">
                            <Nodata titleTop={"No Employees Added"} />
                          </div>
                        )}

                        {/* MODAL START */}
                        {modalData ? (
                          <div
                            className="modal fade"
                            id={`modal-contact-open`}
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header justify-content-end border-bottom-0">
                                  {sessionStorage.getItem("role") != "HOD" ? (
                                    <button
                                      type="submit"
                                      className="btn-edit-icon btn-primary btn"
                                      onClick={() => {
                                        changeDir(
                                          ROUTES.HR.Employee.EditStaffRoute,
                                          modalData.id
                                        );
                                      }}
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <i className="mdi mdi-pencil" />
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                  &nbsp;&nbsp; &nbsp;
                                  <button
                                    type="button"
                                    className=" btn btn-danger btn-close-icon"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <i className="mdi mdi-close" />
                                  </button>
                                </div>
                                <div className="modal-body pt-0">
                                  <div className="row no-gutters">
                                    <div className="col-md-6">
                                      <div className="profile-content-left px-4">
                                        <div className="card text-center px-0 border-0">
                                          <div className="card-img mx-auto">
                                            <img
                                              className="rounded-circle"
                                              src={`${
                                                modalData?.photo
                                                  ? modalData?.photo
                                                  : "assets/images/user_default_img.png"
                                              }`}
                                              alt="user image"
                                              width="30%"
                                              style={{ aspectRatio: "1/1" }}
                                            />
                                          </div>
                                          <div className="card-body">
                                            <h4 className="py-2">
                                              {modalData?.first_name +
                                                " " +
                                                modalData?.last_name}
                                            </h4>
                                            <p>
                                              {
                                                jobPositionOpt?.find(
                                                  (s) => s?.id == modalData.role
                                                )?.name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                        {/* {sessionStorage.getItem("role") ==
                                        "SUPERADMIN" ? (
                                          <button
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="btn btn-outline-danger w-100"
                                            onClick={() => {
                                              Swal.fire({
                                                title: "Are you sure?",
                                                text: "You won't be able to revert this!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText:
                                                  modalData.status == "ACTIVE"
                                                    ? "Yes, delete it!"
                                                    : "Yes, Turn to Active !",
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  deleteEmployee();
                                                }
                                              });
                                            }}
                                          >
                                            {modalData?.status == "ACTIVE"
                                              ? "Delete Employee"
                                              : "Turn Employee Status to Active"}
                                          </button>
                                        ) : null} */}
                                        {sessionStorage.getItem("role") ==
                                          "SUPERADMIN" ||
                                        sessionStorage.getItem("role") ==
                                          "HR" ||
                                        sessionStorage.getItem("role") ==
                                          "ADMIN" ? (
                                          <button
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="btn btn-outline-danger w-100"
                                            onClick={() => {
                                              Swal.fire({
                                                title: "Are you sure?",
                                                text: "You won't be able to revert this!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText:
                                                  modalData.status == "ACTIVE"
                                                    ? "Yes, delete it!"
                                                    : "Yes, Turn to Active!",
                                                html:
                                                  modalData.status == "ACTIVE"
                                                    ? ` <select id="reason" class="swal2-select">
                                                      <option value="">Select a reason</option>
                                                      <option value="Retirement">Retirement</option>
                                                      <option value="Resigned">Resignation</option>
                                                      <option value="Removed">Removed</option>
                                                    </select>
                                                    <br />
                                                    <input type="date" id="date" class="swal2-input" placeholder="Select a date" value="${currentDate}">`
                                                    : ``,
                                                preConfirm: () => {
                                                  const selectElement =
                                                    document.getElementById(
                                                      "reason"
                                                    );
                                                  const selectedReason =
                                                    selectElement
                                                      ? selectElement.value
                                                      : "";
                                                  const dateElement =
                                                    document.getElementById(
                                                      "date"
                                                    );
                                                  const selectedDate =
                                                    dateElement
                                                      ? dateElement.value
                                                      : "";

                                                  if (
                                                    modalData.status ==
                                                      "ACTIVE" &&
                                                    !selectedReason
                                                  ) {
                                                    Swal.showValidationMessage(
                                                      "Please select a reason"
                                                    );
                                                  } else if (
                                                    modalData.status ==
                                                      "ACTIVE" &&
                                                    !selectedDate
                                                  ) {
                                                    Swal.showValidationMessage(
                                                      "Please select a date"
                                                    );
                                                  }

                                                  return modalData.status ==
                                                    "ACTIVE"
                                                    ? {
                                                        reason: selectedReason,
                                                        date: selectedDate,
                                                      }
                                                    : true;
                                                },
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  const { reason, date } =
                                                    result.value || {};
                                                  deleteEmployee(reason, date);
                                                }
                                              });
                                            }}
                                          >
                                            {modalData?.status == "ACTIVE"
                                              ? "Delete Employee"
                                              : "Turn Employee Status to Active"}
                                          </button>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="contact-info px-4">
                                        <h4 className="mb-1">Details</h4>
                                        <p className="text-dark font-weight-medium pt-4 mb-2">
                                          Department
                                        </p>
                                        <p>
                                          {
                                            local_departments.find(
                                              (s) =>
                                                s.id ===
                                                modalData?.department_id
                                            )?.name
                                          }
                                        </p>
                                        <p className="text-dark font-weight-medium pt-4 mb-2">
                                          Email address
                                        </p>
                                        <p>{modalData?.email}</p>
                                        <p className="text-dark font-weight-medium pt-4 mb-2">
                                          Phone Number
                                        </p>
                                        <p>{modalData?.phone}</p>
                                        <p className="text-dark font-weight-medium pt-4 mb-2">
                                          Date of Birth
                                        </p>
                                        <p>{modalData?.dob?.split("T")[0]}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {/* MODAL END */}
                      </div>
                    </div>
                  </div>
                  {/* end card */}
                </div>

                <div style={{ display: "none" }}>
                  <section ref={tableRef} className="">
                    <div className="col-sm-12">
                      {employeeData && employeeData?.length === 0 ? (
                        <tr>
                          <td colSpan={10}>
                            <Nodata />
                          </td>
                        </tr>
                      ) : (
                        employeeData
                          ?.filter((post) => {
                            if (search === "") {
                              return post;
                            } else if (
                              (
                                post.first_name.toLowerCase() +
                                " " +
                                post.last_name.toLowerCase()
                              ).includes(search.toLowerCase())
                            ) {
                              return post;
                            }
                          })
                          .map((data, key) => (
                            <table className="table staff-print">
                              <thead>
                                <tr>
                                  <td>
                                    <p className="text-dark ml-2 float-right">
                                      Printed On - {getCurrentDate()}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <div className="row">
                                      <div className="col-sm-12 text-center">
                                        <img
                                          className="profile-user-img img-responsive mx-auto"
                                          src="../../../assets/images/Nexenstial Logo.jpg"
                                          width="50%"
                                        />
                                        <h3 className="mt-5 mb-4 ml-4 text-primary text-center">
                                          {
                                            collegeOpt?.find(
                                              (s) => s.id === data?.college_id
                                            )?.name
                                          }
                                        </h3>
                                        <p className="ml-3 print-staff-details text-center mt-0">
                                          {
                                            departmentOpt?.find(
                                              (s) => s.id == data?.department_id
                                            )?.name
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr className="row">
                                  <td className="col-sm-8">
                                    <h4 className="text-center my-3 print-staff-details">
                                      PERSONAL DETAILS
                                    </h4>

                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Staff Name :</strong>{" "}
                                          <span className="ml-3">
                                            {data?.first_name +
                                              " " +
                                              data?.last_name}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Staff ID : </strong>
                                          <span className="ml-3">
                                            {data?.staff_id}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Phone Number: </strong>
                                          <span className="ml-3">
                                            {data?.phone}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Email : </strong>
                                          <span className="ml-3">
                                            {data?.email}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Date of Birth : </strong>
                                          <span className="ml-3">
                                            {data?.dob &&
                                              data?.dob.substring(0, 10)}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Gender : </strong>
                                          <span className="ml-3">
                                            {data?.gender}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Mother Name : </strong>
                                          <span className="ml-3">
                                            {data?.mother_name}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Father Name : </strong>
                                          <span className="ml-3">
                                            {data?.father_name}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Role : </strong>
                                          <span className="ml-3">
                                            {data?.role}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Employee Category : </strong>
                                          <span className="ml-3">
                                            {data?.employee_category}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Faculty : </strong>
                                          <span className="ml-3">
                                            {
                                              collegeOpt?.find(
                                                (s) => s.id === data?.college_id
                                              )?.name
                                            }
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Department : </strong>
                                          <span className="ml-3">
                                            {
                                              departmentOpt?.find(
                                                (s) =>
                                                  s.id == data?.department_id
                                              )?.name
                                            }
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Blood Group : </strong>
                                          <span className="ml-3">
                                            {data?.blood_grp}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Marital Status : </strong>
                                          <span className="ml-3">
                                            {data?.marital_status}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Qualification : </strong>
                                          <span className="ml-3">
                                            {data?.qualification}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Adhaar No : </strong>
                                          <span className="ml-3">
                                            {data?.aadhaar}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Nationality : </strong>
                                          <span className="ml-3">
                                            {data?.nationality}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>State : </strong>
                                          <span className="ml-3">
                                            {data?.state}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>District : </strong>{" "}
                                          <span className="ml-3">
                                            {data?.district}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>City : </strong>
                                          <span className="ml-3">
                                            {data?.city}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Religion : </strong>
                                          <span className="ml-3">
                                            {data?.religion}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Caste : </strong>
                                          <span className="ml-3">
                                            {data?.caste}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Category : </strong>
                                          <span className="ml-3">
                                            {data?.category}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Employee Type: </strong>
                                          <span className="ml-3">
                                            {data?.employee_type}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>
                                  </td>
                                  <td className="col-sm-4">
                                    <div className="image-container">
                                      <img
                                        src={
                                          data?.photo
                                            ? data?.photo
                                            : "../../../assets/images/reports/profile.png"
                                        }
                                        className="stafff-img"
                                      />
                                    </div>
                                    <br />
                                    <h3 className="text-center mr-4">
                                      {data?.first_name + " " + data?.last_name}
                                    </h3>
                                    <br />
                                    <div className="mb-3">
                                      <h5 style={{ marginLeft: "30px" }}>
                                        Profile Completion Progress
                                      </h5>
                                      <p
                                        style={{
                                          width: "260px",
                                          marginLeft: "30px",
                                        }}
                                      >
                                        {score !== null ? (
                                          <Progress percent={score} />
                                        ) : (
                                          <p>Loading...</p>
                                        )}
                                      </p>
                                    </div>
                                  </td>
                                  <td className="col-sm-12">
                                    <h4 className="text-center my-3 print-staff-details">
                                      CONTACT DETAILS
                                    </h4>
                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>Email</th>
                                          <th>Contact No</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{data?.email}</td>
                                          <td>{data?.phone}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <h4 className="text-center my-3 print-staff-details">
                                      ADDRESS DETAILS
                                    </h4>
                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>Current Address</th>
                                          <th>Permanent Address</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{data?.current_address}</td>
                                          <td>{data?.permanent_address}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <h4 className="text-center my-4 print-staff-details">
                                      EDUCATION DETAILS
                                    </h4>
                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>SL. NO</th>
                                          <th> Board/University</th>
                                          <th>Year</th>
                                          <th>Education Type</th>
                                          <th>Percentage/CGPA/Grade</th>
                                        </tr>
                                      </thead>
                                      {educationdata &&
                                        educationdata
                                          ?.filter(
                                            (education) =>
                                              education.employee_id === data.id
                                          )
                                          ?.map((education, index) => (
                                            <tbody>
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {education?.university_name}
                                                </td>
                                                <td>
                                                  {education?.from_year
                                                    ? education?.from_year +
                                                      " To "
                                                    : ""}
                                                  {education?.to_year}
                                                </td>
                                                <td>
                                                  {education?.education_type ===
                                                  "other"
                                                    ? education?.other_education_type
                                                    : education?.education_type}
                                                </td>
                                                <td>
                                                  {education?.performance_score
                                                    ? education?.performance_score
                                                    : "__"}
                                                </td>
                                              </tr>
                                            </tbody>
                                          ))}
                                    </table>
                                    <br />
                                    <br></br>{" "}
                                    <h4 className="text-center my-4 print-staff-details">
                                      EXPERIENCE DETAILS
                                    </h4>
                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>SL. NO</th>
                                          <th>Employer</th>
                                          <th>Job Title</th>
                                          <th>Year</th>
                                          <th>Salary</th>
                                          <th>Country / Region</th>
                                          <th>Remark</th>
                                        </tr>
                                      </thead>
                                      {experiencedata &&
                                        experiencedata
                                          ?.filter(
                                            (experience) =>
                                              experience.employee_id === data.id
                                          )
                                          ?.map((experience, index) => (
                                            <tbody>
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>{experience?.employer}</td>
                                                <td>{experience?.job_title}</td>
                                                <td>
                                                  {experience?.from &&
                                                    experience?.from.substring(
                                                      0,
                                                      10
                                                    )}{" "}
                                                  {"->"}{" "}
                                                  {experience?.to &&
                                                    experience?.to.substring(
                                                      0,
                                                      10
                                                    )}{" "}
                                                  ({experience.duration})
                                                </td>
                                                <td>
                                                  {experience?.salary
                                                    ? experience?.salary
                                                    : "__"}
                                                </td>
                                                <td>
                                                  {experience?.region
                                                    ? experience?.region
                                                    : "__"}
                                                </td>
                                                <td>
                                                  {experience?.remark
                                                    ? experience?.remark
                                                    : "__"}
                                                </td>
                                              </tr>
                                            </tbody>
                                          ))}
                                    </table>
                                    <br />
                                    <br />
                                    <h4 className="text-center my-4 print-staff-details">
                                      TRAINING DETAILS
                                    </h4>
                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>SL. NO</th>
                                          <th>Course</th>
                                          <th>Date</th>
                                          <th>Result</th>
                                          <th>Trainer Name</th>
                                          <th>
                                            Institute / University / Company
                                            Name
                                          </th>
                                          <th>Remark</th>
                                        </tr>
                                      </thead>
                                      {trainingdata &&
                                        trainingdata
                                          ?.filter(
                                            (training) =>
                                              training.employee_id === data.id
                                          )
                                          ?.map((training, index) => (
                                            <tbody>
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>{training?.course}</td>
                                                <td>
                                                  {training?.date &&
                                                    training?.date.substring(
                                                      0,
                                                      10
                                                    )}
                                                </td>
                                                <td>
                                                  {training?.result
                                                    ? training?.result
                                                    : "__"}
                                                </td>
                                                {/* <td>
                                                  {training?.trainer_id
                                                    ? training?.trainer_id
                                                    : "__"}
                                                </td> */}
                                                <td>
                                                  {training?.trainer_id !== 0
                                                    ? allempData?.find(
                                                        (s) =>
                                                          s.id ==
                                                          training?.trainer_id
                                                      )?.first_name +
                                                      " " +
                                                      allempData?.find(
                                                        (s) =>
                                                          s.id ==
                                                          training?.trainer_id
                                                      )?.last_name
                                                    : training?.trainer_name}
                                                </td>
                                                <td>
                                                  {training?.institute_name}
                                                </td>
                                                <td>
                                                  {training?.remark
                                                    ? training?.remark
                                                    : "__"}
                                                </td>
                                              </tr>
                                            </tbody>
                                          ))}
                                    </table>
                                    <br />
                                    <br />
                                    <h4 className="text-center my-4 print-staff-details">
                                      DOCUMENT DETAILS
                                    </h4>
                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>SL. NO</th>
                                          <th>Document</th>
                                          <th>Document Type</th>
                                          {/* <th>Valid From</th>
                                          <th>Valid To</th> */}
                                        </tr>
                                      </thead>
                                      {docsdata &&
                                        docsdata
                                          ?.filter(
                                            (document) =>
                                              document.employee_id === data.id
                                          )
                                          ?.map((document, index) => (
                                            <tbody>
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {document?.document_title}
                                                </td>
                                                <td>
                                                  {document?.document_type}
                                                </td>
                                                {/* <td>
                                                  {document?.valid_from &&
                                                  document?.valid_from.substring(
                                                    0,
                                                    10
                                                  )
                                                    ? "Validation"
                                                    : "Validation Upto"}
                                                </td>
                                                <td>
                                                  {document?.valid_from
                                                    ? document?.valid_from &&
                                                      document?.valid_from.substring(
                                                        0,
                                                        10
                                                      ) +
                                                        " -> " +
                                                        (document?.valid_to
                                                          ? document?.valid_to &&
                                                            document?.valid_to.substring(
                                                              0,
                                                              10
                                                            )
                                                          : "Not Available")
                                                    : "Not Available"}
                                                </td> */}
                                              </tr>
                                            </tbody>
                                          ))}
                                    </table>
                                    <h4 className="text-center my-4 print-staff-details">
                                      PUBLICATION DETAILS
                                    </h4>
                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>SL. NO</th>
                                          <th>Publication</th>
                                          <th>Date</th>
                                          <th>Index (Journal)</th>
                                          <th>Impact Factor</th>
                                          <th>Author</th>
                                          <th>Co-Author</th>
                                          <th>Attachment</th>
                                          <th>Attachment Link</th>
                                        </tr>
                                      </thead>
                                      {publicadata &&
                                        publicadata
                                          ?.filter(
                                            (publication) =>
                                              publication.employee_id ===
                                              data.id
                                          )
                                          ?.map((publication, index) => (
                                            <tbody>
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>{publication?.name}</td>
                                                <td>
                                                  {publication?.date &&
                                                    publication?.date.substring(
                                                      0,
                                                      10
                                                    )}
                                                </td>
                                                <td>{publication?.index}</td>
                                                <td>
                                                  {publication?.impact_factor}
                                                </td>
                                                <td>{publication?.author}</td>
                                                <td>
                                                  {publication?.co_author}
                                                </td>
                                                <td>
                                                  {publication?.attachment
                                                    ? "Attached"
                                                    : "Not Attached"}
                                                </td>
                                                <td>
                                                  {publication?.attachment_link ? (
                                                    <a
                                                      href={
                                                        publication?.attachment_link
                                                      }
                                                      target="_blank"
                                                    >
                                                      View
                                                    </a>
                                                  ) : (
                                                    "Not Added"
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          ))}
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ))
                      )}
                    </div>
                  </section>
                </div>
              </div>
              {/* view Modal */}
            </div>
          </div>

          <table
            id=""
            ref={excelRef}
            className="table table-bordered"
            style={{
              borderCollapse: "collapse",
              borderSpacing: 0,
              width: "100%",
              display: "none",
            }}
          >
            <div className="col-12 text-center">
              <div>
                <h4 className="text-center mb-5">
                  Staff Details List -
                  {
                    collegeOpt?.find(
                      (s) => s.id === employeeData[0]?.college_id
                    )?.name
                  }
                </h4>
                <span>
                  {" "}
                  <p className="text-right float-right mr-5">
                    {" "}
                    Printed On - {" " + getCurrentDate()}{" "}
                  </p>
                </span>
              </div>

              <thead>
                <tr>
                  <th>Sl.No.</th>
                  <th>Employee Name</th>
                  <th>Faculty</th>
                  <th>Department</th>
                  <th>Designation</th>
                  <th>Email</th>
                  <th>Employee Type</th>
                  <th>Employee Category</th>
                  <th>Mobile No</th>
                  <th>DOB</th>
                  <th>Gender</th>
                  <th>Marital Status</th>
                  <th>Current Address</th>
                  <th>Permanent Address</th>
                  <th>Adhaar No</th>
                  <th>Qualification</th>
                  <th>Work Experience</th>
                  <th>Date Of Joining</th>
                  <th>EPF Number</th>
                  <th>Work Shift</th>
                  <th>Nationality</th>
                  <th>Country</th>
                  <th>State</th>
                  <th>District</th>
                  <th>City</th>
                  <th>Passport No</th>
                  <th>Visa No</th>
                  <th>Visa Issue</th>
                  <th>Visa Expiry</th>
                  <th>Contract Type</th>
                  <th>Account Title</th>
                  <th>Account No</th>
                  <th>Bank Name</th>
                  <th>IFSC Code</th>
                  <th>Bank Branch</th>
                  <th>Basic Salary</th>
                  <th>Deduction</th>
                  <th>Profile Completion</th>
                </tr>
              </thead>
              <tbody>
                {employeeData && employeeData?.length == 0 ? (
                  <tr>
                    <td colSpan={10}>
                      <Nodata />
                    </td>
                  </tr>
                ) : (
                  employeeData
                    ?.filter((post) => {
                      if (search === "") {
                        return post;
                      } else if (
                        (
                          post.first_name.toLowerCase() +
                          " " +
                          post.last_name.toLowerCase()
                        ).includes(search.toLowerCase())
                      ) {
                        return post;
                      }
                    })
                    .filter((s) => s.id != 1)
                    .map((i, key) => {
                      return (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>
                            {i?.first_name} {i?.last_name}
                          </td>
                          <td>
                            {/* {i?.college_id} */}
                            {
                              collegeOpt?.find((s) => s.id === i?.college_id)
                                ?.name
                            }
                          </td>
                          <td>
                            {
                              departmentOpt?.find(
                                (s) => s.id == i?.department_id
                              )?.name
                            }
                          </td>
                          <td>{i?.role}</td>
                          <td>{i?.email}</td>
                          <td>{i?.employee_type}</td>
                          <td>
                            {
                              users?.find((s) => s.id == i?.id)
                                ?.employee_category
                            }
                          </td>
                          <td>{i?.phone}</td>
                          {/* <td>{i?.dob.split("T")[0]}</td> */}
                          <td>
                            {new Date(i?.dob).toLocaleDateString("en-US")}
                          </td>
                          {/* <td>{i?.dob}</td> */}
                          <td>{i?.gender}</td>
                          <td>{i?.marital_status}</td>
                          <td>{i?.current_address}</td>
                          <td>{i?.permanent_address}</td>
                          <td>{i?.aadhaar}</td>
                          <td>{i?.qualification}</td>
                          <td>{i?.work_experience}</td>

                          <td>
                            {new Date(i?.date_of_joining).toLocaleDateString(
                              "en-US"
                            )}
                          </td>

                          <td>{i?.epf_number}</td>
                          <td>{i?.work_shift}</td>
                          <td>{i?.nationality}</td>
                          <td>{i?.country}</td>
                          <td>{i?.state}</td>
                          <td>{i?.district}</td>
                          <td>{i?.city}</td>
                          <td>{i?.passport_no}</td>
                          <td>{i?.visa_no}</td>
                          <td>{i?.visa_issue}</td>
                          <td>{i?.visa_expiry}</td>
                          <td>{i?.contract_type}</td>
                          <td>{i?.account_title}</td>
                          <td>{i?.account_number}</td>
                          <td>{i?.bank_name}</td>
                          <td>{i?.ifsc_code}</td>
                          <td>{i?.bank_branch}</td>
                          <td>{i?.basic_salary}</td>
                          <td>{i?.deduction}</td>
                          {/* <th>{i?.score}%</th> */}
                          <th>
                            {progressData.find((s) => s.id == i?.id)?.score ||
                              0}{" "}
                            %
                          </th>
                        </tr>
                      );
                    })
                )}
              </tbody>
            </div>
          </table>
        </div>
        {/* End Page-content */}
      </>
    </div>
  );
}

export default ViewStaff;

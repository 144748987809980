import React, { useState, useRef, useEffect } from "react";
import ModalDepartments from "../../../../modals/HR/Employer/ModalDepartments";
import "./../Employer.scss";
import axios from "axios";
import { PROGRAM, COURSES } from "../../../../utils/apiConstants";
import { ALL_DATA } from "../../../../utils/LocalStorageConstants";
import Nodata from "../../../../Components/NoData/Nodata";
import { useDownloadExcel } from "react-export-table-to-excel";
import ModalDepartmentCourses from "../../../../modals/HR/Employer/ModalDepartmentCourses";

function DepartmentCourse({ setLoading }) {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [type, setType] = useState();
  const [edit, setEdit] = useState();
  const collegeData = JSON.parse(localStorage.getItem(ALL_DATA)).college;
  const allProgramData = JSON.parse(localStorage.getItem("ALL_DATA")).program;
  const [selectedId, setSelectedId] = useState();

  // console.log("college data -",collegeData);
  var emp_id = sessionStorage.getItem("employee_id");

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: COURSES,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        // console.log("getData", res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    const config1 = {
      method: "get",
      url: PROGRAM,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config1)
      .then((res) => {
        setLoading(0);
        // console.log("getData", res.data.data);
        setData1(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  const [departmentData, setDepartmentData] = useState([]);

  // const selectCollege = async (id) => {
  //   setSelectedId(id);
  //   setLoading(1);
  //   setDepartmentData(await data?.filter((item) => item?.college_id == id));
  //   setLoading(0);
  // };

  const selectCollege = async (id) => {
    setLoading(1);
    if (id === "all") {
      setDepartmentData(data);
    } else {
      setSelectedId(id);
      setDepartmentData(await data?.filter((item) => item?.college_id == id));
    }
    setLoading(0);
  };
  // console.log("data", data);
  // console.log("departmentData", departmentData);
  // useEffect(() => {
  //   // setLoading(1)
  //   setDepartmentData(data?.filter((item) => item?.college_id == selectedId));
  //   // setLoading(0)
  // }, [data]);

  useEffect(() => {
    setLoading(1);

    if (emp_id == 502) {
      setDepartmentData(data?.filter((item) => item?.college_id == 1111011));
    } else {
      if (selectedId === "all") {
        setDepartmentData(data);
      } else {
        setDepartmentData(
          data?.filter((item) => item?.college_id == selectedId)
        );
      }
    }
    setLoading(0);
  }, [data, selectedId]);

  // useEffect(() => {
  //   getData();
  // }, []);

  useEffect(() => {
    setLoading(1);
    // Set the selectedId initially to an empty string to represent "All"
    setSelectedId("all");
    getData();
  }, []);

  const tableRef = useRef();

  // console.log("tableRef -", tableRef);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: " DepartemntsList ",
    sheet: "Users",
  });

  return (
    <div className="Department">
      <ModalDepartmentCourses
        reloadData={getData}
        type={type}
        data={edit}
        setLoading={setLoading}
      />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0"> Departments</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Employer</li>
                      <li className="breadcrumb-item active">Departments</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {emp_id != 502 ? (
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <h2 className="card-title">Select Criteria</h2>
                      <br />
                      <div className="row d-flex ">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom01">
                              Faculty<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              id="role"
                              name="role"
                              className="form-control"
                              autoComplete="off"
                              onChange={(e) => selectCollege(e.target.value)}
                            >
                              <option value="all">All</option>
                              {collegeData.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end card */}
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="">
              <div className="card">
                <div className="card-body">
                  <div className="row ">
                    <div className="col-md-12 d-flex gap-2 justify-content-end">
                      <button
                        onClick={() => {
                          setType("add");
                          setEdit();
                        }}
                        className="btn btn-nex"
                        data-toggle="modal"
                        data-target="#ModalCourses"
                      >
                        Add +
                      </button>
                      <button className="btn btn-nex" onClick={onDownload}>
                        Export Excel
                      </button>
                    </div>
                  </div>
                  <span></span>
                  <br />
                  <br />
                  <br />

                  <table
                    id="table_id"
                    ref={tableRef}
                    className="display table table-bordered  nowrap table-hover "
                    style={{
                      borderCollapse: "collapse",
                      borderSpacing: 0,
                      width: "100%",
                      display: "none",
                    }}
                  >
                    <div>
                      <h4> Departments List </h4>
                      <thead>
                        <tr>
                          <th>Sl.No.</th>
                          <th> Department Name </th>
                          <th> Department id </th>
                          <th> Department Code </th>
                          <th> College </th>
                          <th> Program </th>
                          <th> Remark </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {departmentData && departmentData?.length == 0 ? (
                          <tr>
                            <td colSpan={10}>
                              <Nodata />
                            </td>
                          </tr>
                        ) : (
                          departmentData?.map((i, key) => {
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{i.name}</td>
                                <td>{i.id}</td>
                                <td>{i.department_id}</td>
                                <td>
                                  {
                                    collegeData?.find(
                                      (s) => s?.id == i?.college_id
                                    )?.name
                                  }
                                </td>
                                <td>
                                  {
                                    data1?.find((s) => s?.id == i?.program_id)
                                      ?.name
                                  }
                                </td>
                                <td>{i.description}</td>
                                <td></td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </div>
                  </table>

                  <div className="row">
                    {departmentData && departmentData?.length !== 0 ? (
                      departmentData?.map((i, key) => (
                        <div
                          className="col-md-3"
                          onClick={() => {
                            setType("edit");
                            setEdit(i);
                          }}
                          data-toggle="modal"
                          data-target="#ModalCourses"
                        >
                          <div className="card" style={{ cursor: "pointer" }}>
                            <div className="card-body">
                              <div className="col-11" key={key}>
                                <div className="role-title">
                                  {i.name}{" "}
                                  <span class="badge badge-info">
                                    id: {i.id}
                                  </span>{" "}
                                  <span>
                                    ({" "}
                                    {i?.program_id
                                      ? allProgramData.find(
                                          (item) => item.id === i?.program_id
                                        )?.name || ""
                                      : ""}
                                    )
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                {">"}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="mx-auto">
                        <Nodata
                          titleTop={"No data available for your search"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepartmentCourse;

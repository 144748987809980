import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Profile from "../Pages/NAAC/profile/Profile";

import BasicInformation from "../Pages/NAAC/profile/sub-pages/BasicInformation";
import ForCommunication from "../Pages/NAAC/profile/sub-pages/ForCommunicaton.jsx";
import StatusOfInstitution from "../Pages/NAAC/profile/sub-pages/StatusOfInstitution.jsx";
import TypeOfInstitution from "../Pages/NAAC/profile/sub-pages/TypeOfInstitution.jsx";
import MinorityRecognition from "../Pages/NAAC/profile/sub-pages/MinorityRecognition.jsx";
import SourceOfFunding from "../Pages/NAAC/profile/sub-pages/SourceOfFunding.jsx";
import DateOfEstablishmentAndAffiliationDetails from "../Pages/NAAC/profile/sub-pages/DateOfEstablishmentAndAffiliationDetails.jsx";
import DetailsOfUgcRecognition from "../Pages/NAAC/profile/sub-pages/DetailsOfUgcRecognition.jsx";
import DetailsOfRecognition from "../Pages/NAAC/profile/sub-pages/DetailsOfRecognition.jsx";
import AffiliatingUniversityActProvideForConfermentOfAutonomy from "../Pages/NAAC/profile/sub-pages/AffiliatingUniversityActProvideForConfermentOfAutonomy.jsx";
import PotentialForExcellenceRecognition from "../Pages/NAAC/profile/sub-pages/PotentialForExcellenceRecognition.jsx";
import LocationOfTheCampusAndArea from "../Pages/NAAC/profile/sub-pages/LocationOfTheCampusAndArea.jsx";
import ProgrammesOfferedByCollege from "../Pages/NAAC/profile/sub-pages/ProgrammesOfferedByCollege.jsx";
import SelfFinancedProgrammesOffered from "../Pages/NAAC/profile/sub-pages/SelfFinancedProgrammesOffered.jsx";
import ListOfDepartments from "../Pages/NAAC/profile/sub-pages/ListOfDepartments.jsx";
import PositionsInTheInstitution from "../Pages/NAAC/profile/sub-pages/PositionsInTheInstitution.jsx";
import QualificationsOfTheTeachingStaff from "../Pages/NAAC/profile/sub-pages/QualificationsOfTheTeachingStaff.jsx";
import NumberOfVisitingFaculty from "../Pages/NAAC/profile/sub-pages/NumberOfVisitingFaculty.jsx";
import FurnishTheNumberOfTheStudentsAdmittedToTheCollege from "../Pages/NAAC/profile/sub-pages/FurnishTheNumberOfTheStudentsAdmittedToTheCollege.jsx";
import DetailsOnStudentsEnrollment from "../Pages/NAAC/profile/sub-pages/DetailsOnStudentsEnrollment.jsx";
import UnitCost from "../Pages/NAAC/profile/sub-pages/UnitCost.jsx";
import DateOfAccreditation from "../Pages/NAAC/profile/sub-pages/DateOfAccreditation.jsx";
import DateOfEstablishmentOfInternalQualityAssurance from "../Pages/NAAC/profile/sub-pages/DateOfEstablishmentOfInternalQualityAssurance.jsx";
import DetailsRegardingSubmissionOfAnnualQualityAssuranceReports from "../Pages/NAAC/profile/sub-pages/DetailsRegardingSubmissionOfAnnualQualityAssuranceReports.jsx";
import AffiliatedInstitutionsToTheUniversity from "../Pages/NAAC/profile/sub-pages/AffiliatedInstitutionsToTheUniversity.jsx";
import FurnishTheDetails from "../Pages/NAAC/profile/sub-pages/FurnishTheDetails.jsx";
import TeachingFaculty from "../Pages/NAAC/profile/sub-pages/TeachingFaculty.jsx";
import NonTeachingStaff from "../Pages/NAAC/profile/sub-pages/NonTeachingStaff.jsx";
import TechnicalStaff from "../Pages/NAAC/profile/sub-pages/TechinicalStaff.jsx";
import PermanentTeachers from "../Pages/NAAC/profile/sub-pages/PermanentTeachers.jsx";
import TemporaryTeachers from "../Pages/NAAC/profile/sub-pages/TemporaryTeachers.jsx";
import PartTimeTeachers from "../Pages/NAAC/profile/sub-pages/PartTimeTeachers.jsx";
import DistinguishedAcademicians from "../Pages/NAAC/profile/sub-pages/DistinguishedAcademicians.jsx";
import ChairsInstituted from "../Pages/NAAC/profile/sub-pages/ChairsInstituted.jsx";
import StudentsInCurrentAcademicYear from "../Pages/NAAC/profile/sub-pages/StudentsInCurrentAcademicYear.jsx";
import IntegratedPrograms from "../Pages/NAAC/profile/sub-pages/IntegratedPrograms.jsx";
import HumanResourceDevelopment from "../Pages/NAAC/profile/sub-pages/HumanResourceDevelopment.jsx";
import EvaluativeReports from "../Pages/NAAC/profile/sub-pages/EvaluativeReports.jsx";
import NatureAndTypeOfUniversity from "../Pages/NAAC/profile/sub-pages/NatureAndTypeOfUniversity.jsx";
import { NAAC } from "../utils/apiConstants.js";
import axios from "axios";
import Loader from "../Components/Loader/Loader.jsx";

const ProfileRoutes = () => {
  const [updatedData, setUpdatedData] = useState("");
  const [loading, setLoading] = useState(0);
  const [data, setData] = useState(null);
  let roleName = sessionStorage.getItem("role");
  let role =
    roleName === "SUPERADMIN"
      ? "registarar"
      : roleName === "Pincipal" ||
        roleName === "Principal" ||
        roleName === "PRINC"
      ? "principal"
      : "employee";
  const getFormData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/get-all-status`,
    };
    await axios(config)
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        // toast.error("Something went wrong");
        console.log(err);
      });
  };
  useEffect(() => {
    getFormData();
  }, [updatedData]);
  return (
    <>
      <Loader loading={loading} />

      <Routes>
        <Route
          path="/"
          element={<Navigate to={`/${role}/naac/profile/basic-information`} />}
        />
        <Route
          path="basic-information"
          element={
            <Profile data={data}>
              <BasicInformation
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="for-communication"
          element={
            <Profile data={data}>
              <ForCommunication
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="status-of-institution"
          element={
            <Profile data={data}>
              <StatusOfInstitution
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="potential-for-excellence"
          element={
            <Profile data={data}>
              <PotentialForExcellenceRecognition
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="nature-and-type"
          element={
            <Profile data={data}>
              <NatureAndTypeOfUniversity
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="technical-staff"
          element={
            <Profile data={data}>
              <TechnicalStaff
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="minority-recognition"
          element={
            <Profile data={data}>
              <MinorityRecognition
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="source-of-funding"
          element={
            <Profile data={data}>
              <SourceOfFunding
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="date-of-establishment-and-affiliation-details"
          element={
            <Profile data={data}>
              <DateOfEstablishmentAndAffiliationDetails
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="affiliated-institution-to-the-university"
          element={
            <Profile data={data}>
              <AffiliatedInstitutionsToTheUniversity
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="details-of-ugc-recognition"
          element={
            <Profile data={data}>
              <DetailsOfUgcRecognition
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="details-of-recognition"
          element={
            <Profile data={data}>
              <DetailsOfRecognition
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="furnish-details"
          element={
            <Profile data={data}>
              <FurnishTheDetails
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        {/* <Route
        path="affiliating-university-act-provide-for-conferment-of-autonomy"
        element={
         <Profile data={data}>
            <AffiliatingUniversityActProvideForConfermentOfAutonomy />
          </Profile>
        }
      /> */}
        <Route
          path="teaching-faculty"
          element={
            <Profile data={data}>
              <TeachingFaculty
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="non-teaching-staff"
          element={
            <Profile data={data}>
              <NonTeachingStaff
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="location-of-the-campus-and-area"
          element={
            <Profile data={data}>
              <LocationOfTheCampusAndArea
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="permanent-teachers"
          element={
            <Profile data={data}>
              <PermanentTeachers
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="temporary-teachers"
          element={
            <Profile data={data}>
              <TemporaryTeachers
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="part-time-teachers"
          element={
            <Profile data={data}>
              <PartTimeTeachers
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="distinguished-academicians"
          element={
            <Profile data={data}>
              <DistinguishedAcademicians
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="chairs-instituted"
          element={
            <Profile data={data}>
              <ChairsInstituted
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="students-in-current-academic-year"
          element={
            <Profile data={data}>
              <StudentsInCurrentAcademicYear
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="integrated-programs"
          element={
            <Profile data={data}>
              <IntegratedPrograms
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="details-on-students-enrollment"
          element={
            <Profile data={data}>
              <DetailsOnStudentsEnrollment
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="human-resource-development"
          element={
            <Profile data={data}>
              <HumanResourceDevelopment
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="evaluative-reports"
          element={
            <Profile data={data}>
              <EvaluativeReports
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="date-of-establishment-of-internal-quality-assurance"
          element={
            <Profile data={data}>
              <DateOfEstablishmentOfInternalQualityAssurance
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
        <Route
          path="details-regarding-submission-of-annual-quality-assurance-reports"
          element={
            <Profile data={data}>
              <DetailsRegardingSubmissionOfAnnualQualityAssuranceReports
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </Profile>
          }
        />
      </Routes>
    </>
  );
};

export default ProfileRoutes;

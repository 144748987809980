import React, { useState } from "react";
import InputTag from "../../../../Components/InputTag.jsx";
import { toast } from "react-toastify";

export default function NatureAndTypeOfUniversity() {
  const [formData, setFormData] = useState({
    status_of_institution: "",
    status_of_university_institution_manual: null,
    type_of_university: "",
  });
  const handleOnchange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  console.log(formData);
  const handleSaveDraft = (e) => {
    e.preventDefault();
    const {
      status_of_institution,
      status_of_university_institution_manual,
      type_of_university,
    } = formData;
    if (
      !(status_of_institution || status_of_university_institution_manual) ||
      !type_of_university
    ) {
      toast.warning("Please fill in all fields");
    }
  };
  return (
    <>
      <span className="font-weight-bold">3. Nature & Type of University</span>
      <span className="text-white whitespace-nowrap w-28 text-center bg-gray-400 px-4 py-1 shadow-sm text-xs rounded-2xl">
        Not Started
      </span>

      <form>
        <div className="sm:col-span-6 space-y-2">
          <label
            htmlFor="status_of_institution"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Please select the status of Institution
          </label>
          <div className="flex  flex-column gap-5 mt-1 sm:col-span-2 ">
            <select
              id="status_of_institution"
              name="status_of_institution"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              defaultValue={"default"}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  status_of_institution: e.target.value,
                }));
              }}
            >
              <option disabled value="default">
                Select Option
              </option>

              <option value="Affiliated College">Affiliated College</option>
              <option value="Constituent College">Constituent College</option>
              <option value="Other">Other</option>
            </select>

            {formData.status_of_institution === "Other" && (
              <InputTag
                type="text"
                name="status_of_university_institution_manual"
                label="Other (Please Specify)"
                onChange={handleOnchange}
              />
            )}
          </div>
          <InputTag
            type="text"
            name="type_of_university"
            label="Type of University"
            onChange={handleOnchange}
          />
        </div>

        <div className="flex justify-start my-3 my-5  mb-3">
          <button onClick={handleSaveDraft} className="btn btn-primary">
            Save as Draft
          </button>
          <button className="btn btn-warning">
            <span>Submit for Review</span>
          </button>
        </div>
      </form>
    </>
  );
}

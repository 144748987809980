import { BASE_URL } from "./apiConstants";

// const BASE_URL = "http://localhost:4000";

export const INVENTORY_ITEM_SUPPLIER = `${BASE_URL}/api/inventory/item_supplier`;
export const INVENTORY_ITEM_STORE = `${BASE_URL}/api/inventory/item_store`;
export const INVENTORY_ITEM_CATEGORY = `${BASE_URL}/api/inventory/item_category`;
export const INVENTORY_ADD_ITEM = `${BASE_URL}/api/inventory/add_item`;
export const INVENTORY_ISSUE_ITEM = `${BASE_URL}/api/inventory/issue_item`;
export const INVENTORY_GET_ITEM = `${BASE_URL}/api/inventory/add_item_stock`;
export const INVENTORY_GET_ITEM_BYID = `${BASE_URL}/api/inventory/add_item_stock`;
export const INVENTORY_ISSUE_ITEM_BYID = `${BASE_URL}/api/inventory/issue_item`;
export const INVENTORY_PURCHASE_ORDER = `${BASE_URL}/api/inventory/purchase-order`;
export const GET_INVENTORY_ISSUE_ITEM_BY_ID = `${BASE_URL}/api/inventory/getIssueItemDataById`;
export const UPDATE_RETURN_DATA_INVENTORY_ITEMS = `${BASE_URL}/api/inventory/UpdateReturnDataItems`;

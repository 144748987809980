import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  GET_EMP_DEDUCTION_BY_ID,
  GET_EMP_EARNING_BY_ID,
  PAYROLL_EMP_DEDUCTION,
  PAYROLL_EMP_EARNING,
  PAYROLL_SALARY,
  UPDATE_EMPLOYEE_BASIC_SALARY,
  UPDATE_PAYROLL_EMP_DEDUCTION,
  UPDATE_PAYROLL_EMP_EARNING,
} from "../utils/apiConstants";
import "./basicSalary.scss";

function EditBasicSalaryModal({ editData, earningData, deductionData }) {
  const [loading, setLoading] = useState(0);
  const [userSalary, setUserSalary] = useState();
  const [empearning, setEmpEarning] = useState([]);
  const [addedRows, setAddedRows] = useState([]); // New state for dynamic rows
  // const [earning, setEarning] = useState();
  // const [type, setType] = useState("percentage");
  // const [amount, setAmount] = useState(0);
  // const [percentage, setPercentage] = useState(0);

  const [empDeduction, setEmpDeduction] = useState([]);
  const [addedDeductionRows, setAddedDeductionRows] = useState([]); // New state for dynamic rows
  // const [deduction, setDeduction] = useState();
  // const [typeDeduction, setTypeDeduction] = useState("percentage");
  // const [amountDeduction, setAmountDeduction] = useState(0);
  // const [percentageDeduction, setPercentageDeduction] = useState(0);

  //////////////////////Earnings/////////////////////

  const id = editData ? editData?.employee_id : "";

  useEffect(() => {
    getEmployeeSalary();
    getEmployeeEarning();
  }, [id]);

  const getEmployeeSalary = async () => {
    const config = {
      method: "get",
      url: `${PAYROLL_SALARY}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        let s = res.data.data[0];
        setUserSalary(s);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });
  };

  const getEmployeeEarning = async () => {
    const config = {
      method: "get",
      url: `${GET_EMP_EARNING_BY_ID}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        let s = res.data.data;
        setEmpEarning(s);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setUserSalary((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const addEarningRow = () => {
    setAddedRows([
      ...addedRows,
      { earning: "", type: "percentage", amount: 0, percentage: 0 },
    ]);
  };

  const removeEarningRow = (index) => {
    const updatedRows = addedRows.filter((_, rowIndex) => rowIndex !== index);
    setAddedRows(updatedRows);
  };

  const handleEarningChange = (index, key, value, isExisting = false) => {
    //////////////////////////////////////////
    if (isExisting) {
      const updatedEarnings = [...empearning];

      if (key === "type") {
        // Reset amount or percentage to 0 when type changes
        if (value === "percentage") {
          updatedEarnings[index].amount = 0; // Reset amount to 0
        } else if (value === "amount") {
          updatedEarnings[index].percentage = 0; // Reset percentage to 0
        }
      }

      updatedEarnings[index][key] = value;
      setEmpEarning(updatedEarnings);
    } else {
      const newRows = [...addedRows];

      if (key === "type") {
        // Reset amount or percentage to 0 when type changes
        if (value === "percentage") {
          newRows[index].amount = 0; // Reset amount to 0
        } else if (value === "amount") {
          newRows[index].percentage = 0; // Reset percentage to 0
        }
      }

      newRows[index][key] = value;
      setAddedRows(newRows);
    }
  };

  //////////////////////Deductions///////////////////

  useEffect(() => {
    getEmployeeDeduction();
  }, [id]);

  const getEmployeeDeduction = async () => {
    const config = {
      method: "get",
      url: `${GET_EMP_DEDUCTION_BY_ID}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        let s = res.data.data;
        setEmpDeduction(s);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });
  };

  const addDeductionRow = () => {
    setAddedDeductionRows([
      ...addedDeductionRows,
      { deduction: "", type: "percentage", amount: 0, percentage: 0 },
    ]);
  };

  const removeDeductionRow = (index) => {
    const updatedRows = addedDeductionRows.filter(
      (_, rowIndex) => rowIndex !== index
    );
    setAddedDeductionRows(updatedRows);
  };

  const handleDeductionChange = (index, key, value, isExisting = false) => {
    if (isExisting) {
      const updatedDeduction = [...empDeduction];

      if (key === "type") {
        // Reset amount or percentage to 0 when type changes
        if (value === "percentage") {
          updatedDeduction[index].amount = 0; // Reset amount to 0
        } else if (value === "amount") {
          updatedDeduction[index].percentage = 0; // Reset percentage to 0
        }
      }

      updatedDeduction[index][key] = value;
      setEmpDeduction(updatedDeduction);
    } else {
      const newRows = [...addedDeductionRows];

      if (key === "type") {
        // Reset amount or percentage to 0 when type changes
        if (value === "percentage") {
          newRows[index].amount = 0; // Reset amount to 0
        } else if (value === "amount") {
          newRows[index].percentage = 0; // Reset percentage to 0
        }
      }

      newRows[index][key] = value;
      setAddedDeductionRows(newRows);
    }
  };

  /////////////////////
  const editSalary = async () => {
    const userSalaryId = userSalary?.id || 0;

    const config = {
      method: "put",
      url: `${PAYROLL_SALARY}/${userSalaryId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...userSalary,
        employee_id: id,
        designation: editData?.designation,
      },
    };

    setLoading(1);

    try {
      await axios(config);
      toast.success("basic Salary Updated Successfully");
    } catch (error) {
      toast.error("Error while updating salary details");
      console.error("Error updating salary:", error);
    }

    //updating basic saalry in employee table also//

    const config5 = {
      method: "put",
      url: `${UPDATE_EMPLOYEE_BASIC_SALARY}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        basic_salary: userSalary?.basic_salary,
        pay_band: userSalary?.pay_band,
        grade: userSalary?.grade,
      },
    };

    setLoading(1);

    try {
      await axios(config5);
      // toast.success("Employee basic Salary Updated Successfully");
    } catch (error) {
      toast.error("Error while updating salary details");
      console.error("Error updating salary:", error);
    }

    //////////Earnings////////////

    // Handle updating or creating employee earnings
    for (const earningRow of empearning) {
      const emp_id = earningRow?.id || 0;
      const config2 = {
        method: "put",
        url: `${UPDATE_PAYROLL_EMP_EARNING}/${emp_id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        },
        data: {
          ...earningRow,
          employee_id: id,
          earning_id: earningRow.earning_id,
          type: earningRow.type,
          amount: earningRow.amount,
          percentage: earningRow.percentage,
          status: "ACTIVE",
        },
      };

      try {
        await axios(config2);
        // toast.success("Employee Earnings Updated");
      } catch (error) {
        toast.error("Error while updating employee earnings");
        console.error("Error updating earnings:", error);
      }
    }

    // Handle adding new rows
    for (const row of addedRows) {
      const config3 = {
        method: "post", // Assuming new rows are created with POST request
        url: `${PAYROLL_EMP_EARNING}`, // Adjust URL as needed
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        },
        data: {
          employee_id: id,
          earning_id: row.earning,
          type: row.type,
          amount: row.amount,
          percentage: row.percentage,
          status: "ACTIVE",
        },
      };

      try {
        await axios(config3);
        // toast.success("New Employee Earnings Added");
      } catch (error) {
        toast.error("Error while adding new employee earnings");
        console.error("Error adding earnings:", error);
      }
    }

    ///////////Deductions////////

    // Handle updating or creating employee earnings
    for (const deductionRow of empDeduction) {
      const emp_id = deductionRow?.id || 0;
      const config4 = {
        method: "put",
        url: `${UPDATE_PAYROLL_EMP_DEDUCTION}/${emp_id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        },
        data: {
          ...deductionRow,
          employee_id: id,
          deduction_id: deductionRow.deduction_id,
          type: deductionRow.type,
          amount: deductionRow.amount,
          percentage: deductionRow.percentage,
          status: "ACTIVE",
        },
      };

      try {
        await axios(config4);
        // toast.success("Employee Deductions Updated");
      } catch (error) {
        toast.error("Error while updating employee deductions");
        console.error("Error updating deductions:", error);
      }
    }

    // Handle adding new rows
    for (const row of addedDeductionRows) {
      const config5 = {
        method: "post", // Assuming new rows are created with POST request
        url: `${PAYROLL_EMP_DEDUCTION}`, // Adjust URL as needed
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        },
        data: {
          employee_id: id,
          deduction_id: row.deduction,
          type: row.type,
          amount: row.amount,
          percentage: row.percentage,
          status: "ACTIVE",
        },
      };

      try {
        await axios(config5);
        // toast.success("New Employee Deductions Added");
      } catch (error) {
        toast.error("Error while adding new employee deductions");
        console.error("Error adding deductions:", error);
      }
    }

    setLoading(0);

    // Simulate a button click on the BasicSalary page
    const searchButton = document.getElementById("searchButtonBasicSalary");
    if (searchButton) {
      searchButton.click();
    }
  };

  console.log("userSalary -", userSalary);
  console.log("empearning -", empearning);
  console.log("empDeduction -", empDeduction);

  return (
    <div
      className="modal fade"
      id="EditBasicSalaryModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              EDIT STAFF BASIC SALARY
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-body">
            <table className="table table-bordered table-sm mt-2">
              <tbody>
                <tr>
                  <td rowSpan={3} className="text-center" width="130">
                    <img
                      src={
                        editData?.photo
                          ? editData?.photo
                          : // : "../assets/images/reports/profile.png"
                            "../assets/images/user_default_img.png"
                      }
                      alt="Profile"
                      className="img-fluid rounded"
                    />
                  </td>
                  <td colSpan={3}>
                    <strong>Name:</strong> {editData?.employee_name}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <strong>Department:</strong> {editData?.department_name}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Designation:</strong>{" "}
                    {editData?.designation || editData?.role}
                  </td>
                  <td>
                    <strong>Employee Category:</strong>{" "}
                    {editData?.employee_category}
                  </td>
                </tr>
              </tbody>
            </table>

            <h5 className="d-flex justify-content-between mt-4">
              Basic salary
            </h5>
            <br />
            <div className="row mt-2">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="validationCustom01">Basic Salary</label>
                  <input
                    type="text"
                    className="form-control"
                    id="basic_salary"
                    name="basic_salary"
                    value={userSalary?.basic_salary || ""}
                    onChange={handleChange2}
                    placeholder="Enter basic salary"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="validationCustom01"> Pay Band</label>
                  <input
                    type="text"
                    className="form-control"
                    id="payBand"
                    name="pay_band"
                    value={userSalary?.pay_band || ""}
                    onChange={handleChange2}
                    placeholder="Enter Pay Band"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="validationCustom01"> Grade</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Grade"
                    name="grade"
                    value={userSalary?.grade}
                    onChange={handleChange2}
                    placeholder="Enter Grade"
                  />
                </div>
              </div>
            </div>

            {/* ///////////////Earnings///////////////// */}
            <h5 className="d-flex justify-content-between mt-4">
              Earnings
              <button
                className="btn btn-primary btn-sm"
                onClick={addEarningRow}
              >
                Add Earning +
              </button>
            </h5>
            <br />

            {/* Display Existing Employee Earnings */}
            {empearning.length > 0 && (
              <>
                {empearning.map((row, index) => (
                  <div className="row" key={index}>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Earning Title</label>
                        <select
                          className="form-control"
                          value={row.earning_id}
                          onChange={(e) => {
                            console.log("Index:", index); // Log the index
                            console.log(
                              "Existing earning value:",
                              row.earning_id
                            ); // Log the existing earning value
                            console.log("New earning value:", e.target.value); // Log the new value from select

                            handleEarningChange(
                              index,
                              "earning_id",
                              e.target.value,
                              true
                            );
                          }}
                        >
                          <option value="">Select</option>
                          {earningData?.map((i, key) => (
                            <option value={i.id} key={key}>
                              {i.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Type</label>
                        <select
                          className="form-control"
                          value={row.type}
                          onChange={(e) =>
                            handleEarningChange(
                              index,
                              "type",
                              e.target.value,
                              true
                            )
                          }
                        >
                          <option value="percentage">Percentage</option>
                          <option value="amount">Amount</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>
                          {row.type === "amount"
                            ? "Amount (₹)"
                            : "Percentage (%)"}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={
                            row.type === "amount" ? row.amount : row.percentage
                          }
                          onChange={(e) =>
                            handleEarningChange(
                              index,
                              row.type === "amount" ? "amount" : "percentage",
                              e.target.value,
                              true
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}

            {/* Display Newly Added Rows */}
            {addedRows.length > 0 && (
              <>
                {addedRows.map((row, index) => (
                  <div className="row" key={index}>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Earning Title</label>
                        <select
                          className="form-control"
                          value={row.earning}
                          onChange={(e) =>
                            handleEarningChange(
                              index,
                              "earning",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select</option>
                          {earningData?.map((i, key) => (
                            <option value={i.id} key={key}>
                              {i.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Type</label>
                        <select
                          className="form-control"
                          value={row.type}
                          onChange={(e) =>
                            handleEarningChange(index, "type", e.target.value)
                          }
                        >
                          <option value="percentage">Percentage</option>
                          <option value="amount">Amount</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>
                          {row.type === "amount"
                            ? "Amount (₹)"
                            : "Percentage (%)"}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={
                            row.type === "amount" ? row.amount : row.percentage
                          }
                          onChange={(e) =>
                            handleEarningChange(
                              index,
                              row.type === "amount" ? "amount" : "percentage",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => removeEarningRow(index)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ))}
              </>
            )}

            <br />
            <br />
            {/* //////////////////Deductions/////////////////// */}
            <h5 className="d-flex justify-content-between mt-2">
              Deductions
              <button
                className="btn btn-primary btn-sm"
                onClick={addDeductionRow}
              >
                Add Deduction +
              </button>
            </h5>
            <br />

            {/* Display Existing Employee Earnings */}
            {empDeduction.length > 0 && (
              <>
                {empDeduction?.map((row, index) => (
                  <div className="row" key={index}>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Deduction Title</label>
                        <select
                          className="form-control"
                          value={row.deduction_id}
                          onChange={(e) => {
                            console.log("Index:", index); // Log the index
                            console.log(
                              "Existing deduction value:",
                              row.deduction_id
                            ); // Log the existing deduction value
                            console.log("New deduction value:", e.target.value); // Log the new value from select

                            handleDeductionChange(
                              index,
                              "deduction_id",
                              e.target.value,
                              true
                            );
                          }}
                        >
                          <option value="">Select</option>
                          {deductionData?.map((i, key) => (
                            <option value={i.id} key={key}>
                              {i.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Type</label>
                        <select
                          className="form-control"
                          value={row.type}
                          onChange={(e) =>
                            handleDeductionChange(
                              index,
                              "type",
                              e.target.value,
                              true
                            )
                          }
                        >
                          <option value="percentage">Percentage</option>
                          <option value="amount">Amount</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>
                          {row.type === "amount"
                            ? "Amount (₹)"
                            : "Percentage (%)"}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={
                            row.type === "amount" ? row.amount : row.percentage
                          }
                          onChange={(e) =>
                            handleDeductionChange(
                              index,
                              row.type === "amount" ? "amount" : "percentage",
                              e.target.value,
                              true
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}

            {/* Display Newly Added Rows */}
            {addedDeductionRows.length > 0 && (
              <>
                {addedDeductionRows.map((row, index) => (
                  <div className="row" key={index}>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Deduction Title</label>
                        <select
                          className="form-control"
                          value={row.deduction}
                          onChange={(e) =>
                            handleDeductionChange(
                              index,
                              "deduction",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select</option>
                          {deductionData?.map((i, key) => (
                            <option value={i.id} key={key}>
                              {i.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Type</label>
                        <select
                          className="form-control"
                          value={row.type}
                          onChange={(e) =>
                            handleDeductionChange(index, "type", e.target.value)
                          }
                        >
                          <option value="percentage">Percentage</option>
                          <option value="amount">Amount</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>
                          {row.type === "amount"
                            ? "Amount (₹)"
                            : "Percentage (%)"}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={
                            row.type === "amount" ? row.amount : row.percentage
                          }
                          onChange={(e) =>
                            handleDeductionChange(
                              index,
                              row.type === "amount" ? "amount" : "percentage",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => removeDeductionRow(index)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ))}
              </>
            )}

            <div className="d-flex justify-content-end mt-3">
              <button
                className="btn btn-success"
                type="submit"
                data-dismiss="modal"
                aria-label="Close"
                onClick={editSalary}
              >
                Save Changes
              </button>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBasicSalaryModal;

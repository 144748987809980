import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ROUTES } from "../../../Router/routerConfig";
import { useNavigate } from "react-router-dom";
import { INVENTORY_ISSUE_ITEM } from "../../../utils/Inventory.apiConst";
import { SESSION_ROLE } from "../../../utils/sessionStorageContants";
import { EMPLOYEE } from "../../../utils/apiConstants";
import ModalIssueItems from "../../../modals/ModalIssueItems";

function IssueItems({ setLoading, collegeId }) {
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState([]);

  const navigate = useNavigate();

  const getdata = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_ISSUE_ITEM}?college_id=${collegeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        const filteredData = res.data.data?.filter(
          (s) => s?.fetchIssueItem.item_status == "Issued"
        );
        // setData(res.data.data);
        setData(filteredData);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  const handleDelete = (id) => {
    const config = {
      method: "put",
      url: `${INVENTORY_ISSUE_ITEM}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "INACTIVE",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        getdata();
        toast.success("Success");
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
        getdata();
        toast.error("Something Went Wrong");
      });
  };

  const move = () => {
    navigate(ROUTES.Registar.Inventory.IssueItem);
  };

  const move2 = () => {
    navigate(ROUTES.Registar.Inventory.ViewAllIssueItems);
  };

  useEffect(() => {
    getdata();
  }, []);

  console.log("data -", data);

  return (
    <div ClassName="IssueItem">
      <ModalIssueItems data={modalData} />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Issue Item List</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Inventory</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Issue Item List
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row ">
              <div className="col-md-12 ">
                <button
                  href="javascript:void(0)"
                  onClick={() => move()}
                  className="btn btn-primary btn-sm mb-2 float-right ml-2 mr-2"
                >
                  Issue Item
                </button>
                <button
                  href="javascript:void(0)"
                  onClick={() => move2()}
                  className="btn btn-success btn-sm mb-2 float-right mr-1"
                >
                  View All
                </button>
              </div>
            </div>
            {/* container-fluid */}
            <div className="row mt-1">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title">Issue Item Lists</h4>
                      </div>
                    </div>
                    <hr />
                    <table
                      id=""
                      className="table table-bordered dt-responsive nowrap table-hover "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th> Sl. No.</th>
                          <th>Item</th>
                          <th>Item Category</th>
                          <th>Issue Date</th>
                          {/* <th>Return Date</th> */}
                          <th>Issue To</th>
                          <th>Issue By</th>
                          <th>Quantity</th>
                          <th>Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data?.map((data, key) => {
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{data?.addItem.item_name}</td>
                                <td>
                                  {" "}
                                  {data?.itemCategory.item_category_name}
                                </td>
                                <td>
                                  {" "}
                                  {
                                    data?.fetchIssueItem.issue_date?.split(
                                      "T"
                                    )[0]
                                  }
                                </td>
                                {/* <td>
                                  {" "}
                                  {
                                    data?.fetchIssueItem.return_date?.split(
                                      "T"
                                    )[0]
                                  }
                                </td> */}

                                <td>
                                  {data?.issueTo?.first_name +
                                    " " +
                                    data?.issueTo?.last_name}
                                </td>

                                <td>
                                  {data?.issueBy?.first_name +
                                    " " +
                                    data?.issueBy?.last_name}
                                </td>
                                <td> {data?.fetchIssueItem.issue_quantity}</td>
                                <td>
                                  {" "}
                                  <span className="badge badge-soft-success">
                                    {" "}
                                    {data?.fetchIssueItem.item_status}
                                  </span>
                                </td>

                                <td className="text-center">
                                  <span
                                    className="badge badge-light text-danger"
                                    data-toggle="tooltip"
                                    title="Delete"
                                    onClick={() =>
                                      handleDelete(data?.fetchIssueItem?.id)
                                    }
                                  >
                                    {" "}
                                    <i
                                      class="fa fa-trash "
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span>
                                    <button
                                      className="btn btn-primary btn-sm ml-3"
                                      data-toggle="modal"
                                      data-target="#ModalIssueItems"
                                      onClick={() => {
                                        setModalData(data);
                                      }}
                                    >
                                      Return
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <br />
                  </div>
                </div>
              </div>{" "}
              {/* end col */}
            </div>{" "}
            {/* end row */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default IssueItems;

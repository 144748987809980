import React from "react";

//Components
import Stepper from "../../../Components/Stepper.jsx";
import StatusBadges from "../../../Components/StatusBadge.jsx";
import extended_profile_stepper_data from "./extended-profile-stepper-data";
import { IoMdSend } from "react-icons/io";

export default function ExtendedProfile({ children, data }) {
  return (
    <>
      <div className="shadow-xl p-3 border my-2 mb-20 bg-white ">
        <div className="d-flex flex-column gap-4">
          <div className="flex justify-content-between flex-column align-items-start  ">
            <h3>Extended Profile of the University</h3>
            <StatusBadges />
          </div>
          {/* <div className="flex justify-end gap-4 text-sm">
            <div className="flex justify-center my-3 items-center">
              <span className="font-weight-bold ">Status of the Application</span>
              <div className="bg-green-500 px-4 py-1 text-white rounded-full mr-2 shadow-md">
                Approved
              </div>
            </div>
          </div> */}

          <div className="flex justify-start md:justify-center">
            <Stepper data={extended_profile_stepper_data(data)} />
          </div>

          {children}
        </div>
      </div>
      <div className="shadow p-3 pb-4 border bg-white mt-4 mb-5">
        <p>Team discussion</p>
        <form className="d-flex gap-2 align-items-center">
          <div className="flex-grow-1">
            <input
              type="text"
              className="form-control border-bottom border-0 rounded-0"
              placeholder="Write your message here"
            />
            <hr />
          </div>
          <button type="submit" className="btn btn-primary text-white">
            <IoMdSend />
          </button>
        </form>
      </div>
    </>
  );
}

import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../../utils/LocalStorageConstants";
import { FEE_DETAILS, COLLECT_FEE } from "../../../utils/fees.apiConst";
import { feeJsonData } from "./../../../Data/jsonData/Fee/FeeJsonData";

const ModalAddFee = ({
  data,
  mainData,
  setLoading,
  reloadData,
  discount,
  collegeId,
}) => {
  console.log("data - ", data);
  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());
  const [addedAmount, setAddedAmount] = useState("");

  const [mode, setMode] = useState("CASH");

  let role = sessionStorage.getItem("role");

  const [note, setNote] = useState("");
  const [discountNote, setDiscountNote] = useState("");
  const [fineNote, setFineNote] = useState("");

  var curr = new Date();
  curr.setDate(curr.getDate());
  const [date, setDate] = useState(curr.toISOString().substring(0, 10));

  const [discountDet, setDiscountDet] = useState();

  const [addDiscount, setAddDiscount] = useState(0);

  const [addFine, setAddfine] = useState(0);

  const [user, setUser] = useState({
    date: new Date().toISOString,
    amount: "",
    paid_amount: "",
    fee_type_id: "",
    discount: 0,
    fine: 0,
  });

  const clearData = () => {
    setAddedAmount("");
    setNote("");
    setMode("CASH");
    setAddDiscount(0);
    setAddfine(0);
  };

  const handleSubmit = async () => {
    if (addedAmount < 0)
      return toast.error("Can't submit. Amount should not be negative");
    if (addDiscount < 0)
      return toast.error("Can't submit. Dsicount should not be negative");
    if (addFine < 0)
      return toast.error("Can't submit. Fine should not be negative");

    if (addDiscount > 0 && !discountNote)
      return toast.error("Discount note is compulsory");
    if (addFine > 0 && !fineNote) return toast.error("Fine note is compulsory");

    if (!date) {
      return toast.error("Please Select Date");
    }

    const config = {
      method: "post",
      url: `${COLLECT_FEE}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        student_semester_id: data?.student_semester_id,
        student_fee_details_id: data?.id,
        amount: addedAmount - addDiscount,
        type: mode,
        collected_by: sessionStorage.getItem("employee_id"),
        college_id: data?.college_id,
        note: note,
        discount: addDiscount,
        fine: addFine,
        discount_note: discountNote,
        fine_note: fineNote,
        date: date,
        college_code: collegeOpt.find((s) => s.id == data?.college_id)?.code,
      },
    };

    console.log("data to backend - ", {
      student_semester_id: data?.student_semester_id,
      student_fee_details_id: data?.id,
      amount: parseInt(addedAmount - addDiscount),
      type: mode,
      collected_by: await sessionStorage.getItem("employee_id"),
      college_id: data?.college_id,
      note: note,
      discount: addDiscount,
      fine: addFine,
      discount_note: discountNote,
      fine_note: fineNote,
      date: date,
      college_code: collegeOpt.find((s) => s.id == data?.college_id)?.code,
    });

    setLoading(1);
    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Fee Collected Successfully");
        reloadData();
        clearData();
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
        console.log(err);
      });
    setLoading(0);
  };

  const [maxAmount, setMaxAmount] = useState("");

  useEffect(() => {
    setUser((prev) => ({
      ...prev,
      amount: data?.amount,
      fee_type_id: data?.fee_type_id,
      paid_amount: data?.paid_amount,
    }));

    setAddedAmount(data?.amount - data?.paid_amount - data?.discount);
    setMaxAmount(data?.amount - data?.paid_amount - data?.discount);
  }, [data]);
  return (
    <div className="ModalAddFee">
      <div
        className="modal fade"
        id="addfee"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addfee"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addfee1">
                Collect Fee : {data?.fee_type_id}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">
                      {" "}
                      Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      name="feedate"
                      className="form-control"
                      value={date}
                      max={date}
                      readOnly={
                        sessionStorage.getItem("role") == "CASHIER"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="">
                    Payment Mode <span className="text-danger">*</span>
                  </label>{" "}
                  <br />
                  <select
                    name=""
                    id=""
                    className="form-control"
                    value={mode}
                    onChange={(e) => {
                      setMode(e.target.value);
                    }}
                  >
                    {feeJsonData?.map((i, key) => (
                      <option value={i?.id} key={key}>
                        {i?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-12">
                  <div className="form-group mt-3">
                    <label htmlFor="">
                      {" "}
                      Amount <span className="text-danger">*</span>
                    </label>
                    <input
                      // type="number"
                      name="amount"
                      className="form-control"
                      required=""
                      id="amount"
                      min={0}
                      max={maxAmount}
                      value={addedAmount}
                      onChange={(e) => {
                        if (e.target.value <= maxAmount)
                          setAddedAmount(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-1">
                  <div className="form-group">
                    <label htmlFor="">Note</label>
                    <textarea
                      cols={1}
                      rows={2}
                      className="form-control"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  {/* <label htmlFor="">Discount</label> <br />
                  <select
                    name=""
                    id=""
                    className="form-control"
                    value={discountDet}
                    onChange={(e) => {
                      setDiscountDet(e.target.value);
                    }}
                  >
                    <option value="">Select Discount</option>
                    {discount?.map((i, key) => (
                      <option value={i?.id} key={key}>
                        {i?.name} - ( ₹ {i.amount}){" "}
                      </option>
                    ))}
                  </select> */}
                  <div className="form-group">
                    <label htmlFor=""> Discount</label>
                    <input
                      // type="number"
                      name="amount"
                      className="form-control"
                      required=""
                      id="amount"
                      min={0}
                      max={maxAmount}
                      value={addDiscount}
                      disabled={parseInt(addedAmount) <= 0}
                      onChange={(e) => {
                        if (e.target.value <= maxAmount)
                          setAddDiscount(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="form-group">
                    <label htmlFor=""> Fine</label>
                    <input
                      // type="number"
                      name="amount"
                      className="form-control"
                      required=""
                      id="amount"
                      min={0}
                      max={maxAmount}
                      value={addFine}
                      onChange={(e) => {
                        setAddfine(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-1">
                  <div className="form-group">
                    <label htmlFor="">Discount Note</label>
                    <textarea
                      cols={1}
                      rows={2}
                      className="form-control"
                      value={discountNote}
                      onChange={(e) => setDiscountNote(e.target.value)}
                    />
                  </div>
                  {addDiscount > 0 ? (
                    <p className="text-danger">
                      Trust Grant note is compulsory.
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-6 mt-1">
                  <div className="form-group">
                    <label htmlFor="">Fine Note</label>
                    <textarea
                      cols={1}
                      rows={2}
                      className="form-control"
                      value={fineNote}
                      onChange={(e) => setFineNote(e.target.value)}
                    />
                  </div>
                  {addFine > 0 ? (
                    <p className="text-danger">Fine note is compulsory.</p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <input
                type="hidden"
                name="group_id"
                id="group_id"
                defaultValue={4}
              />
              <input
                type="hidden"
                name="master_id"
                id="master_id"
                defaultValue={1}
              />
              <button
                type="submit"
                className="btn btn-success"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleSubmit}
              >
                Collect Fee
              </button>
              {/*  <a href="javascript:void(0)"  target="_blank" type="submit" class="btn btn-success">Collect Fee & Print</a> */}
              <input type="hidden" name="id" defaultValue={1} />
              <input type="hidden" name="stdid" defaultValue={1} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddFee;

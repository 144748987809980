import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import { UPDATE_RETURN_DATA_INVENTORY_ITEMS } from "../utils/Inventory.apiConst";
import { COLLEGES, DEPARTMENTS } from "../utils/apiConstants";

function ModalIssueItems({ data }) {
  console.log("data -", data);
  const [loading, setLoading] = useState(0);
  const [returnData, setReturnData] = useState({
    return_by: "",
    return_note: "",
    return_date: new Date().toISOString().split("T")[0], // Set default to current date
  });

  const id = data ? data?.fetchIssueItem?.id : "";
  console.log("id -", id);

  const returnId = sessionStorage.getItem("employee_id");

  const [collegeOpt, setCollegeOpt] = useState([]);
  const [departmentOpt, setDepartmentOpt] = useState([]);

  const department = data?.issueBy?.department_id || "";

  const getCollegeData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: COLLEGES,
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        setCollegeOpt(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });

    const config2 = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: DEPARTMENTS,
    };

    setLoading(1);
    await axios(config2)
      .then((res) => {
        setLoading(0);
        setDepartmentOpt(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    getCollegeData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReturnData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  /////////////////////
  const updateData = async () => {
    const config = {
      method: "put",
      url: `${UPDATE_RETURN_DATA_INVENTORY_ITEMS}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        return_by: returnId,
        return_note: returnData?.return_note,
        return_date: returnData?.return_date,
        item_status: "Returned",
      },
    };

    setLoading(1);

    try {
      await axios(config);
      toast.success("Return Items Successfully");
    } catch (error) {
      toast.error("Error while updating Return Items data");
      console.error("Error updating salary:", error);
    }

    setLoading(0);
  };

  //   console.log("returnId -", returnId);
  //   console.log("returnData?.return_note -", returnData?.return_note);
  //   console.log("returnData?.return_date -", returnData?.return_date);
  // console.log("collegeOpt -", collegeOpt);
  console.log("departmentOpt -", departmentOpt);

  return (
    <div
      className="modal fade"
      id="ModalIssueItems"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              RETURN ITEM
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-body">
            <table className="table table-bordered table-sm mt-2">
              <tbody>
                <tr>
                  <td>
                    <strong>Item:</strong> {data?.addItem?.item_name || ""}
                  </td>
                  <td>
                    <strong>Item Category:</strong>{" "}
                    {data?.itemCategory?.item_category_name || ""}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Issue Date:</strong>{" "}
                    {data?.fetchIssueItem?.issue_date?.split("T")[0] || ""}
                  </td>
                  <td>
                    <strong>Issue To:</strong>{" "}
                    {data?.issueTo?.first_name +
                      " " +
                      data?.issueTo?.last_name || ""}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Issue By:</strong>{" "}
                    {data?.issueBy?.first_name +
                      " " +
                      data?.issueBy?.last_name || ""}
                  </td>
                  <td>
                    <strong>Quantity:</strong>{" "}
                    {data?.fetchIssueItem?.issue_quantity || ""}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Faculty:</strong>{" "}
                    {collegeOpt?.find(
                      (s) => s.id === data?.fetchIssueItem?.college_id
                    )?.name || ""}
                  </td>
                  <td>
                    <strong>Department:</strong>{" "}
                    {departmentOpt?.find((s) => s.id == department)?.name || ""}
                  </td>
                </tr>
              </tbody>
            </table>

            <br />
            <div className="row mt-2">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="validationCustom01">Return Note</label>
                  <input
                    type="text"
                    className="form-control"
                    id="return_note"
                    name="return_note"
                    value={returnData?.return_note || ""}
                    onChange={handleChange}
                    placeholder="Enter Return Note"
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="validationCustom01">Return Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="return_date"
                    name="return_date"
                    value={returnData?.return_date || ""}
                    onChange={handleChange}
                    placeholder="Select Return Date"
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-3">
              <button
                className="btn btn-success"
                type="submit"
                data-dismiss="modal"
                aria-label="Close"
                onClick={updateData}
              >
                RETURN
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalIssueItems;

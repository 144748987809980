import React, { useState } from "react";
import InputTag from "../../../../Components/InputTag.jsx";

export default function StatusOfInstitution() {
  const [other, setOther] = useState(false);

  return (
    <>
      <span className="font-weight-bold">3. Nature & Type of University</span>
      <span className="text-white whitespace-nowrap w-28 text-center bg-gray-400 px-4 py-1 shadow-sm text-xs rounded-2xl">
        Not Started
      </span>

      <form>
        <div className="sm:col-span-6 space-y-2">
          <label
            htmlFor="status-of-institution"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Please select the status of Institution
          </label>
          <div className="flex  flex-column gap-5 mt-1 sm:col-span-2 ">
            <select
              id="status-of-institution"
              name="status-of-institution"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              defaultValue={"default"}
              onChange={(e) => {
                e.target.value === "Other" ? setOther(true) : setOther(false);
              }}
            >
              <option disabled value="default">
                Select Option
              </option>

              <option value="Affiliated College">Affiliated College</option>
              <option value="Constituent College">Constituent College</option>
              <option value="Other">Other</option>
            </select>

            {other && (
              <InputTag
                type="text"
                name="status-of-institution-manual"
                label="Other (Please Specify)"
              />
            )}
          </div>
          <InputTag
            type="text"
            name="type-of-university"
            label="Type of University"
          />
        </div>

        <div className="flex justify-start my-3 my-5  mb-3">
          <button className="btn btn-primary">Save as Draft</button>
          <button className="btn btn-warning">
            <span>Submit for Review</span>
          </button>
        </div>
      </form>
    </>
  );
}

import React from "react";

export default function SelectTag(props) {
  return (
    <div className="form-group">
      {props.label && (
        <label htmlFor={props.name} className="form-label">
          {props.label}
        </label>
      )}

      <div className="mt-1 ">
        <select
          id={props.name}
          name={props.name}
          className="form-control"
          defaultValue={props.default ? props.default : "default"}
          {...props}
        >
          <option disabled value="default">
            Select Option
          </option>

          {props.data.map((item) => (
            <option key={item.id} value={item.value}>
              {item.value}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Upload,
  Table,
  Popconfirm,
  message,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import { getFileUrl } from "../../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../../utils/AssetsReferenceTypes";
import { toast } from "react-toastify";

const { Option } = Select;
const defaultDocOptions = [
  { value: "aadhar", label: "Aadhar" },
  { value: "profile", label: "Profile" },
  { value: "resume", label: "Resume" },
  { value: "other", label: "Other" },
];
const Documents = ({
  onFinish,
  initialValues,
  prev,
  next,
  docOptions,
  setLoading,
  setFormData,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [documentsData, setDocumentsData] = useState(
    initialValues.length > 0 ? initialValues : []
  );
  const [editingKey, setEditingKey] = useState(null);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const addAttachment = async (file) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `Resume`,
        file.name.split(".")[1],
        setLoading,
        file
      );
      setFileUrl(d);
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("File upload failed");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setIsOtherSelected(false);
    setEditingKey(null);
    setUploadedFile(null);
    setFileUrl(""); // Reset URL when modal is canceled
  };
  // const handleDocSubmit = () => {
  //   if (docOptions) {
  //     const presentTypes = documentsData.map((doc) => doc.documentType);
  //     const missingTypes = docOptions.filter(
  //       (type) => !presentTypes.includes(type)
  //     );
  //     const missingDoc = missingTypes.join(",");

  //     if (!(documentsData.length >= docOptions.length)) {
  //       toast.error(`please add ${missingDoc} documents`);
  //       return;
  //     }
  //   }
  //   handleSubmit();
  // };
  const handleDocNext = () => {
    if (docOptions) {
      const presentTypes = documentsData.map((doc) => doc.documentType);
      const missingTypes = docOptions.filter(
        (type) => !presentTypes.includes(type)
      );
      const missingDoc = missingTypes.join(",");

      if (!(documentsData.length >= docOptions.length)) {
        toast.error(`please add ${missingDoc} documents`);
        return;
      }
    }
    next();
  };
  // const handleFinish = (values) => {
  //   const newEntry = {
  //     key: editingKey !== null ? editingKey : uuidv4(),
  //     documentType: isOtherSelected
  //       ? values.otherDocumentType
  //       : values.documentType,
  //     fileName: uploadedFile ? uploadedFile.name : "No file uploaded",
  //     documentUpload: fileUrl,
  //     uploadDate: moment().format("MMM DD, YYYY"),
  //   };

  //   if (editingKey !== null) {
  //     const updatedData = documentsData.map((item) =>
  //       item.key === editingKey ? newEntry : item
  //     );
  //     setDocumentsData(updatedData);
  //     setEditingKey(null);
  //   } else {
  //     setDocumentsData([...documentsData, newEntry]);
  //   }

  //   setIsModalVisible(false);
  //   form.resetFields();
  //   setUploadedFile(null);
  //   setIsOtherSelected(false);

  //   onFinish(newEntry);
  // };
  const handleFinish = (values) => {
    const newEntry = {
      key: editingKey !== null ? editingKey : uuidv4(), // Use UUID for unique keys
      documentType: isOtherSelected
        ? values.otherDocumentType
        : values.documentType,
      fileName: uploadedFile ? uploadedFile.name : "No file uploaded",
      documentUpload: fileUrl,
      uploadDate: moment().format("MMM DD, YYYY"),
    };

    let updatedData;

    if (editingKey !== null) {
      updatedData = documentsData.map((item) =>
        item.key === editingKey ? newEntry : item
      );
      setEditingKey(null);
    } else {
      updatedData = [...documentsData, newEntry];
    }

    setDocumentsData(updatedData);
    setIsModalVisible(false);
    form.resetFields();
    setUploadedFile(null);
    setIsOtherSelected(false);

    // Pass the updated array back to the parent
    onFinish(updatedData);
  };
  const handleDelete = (key) => {
    const updatedData = documentsData.filter((item) => item.key !== key);
    setDocumentsData(updatedData);
    // onFinish(updatedData);
    setFormData((prev) => ({ ...prev, documents: updatedData }));
  };

  const onDocumentTypeChange = (value) => {
    setIsOtherSelected(value === "other");
  };

  const beforeUpload = (file) => {
    setUploadedFile(file);
    addAttachment(file); // Call the API to upload the file and get the URL
    return false; // Prevent automatic upload by the Upload component
  };

  const columns = [
    {
      title: "Document Type",
      dataIndex: "documentType",
      key: "documentType",
    },
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Uploaded On",
      dataIndex: "uploadDate",
      key: "uploadDate",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Popconfirm
          title="Are you sure you want to delete this document?"
          onConfirm={() => handleDelete(record.key)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" danger icon={<DeleteOutlined />}>
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-end">
        <button className="btn btn-primary" type="button" onClick={showModal}>
          Add Document
        </button>
      </div>

      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={documentsData}
          pagination={false}
          style={{ marginTop: 20 }}
        />
      </div>
      <div className="d-flex align-items-baseline justify-content-between gap-2 mt-10">
        <button className="btn btn-secondary" onClick={prev}>
          Previous
        </button>
        <button
          disabled={documentsData.length > 0 ? false : true}
          className="btn btn-success"
          onClick={handleDocNext}
          style={{
            cursor: documentsData.length > 0 ? "pointer" : "not-allowed",
          }}
        >
          next
        </button>
      </div>
      <Modal
        title="Upload Document"
        open={isModalVisible}
        onCancel={handleCancel}
        centered
        footer={null}
      >
        <Form
          scrollToFirstError={true}
          form={form}
          layout="vertical"
          onFinish={handleFinish}
        >
          <Form.Item
            label="Select Document Type"
            name="documentType"
            rules={[
              {
                required: !isOtherSelected,
                message: "Please select a document type",
              },
            ]}
          >
            <Select placeholder="Choose Option" onChange={onDocumentTypeChange}>
              {docOptions.length > 0
                ? docOptions.map((item) => (
                    <Option key={item} value={item}>
                      {item?.toUpperCase()}
                    </Option>
                  ))
                : defaultDocOptions.map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
              {/* <Option value="other">Other</Option> */}
            </Select>
          </Form.Item>

          {isOtherSelected && (
            <Form.Item
              label="Enter Document Type"
              name="otherDocumentType"
              rules={[
                { required: true, message: "Please input the document type" },
              ]}
            >
              <Input placeholder="Enter document type" />
            </Form.Item>
          )}
          <p className="text-danger">
            Note: Only PDF and JPEG/PNG files are allowed
          </p>
          <Form.Item
            label="Upload Document"
            name="documentUpload"
            rules={[{ required: true, message: "Please upload a document" }]}
          >
            <Upload
              accept=".pdf, .jpg, .jpeg, .png"
              beforeUpload={beforeUpload} // Handle the upload manually
            >
              <Button icon={<UploadOutlined />}>Choose File</Button>
            </Upload>
          </Form.Item>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Save Document
            </button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default Documents;

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Http } from "../../../../Services/Services";
import {
  LEAVE_ENTITLEMENT_REPORT,
  LEAVE_GET_ALL,
} from "../../../../utils/apiConstants";
import { toast } from "react-toastify";
import { LOCAL_COLLEGE } from "../../../../utils/LocalStorageConstants";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import getUniversityDetails from "../../../../utils/universityDetails.api";

const EntitlementDetaildReport = ({ collegeId, setLoading }) => {
  const [universityData, setUniversityData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUniversityDetails();
        setUniversityData(data);
      } catch (error) {
        console.error(`Error fetching university details: ${error.message}`);
      }
    };

    fetchData();
  }, []);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  const collegeList = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  const [data, setData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);

  const [allData, setAllData] = useState();

  const getAlldata = async () => {
    const config = {
      method: "get",
      url: `${LEAVE_GET_ALL}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };
    axios(config)
      .then((res) => {
        setAllData(res.data.leaveType);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getAlldata();
  }, []);

  const calculateDifference = (a1, a2) => {
    var Difference_In_Time = new Date(a2).getTime() - new Date(a1).getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 60 * 60 * 24);
    return Difference_In_Days;
  };

  const calculateMonthDifference = (a1, a2, type) => {
    const date1 = new Date(a1);
    const date2 = new Date(a2);

    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();

    const month1 = date1.getMonth();
    const month2 = date2.getMonth();

    const yearDifference = year2 - year1;
    const monthDifference = month2 - month1;

    let x = type
      ? yearDifference * 12 + monthDifference + 1
      : yearDifference * 12 + monthDifference;
    return x;
  };

  const getData = async () => {
    setLoading(true);
    let response = await Http.get(
      `${LEAVE_ENTITLEMENT_REPORT}?college_id=${collegeId}`
    ).catch((error) => {
      console.log(error);
      toast.error(error.message || "Error while fetching reports");
    });
    if (response) {
      const empSet = new Set();
      const leaveSet = new Set();
      for (const i of response.data.data) {
        if (i?.earning_policy == "DAILY") {
          var earning = (
            (calculateDifference(i?.from_date, new Date()) * 15) /
            365
          ).toFixed(2);

          if (earning < 0) {
            earning = 0;
          }
          // console.log(earning)
          i.earned = parseFloat(earning);
          i.balance = parseFloat((earning - i?.taken_days).toFixed(2));
          i.taken_days = parseFloat(i.taken_days);
        } else if (i?.earning_policy == "Starting of the month") {
          let earning = (
            (calculateMonthDifference(i?.from_date, new Date(), 1) *
              i?.entitled_days) /
            12
          ).toFixed(2);

          if (earning < 0) {
            earning = 0;
          }
          i.earned = parseFloat(earning);
          i.balance = parseFloat((earning - i.taken_days).toFixed(2));
          i.taken_days = i?.taken_days
            ? parseFloat(i.taken_days)
            : i.taken_days;
        } else if (i?.earning_policy == "End of the Month") {
          let earning = (
            (calculateMonthDifference(i?.from_date, new Date(), 0) *
              i?.entitled_days) /
            12
          ).toFixed(2);

          if (earning < 0) {
            earning = 0;
          }
          i.earned = earning;
          i.balance = (earning - i.taken_days).toFixed(2);
          i.taken_days = parseFloat(i.taken_days);
        }
        empSet.add(i.employee_id);
        leaveSet.add(i.leave_type);
      }

      setData(response.data.data);
      setEmployees(Array.from(empSet));
      console.log("emp - ", Array.from(empSet));
      console.log(Array.from(leaveSet));
      setLeaveTypes(Array.from(leaveSet));
    }
    console.log("data - ", response.data.data);
    setLoading(false);
  };

  const tableRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename:
      "Entitlement Detailed Report-" +
      new Date().getFullYear() +
      "-" +
      collegeList?.find((s) => s.id == collegeId)?.name,
    sheet: "Entitlement",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  //   const handlePrint = () => {
  //     PrintRecipt();
  //   };

  const handlePrint = () => {
    // Make the table visible
    tableRef.current.style.display = "table";

    // Delay the PDF export
    setTimeout(() => {
      PrintRecipt();
      // Hide the table again if needed
      tableRef.current.style.display = "none";
    }, 1); // Adjust the delay as needed
  };

  useEffect(() => {
    getData();
  }, []);

  console.log("allData -", allData);
  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h6 className="card-header">Entitlement Detailed Report</h6>
              </div>
            </div>
            <div className="mt-3 row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex mb-3">
                          <button
                            onClick={onDownload}
                            className="btn btn-success ml-auto"
                          >
                            Export Excel
                          </button>
                          <button
                            className="btn btn-primary ml-2"
                            onClick={handlePrint}
                          >
                            Export PDF
                          </button>
                        </div>
                        <div className="table-responsive fixTableHead">
                          <table className="table table-bordered ">
                            <thead className="">
                              <tr>
                                <th
                                  colSpan={Number(leaveTypes.length) * 3 + 17}
                                >
                                  {
                                    collegeList?.find((s) => s.id == collegeId)
                                      ?.name
                                  }
                                </th>
                              </tr>

                              <tr>
                                <th rowSpan={2}>Sl.Name</th>
                                <th rowSpan={2}>Employee Name</th>
                                <th rowSpan={2}>Designation</th>

                                {leaveTypes?.map((item, index) => {
                                  console.log("item -", item);
                                  return (
                                    <th
                                      colSpan={4}
                                      key={index}
                                      className="text-center"
                                    >
                                      {item}
                                      {/* {allData
                                        ? allData?.find((s) => s.id == item)
                                            ?.description
                                        : item} */}
                                    </th>
                                  );
                                })}
                                <th colSpan={4} className="text-center">
                                  Total
                                </th>
                              </tr>

                              <tr>
                                {leaveTypes?.map((item, index) => (
                                  <>
                                    <th>Entitled</th>
                                    <th>Earned</th>
                                    <th>Taken</th>
                                    <th>Balance</th>
                                  </>
                                ))}
                                <th>Entitled</th>
                                <th>Earned</th>
                                <th>Taken</th>
                                <th>Balance</th>
                              </tr>
                            </thead>
                            <tbody>
                              {employees?.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <th>
                                    {
                                      data?.find((s) => s.employee_id == item)
                                        ?.first_name
                                    }{" "}
                                    {
                                      data?.find((s) => s.employee_id == item)
                                        ?.last_name
                                    }
                                  </th>
                                  <td>
                                    {
                                      data?.find((s) => s.employee_id == item)
                                        ?.role
                                    }{" "}
                                  </td>
                                  {leaveTypes?.map((leave, index2) => (
                                    <>
                                      <td className="text-primary">
                                        {data
                                          ?.filter(
                                            (s) =>
                                              s.employee_id == item &&
                                              s.leave_type == leave
                                          )
                                          ?.reduce(
                                            (a, b) => a + b.entitled_days,
                                            0
                                          )
                                          ?.toFixed(2) || "0"}
                                      </td>
                                      <td className="text-primary">
                                        {data
                                          ?.filter(
                                            (s) =>
                                              s.employee_id == item &&
                                              s.leave_type == leave
                                          )
                                          ?.reduce((a, b) => a + b.earned, 0)
                                          ?.toFixed(2) || "0"}
                                      </td>
                                      <td className="text-danger">
                                        {data
                                          ?.filter(
                                            (s) =>
                                              s.employee_id == item &&
                                              s.leave_type == leave
                                          )
                                          ?.reduce(
                                            (a, b) => a + b.taken_days,
                                            0
                                          )
                                          ?.toFixed(2) || "0"}
                                      </td>
                                      <td className="text-success">
                                        {data
                                          ?.filter(
                                            (s) =>
                                              s.employee_id == item &&
                                              s.leave_type == leave
                                          )
                                          ?.reduce((a, b) => a + b.balance, 0)
                                          ?.toFixed(2) || "0"}
                                      </td>
                                    </>
                                  ))}
                                  <th className="text-primary">
                                    {data
                                      ?.filter((s) => s.employee_id == item)
                                      ?.reduce((a, b) => a + b.entitled_days, 0)
                                      ?.toFixed(2) || "0"}
                                  </th>
                                  <th className="text-primary">
                                    {data
                                      ?.filter((s) => s.employee_id == item)
                                      ?.reduce((a, b) => a + b.earned, 0)
                                      ?.toFixed(2) || "0"}
                                  </th>
                                  <th className="text-danger">
                                    {data
                                      ?.filter((s) => s.employee_id == item)
                                      ?.reduce((a, b) => a + b.taken_days, 0)
                                      ?.toFixed(2) || "0"}
                                  </th>
                                  <th className="text-success">
                                    {data
                                      ?.filter((s) => s.employee_id == item)
                                      ?.reduce((a, b) => a + b.balance, 0)
                                      ?.toFixed(2) || "0"}
                                  </th>
                                </tr>
                              ))}
                              <tr>
                                <th colSpan={3}>Total</th>
                                {leaveTypes?.map((leave, index2) => (
                                  <>
                                    <th>
                                      {data
                                        ?.filter((s) => s.leave_type == leave)
                                        ?.reduce(
                                          (a, b) => a + b.entitled_days,
                                          0
                                        )
                                        ?.toFixed(2) || "0"}
                                    </th>
                                    <th>
                                      {data
                                        ?.filter((s) => s.leave_type == leave)
                                        ?.reduce((a, b) => a + b.earned, 0)
                                        ?.toFixed(2) || "0"}
                                    </th>
                                    <th className="text-danger">
                                      {data
                                        ?.filter((s) => s.leave_type == leave)
                                        ?.reduce((a, b) => a + b.taken_days, 0)
                                        ?.toFixed(2) || "0"}
                                    </th>
                                    <th className="text-success">
                                      {data
                                        ?.filter((s) => s.leave_type == leave)
                                        ?.reduce((a, b) => a + b.balance, 0)
                                        ?.toFixed(2) || "0"}
                                    </th>
                                  </>
                                ))}
                                <th>
                                  {data
                                    ?.reduce((a, b) => a + b.entitled_days, 0)
                                    ?.toFixed(2) || "0"}
                                </th>
                                <th>
                                  {data
                                    ?.reduce((a, b) => a + b.earned, 0)
                                    ?.toFixed(2) || "0"}
                                </th>
                                <th className="text-danger">
                                  {data
                                    ?.reduce((a, b) => a + b.taken_days, 0)
                                    ?.toFixed(2) || "0"}
                                </th>
                                <th className="text-success">
                                  {data
                                    ?.reduce((a, b) => a + b.balance, 0)
                                    ?.toFixed(2) || "0"}
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <table
                ref={tableRef}
                className="table table-bordered"
                style={{ display: "none" }}
              >
                <thead>
                  <tr>
                    <th
                      colSpan={Number(leaveTypes.length) * 3 + 6}
                      className="text-center"
                    >
                      <p className="text-dark float-left">
                        Printed On - {getCurrentDate()}
                      </p>
                      <h3 className="mt-5 mb-4 text-primary">
                        <img
                          className="profile-user-img img-responsive float-left"
                          src={universityData.logo_primary}
                          width="250px"
                          height="70px"
                          // style={{ aspectRatio: "1/1" }}
                        />
                        <span className="print-staff-details">
                          {collegeList?.find((s) => s.id == collegeId)?.name}
                        </span>
                      </h3>
                    </th>
                    <th>
                      <h3
                        style={{
                          marginTop: "10px",
                          marginLeft: "20px",
                        }}
                      >
                        DETAILED ENTITLEMENT REPORT
                      </h3>
                    </th>
                  </tr>
                </thead>

                <tr className="mt-2">
                  <th rowSpan={2}>Sl.Name</th>
                  <th rowSpan={2}>Employee Name</th>
                  {leaveTypes?.map((item, index) => (
                    <th colSpan={4} key={index}>
                      {item}
                    </th>
                  ))}
                  <th colSpan={4} className="text-center">
                    Total
                  </th>
                </tr>
                <tr>
                  {leaveTypes?.map((item, index) => (
                    <>
                      <th>Entitled</th>
                      <th>Earned</th>
                      <th>Taken</th>
                      <th>Balance</th>
                    </>
                  ))}
                  <th>Entitled</th>
                  <th>Earned</th>
                  <th>Taken</th>
                  <th>Balance</th>
                </tr>
                {employees?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {data?.find((s) => s.employee_id == item)?.first_name}{" "}
                      {data?.find((s) => s.employee_id == item)?.last_name}
                    </td>
                    {leaveTypes?.map((leave, index2) => (
                      <>
                        <td>
                          {data
                            ?.filter(
                              (s) =>
                                s.employee_id == item && s.leave_type == leave
                            )
                            ?.reduce((a, b) => a + b.entitled_days, 0)
                            ?.toFixed(2) || "0"}
                        </td>
                        <td>
                          {data
                            ?.filter(
                              (s) =>
                                s.employee_id == item && s.leave_type == leave
                            )
                            ?.reduce((a, b) => a + b.earned, 0)
                            ?.toFixed(2) || "0"}
                        </td>
                        <td>
                          {data
                            ?.filter(
                              (s) =>
                                s.employee_id == item && s.leave_type == leave
                            )
                            ?.reduce((a, b) => a + b.taken_days, 0)
                            ?.toFixed(2) || "0"}
                        </td>
                        <td>
                          {data
                            ?.filter(
                              (s) =>
                                s.employee_id == item && s.leave_type == leave
                            )
                            ?.reduce((a, b) => a + b.balance, 0)
                            ?.toFixed(2) || "0"}
                        </td>
                      </>
                    ))}
                    <td>
                      {data
                        ?.filter((s) => s.employee_id == item)
                        ?.reduce((a, b) => a + b.entitled_days, 0)
                        ?.toFixed(2) || "0"}
                    </td>
                    <td>
                      {data
                        ?.filter((s) => s.employee_id == item)
                        ?.reduce((a, b) => a + b.earned, 0)
                        ?.toFixed(2) || "0"}
                    </td>
                    <td>
                      {data
                        ?.filter((s) => s.employee_id == item)
                        ?.reduce((a, b) => a + b.taken_days, 0)
                        ?.toFixed(2) || "0"}
                    </td>
                    <td>
                      {data
                        ?.filter((s) => s.employee_id == item)
                        ?.reduce((a, b) => a + b.balance, 0)
                        ?.toFixed(2) || "0"}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={2} className="text-center">
                    Total
                  </td>
                  {leaveTypes?.map((leave, index2) => (
                    <>
                      <td>
                        {data
                          ?.filter((s) => s.leave_type == leave)
                          ?.reduce((a, b) => a + b.entitled_days, 0)
                          ?.toFixed(2) || "0"}
                      </td>
                      <td>
                        {data
                          ?.filter((s) => s.leave_type == leave)
                          ?.reduce((a, b) => a + b.earned, 0)
                          ?.toFixed(2) || "0"}
                      </td>
                      <td>
                        {data
                          ?.filter((s) => s.leave_type == leave)
                          ?.reduce((a, b) => a + b.taken_days, 0)
                          ?.toFixed(2) || "0"}
                      </td>
                      <td>
                        {data
                          ?.filter((s) => s.leave_type == leave)
                          ?.reduce((a, b) => a + b.balance, 0)
                          ?.toFixed(2) || "0"}
                      </td>
                    </>
                  ))}
                  <td>
                    {data
                      ?.reduce((a, b) => a + b.entitled_days, 0)
                      ?.toFixed(2) || "0"}
                  </td>
                  <td>
                    {data?.reduce((a, b) => a + b.earned, 0)?.toFixed(2) || "0"}
                  </td>
                  <td>
                    {data?.reduce((a, b) => a + b.taken_days, 0)?.toFixed(2) ||
                      "0"}
                  </td>
                  <td>
                    {data?.reduce((a, b) => a + b.balance, 0)?.toFixed(2) ||
                      "0"}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntitlementDetaildReport;

import React, { useState, useContext, useMemo, useEffect } from "react";
import {
  HolderOutlined,
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Input,
  InputNumber,
  Button,
  Table,
  Select,
  Space,
  Radio,
  Upload,
} from "antd";

const RowContext = React.createContext({});
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const DraggableRow = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };

  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const DraggableTable = ({
  dataSource,
  setDataSource,
  tcsOrTds,
  setTcsOrTds,
  formData,
  setFormData,
}) => {
  const columns = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "Item Details",
      dataIndex: "itemDetails",
      render: (_, record, index) => (
        <Select
          className="w-100"
          showSearch
          placeholder="Select a person"
          optionFilterProp="label"
          onChange={(value) => {
            const updatedData = [...dataSource];
            updatedData[index]["itemDetails"] = value;
            setDataSource(updatedData);
          }}
          options={[
            {
              value: "jack",
              label: "Jack",
            },
            {
              value: "lucy",
              label: "Lucy",
            },
            {
              value: "tom",
              label: "Tom",
            },
          ]}
        />
      ),
    },
    {
      title: "Account",
      dataIndex: "account",
      render: (_, record, index) => (
        <Select
          className="w-100"
          showSearch
          placeholder="Select a person"
          optionFilterProp="label"
          onChange={(value) => {
            const updatedData = [...dataSource];
            updatedData[index]["account"] = value;
            setDataSource(updatedData);
          }}
          options={[
            {
              value: "TDS Receivable",
              label: "TDS Receivable",
            },
            {
              value: "Advance Tax",
              label: "Advance Tax",
            },
            {
              value: "Employee Advance",
              label: "Employee Advance",
            },
          ]}
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (_, record, index) => (
        <Input
          placeholder="Enter Quantity"
          type="number"
          value={record.quantity}
          min={1}
          onChange={(e) => handleInputChange(e, index, "quantity")}
        />
      ),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      render: (_, record, index) => (
        <Input
          placeholder="Enter Rate"
          type="number"
          value={record.rate}
          min={0}
          onChange={(e) => handleInputChange(e, index, "rate")}
        />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record, index) => (
        <Button
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteRow(index)}
          danger
        />
      ),
    },
  ];
  const handleOnChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };
  const handleInputChange = (e, index, field) => {
    const updatedData = [...dataSource];
    updatedData[index][field] = e.target.value;
    const quantity = parseFloat(updatedData[index].quantity) || 1;
    const rate = parseFloat(updatedData[index].rate) || 0;
    updatedData[index].amount = (quantity * rate).toFixed(2);
    setDataSource(updatedData);
  };

  const handleDeleteRow = (index) => {
    setDataSource((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddRow = () => {
    setDataSource((prev) => [
      ...prev,
      {
        key: Date.now().toString(),
        itemDetails: "",
        account: "",
        quantity: 1.0,
        rate: 0.0,
        amount: 0.0,
      },
    ]);
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.key === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.key === over?.id
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  let subTotal = dataSource?.reduce(
    (acc, curr) => acc + parseFloat(curr?.amount) || 0,
    0
  );
  useEffect(() => {
    setFormData((prev) => ({ ...prev, subTotal: subTotal }));
    let discountAmount = 0;
    if (formData?.percentageOrRs === "percentage") {
      discountAmount = (subTotal * formData?.discount) / 100;
    } else {
      discountAmount = formData?.discount;
    }
    const extractPercentage = (label) => {
      const match = label.match(/\[(\d+(\.\d+)?)%\]/);
      return match ? parseFloat(match[1]) : 0;
    };

    const taxPercentage = formData?.selectedTax
      ? extractPercentage(formData?.selectedTax)
      : 0;
    const taxAmount = (subTotal * taxPercentage) / 100;

    const discountedTotal = Math.max(
      subTotal - discountAmount - taxAmount + formData?.adjustmentAmount,
      0
    ); // Ensure total doesn't go negative
    setFormData((prev) => ({ ...prev, selectedTaxAmount: taxAmount }));
    setFormData((prev) => ({ ...prev, total: discountedTotal }));
  }, [
    subTotal,
    formData?.discount,
    formData?.percentageOrRs,
    tcsOrTds,
    formData?.selectedTax,
    formData?.adjustmentAmount,
  ]);
  const discountedAmount =
    formData?.percentageOrRs === "percentage"
      ? (subTotal * formData?.discount) / 100
      : formData?.discount;

  const handleAdjustmentChange = (value) => {
    setFormData((prev) => ({ ...prev, adjustmentAmount: value }));
  };

  const extractPercentage = (label) => {
    const match = label.match(/\[(\d+(\.\d+)?)%\]/);
    return match ? parseFloat(match[1]) : 0;
  };
  console.log("formData", formData);
  const taxPercentage = formData?.selectedTax
    ? extractPercentage(formData?.selectedTax)
    : 0;
  const taxAmount = (subTotal * taxPercentage) / 100;
  return (
    <div>
      {dataSource ? (
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={dataSource?.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              rowKey="key"
              rowClassName="editable-row"
              components={{
                body: {
                  row: DraggableRow,
                },
              }}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </SortableContext>
        </DndContext>
      ) : (
        ""
      )}
      <div className="row my-4 ">
        <div className="col-md-6 d-flex flex-column justify-content-between">
          <Button
            style={{ width: "fit-content" }}
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddRow}
          >
            Add Row
          </Button>
          <div className="form-group">
            <label className="form-label" htmlFor="customerNotes">
              Customer Notes
            </label>
            <textarea
              placeholder="Will be displayed on purchase order"
              className="form-control w-75"
              name="customerNotes"
              id="customerNotes"
              rows={3}
            ></textarea>
          </div>
        </div>

        <div className="col-md-6 p-3 card bg-light d-flex flex-column gap-2">
          <div className="d-flex justify-content-between">
            <p>
              <strong>Sub Total</strong>
            </p>
            <p>
              <strong>{subTotal ? subTotal.toFixed(2) : "0.00"}</strong>
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p>Discount</p>
            </div>
            <div className="col-md-4">
              <Space.Compact>
                <InputNumber
                  min={0}
                  className="w-100"
                  value={formData?.discount}
                  onChange={(value) => handleOnChange("discount", value || 0)}
                />
                <Select
                  value={formData?.percentageOrRs}
                  onChange={(value) => handleOnChange("percentageOrRs", value)}
                  options={[
                    { value: "percentage", label: "%" },
                    { value: "Rs", label: "Rs" },
                  ]}
                />
              </Space.Compact>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <p>-{(discountedAmount || 0).toFixed(2)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Radio.Group
                onChange={(e) => setTcsOrTds(e.target.value)}
                value={tcsOrTds}
              >
                <Radio value={"TDS"}>TDS</Radio>
                <Radio value={"TCS"}>TCS</Radio>
              </Radio.Group>
            </div>
            <div className="col-md-4">
              <Select
                className="w-100"
                placeholder="Select a Tax"
                value={formData?.selectedTax}
                onChange={(value) => {
                  handleOnChange("selectedTax", value);
                }}
                options={[
                  {
                    value: "Commission or Brokerage [2%]",
                    label: "Commission or Brokerage [2%]",
                  },
                  {
                    value: "Commission or Brokerage (Reduced) [3.75%]",
                    label: "Commission or Brokerage (Reduced) [3.75%]",
                  },
                  { value: "Dividend [10%]", label: "Dividend [10%]" },
                  {
                    value: "Dividend (Reduced) [7.5%]",
                    label: "Dividend (Reduced) [7.5%]",
                  },
                  {
                    value: "Other interest than securities [10%]",
                    label: "Other interest than securities [10%]",
                  },
                  {
                    value: "Other interest than securities (Reduced) [7.5%]",
                    label: "Other interest than securities (Reduced) [7.5%]",
                  },
                  {
                    value: "Payment of contractors for others [2%]",
                    label: "Payment of contractors for others [2%]",
                  },
                  {
                    value: "Payment of contractors for others (Reduced) [1.5%]",
                    label: "Payment of contractors for others (Reduced) [1.5%]",
                  },
                ]}
              />
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <p>- {`₹ ${(taxAmount || 0).toFixed(2)}`}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Input
                placeholder="Enter Quantity"
                defaultValue={"Adjustment"}
                type="text"
              />
            </div>
            <div className="col-md-4">
              <InputNumber
                className="w-100"
                value={formData?.adjustmentAmount}
                onChange={handleAdjustmentChange}
                min={0}
              />
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <p>+ {`₹ ${(formData?.adjustmentAmount || 0).toFixed(2)}`}</p>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <p>
              <strong>Total</strong>
            </p>
            <p>
              <strong>{(formData?.total || 0).toFixed(2)}</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="row bg-light p-3">
        <div className="col-md-6 border-1 border-right border-secondary ">
          <div className="form-group">
            <label className="form-label" htmlFor="termsAndConditions">
              Terms & Conditions
            </label>
            <textarea
              placeholder="Enter the terms and conditions of your business to be displayed in transaction"
              className="form-control"
              name="termsAndConditions"
              id="termsAndConditions"
              rows={3}
            ></textarea>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group d-flex flex-column">
            <label className="form-label" htmlFor="termsAndConditions">
              Attach File(s) to Purchase Order
            </label>
            <Upload>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
            <small className="mt-2">
              You can upload a maximum of 10 files, 10MB each
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DraggableTable;
